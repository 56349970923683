<template>
  <div class="centered-restricted-width text-center">
    <v-icon class="mb-6"
            color="primary"
            size="64">
      {{ icon }}
    </v-icon>
    <h5 class="mb-4 secondary--text text--darken-4 text-h5">{{ title }}</h5>
    <slot>
      <p class="mb-0 secondary--text text--darken-4">{{ text }}</p>
    </slot>
    <slot name="extension" />
  </div>
</template>

<script>
  export default {
    name: 'NotificationWithIcon',
    props: {
      icon: String,
      text: String,
      title: String
    }
  }
</script>