<template>
  <div>
    <invite-dialog ref="inviteDialog"
                   v-model="inviteDialog" />

    <image-header id="find"
                  variant="customer">
      <h3 class="text-h4 mb-3">Finden Sie <strong class="font-weight-black primary--text">den richtigen</strong></h3>
      <h5 v-bind:class="$vuetify.breakpoint.mdAndUp ? 'text-h3' : 'text-h4'"><span ref="typed"></span></h5>
    </image-header>

    <v-container>
      <v-row justify="center">
        <v-col cols="12"
               xl="8">
          <consultant-search-card v-bind:class="$vuetify.breakpoint.mdAndUp ? 'move-up' : null"
                                  v-bind:loading="loading"
                                  v-on:search="search" />
        </v-col>
      </v-row>
    </v-container>

    <div id="results">
      <v-expand-transition>
        <full-width-section color="transparent"
                            v-if="hasResults">
          <v-container>
            <v-row justify="center">
              <v-col cols="12"
                     xl="8">
                <consultant-list v-bind:consultants="searchResults"
                                 v-on:invite="showInviteDialog" />
              </v-col>
            </v-row>
          </v-container>
        </full-width-section>
      </v-expand-transition>
    </div>

    <!--<full-width-section color="transparent">
      <v-container>
        <v-row>
          <v-col align="center"
                 cols="12"
                 md="auto">
            <v-img contain
                   height="80"
                   src="/images/logo-AXA2.svg"
                   width="96" />
          </v-col>
          <v-spacer />
          <v-col align="center"
                 cols="12"
                 md="auto">
            <v-img contain
                   height="80"
                   src="/images/logo-Allianz.png"
                   width="196" />
          </v-col>
          <v-spacer />
          <v-col align="center"
                 cols="12"
                 md="auto">
            <v-img contain
                   height="80"
                   src="/images/logo-AXA2.svg"
                   width="96" />
          </v-col>
          <v-spacer />
          <v-col align="center"
                 cols="12"
                 md="auto">
            <v-img contain
                   height="80"
                   src="/images/logo-Allianz.png"
                   width="196" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>-->

    <full-width-section color="transparent">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 xl="8">
            <h4 class="mb-6 text-center text-h4 primary--text">{{ $t('heresHowYouBenefit') }}</h4>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="text-center"
                 cols="12"
                 md="3">
            <img alt="icon" class="icon" src="/images/icons/security.svg" />
            <h5 class="mb-3 text-h6 primary--text">{{ $t('aFeelingOfSecurity') }}</h5>
            <p>{{ $t('honestRecommendationsAndPpinionsFromManyOtherCustomers') }}</p>
          </v-col>
          <v-col class="text-center"
                 cols="12"
                 md="3">
            <img alt="icon" class="icon" src="/images/icons/success.svg" />
            <h5 class="mb-3 text-h6 primary--text">{{ $t('makeBetterDecisions') }}</h5>
            <p>{{ $t('thanksToMoreTransparencyAndTheBestAdvisorsAtYourSide') }}</p>
          </v-col>
          <v-col class="text-center"
                 cols="12"
                 md="3">
            <img alt="icon" class="icon" src="/images/icons/time.svg" />
            <h5 class="mb-3 text-h6 primary--text">{{ $t('significantlySaveTime') }}</h5>
            <p>{{ $t('findTheRightFinancialAdviceInTwoMinutes') }}</p>
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>

    <full-width-section color="secondary">
      <v-container>
        <v-row>
          <v-col cols="12"
                 lg="4"
                 xl="3"
                 offset-xl="2">
            <h4 class="mb-6 text-h4 primary--text">{{ $t('popularConsultants') }}</h4>
            <top-consultants-carousel v-bind:consultants="topConsultants" />
          </v-col>
          <v-col cols="12"
                 lg="8"
                 xl="6">
            <h4 class="mb-6 text-h4 primary--text">{{ $t('findYourPerfectConsultation') }}</h4>
            <highlight-list color="primary"
                            v-bind:items="highlights2" />
          </v-col>
        </v-row>
      </v-container>
      <div class="text-center">
        <v-btn color="primary"
               dark
               depressed
               x-large
               v-on:click="scrollTo('find')">
          <v-icon color="accent"
                  left>
            mdi-magnify
          </v-icon>
          <span>{{ $t('findConsultant') }}</span>
        </v-btn>
      </div>
    </full-width-section>

    <full-width-section color="transparent">
      <v-container>
        <v-row>
          <v-carousel height="auto"
                      hide-delimiters
                      v-model="adviceSlide">
            <v-carousel-item class="carousel-item"
                             v-bind:key="index"
                             v-for="(advice, index) in advices">
              <v-col cols="12"
                     offset-xl="2"
                     xl="8">
                <text-card v-bind:align-with-arrows="$vuetify.breakpoint.lgAndDown"
                           color="secondary"
                           icon="mdi-lightbulb-on-outline"
                           v-bind:subtitle="$t('ourAdvice')"
                           v-bind:text="advice.text"
                           v-bind:title="advice.title" />
              </v-col>
            </v-carousel-item>
          </v-carousel>
        </v-row>
      </v-container>
    </full-width-section>

    <!--<full-width-section color="transparent">
      <v-container>
        <v-row>
          <v-col cols="12"
                     offset-xl="2"
                     xl="8">

            <h4 class="primary--text text-h4">In 3 einfachen Schritten loslegen</h4>
              <v-row align="center" justify="center" class="mb-10"
                                v-for="(step, index) in steps"
                                v-bind:key="index">
              <v-col cols="auto">
                <v-avatar class="text-h4 white--text font-weight-bold"
                          color="primary"
                          size="96">
                  {{ step.number }}
                </v-avatar>
              </v-col>
              <v-col cols="auto">
                <v-icon class="mr-6"
                        color="accent"
                        size="56">
                  {{ step.icon }}
                </v-icon>
              </v-col>
              <v-col cols="5">
                <span class="text-h5 font-weight-bold">{{ step.text }}</span>
              </v-col>
              </v-row>

          </v-col>
        </v-row>
      </v-container>
    </full-width-section>-->

    <!--<full-width-section color="transparent">
      <v-container>
        <v-row>
          <v-col cols="12"
                     offset-xl="2"
                     xl="8">
            <v-card color="accent"
                    flat>
              <v-card-text class="pa-6">
                <v-row align="center">
                  <v-col>
                    <h4 class="text-h4 primary--text">Sie möchten Ihrem Berater oder Ihrer Beraterin Feedback geben?</h4>
                  </v-col>
                  <v-col class="text-center"
                         v-bind:cols="!$vuetify.breakpoint.mdAndUp ? 12 : 'auto'">
                    <v-btn color="primary"
                           depressed
                           light
                           v-on:click="scrollTo('find')"
                           x-large>
                      <v-icon color="accent"
                              left>
                        mdi-star
                      </v-icon>
                      <span>Jetzt bewerten</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>-->

    <full-width-section color="transparent"
                        id="faq">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 xl="8">
            <v-icon class="mb-2"
                    color="primary"
                    size="64">
              mdi-chat-outline
            </v-icon>
            <h4 class="mb-6 text-h4 primary--text">{{ $t('whatWeAreOftenAsked') }}</h4>
            <faq-display v-bind:faqs="faqs" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>

    <full-width-section color="secondary"
                        id="contact">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 xl="8">
            <h4 class="mb-6 text-h4 primary--text">{{ $t('contactUs') }}</h4>
            <contact-form v-bind:success-message="$t('weArePleasedThatYouHaveContactedUsWeWillRespondToYourMessageAsSoonAsPossible')"
                          v-on:send="sendEmail" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>



    <!--<v-container class="my-16">
      <h1 class="display-1 mb-10 secondary--text text--darken-3">Warum Confederia?</h1>
      <v-row>
        <v-col cols="12"
               md="4"
               v-bind:key="infoCard.id"
               v-for="infoCard in infoCards">
          <v-card flat outlined class="fill-height">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  {{ infoCard.title }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-avatar tile
                                  size="48">
                <v-img v-bind:src="`/images/icons/${infoCard.icon}.png`"></v-img>
              </v-list-item-avatar>
            </v-list-item>
            <v-card-text>
              {{ infoCard.subTitle }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="primary py-16">
      <v-container>
        <h1 class="display-1 pb-4 pt-2 secondary--text text--darken-3">Sie sind selbst Berater?</h1>
        <p class="white--text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis doloremque ipsum repellendus odit delectus officiis sint alias veniam voluptas accusamus. Et nulla velit commodi nisi tempora facere esse excepturi pariatur!</p>
        <v-btn class="mt-2"
               dark
               depressed
               large
               rounded
               color="secondary darken-2"
               v-bind:to="{ name: 'for-consultants' }">
          Preise &amp; Details
        </v-btn>
      </v-container>
    </div>-->





  </div>
</template>

<script>
  import Typed from 'typed.js'

  import { mapActions, mapGetters } from 'vuex'

  import mapHelpers from '@/mixins/v2/map-helpers'

  import ImageHeader from '@/components/v2/base/ImageHeader.vue'
  import ConsultantList from '@/components/v2/base/ConsultantList.vue'
  import ContactForm from '@/components/v2/base/ContactForm.vue'
  import FaqDisplay from '@/components/base/FaqDisplay.vue'
  import TopConsultantsCarousel from '@/components/v2/base/TopConsultantsCarousel.vue'
  import ConsultantSearchCard from '@/components/v2/base/ConsultantSearchCard.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import HighlightList from '@/components/v2/base/HighlightList.vue'
  import InviteDialog from '@/components/v2/base/InviteDialog.vue'
  import TextCard from '@/components/v2/base/TextCard.vue'

  export default {
    components: {
      ConsultantList,
      ContactForm,
      ImageHeader,
      FaqDisplay,
      TopConsultantsCarousel,
      ConsultantSearchCard,
      FullWidthSection,
      HighlightList,
      InviteDialog,
      TextCard
    },
    computed: {
      faqs() {
        return [
          {
            id: 1,
            question: this.$t('howCanConfederiaHelpYou'),
            text: this.$t('atConfederiaHelpsYouByTheirExperienceReportsToFindAGoodAdvisorAndAvoidBadAdvisorsBySubmittingARatingToYourFinancialAdvisorYouInTurnHelpOtherCustomersAdditionallyYouCanContributeWithAnHonestRatingToProvidersImprovingTheirServicesAndOfferingsOurUniqueSellingPointIsEvaluatingYourRatingBasedOnYourRatingWeProvideYouWithTipsAndHintsThatYouCanDiscussWithYourFinancialAdvisor')
          },
          {
            id: 2,
            question: this.$t('isConfederiaIndependentOfTheListedAdvisorsAndCompanies'),
            text: this.$t('thePlatformIs100PercentIndependentConfederiaDoesNotReceiveAnyAdvertisingRevenueOrOtherSponsorshipContributionsThePlatformDoesNotReceiveAnyCommissionOnTransactionsOrOtherSuccessBasedIncentives')
          },
          {
            id: 3,
            question: this.$t('isTheUseOfThePlatformFreeOfCharge'),
            text: this.$t('theUseOfThePlatformIsFreeForEndCustomersFinancialAdvisorsPayAFixedAmountToParticipateInConfederiaThePlatformDoesNotReceiveAnyCommissionOnTransactionsOrOtherSuccessBasedIncentives')
          },
          {
            id: 4,
            question: this.$t('whatShouldYouConsiderWhenRatingYourAdvisor'),
            text: this.$t('youCanRateAnyFinancialAdvisorThePrerequisiteIsThatYouHaveAnOngoingBusinessRelationshipWithTheAdvisorReviewsShouldBeHonestAndFairAndShouldNotContainAnyInsults')
          },
          {
            id: 5,
            question: this.$t('whatHappensToYourData'),
            text: this.$t('yourDataIsNotSharedWeDoNotSellAddressesOrSendYouNewslettersOrOtherInformationYourPrivacyIsOfTheUtmostImportanceToUsOurServersAreLocatedInSwitzerlandAndTheDataIsStronglyEncryptedYouHaveFullControlOverWithWhomYouChooseToShareYourContactInformationForMoreInformationPleaseReferToOurPrivacyPolicy')
          }
        ]
      },
      hasResults() {
        return this.searchResults != null
      },
      ...mapGetters({
        searchResults: 'local/searchResults',
        consultants: 'base/consultants',
        topConsultants: 'base/topConsultants'
      })
    },
    async created() {
      await this.loadConsultants()
      await this.loadTopConsultants()
      await this.loadConsultingTopics()
    },
    data: () => ({
      adviceSlide: 0,
      advices: [
        {
          text: 'Ein guter Finanzberater zeichnet sich vor allem durch aufrichtiges Interesse an Ihrer Lebenssituation und Ihren finanziellen Bedürfnissen aus. Er bringt Fachkenntnisse und Erfahrung ein und bietet Ihnen die passenden Lösungen für Ihr Anliegen. Ein guter Finanzberater begleitet Sie langfristig und unterstützt Sie bei der Erreichung Ihrer Ziele.',
          title: 'Was macht einen guten Finanzberater aus?'
        },
        {
          text: 'Feedback ist entscheidend für einen Finanzberater, um seine Beratung kontinuierlich verbessern zu können. Darüber hinaus fördert ein offener Dialog das Vertrauensverhältnis zwischen Berater und Kunden.',
          title: 'Warum ist Feedback wichtig?'
        },
        {
          text: 'Vor der Suche nach dem richtigen Berater sollten Sie Ihre finanziellen Ziele und Bedürfnisse klar definieren. Danach können Sie recherchieren und Empfehlungen einholen. Achten Sie dabei auf die Qualifikationen, Erfahrung und Spezialisierung. Es ist wichtig, jemanden zu finden, der Erfahrung in den Bereichen hat, die für Sie relevant sind.',
          title: 'Wie finde ich einen Berater, der zu mir passt?'
        }
      ],
      filteredConsultants: null,
      loading: false,
      // DEMO
      /*highlights: [
        'Im Beratungsgespräch entspannt zurücklehnen, weil Sie einen vertrauenswürdigen und qualifizierten Berater an Ihrer Seite haben, der Ihre Sprache spricht und Sie wirklich versteht.',
        'Viel Lehrgeld sparen, weil Sie durch unsere Checkliste wissen, wie ein für Sie optimales Beratungsgespräch abläuft.',
        'Ein Gefühl der Sicherheit, weil Sie sich Ihren Berater aufgrund der Empfehlungen vieler anderer Kunden aussuchen.',
        'Deutlich an Zeit sparen, weil Sie Ihren passenden Berater einfach und blitzschnell finden.',
        'Bessere Entscheidungen treffen, weil Sie mehr Transparenz und den besten Berater in Ihrer Seite wissen.'
      ],*/
      highlights2: [
        'Qualifizierte Finanzberater für alle Themen und Lebenslagen',
        'Authentische und verfizierte Bewertungen',
        'Komplett kostenfrei für Kunden',
        'Schnell und anonym bewerten',
        'Unabhängig und neutral – keine Sponsoren'
      ],
      inviteDialog: false,
      searchCoordinate: null,
      steps: [
        {
          number: '1',
          icon: 'mdi-magnify',
          text: 'Berater finden'
        },
        {
          number: '2',
          icon: 'mdi-calendar',
          text: 'Beratungstermin vereinbaren'
        },
        {
          number: '3',
          icon: 'mdi-star-outline',
          text: 'Bewertung abgeben'
        }
      ],
      infoCards: [
        {
          id: 1,
          title: 'Transparent',
          subTitle: 'Gezielten Fragen für eine objektive Bewertung',
          text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          icon: 'kostenlos'
        },
        {
          id: 2,
          title: 'Kostenlos',
          subTitle: 'Für Sie als Kunde ist die Nutzung ohne Kosten',
          text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          icon: 'kostenlos'
        },
        {
          id: 3,
          title: 'Integer',
          subTitle: 'Unsere Bewertungen stammen von echten Kunden',
          text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          icon: 'kostenlos'
        },
        {
          id: 4,
          title: 'Vielseitig',
          subTitle: 'Bei uns finden Sie für jedes Anliegen den Richtigen',
          text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          icon: 'kostenlos'
        },
        {
          id: 5,
          title: 'Unabhängig',
          subTitle: 'Wir bewerten nach Qualität und nach gleichen Maßstäben',
          text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          icon: 'kostenlos'
        },
        {
          id: 6,
          title: 'Swiss made',
          subTitle: 'Wir erfüllen die gehobenen Standards an Qualität',
          text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          icon: 'kostenlos'
        }
      ]
    }),
    methods: {
      // IMPRV: Refactor and move to backend
      async inviteConsultant(email) {
        let clientSideEmail = {
          recipientEmail: email.consultantEmail,
          recipientName: email.consultantName,
          templateName: 'DefaultCallToAction',
          values: {
            cc: 'info@confederia.ch',
            callToActionHeadline: 'Jetzt kostenlos testen',
            callToActionText: 'Registrieren Sie sich jetzt für einen kostenlosen Probemonat und überzeugen Sie sich selbst von den Vorteilen.',
            callToActionLabel: 'Jetzt registrieren',
            callToActionUrl: 'https://beta.confederia.ch/for-consultants',
            subject: `Sie wurden eingeladen, Confederia beizutreten`,
            headline: `Grüezi ${email.consultantName}!<br>${email.name} hat Sie zu Confederia eingeladen!`,
            text: `Hallo ${email.consultantName},<br><br>Ihr Kunde ${email.name} (${email.email}) möchte Sie auf unserer Plattform Confederia bewerten.<br><br>Confederia ist die Schweizer Plattform für die Bewertung und Suche von Top-Beratern im Finanz-, Verwaltungs- und Immobilienbereich.<br><br>Wir bieten Ihnen die Möglichkeit, Ihr Können und Ihre Qualifikationen in den Vordergrund zu stellen.`,
            closingText: 'Wir freuen uns auf Sie!<br><br><strong>Thomas Oster und Christian Sommerfeld</strong><br>Gründer von Confederia'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      scrollTo(name) {
        this.$vuetify.goTo(`#${name}`), {
        }
      },
      async search(searchOptions) {
        this.loading = true

        if (!!document.amplitude) {
          amplitude.track('Search');
        }
        



        if (searchOptions.postalCode != null) {
          this.searchCoordinate = await this.lookup({ address: searchOptions.postalCode + ' Schweiz' })
        }



        await this.loadConsultants()

        let filteredConsultants = this.consultants.filter(c => c.state !== 'disabled')

        if (searchOptions.type === 'canton') {
          filteredConsultants = filteredConsultants.filter(c => c.canton === searchOptions.canton)
        } else if (searchOptions.type === 'name') {
          filteredConsultants = filteredConsultants.filter(c => `${c.id} ${c.number} ${c.givenName} ${c.familyName} ${c.companyName}`.toLowerCase().includes(searchOptions.name.toLowerCase()))
        } else if (searchOptions.type === 'perimeter') {
          if (this.searchCoordinate != null) {
            filteredConsultants = filteredConsultants.map(c => ({
              distance: this.calculateDistance(this.searchCoordinate.latitude, this.searchCoordinate.longitude, c.latitude, c.longitude),
              ...c
            }))
          }

          filteredConsultants = filteredConsultants.filter(c => c.postalCode === searchOptions.postalCode || c.distance <= searchOptions.perimeter)

          if (searchOptions.consultingTopics != null && searchOptions.consultingTopics.length !== 0) {
            console.log(searchOptions.consultingTopics)
            filteredConsultants = filteredConsultants.filter(c => c.consultingTopicIds.some(c2 => searchOptions.consultingTopics.includes(c2)))
          }

          if (searchOptions.tags != null && searchOptions.tags.length !== 0) {
            filteredConsultants = filteredConsultants.filter(c => c.tags.some(c2 => searchOptions.tags.includes(c2)))
          }

          if (searchOptions.languages != null && searchOptions.languages.length !== 0) {
            filteredConsultants = filteredConsultants.filter(c => c.languages.some(c2 => searchOptions.languages.includes(c2)))
          }

          if (searchOptions.ratingCount != null) {
            filteredConsultants = filteredConsultants.filter(c => c.ratingCount >= searchOptions.ratingCount)
          }

        }


        this.loading = false

        // sort by rating and rating count
        this.filteredConsultants = filteredConsultants.sort((a, b) => {
          if (a.ratingAverage === b.ratingAverage) {
            return b.ratingCount - a.ratingCount
          }

          return b.ratingAverage - a.ratingAverage
        })

        this.setSearchResults({ searchResults: this.filteredConsultants })

        this.scrollTo('results')
      },
      async sendEmail(email) {
        // IMPRV: Refactor and move to backend
        let clientSideEmail = {
          recipientEmail: 'info@confederia.ch',
          recipientName: 'Confederia',
          templateName: 'DefaultText',
          values: {
            subject: `Anfrage von ${email.name}`,
            headline: `${email.name} hat eine Nachricht gesendet!`,
            text: `Hallo,<br><br>ein Interessent hat Ihnen über das Kontaktformular (Kundenseite) eine Frage zukommen lassen:<br><br>${email.text}<br><br>Name: ${email.name}<br>E-Mail-Adresse: ${email.email}`,
            closingText: 'Diese Nachricht wurde automatisch an Sie versandt. Bitte antworten Sie nicht auf diese E-Mail.<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      async showInviteDialog() {
        await this.$refs.inviteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.inviteConsultant(r)

          this.loading = false
        })
      },
      ...mapActions({
        setSearchResults: 'local/setSearchResults',
        createEmail: 'base/createEmail',
        loadConsultants: 'base/loadConsultants',
        loadTopConsultants: 'base/loadTopConsultants',
        loadConsultingTopics: 'base/loadConsultingTopics',
        createEmail: 'base/createEmail',
        lookup: 'base/lookup'
      })
    },
    mounted() {
      var typed = new Typed(this.$refs.typed, {
        strings: [ 'Finanzberater.', 'Versicherungsberater.', 'Bankberater.', 'Immobilienberater.', 'Steuerberater.', 'Vermögensverwalter.', 'Treuhänder.' ],
        typeSpeed: 30,
        loop: true
      })
    },
    mixins: [ mapHelpers ],
    name: 'HomeView'
  }
</script>

<style scoped>
  .carousel-item {
    min-height: 400px;
  }

  .move-up {
    margin-top: -80px;
  }

  .icon {
    display: block;
    margin: 0 auto 1rem auto;
    max-height: 48px;
  }
</style>

<i18n>
  {
    "de": {
      "atConfederiaHelpsYouByTheirExperienceReportsToFindAGoodAdvisorAndAvoidBadAdvisorsBySubmittingARatingToYourFinancialAdvisorYouInTurnHelpOtherCustomersAdditionallyYouCanContributeWithAnHonestRatingToProvidersImprovingTheirServicesAndOfferingsOurUniqueSellingPointIsEvaluatingYourRatingBasedOnYourRatingWeProvideYouWithTipsAndHintsThatYouCanDiscussWithYourFinancialAdvisor": "Auf Confederia helfen Ihnen andere Kunden durch ihre Erfahrungsberichte dabei, einen guten Berater zu finden und schlechte Berater zu meiden. Durch Abgabe einer Bewertung zu Ihrem Finanzberater helfen Sie wiederum anderen Kunden. Zudem können Sie mit einer ehrlichen Bewertung dazu beitragen, dass Anbieter ihre Leistungen und ihren Service verbessern.  Unser Alleinstellungsmerkmal besteht darin, Ihre Bewertung auszuwerten. Anhand Ihrer Bewertung geben wir Ihnen Tipps und Hinweise, die Sie mit Ihrem Finanzberater besprechen können.",
      "customersContactedTheseConsultantsToday": "Kunden haben heute diese Berater kontaktiert.",
      "contactUs": "Kontaktieren Sie uns",
      "findConsultant": "Berater finden",
      "findYourPerfectConsultation": "Finden Sie Ihre perfekte Beratung",
      "heresHowYouBenefit": "So profitieren Sie",
      "howCanConfederiaHelpYou": "Wie kann Ihnen Confederia helfen?",
      "isConfederiaIndependentOfTheListedAdvisorsAndCompanies": "Ist Confederia unabhängig von den gelisteten Beratern und Unternehmen?",
      "isTheUseOfThePlatformFreeOfCharge": "Ist die Nutzung der Plattform kostenlos?",
      "thePlatformIs100PercentIndependentConfederiaDoesNotReceiveAnyAdvertisingRevenueOrOtherSponsorshipContributionsThePlatformDoesNotReceiveAnyCommissionOnTransactionsOrOtherSuccessBasedIncentives": "Die Plattform ist zu 100 % unabhängig. Confederia erhält keinerlei Werbeeinnahmen oder sonstige Sponsoringbeiträge. Die Plattform erhält keine Abschlussprovision oder sonstige Erfolgsbeteiligungen.",
      "theUseOfThePlatformIsFreeForEndCustomersFinancialAdvisorsPayAFixedAmountToParticipateInConfederiaThePlatformDoesNotReceiveAnyCommissionOnTransactionsOrOtherSuccessBasedIncentives": "Die Nutzung der Plattform ist für Endkunden kostenlos. Finanzberater zahlen einen festen Betrag, um bei Confederia teilzunehmen. Die Plattform erhält keine Abschlussprovision oder sonstige Erfolgsbeteiligungen.",
      "ourAdvice": "Unser Tipp",
      "popularConsultants": "Beliebte Berater",
      "weArePleasedThatYouHaveContactedUsWeWillRespondToYourMessageAsSoonAsPossible": "Wir freuen uns, dass Sie uns kontaktiert haben. Wir werden uns so schnell wie möglich bei Ihnen melden.",
      "whatHappensToYourData": "Was passiert mit Ihren Daten?",
      "whatShouldYouConsiderWhenRatingYourAdvisor": "Worauf müssen Sie bei der Bewertung Ihres Beraters achten?",
      "whatWeAreOftenAsked": "Was wir oft gefragt werden",
      "youCanRateAnyFinancialAdvisorThePrerequisiteIsThatYouHaveAnOngoingBusinessRelationshipWithTheAdvisorReviewsShouldBeHonestAndFairAndShouldNotContainAnyInsults": "Sie können jeden Finanzberater bewerten. Voraussetzung hierfür ist, dass Sie mit dem Berater in einer laufenden Geschäftsbeziehung stehen. Bewertungen sollten ehrlich und fair sein – und dürfen keine Beleidigungen enthalten.",
      "yourDataIsNotSharedWeDoNotSellAddressesOrSendYouNewslettersOrOtherInformationYourPrivacyIsOfTheUtmostImportanceToUsOurServersAreLocatedInSwitzerlandAndTheDataIsStronglyEncryptedYouHaveFullControlOverWithWhomYouChooseToShareYourContactInformationForMoreInformationPleaseReferToOurPrivacyPolicy": "Ihre Daten werden nicht weitergegeben. Wir verkaufen keine Adressen und wir senden Ihnen keine Newsletter oder sonstige Informationen zu. Ihr Datenschutz hat für uns höchste Priorität. Nur Sie entscheiden, mit wem Sie Ihre Kontaktdaten teilen möchten. Weitere Informationen finden sie in unserer <a target=\"_blank\" href=\"/privacy-policy\">Datenschutzerklärung</a>.",

      "aFeelingOfSecurity": "Ein Gefühl der Sicherheit",
      "honestRecommendationsAndPpinionsFromManyOtherCustomers": "Ehrliche Empfehlungen und Meinungen vieler anderer Kunden",
      "makeBetterDecisions": "Bessere Entscheidungen treffen",
      "thanksToMoreTransparencyAndTheBestAdvisorsAtYourSide": "Dank mehr Transparenz und den besten Beratern an Ihrer Seite",
      "significantlySaveTime": "Deutlich an Zeit sparen",
      "findTheRightFinancialAdviceInTwoMinutes": "In zwei Minuten zur passenden Finanzberatung"
    },
    "en": {
      "atConfederiaHelpsYouByTheirExperienceReportsToFindAGoodAdvisorAndAvoidBadAdvisorsBySubmittingARatingToYourFinancialAdvisorYouInTurnHelpOtherCustomersAdditionallyYouCanContributeWithAnHonestRatingToProvidersImprovingTheirServicesAndOfferingsOurUniqueSellingPointIsEvaluatingYourRatingBasedOnYourRatingWeProvideYouWithTipsAndHintsThatYouCanDiscussWithYourFinancialAdvisor": "At Confederia, other customers help you by sharing their reviews and experiences, which can assist you in finding a good advisor and avoiding bad ones. By submitting a review about your financial advisor, you, in turn, help other customers. Additionally, with an honest review, you can contribute to providers improving their services and offerings. Our unique feature is evaluating your review. Based on your evaluation, we provide you with tips and suggestions that you can discuss with your financial advisor.",
      "customersContactedTheseConsultantsToday": "Customers contacted these consultants today.",
      "contactUs": "Contact us",
      "findConsultant": "Find consultant",
      "findYourPerfectConsultation": "Find your perfect consultation",
      "heresHowYouBenefit": "Here's how you benefit",
      "howCanConfederiaHelpYou": "How can Confederia help you?",
      "isConfederiaIndependentOfTheListedAdvisorsAndCompanies": "Is Confederia independent of the listed advisors and companies?",
      "isTheUseOfThePlatformFreeOfCharge": "Is the use of the platform free of charge?",
      "thePlatformIs100PercentIndependentConfederiaDoesNotReceiveAnyAdvertisingRevenueOrOtherSponsorshipContributionsThePlatformDoesNotReceiveAnyCommissionOnTransactionsOrOtherSuccessBasedIncentives": "The platform is 100% independent. Confederia does not receive any advertising revenue or sponsorship contributions. The platform does not receive any commission on transactions or other success-based incentives.",
      "theUseOfThePlatformIsFreeForEndCustomersFinancialAdvisorsPayAFixedAmountToParticipateInConfederiaThePlatformDoesNotReceiveAnyCommissionOnTransactionsOrOtherSuccessBasedIncentives": "The use of the platform is free for end customers. Financial advisors pay a fixed amount to participate in Confederia. The platform does not receive any commission on transactions or other success-based incentives.",
      "ourAdvice": "Our advice",
      "popularConsultants": "Popular consultants",
      "weArePleasedThatYouHaveContactedUsWeWillRespondToYourMessageAsSoonAsPossible": "We are pleased that you have contacted us. We will respond to your message as soon as possible.",
      "whatHappensToYourData": "What happens to your data?",
      "whatShouldYouConsiderWhenRatingYourAdvisor": "What should you consider when rating your advisor?",
      "whatWeAreOftenAsked": "What we are often asked",
      "youCanRateAnyFinancialAdvisorThePrerequisiteIsThatYouHaveAnOngoingBusinessRelationshipWithTheAdvisorReviewsShouldBeHonestAndFairAndShouldNotContainAnyInsults": "You can rate any financial advisor. The prerequisite is that you have an ongoing business relationship with the advisor. Reviews should be honest and fair, and should not contain any insults.",
      "yourDataIsNotSharedWeDoNotSellAddressesOrSendYouNewslettersOrOtherInformationYourPrivacyIsOfTheUtmostImportanceToUsOurServersAreLocatedInSwitzerlandAndTheDataIsStronglyEncryptedYouHaveFullControlOverWithWhomYouChooseToShareYourContactInformationForMoreInformationPleaseReferToOurPrivacyPolicy": "Your data is not shared. We do not sell addresses or send you newsletters or other information. Your privacy is of the utmost importance to us. You have full control over with whom you choose to share your contact information.",

      "aFeelingOfSecurity": "A feeling of security",
      "honestRecommendationsAndPpinionsFromManyOtherCustomers": "Honest recommendations and opinions from many other customers",
      "makeBetterDecisions": "Make better decisions",
      "thanksToMoreTransparencyAndTheBestAdvisorsAtYourSide": "Thanks to more transparency and the best advisors at your side",
      "significantlySaveTime": "Significantly save time",
      "findTheRightFinancialAdviceInTwoMinutes": "Find the right financial advice in two minutes"
    }
  }
</i18n>