<template>
  <div>
    <v-card-text>
      <h4 class="mb-6 text-h4 primary--text">{{ $t('yourPersonalDetails') }}</h4>
      <p class="mb-0">{{ $t('inTheFirstStepWeWouldLikeToLearnABitMoreAboutYouWeUseThisInformationToBeAbleToCreateYourPersonalBrokerProfile') }}</p>
    </v-card-text>

    <consultant-personal-details disable-email
                                 ref="consultantPersonalDetails"
                                 v-bind:consultant="consultant" />
  </div>
</template>

<script>
  import ConsultantPersonalDetails from '@/components/v2/account/ConsultantPersonalDetails'

  export default {
    components: {
      ConsultantPersonalDetails
    },
    methods: {
      validate() {
        return this.$refs.consultantPersonalDetails.validate()
      }
    },
    name: 'AccountPersonalDetailsStep',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "inTheFirstStepWeWouldLikeToLearnABitMoreAboutYouWeUseThisInformationToBeAbleToCreateYourPersonalBrokerProfile": "Im ersten Schritt würden wir gerne etwas mehr über Sie erfahren! Diese Informationen nutzen wir, um Ihr persönliches Beraterprofil erstellen zu können.",
      "yourPersonalDetails": "Ihre Personalien"
    },
    "en": {
      "inTheFirstStepWeWouldLikeToLearnABitMoreAboutYouWeUseThisInformationToBeAbleToCreateYourPersonalBrokerProfile": "In the first step we would like to learn a bit more about you! We use this information to be able to create your personal broker profile.",
      "yourPersonalDetails": "Your personal details"
    }
  }
</i18n>