<template>
  <div>
    <image-header left
                  variant="about-us">
      <h3 class="text-h3 mb-6">{{ $t('togetherForFinancialTransparency') }}</h3>
    </image-header>

    <v-container>
      <v-row justify="center">
        <v-col cols="12"
               xl="8">
          <text-card color="secondary"
                     icon="mdi-format-quote-close"
                     v-bind:bottom-title="$t('christianFounder')"
                     v-bind:class="$vuetify.breakpoint.mdAndUp ? 'move-up' : null"
                     v-bind:text="$t('financialAdviceCanBeSoSimpleWhenTheRightPeopleAreSittingAcrossFromEachOtherCustomerAndAdvisorMustMatchThatsWhatWeWantToAchieve')">
          </text-card>
        </v-col>
      </v-row>
    </v-container>

    <full-width-section color="transparent">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 md="4">
            <img alt="icon" class="icon-base" src="/images/icons/vision.svg" />
            <!--<v-icon class="mb-2"
                    color="accent"
                    size="48">
              mdi-eye-outline
            </v-icon>-->
            <h4 class="mb-3 text-h4 primary--text">{{ $t('vision') }}</h4>
            <p>{{ $t('atConfederiaWeHaveAClearVisionWeWantToCreateTransparencyInTheSwissMarketByGivingPeopleTheOpportunityToFindThePerfectAdviceAndTheRightAdvisorInTheFieldsOfRealEstateFinanceAndInsuranceOurPlatformMakesFinancialPlanningEasierByPromotingTrustAndTransparency') }}</p>
          </v-col>
          <v-col cols="12"
                 md="4">
            <img alt="icon" class="icon-base" src="/images/icons/mission.svg" />
            <!--<v-icon class="mb-2"
                    color="accent"
                    size="48">
              mdi-heart-outline
            </v-icon>-->
            <h4 class="mb-3 text-h4 primary--text">{{ $t('mission') }}</h4>
            <p>{{ $t('redefiningConsultingByHelpingCustomersFindTheBestAdvisorsAndProvidingAPlatformForAdvisorsToShowcaseThemselvesThusMakingFinancialPlanningInSwitzerlandMoreTransparentAndAccessible') }}</p>
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>

    <full-width-section color="secondary">
      <v-container>
        <v-card flat>
          <v-card-text class="pa-6">
            <v-row justify="center">
              <v-col class="text-center"
                     cols="12"
                     md="3">
                <img alt="icon" class="icon-base icon-center" src="/images/icons/transparenz.svg" />
                <!--<v-icon class="mb-2"
                        color="accent"
                        size="48">
                  mdi-circle-opacity
                </v-icon>-->
                <h5 class="mb-3 text-h5 primary--text">{{ $t('transparency') }}</h5>
                <p>{{ $t('weBelieveInOpenAndHonestCommunicationOurPlatformAllowsCustomersToLeaveReviewsAndRecognizeAdvisorsWhoDoExcellentWork') }}</p>
              </v-col>
              <v-col class="text-center"
                     cols="12"
                     md="3">
                <img alt="icon" class="icon-base icon-center" src="/images/icons/qualitaet.svg" />
                <!--<v-icon class="mb-2"
                        color="accent"
                        size="48">
                  mdi-star-shooting-outline
                </v-icon>-->
                <h5 class="mb-3 text-h5 primary--text">{{ $t('quality') }}</h5>
                <p>{{ $t('wePrioritizeQualityAndExcellenceInAllAreasWeOnlyCollaborateWithQualifiedAndTrustworthyAdvisorsToEnsureThatOurCustomersReceiveTheBestPossibleSupport') }}</p>
              </v-col>
              <v-col class="text-center"
                     cols="12"
                     md="3">
                <img alt="icon" class="icon-base icon-center" src="/images/icons/accessibility.svg" />
                <!--<v-icon class="mb-2"
                        color="accent"
                        size="48">
                  mdi-door-open
                </v-icon>-->
                <h5 class="mb-3 text-h5 primary--text">{{ $t('accessibility') }}</h5>
                <p>{{ $t('everyoneShouldHaveAccessToHighQualityAdviceOurPlatformIsUserFriendlyAndMakesItEasyToFindTheRightAdvisor') }}</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </full-width-section>

    <full-width-section color="transparent">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 md="4" class="text-center">
            <v-avatar class="mb-6"
                 v-bind:size="$vuetify.breakpoint.mdAndDown ? 128 : 192">
              <v-img src="/images/v2/christian.jpg" />
            </v-avatar>
            <h4 class="mb-3 text-h4 text-center primary--text">{{ $t('christianFounder') }}</h4>
            <p class="text-center">{{ $t('christianText') }}</p>
          </v-col>
          <v-col cols="12"
                 md="4" class="text-center">
            <v-avatar class="mb-6"
                 v-bind:size="$vuetify.breakpoint.mdAndDown ? 128 : 192">
              <v-img src="/images/v2/thomas.jpg" />
            </v-avatar>
            <h4 class="mb-3 text-h4 text-center primary--text">{{ $t('thomasFounder') }}</h4>
            <p class="text-center">{{ $t('thomasText') }}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="text-center">
        <v-btn class="text-center"
                 color="primary"
                 dark
                 depressed
                 large
                 target="_blank"
                 v-on:click="bookDemo">
            <v-icon color="accent"
                    left>
              mdi-send
            </v-icon>
            {{ $t('bookDemo') }}
          </v-btn>
      </v-container>
    </full-width-section>

    <full-width-section color="transparent">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 md="4">
            <h4 class="mb-3 text-h4 primary--text">{{ $t('howWeSupportAdvisors') }}</h4>
            <p>{{ $t('forAdvisorsConfederiaOffersAUniqueOpportunityToShowcaseThemselvesAndMakeTheirExpertiseAccessibleToABroadAudienceWeProvideToolsAndResourcesToIncreaseYourReachAndStrengthenYourPositioningWithUsYouCanLeverageYourExpertiseToTheFullestWhileGainingTheTrustOfYourClients') }}</p>
          </v-col>
          <v-col cols="12"
                 md="4">
            <h4 class="mb-3 text-h4 primary--text">{{ $t('howWeSupportCustomers') }}</h4>
            <p>{{ $t('ourPlatformAllowsCustomersToFindAdvisorsWhoMatchTheirIndividualNeedsWithReviewsAndRecommendationsFromOtherClientsYouCanBeConfidentInMakingTheRightChoiceWeWantToAssistYouInShapingYourFinancialFutureAndReceivingTheBestPossibleAdvice') }}</p>
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>

    <full-width-section color="secondary"
                        id="contact">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 xl="8">
            <h4 class="mb-6 text-h4 primary--text">{{ $t('contactUs') }}</h4>
            <contact-form v-bind:success-message="$t('weArePleasedThatYouHaveContactedUsWeWillRespondToYourMessageAsSoonAsPossible')"
                          v-on:send="sendEmail" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import CorporateRequestDialog from '@/components/v2/base/CorporateRequestDialog.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import HighlightList from '@/components/v2/base/HighlightList.vue'
  import ImageHeader from '@/components/v2/base/ImageHeader.vue'
  import PricingTableCard from '@/components/v2/base/PricingTableCard.vue'
  import TextCard from '@/components/v2/base/TextCard.vue'
  import ContactForm from '@/components/v2/base/ContactForm.vue'

  export default {
    components: {
      CorporateRequestDialog,
      FullWidthSection,
      HighlightList,
      ImageHeader,
      PricingTableCard,
      TextCard,


      ContactForm,
    },
    methods: {
      bookDemo() {
         // goto https://book.calenso.com/confederiaklg/orga@confederia.ch
         if (!!document.amplitude) {
          amplitude.track('Book Demo');
        }
        

         window.open('https://book.calenso.com/confederiaklg/orga@confederia.ch', '_blank')
      },
      async sendEmail(email) {
        // IMPRV: Move to Backend
        let clientSideEmail = {
          recipientEmail: 'orga@confederia.ch',
          recipientName: 'Confederia',
          templateName: 'DefaultText',
          values: {
            subject: `Anfrage von ${email.name}`,
            headline: `${email.name} hat eine Nachricht gesendet!`,
            text: `Hallo,<br><br>ein Interessent hat Ihnen über das Kontaktformular (Beraterseite) eine Frage zukommen lassen:<br><br>${email.text}<br><br>Name: ${email.name}<br>E-Mail-Adresse: ${email.email}`,
            closingText: 'Diese Nachricht wurde automatisch an Sie versandt. Bitte antworten Sie nicht auf diese E-Mail.<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      async sendExtendedEmail(email) {
        // IMPRV: Move to backend
        let clientSideEmail = {
          recipientEmail: 'orga@confederia.ch',
          recipientName: 'Confederia',
          templateName: 'DefaultText',
          values: {
            subject: `Corporate-Anfrage von ${email.givenName} ${email.familyName} (${email.company})`,
            headline: `${email.company} hat eine Nachricht gesendet!`,
            text: `Hallo,<br><br>ein Interessent hat Ihnen über das Kontaktformular (Corporate-Anfrage) eine Frage zukommen lassen:<br><br>${email.comment}<br><br>Name: ${email.givenName} ${email.familyName}<br>Firma: ${email.company}<br>Telefonnummer: ${email.phone}<br>Anzahl Beraterprofile: ${email.consultantsCount}<br>Sprache: ${email.language}<br>E-Mail-Adresse: ${email.email}`,
            closingText: 'Diese Nachricht wurde automatisch an Sie versandt. Bitte antworten Sie nicht auf diese E-Mail.<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      async showCorporateRequestDialog() {
        await this.$refs.corporateRequestDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.sendExtendedEmail(r)

          this.loading = false
        })
      },
      ...mapActions({
        createEmail: 'base/createEmail'
      })
    },
    name: 'AboutUsView'
  }
</script>

<style>
  .move-up {
    margin-top: -80px;
  }

  .icon-base {
    display: block;

    height: 48px;
    margin-bottom: 1rem;
  }

  .icon-center {
    margin: 0 auto 1rem auto;
  }
</style>

<i18n>
  {
    "de": {
      "bookDemo": "Vorstellung vereinbaren",
      "christianFounder": "Christain, Founder",
      "thomasFounder": "Thomas, Founder",
      "christianText": "Als CEO und Founder trage ich die Leidenschaft für finanzielle Selbstbestimmung tief in meinem Herzen. Die Idee zu Confederia entstand aus meiner festen Überzeugung, Menschen dazu zu befähigen, sich aktiv und bewusst mit ihren Finanzen auseinanderzusetzen. Mit über 10 Jahren Erfahrung im Versicherungssektor und einer soliden Führungshistorie strebe ich mit Confederia danach, Menschen die bestmögliche Beratung zu vermitteln. Meine Vision ist es, Finanzwissen für jeden verständlicher und transparenter zu machen und Zugang zu Finanzberatungen von höchster Qualität zu ermöglichen.",
      "thomasText": "Als Co-Founder von Confederia bringe meine über 20 Jahre umfassende Expertise in der Finanz- und Versicherungsbranche in unser Unternehmen ein. Mein Herz schlägt für alles Operative, und auch ich teile die Vision, den Zugang zu den besten und passenden Finanzberatern zu erleichtern. Mit meinem Erfahrungsschatz stehe ich an vorderster Front, um sicherzustellen, dass Confederia als Plattform nicht nur Innovation und Transparenz, sondern auch bewährte Exzellenz in der Finanzwelt bietet.",
      "togetherForFinancialTransparency": "Gemeinsam für finanzielle Transparenz.",
      "financialAdviceCanBeSoSimpleWhenTheRightPeopleAreSittingAcrossFromEachOtherCustomerAndAdvisorMustMatchThatsWhatWeWantToAchieve": "Finanzberatung kann so einfach sein, wenn sich die richtigen Personen gegenüber sitzen. Kunde und Berater müssen zueinander passen: das wollen wir erreichen.",
      "christianFounder": "Christian, Founder",
      "vision": "Vision",
      "mission": "Mission",
      "atConfederiaWeHaveAClearVisionWeWantToCreateTransparencyInTheSwissMarketByGivingPeopleTheOpportunityToFindThePerfectAdviceAndTheRightAdvisorInTheFieldsOfRealEstateFinanceAndInsuranceOurPlatformMakesFinancialPlanningEasierByPromotingTrustAndTransparency": "Wir bei Confederia haben eine klare Vision: Wir wollen Transparenz auf dem Schweizer Markt schaffen, indem wir Menschen die Möglichkeit geben, die perfekte Beratung und den richtigen Berater in den Bereichen Immobilien, Finanzen und Versicherungen zu finden. Unsere Plattform macht finanzielle Vorsorge einfacher, indem sie Vertrauen und Transparenz fördert.",
      "redefiningConsultingByHelpingCustomersFindTheBestAdvisorsAndProvidingAPlatformForAdvisorsToShowcaseThemselvesThusMakingFinancialPlanningInSwitzerlandMoreTransparentAndAccessible": "Unsere Mission bei Confederia: Beratung neu definieren. Wir unterstützen Kunden bei der Suche nach den besten Beratern und bieten diesen eine Plattform. So schaffen wir mehr Transparenz und Zugänglichkeit in der Finanz- und Immobilienbranche der Schweiz.",
      "transparency": "Transparenz",
      "quality": "Qualität",
      "accessibility": "Zugänglichkeit",
      "weBelieveInOpenAndHonestCommunicationOurPlatformAllowsCustomersToLeaveReviewsAndRecognizeAdvisorsWhoDoExcellentWork": "Wir glauben an offene und ehrliche Kommunikation. Unsere Plattform ermöglicht es Kunden, Bewertungen abzugeben und Berater, die hervorragende Arbeit leisten, zu erkennen.",
      "wePrioritizeQualityAndExcellenceInAllAreasWeOnlyCollaborateWithQualifiedAndTrustworthyAdvisorsToEnsureThatOurCustomersReceiveTheBestPossibleSupport": "Wir setzen auf Qualität und Exzellenz in allen Bereichen. Wir arbeiten nur mit qualifizierten und vertrauenswürdigen Beratern zusammen, um sicherzustellen, dass unsere Kunden die bestmögliche Unterstützung erhalten.",
      "everyoneShouldHaveAccessToHighQualityAdviceOurPlatformIsUserFriendlyAndMakesItEasyToFindTheRightAdvisor": "Jeder sollte Zugang zu hochwertiger Beratung haben. Unsere Plattform ist einfach zu bedienen und erleichtert das Finden des richtigen Beraters oder der richtigen Beraterin.",
      "howWeSupportAdvisors": "Wie wir Berater unterstützen",
      "howWeSupportCustomers": "Wie wir Kunden unterstützen",
      "forAdvisorsConfederiaOffersAUniqueOpportunityToShowcaseThemselvesAndMakeTheirExpertiseAccessibleToABroadAudienceWeProvideToolsAndResourcesToIncreaseYourReachAndStrengthenYourPositioningWithUsYouCanLeverageYourExpertiseToTheFullestWhileGainingTheTrustOfYourClients": "Für Berater bietet Confederia eine einzigartige Gelegenheit, sich zu präsentieren und ihre Expertise einem breiten Publikum zugänglich zu machen. Wir bieten Tools und Ressourcen, um Ihre Reichweite zu erhöhen und Ihre Positionierung zu stärken. Bei uns können Sie Ihr Fachwissen optimal einsetzen und gleichzeitig das Vertrauen Ihrer Kunden gewinnen.",
      "ourPlatformAllowsCustomersToFindAdvisorsWhoMatchTheirIndividualNeedsWithReviewsAndRecommendationsFromOtherClientsYouCanBeConfidentInMakingTheRightChoiceWeWantToAssistYouInShapingYourFinancialFutureAndReceivingTheBestPossibleAdvice": "Unsere Plattform ermöglicht es Kunden, Berater zu finden, die zu ihren individuellen Bedürfnissen passen. Mit Bewertungen und Empfehlungen von anderen Kunden können Sie sicher sein, die richtige Entscheidung zu treffen. Wir möchten Ihnen helfen, Ihre finanzielle Zukunft zu gestalten und die bestmögliche Beratung zu erhalten.",


      "contactUs": "Kontaktieren Sie uns",
      "startFreeTrail": "Kostenlos testen",
      "weArePleasedThatYouHaveContactedUsWeWillRespondToYourMessageAsSoonAsPossible": "Wir freuen uns, dass Sie uns kontaktiert haben. Wir werden uns so schnell wie möglich bei Ihnen melden.",
      "whatWeAreOftenAsked": "Was wir oft gefragt werden",
      "yourAdvantages": "Ihre Vorteile"
    },
    "en": {
      "bookDemo": "Book Demo",
      "christianFounder": "Christain, Founder",
      "thomasFounder": "Thomas, Founder",
      "christianText": "As CEO and Founder, I carry the passion for financial empowerment deep in my heart. The idea for Confederia emerged from my firm belief in empowering people to actively and consciously engage with their finances. With over 10 years of experience in the insurance sector and a solid leadership history, I aspire, with Confederia, to provide people with the best possible guidance. My vision is to make financial knowledge more understandable and transparent for everyone and to facilitate access to the highest quality financial advice.",
      "thomasText": "As Co-Founder of Confederia, I contribute my extensive expertise of over 20 years in the financial and insurance industry to our company. My passion lies in all operational aspects, and like my fellow Co-Founder, I share the vision of facilitating access to the best and most suitable financial advisors. With my wealth of experience, I stand at the forefront to ensure that Confederia, as a platform, not only provides innovation and transparency but also embodies proven excellence in the financial world.",
      "togetherForFinancialTransparency": "Together for financial transparency.",
      "financialAdviceCanBeSoSimpleWhenTheRightPeopleAreSittingAcrossFromEachOtherCustomerAndAdvisorMustMatchThatsWhatWeWantToAchieve": "Financial advice can be so simple when the right people are sitting across from each other. Customer and advisor must match: that's what we want to achieve.",
      "christianFounder": "Christian, Founder",
      "vision": "Vision",
      "mission": "Mission",
      "atConfederiaWeHaveAClearVisionWeWantToCreateTransparencyInTheSwissMarketByGivingPeopleTheOpportunityToFindThePerfectAdviceAndTheRightAdvisorInTheFieldsOfRealEstateFinanceAndInsuranceOurPlatformMakesFinancialPlanningEasierByPromotingTrustAndTransparency": "At Confederia, we have a clear vision: we want to create transparency in the Swiss market by giving people the opportunity to find the perfect advice and the right advisor in the fields of real estate, finance, and insurance. Our platform makes financial planning easier by promoting trust and transparency.",
      "redefiningConsultingByHelpingCustomersFindTheBestAdvisorsAndProvidingAPlatformForAdvisorsToShowcaseThemselvesThusMakingFinancialPlanningInSwitzerlandMoreTransparentAndAccessible": "Redefining consulting by helping customers find the best advisors and providing a platform for advisors to showcase themselves, thus making financial planning in Switzerland more transparent and accessible.",
      "transparency": "Transparency",
      "quality": "Quality",
      "accessibility": "Accessibility",
      "weBelieveInOpenAndHonestCommunicationOurPlatformAllowsCustomersToLeaveReviewsAndRecognizeAdvisorsWhoDoExcellentWork": "We believe in open and honest communication. Our platform allows customers to leave reviews and recognize advisors who do excellent work.",
      "wePrioritizeQualityAndExcellenceInAllAreasWeOnlyCollaborateWithQualifiedAndTrustworthyAdvisorsToEnsureThatOurCustomersReceiveTheBestPossibleSupport": "We prioritize quality and excellence in all areas. We only collaborate with qualified and trustworthy advisors to ensure that our customers receive the best possible support.",
      "everyoneShouldHaveAccessToHighQualityAdviceOurPlatformIsUserFriendlyAndMakesItEasyToFindTheRightAdvisor": "Everyone should have access to high-quality advice. Our platform is user-friendly and makes it easy to find the right advisor.",
      "howWeSupportAdvisors": "How we support advisors",
      "howWeSupportCustomers": "How we support customers",
      "forAdvisorsConfederiaOffersAUniqueOpportunityToShowcaseThemselvesAndMakeTheirExpertiseAccessibleToABroadAudienceWeProvideToolsAndResourcesToIncreaseYourReachAndStrengthenYourPositioningWithUsYouCanLeverageYourExpertiseToTheFullestWhileGainingTheTrustOfYourClients": "For advisors, Confederia offers a unique opportunity to showcase themselves and make their expertise accessible to a broad audience. We provide tools and resources to increase your reach and strengthen your positioning. With us, you can leverage your expertise to the fullest while gaining the trust of your clients.",
      "ourPlatformAllowsCustomersToFindAdvisorsWhoMatchTheirIndividualNeedsWithReviewsAndRecommendationsFromOtherClientsYouCanBeConfidentInMakingTheRightChoiceWeWantToAssistYouInShapingYourFinancialFutureAndReceivingTheBestPossibleAdvice": "Our platform allows customers to find advisors who match their individual needs. With reviews and recommendations from other clients, you can be confident in making the right choice. We want to assist you in shaping your financial future and receiving the best possible advice.",



      "contactUs": "Contact us",
      "startFreeTrail": "Start free trial",
      "weArePleasedThatYouHaveContactedUsWeWillRespondToYourMessageAsSoonAsPossible": "We are pleased that you have contacted us. We will respond to your message as soon as possible.",
      "whatWeAreOftenAsked": "What we are often asked",
      "yourAdvantages": "Your advantages"
    }
  }
</i18n>