export default class QuestionDefinitionService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(questionDefinition, options) {
    const response = await fetch(`${this.baseUri}/questionDefinition`, {
      body: JSON.stringify(questionDefinition),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUri}/questionDefinition/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    const response = await fetch(`${this.baseUri}/questionDefinition/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/questionDefinition`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(id, questionDefinition, options) {
    const response = await fetch(`${this.baseUri}/questionDefinition/${id}`, {
      body: JSON.stringify(questionDefinition),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }
}