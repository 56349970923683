<template>
  <div>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />
    <top-header color="white"
                v-bind:title="$t('allConsultants')">
      <template slot="body">
        <div class="mb-12 mt-2 text-body-1">
          {{ $t('allConsultantsOnConfederia') }}
        </div>
      </template>
    </top-header>
    <div>
      <full-width-section color="secondary">
        <v-container>
          <v-row justify="center">
            <v-col>
              <consultant-grid v-bind:consultants="consultants" />
            </v-col>
          </v-row>
        </v-container>
      </full-width-section>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ConsultantGrid from '@/components/v2/base/ConsultantGrid.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      ConsultantGrid,
      FullWidthSection,
      TopHeader
    },
    computed: {
      ...mapGetters({
        consultants: 'base/consultants'
      })
    },
    async created() {
      this.loading = true

      await this.loadConsultants()
      await this.loadConsultingTopics()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadConsultants: 'base/loadConsultants',
        loadConsultingTopics: 'base/loadConsultingTopics'
      })
    },
    name: 'ConsultantsView'
  }
</script>

<i18n>
  {
    "de": {
      "allConsultants": "Alle Berater",
      "allConsultantsOnConfederia": "Alle Berater auf Confederia"
    },
    "en": {
      "allConsultants": "All consultants",
      "allConsultantsOnConfederia": "All consultants on Confederia"
    }
  }
</i18n>