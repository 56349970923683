<template>
  <v-card class="fill-height"
          color="white"
          flat>
    <v-card-text class="pa-6">
      <h6 class="primary--text text-h6">{{ $t('mySpecialties') }}</h6>
      <h4 class="mb-6 primary--text text-h4">{{ $t('consultingTopics') }}</h4>
      <v-row>
        <v-col align="center"
               cols="4"
               v-bind:key="consultingTopic.id"
               v-for="consultingTopic in consultingTopics">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <img v-bind="attrs"
                   v-on="on"
                  alt="icon"
                  class="icon"
                  v-bind:src="`/images/v2/${consultingTopic.icon}${hasConsultingTopic(consultingTopic.id) ? '-active' : ''}.svg`" />
            </template>
            <p class="font-weight-bold">{{ consultingTopic.name }}</p>
            <span v-html="consultingTopic.description"></span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex';

  import OverallRating from '@/components/v2/base/OverallRating.vue'

  export default {
    components: {
      OverallRating
    },
    computed: {
      ...mapGetters({
        consultingTopics: 'base/consultingTopics'
      })
    },
    methods: {
      hasConsultingTopic (consultingTopicId) {
        return this.consultant.consultingTopicIds.includes(consultingTopicId)
      }
    },
    name: 'ConsultingTopicsCard',
    props: {
      consultant: Object
    }
  }
</script>

<style>
.icon {
    display: block;
    margin: 0 auto 1rem auto;
    max-height: 48px;
  }
</style>

<i18n>
  {
    "de": {
      "consultingTopics": "Themen",
      "mySpecialties": "Meine Spezialgebiete"
    },
    "en": {
      "consultingTopics": "Consulting topics",
      "mySpecialties": "My specialties"
    }
  }
</i18n>