<template>
  <div>
    <v-row class="mb-6"
           v-if="evaluations.length > 0">
      <v-spacer />
      <v-col cols="12"
             md="auto">
        <v-select background-color="secondary"
                  clearable
                  flat
                  hide-details="auto"
                  prepend-inner-icon="mdi-message-outline"
                  solo
                  v-bind:items="consultingTopics | items({ text: 'name', value: 'id' })"
                  v-bind:label="$t('consultingTopics')"
                  v-model="consultingTopicFilter" />
      </v-col>
      <v-col cols="12"
             md="auto">
        <v-select background-color="secondary"
                  flat
                  hide-details="auto"
                  prepend-inner-icon="mdi-sort"
                  solo
                  v-bind:items="sortItems"
                  v-bind:label="$t('sortBy')"
                  v-model="sortBy" />
      </v-col>
    </v-row>
    <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 4, 8, 16, 32 ] }"
                     v-bind:hide-default-footer="filteredEvaluations.length === 0"
                     v-bind:items="filteredEvaluations"
                     v-bind:items-per-page="4"
                     v-bind:sort-by="sortBy"
                     v-bind:sort-desc="sortDesc">
      <template v-slot:no-data v-slot:no-results>
        <notification-with-icon icon="mdi-magnify"
                                v-bind:text="internal ? $t('untilNowYourCustomersHaventReviewedYouYetManyReviewsIncreaseYourTrustAndGiveYouAnEvenBroaderReachHaveYouHadCustomerAppointmentsInTheLastFewDaysDoYouHaveLongTermClientsYouveBeenServingForAWhileInviteThemToLeaveAReviewUsingYourIndividualLink') : $t('soFarYourConsultantHasNotReceivedAnyEvaluationsThisMakesYourVoiceAllTheMoreValuableYourOpinionCanMakeABigDifferenceNotOnlyForUsButAlsoForAllFutureCustomersWhoAreLookingForHighQualityConsultingServices')"
                                v-bind:title="$t('thereAreNoEvaluationsYet')" />
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col cols="12"
                 md="6"
                 v-bind:key="evaluation.id"
                 v-for="evaluation in props.items">
            <evaluation-card color="secondary"
                             fill-height
                             v-bind:editable="editable"
                             v-bind:evaluation="evaluation"
                             v-on:add-comment="$emit('add-comment', $event)"
                             v-on:publish-evaluation="$emit('publish-evaluation', $event)"
                             v-on:report-evaluation="$emit('report-evaluation', $event)"
                             v-on:verify-evaluation="$emit('verify-evaluation', $event)" />
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import EvaluationCard from '@/components/v2/base/EvaluationCard.vue'
  import NotificationWithIcon from '@/components/v2/base/NotificationWithIcon.vue'

  export default {
    components: {
      EvaluationCard,
      NotificationWithIcon
    },
    computed: {
      filteredEvaluations() {
        if (this.consultingTopicFilter) {
          return this.evaluations.filter(e => e.consultingTopicIds.includes(this.consultingTopicFilter))
        }

        return this.evaluations
      },
      sortDesc () {
        return this.sortBy === 'overallRating' || this.sortBy === 'date'
      },
      sortItems() {
        return [
          {
            text: this.$t('date'),
            value: 'date'
          },
          /*{
            text: this.$t('name'),
            value: 'customer.displayName'
          },*/
          {
            text: this.$t('rating'),
            value: 'overallRating'
          }
        ]
      },
      ...mapGetters({
        consultingTopics: 'base/consultingTopics'
      })
    },
    data: () => ({
      consultingTopicFilter: null,
      sortBy: 'date'
    }),
    name: 'EvaluationList',
    props: {
      editable: Boolean,
      evaluations: Array,
      internal: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "consultingTopics": "Themen",
      "date": "Datum",
      "name": "Name",
      "rating": "Bewertung",
      "soFarYourConsultantHasNotReceivedAnyEvaluationsThisMakesYourVoiceAllTheMoreValuableYourOpinionCanMakeABigDifferenceNotOnlyForUsButAlsoForAllFutureCustomersWhoAreLookingForHighQualityConsultingServices": "Bislang hat Ihr Berater noch keine Bewertungen erhalten - das macht Ihre Stimme umso wertvoller! Ihre Meinung kann einen großen Unterschied machen. Nicht nur für uns, sondern auch für alle zukünftigen Kunden, die auf der Suche nach qualitativ hochwertigen Beratungsleistungen sind.",
      "sortBy": "Sortieren nach",
      "thereAreNoEvaluationsYet": "Es sind noch keine Bewertungen vorhanden",
      "untilNowYourCustomersHaventReviewedYouYetManyReviewsIncreaseYourTrustAndGiveYouAnEvenBroaderReachHaveYouHadCustomerAppointmentsInTheLastFewDaysDoYouHaveLongTermClientsYouveBeenServingForAWhileInviteThemToLeaveAReviewUsingYourIndividualLink": "Bislang haben Ihre Kunden Sie noch nicht bewertet. Viele Bewertungen erhöhen Ihren Trust und Sie bekommen eine noch größere Reichweite. Hatten Sie die letzten Tage Kundentermine? Haben Sie Bestandskunden, die Sie schon lange betreuen? Laden Sie sie mit Ihrem individuellen Link zu einer Bewertung ein!"
    },
    "en": {
      "consultingTopics": "Consulting topics",
      "date": "Date",
      "name": "Name",
      "rating": "Rating",
      "soFarYourConsultantHasNotReceivedAnyEvaluationsThisMakesYourVoiceAllTheMoreValuableYourOpinionCanMakeABigDifferenceNotOnlyForUsButAlsoForAllFutureCustomersWhoAreLookingForHighQualityConsultingServices": "So far, your consultant has not received any evaluations - this makes your voice all the more valuable! Your opinion can make a big difference. Not only for us, but also for all future customers who are looking for high-quality consulting services.",
      "sortBy": "Sort by",
      "thereAreNoEvaluationsYet": "There are no evaluations yet",
      "untilNowYourCustomersHaventReviewedYouYetManyReviewsIncreaseYourTrustAndGiveYouAnEvenBroaderReachHaveYouHadCustomerAppointmentsInTheLastFewDaysDoYouHaveLongTermClientsYouveBeenServingForAWhileInviteThemToLeaveAReviewUsingYourIndividualLink": "Until now, your customers haven't reviewed you yet. Many reviews increase your trust and give you an even broader reach. Have you had customer appointments in the last few days? Do you have longTerm clients you've been serving for a while? Invite them to leave a review using your individual link!"
    }
  }
</i18n>