<template>
  <div>
    <top-header color="secondary"
                v-bind:title="$t('dashboard')">
      <template slot="body">
        <v-row class="mt-2">
          <v-col cols="12" md="3">
            <v-select flat
                      solo
                      v-bind:items="periods"
                      v-model="period" />
          </v-col>
        </v-row>
      </template>
    </top-header>
    <v-container>
      <v-row>
        <v-col cols="12"
               md="6">
          <dashboard-stat-graph v-bind:graph-values="randomValues()"
                                v-bind:title="$t('revenue')"
                                v-bind:value="randomValue(10000, 20000) | formatCurrencyValue({ currency: 'CHF' })" />
        </v-col>
        <v-col cols="12"
               md="6">
          <dashboard-stat-graph v-bind:graph-values="randomValues()"
                                v-bind:title="$t('profit')"
                                v-bind:value="randomValue(2500, 10000) | formatCurrencyValue({ currency: 'CHF' })" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <dashboard-stat-graph v-bind:graph-values="randomValues()"
                                v-bind:title="$t('companies')"
                                v-bind:value="companies.length| formatValue({ fractionDigits: 0 })" />
        </v-col>
        <v-col cols="12"
               md="6">
          <dashboard-stat-graph v-bind:graph-values="randomValues()"
                                v-bind:title="$t('consultants')"
                                v-bind:value="consultants.length | formatValue({ fractionDigits: 0 })" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <dashboard-list-card add-route-name="administration-company-add"
                               icon="mdi-domain"
                               overview-route-name="administration-companies"
                               route-name="administration-company"
                               v-bind:items="companyItems"
                               v-bind:title="$t('companies')" />
        </v-col>
        <v-col cols="12"
               md="6">
          <dashboard-list-card add-route-name="administration-consultant-add"
                               icon="mdi-account-tie"
                               overview-route-name="administration-consultants"
                               route-name="administration-consultant"
                               v-bind:items="consultantItems"
                               v-bind:title="$t('consultants')" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import DashboardListCard from '@/components/administration/DashboardListCard.vue'
  import DashboardStatGraph from '@/components/administration/DashboardStatGraph.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      DashboardListCard,
      DashboardStatGraph,
      TopHeader
    },
    computed: {
      consultantItems() {
        return this.consultants.map(c => ({
          id: c.id,
          subtitle: `${c.streetName} ${c.streetNumber}, ${c.postalCode} ${c.city}`,
          title: `${c.givenName} ${c.familyName} (${c.id})`
        })).slice(0, 5)
      },
      periods() {
        return [
          {
            text: this.$t('day'),
            value: 'day'
          },
          {
            text: this.$t('week'),
            value: 'week'
          },
          {
            text: this.$t('month'),
            value: 'month'
          },
          {
            text: this.$t('year'),
            value: 'year'
          }
        ]
      },
      companyItems() {
        return this.companies.map(c => ({
          id: c.id,
          subtitle: `${c.streetName} ${c.streetNumber}, ${c.postalCode} ${c.city}`,
          title: `${c.name} (${c.id})`
        })).slice(0, 5)
      },
      salesAgentItems() {
        return this.salesAgents.map(c => ({
          id: c.id,
          subtitle: `${c.streetName} ${c.streetNumber}, ${c.postalCode} ${c.city}`,
          title: `${c.givenName} ${c.familyName} (${c.id})`
        }))
      },
      ...mapGetters({
        companies: 'base/companies',
        consultants: 'base/consultants',
        salesAgents: 'administration/salesAgents'
      })
    },
    async created() {
      await this.loadConsultants()
      await this.loadSalesAgents()
      await this.loadCompanies()
    },
    data: () => ({
      period: 'day'
    }),
    methods: {
      randomValue(min, max) {
        return Math.random() * (max - min) + min;
      },
      randomValues() {
        return Array.from({ length: 20 }, () => Math.floor(Math.random() * 20));
      },
      ...mapActions({
        loadConsultants: 'base/loadConsultants',
        loadCompanies: 'base/loadCompanies',
        loadSalesAgents: 'administration/loadSalesAgents'
      })
    },
    name: 'Dashboard'
  }
</script>

<i18n>
  {
    "de": {
      "consultants": "Berater",
      "day": "Tag",
      "dashboard": "Dashboard",
      "month": "Monat",
      "profit": "Gewinn",
      "revenue": "Umsatz",
      "salesAgents": "Verkäufer",
      "week": "Woche",
      "year": "Jahr",
      "companies": "Unternehmen"
    },
    "en": {
      "consultants": "Consultants",
      "day": "Day",
      "dashboard": "Dashboard",
      "month": "Month",
      "profit": "Profit",
      "revenue": "Revenue",
      "salesAgents": "Sales agents",
      "week": "Week",
      "year": "Year",
      "companies": "Companies"
    }
  }
</i18n>