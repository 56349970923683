<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-autocomplete clearable
                          hide-details="auto"
                          outlined
                          v-bind:items="companies | items({ text: 'name', value: 'id' })"
                          v-bind:label="$t('company')"
                          v-model="consultant.companyId" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select hide-details="auto"
                    outlined
                    v-bind:items="companyLinkStates | vueI18nItems({ text: 'label' })"
                    v-bind:label="$t('linkState')"
                    v-bind:rules="[ rules.required ]"
                    v-model="consultant.companyLinkState" />
        </v-col>
      </v-row>
    </v-card-text>
    <template v-if="company != null">
    <v-divider />
      <v-card-text>
        <v-img class="mb-6"
               contain
               height="128"
               position="left"
               width="256"
               v-bind:src="company.logoAttachment | attachmentUrl({ defaultUrl: '/images/profile-none.jpg'} )" />
        <h5 class="primary--text text-h5">{{ company.name }}</h5>
        <p class="mb-0">
          {{ company.streetName }} {{ company.streetNumber }}<br>
          {{ company.postalCode }} {{ company.city }}
        </p>
      </v-card-text>
    </template>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import rules from '@/mixins/v2/rules'

  export default {
    computed: {
      company() {
        if (this.consultant?.companyId == null) {
          return null
        }

        return this.getCompany(this.consultant.companyId)
      },
      ...mapGetters({
        companies: 'base/companies',
        companyLinkStates: 'local/companyLinkStates',
        getCompany: 'base/company'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    mixins: [ rules ],
    name: 'ConsultantCompanyDetails',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "company": "Unternehmen",
      "linkState": "Verknüpfungsstatus"
    },
    "en": {
      "company": "Company",
      "linkState": "Link state"
    }
  }
</i18n>