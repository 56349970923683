<template>
  <div>
    <v-carousel cycle
                height="auto"
                hide-delimiters
                v-model="slide">
      <v-carousel-item v-bind:key="consultant.id"
                       v-for="consultant in consultants">
        <consultant-card class="carousel-item"
                         v-bind:consultant="consultant" />
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
  import ConsultantCard from '@/components/v2/base/ConsultantCard.vue'

  export default {
    components: {
      ConsultantCard
    },
    data: () => ({
      slide: 0
    }),
    name: 'TopConsultantsCarousel',
    props: {
      consultants: Array
    }
  }
</script>

<style scoped>
  .carousel-item {
    min-height: 480px;
  }
</style>