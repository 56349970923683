<template>
  <div>
    <v-card-text class="pa-6">
      <p class="text-h6 text-center">{{ questionDefinition.text }}</p>
      <p class="text-body-1 text-center">{{ questionDefinition.description }}</p>
      <div class="text-center">
        <v-row justify="center">
          <v-col cols="12"
                 md="4"
                 sm="6">
            <v-text-field clearable
                          hide-details
                          outlined
                          type="number"
                          v-bind:prefix="questionDefinition.prefix"
                          v-bind:suffix="questionDefinition.suffix"
                          v-on:input="error = false"
                          v-model="model[questionDefinition.id]" />
          </v-col>
        </v-row>
        <v-expand-transition>
          <p class="error--text mb-0 mt-4"
             v-if="error">
            {{ $t('global.pleaseAnswerThisQuestion') }}
          </p>
        </v-expand-transition>
      </div>
    </v-card-text>
  </div>
</template>

<script>
  export default {
    computed: {
      value() {
        return this.model[this.questionDefinition.id]
      }
    },
    data: () => ({
      error: false
    }),
    methods: {
      validate() {
        if (!this.questionDefinition.required) {
          return true
        }

        if (this.value == null || this.value.length === 0) {
          this.error = true

          return false
        }

        return true
      }
    },
    name: 'TextQuestion',
    props: {
      model: Object,
      questionDefinition: Object
    }
  }
</script>