<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('number')"
                        v-bind:rules="[ rules.required ]"
                        v-model="salesAgent.number" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('companyName')"
                        v-model="salesAgent.companyName" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select filled
                    hide-details="auto"
                    v-bind:items="salutationItems"
                    v-bind:label="$t('salutation')"
                    v-bind:rules="[ rules.required ]"
                    v-model="salesAgent.salutation" />
        </v-col>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('title')"
                        v-model="salesAgent.title" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('givenName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="salesAgent.givenName" />
        </v-col>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('middleNames')"
                        v-model="salesAgent.middleNames" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('familyName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="salesAgent.familyName" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-field filled
                      hide-details="auto"
                      prepend-inner-icon="mdi-cake-variant"
                      type="date-of-birth"
                      v-bind:label="$t('dateOfBirth')"
                      v-bind:rules="[ rules.required ]"
                      v-model="salesAgent.dateOfBirth" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-field filled
                      hide-details="auto"
                      prepend-inner-icon="mdi-calendar-check"
                      v-bind:label="$t('dateOfJoining')"
                      v-bind:rules="[ rules.required ]"
                      v-model="salesAgent.dateOfJoining" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-subheader>{{ $t('address') }}</v-subheader>

    <v-card-text>
      <v-row>
        <v-col cols="9">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('streetName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="salesAgent.streetName" />
        </v-col>
        <v-col cols="3">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('streetNumber')"
                        v-bind:rules="[ rules.required ]"
                        v-model="salesAgent.streetNumber" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('postalCode')"
                        v-bind:rules="[ rules.required ]"
                        v-model="salesAgent.postalCode" />
        </v-col>
        <v-col cols="9">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('city')"
                        v-bind:rules="[ rules.required ]"
                        v-model="salesAgent.city" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-subheader>{{ $t('contactInformation') }}</v-subheader>

    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('email')"
                        v-bind:rules="[ rules.required ]"
                        v-model="salesAgent.email" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('phone')"
                        v-model="salesAgent.phone" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('phoneCellular')"
                        v-model="salesAgent.phoneCellular" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DateField from '@/components/common/DateField.vue'

  export default {
    components: {
      DateField
    },
    computed: {
      salutationItems() {
        return this.salutations.map(s => ({
          text: this.$t(s.label),
          value: s.value
        }))
      },
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        salutations: 'local/salutations'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'SalesAgentDetails',
    props: {
      salesAgent: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "address": "Adresse",
      "city": "Ort",
      "companyName": "Firmenname",
      "contactInformation": "Kontaktdaten",
      "dateOfBirth": "Geburtsdatum",
      "dateOfJoining": "Eintrittsdatum",
      "email": "E-Mail-Adresse",
      "familyName": "Nachname",
      "givenName": "Vorname",
      "middleNames": "Zweite Vornamen",
      "number": "Nummer",
      "phone": "Telefonnummer",
      "phoneCellular": "Telefonnummer (mobil)",
      "placeOfBirth": "Geburtsort",
      "postalCode": "Postleitzahl",
      "salutation": "Anrede",
      "streetName": "Straße",
      "streetNumber": "Hausnummer",
      "title": "Titel"
    },
    "en": {
      "address": "Address",
      "city": "City",
      "companyName": "Company name",
      "contactInformation": "Contact information",
      "dateOfBirth": "Date of birth",
      "dateOfJoining": "Date of joining",
      "email": "Email",
      "familyName": "Family name",
      "givenName": "Given name",
      "middleNames": "Middle names",
      "number": "Number",
      "phone": "Phone",
      "phoneCellular": "Phone (cellular)",
      "placeOfBirth": "Place of birth",
      "postalCode": "Postal code",
      "salutation": "Salutation",
      "streetName": "Street name",
      "streetNumber": "Street number",
      "title": "Title"
    }
  }
</i18n>