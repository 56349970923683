<template>
  <v-list nav>
    <v-list-item exact
                 link
                 v-bind:to="{ name: 'home' }">
      <v-list-item-icon>
        <v-icon color="accent">mdi-magnify</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('findConsultants') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item exact
                 link
                 v-bind:to="{ name: 'for-consultants' }">
      <v-list-item-icon>
        <v-icon color="accent">mdi-account-tie</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('forConsultants') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: 'PrimaryNavigationView'
  }
</script>

<i18n>
  {
    "de": {
      "findConsultants": "Berater finden",
      "forConsultants": "Für Berater"
    },
    "en": {
      "findConsultants": "Find consultants",
      "forConsultants": "For consultants"
    }
  }
</i18n>