<template>
  <v-form ref="form">
    <v-textarea flat
                hide-details="auto"
                rows="10"
                solo
                v-bind:counter="1000"
                v-bind:label="$t('pleaseWriteSomethingAboutYourCompany')"
                v-bind:rules="[ rules.maxLength(1000) ]"
                v-model="company.introduction" />
  </v-form>
</template>

<script>
  import rules from '@/mixins/v2/rules'

  export default {
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    mixins: [ rules ],
    name: 'CompanyIntroduction',
    props: {
      company: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "pleaseWriteSomethingAboutYourCompany": "Bitte schreiben Sie etwas über Ihr Unternehmen…"
    },
    "en": {
      "pleaseWriteSomethingAboutYourCompany": "Please write something about your company…"
    }
  }
</i18n>