<template>
  <div>
    <v-row class="mb-6"
           v-if="companies.length > 0">
      <v-spacer />
      <v-col cols="12"
             md="auto">
        <v-select flat
                  hide-details="auto"
                  prepend-inner-icon="mdi-sort"
                  solo
                  v-bind:items="sortItems"
                  v-bind:label="$t('sortBy')"
                  v-model="sortBy" />
      </v-col>
    </v-row>
    <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                     v-bind:items="companies"
                     v-bind:items-per-page="10"
                     v-bind:sort-by="sortBy"
                     v-bind:sort-desc="sortDesc">
      <template v-slot:no-data v-slot:no-results>
        <notification-with-icon icon="mdi-magnify"
                                v-bind:text="$t('thereAreNoCompaniesYet')"
                                v-bind:title="$t('noCompaniesYet')" />
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col cols="12"
                 lg="4"
                 sm="6"
                 v-bind:key="company.id"
                 v-for="company in props.items"
                 xl="3">
            <company-card v-bind:color="color"
                          v-bind:company="company" />
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
  import CompanyCard from '@/components/v2/base/CompanyCard.vue'
  import NotificationWithIcon from '@/components/v2/base/NotificationWithIcon.vue'

  export default {
    components: {
      CompanyCard,
      NotificationWithIcon
    },
    computed: {
      sortDesc () {
        return this.sortBy === 'overallRating'
      },
      sortItems() {
        return [
          {
            text: this.$t('name'),
            value: 'formattedName'
          },
          {
            text: this.$t('rating'),
            value: 'overallRating'
          }
        ]
      }
    },
    data: () => ({
      sortBy: 'overallRating'
    }),
    name: 'CompanyGrid',
    props: {
      color: String,
      companies: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "name": "Name",
      "noCompaniesYet": "Noch keine Unternehmen",
      "rating": "Bewertung",
      "sortBy": "Sortieren nach",
      "thereAreNoCompaniesYet": "Es sind noch keine Unternehmen vorhanden."
    },
    "en": {
      "name": "Name",
      "noCompaniesYet": "No companies yet",
      "rating": "Rating",
      "sortBy": "Sort by",
      "thereAreNoCompaniesYet": "There are no companies yet."
    }
  }
</i18n>