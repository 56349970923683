<template>
  <div>
    <v-card-text>
      <v-autocomplete clearable
                      hide-details="auto"
                      outlined
                      prepend-inner-icon="mdi-magnify"
                      v-bind:items="filteredConsultants | items({ text: c => `${c.formattedName} (${c.company?.name ?? 'Kein Unternehmen'}, ${c.email})`, value: 'id' })"
                      v-bind:label="$t('name')"
                      v-model="consultantId"
                      v-on:change="selectConsultant">
        <template v-slot:no-data>
          <v-list-item v-on:click="$emit('next')">
            <v-list-item-content>
              <v-list-item-title>{{ $t('unfortunatelyYourProfileDoesNotExistYetPleaseProceedToCreateANewProfile') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon color="accent">mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-autocomplete>
    </v-card-text>
    <v-card-text v-if="emailMismatch">
      <v-alert type="error">{{ $t('unfortunatelyTheEmailAddressSavedForThisProfileDoesNotMatchTheEmailAddressWithWhichYouRegisteredPleaseRegisterWithTheEmailAddressToClaimThisProfile', { email: consultantEmail }) }}</v-alert>
    </v-card-text>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      consultantEmail() {
        if (this.consultantId == null) {
          return null
        }

        const consultant = this.consultants.find(c => c.id === this.consultantId)

        return consultant?.email
      },
      filteredConsultants() {
        return this.consultants.filter(c => c.state === 'unclaimed')
      },
      ...mapGetters({
        consultants: 'base/consultants'
      })
    },
    data: () => ({
      consultantId: null,
      emailMismatch: false
    }),
    methods: {
      selectConsultant(id) {
        this.emailMismatch = false

        if (id == null) {
          return
        }

        const consultant = this.consultants.find(c => c.id === id)

        if (consultant.email !== this.email) {
          this.emailMismatch = true

          return
        }

        this.$emit('select-consultant', id)
      }
    },
    name: 'AccountClaim',
    props: {
      email: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "name": "Name",
      "unfortunatelyTheEmailAddressSavedForThisProfileDoesNotMatchTheEmailAddressWithWhichYouRegisteredPleaseRegisterWithTheEmailAddressToClaimThisProfile": "Leider stimmt die für dieses Profil hinterlegte E-Mail-Adresse nicht mit der E-Mail-Adresse überein, mit welcher Sie sich registriert haben. Bitte registrieren Sie sich mit der hinterlegten E-Mail-Adresse {email}, um dieses Profil zu beanspruchen.",
      "unfortunatelyYourProfileDoesNotExistYetPleaseProceedToCreateANewProfile": "Leider existiert ihr Profil noch nicht. Fahren Sie fort, um ein neues Profil zu erstellen."
    },
    "en": {
      "name": "Name",
      "unfortunatelyTheEmailAddressSavedForThisProfileDoesNotMatchTheEmailAddressWithWhichYouRegisteredPleaseRegisterWithTheEmailAddressToClaimThisProfile": "Unfortunately, the email address saved for this profile does not match the email address with which you registered. Please register with the stored email address {email} to claim this profile.",
      "unfortunatelyYourProfileDoesNotExistYetPleaseProceedToCreateANewProfile": "Unfortunately, your profile does not exist yet. Please proceed to create a new profile."
    }
  }
</i18n>