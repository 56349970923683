<template>
  <div>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />
      <div v-if="consultant != null && evaluation != null">
        <questionnaire-header v-bind:consultant="consultant" />

        <full-width-section color="secondary">
          <v-container>
            <v-row justify="center">
              <v-col cols="12"
                     lg="12"
                     xl="8">
                <h4 class="primary--text text-h4">{{ $t('thankYou') }}</h4>
                <h6 class="mb-6 primary--text text-h6">{{ $t('belowYouWillSeeYourPubliclyDisplayedReview') }}</h6>
                <p v-if="evaluation.overallRating < 4">{{ $t('lowerFourStars') }}</p>
                <evaluation-card color="white"
                                 v-bind:evaluation="evaluation" />
              </v-col>
            </v-row>
          </v-container>
        </full-width-section>

        <full-width-section color="white">
          <v-container>
            <v-row justify="center">
              <v-col cols="12"
                     lg="12"
                     xl="8">
                <h4 class="primary--text text-h4">{{ $t('yourProtocol') }}</h4>
                <h6 class="mb-6 primary--text text-h6">{{ $t('additionallyYouCanReviewYourAnswersAndOurNotesOnThemBelow') }}</h6>
                <evaluation-question-overview-card color="secondary"
                                                   v-bind:evaluation="evaluation" />
              </v-col>
            </v-row>
          </v-container>
        </full-width-section>
      </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import EvaluationCard from '@/components/v2/base/EvaluationCard.vue'
  import EvaluationQuestionOverviewCard from '@/components/v2/base/EvaluationQuestionOverviewCard.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import QuestionnaireHeader from '@/components/v2/questionnaire/QuestionnaireHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      await this.get(to.params.evaluationId)

      next()
    },
    components: {
      EvaluationCard,
      EvaluationQuestionOverviewCard,
      FullWidthSection,
      QuestionnaireHeader
    },
    computed: {
      ...mapGetters({
        consultingTopics: 'base/consultingTopics',
        getConsultant: 'base/consultant',
        getEvaluation: 'base/evaluation'
      })
    },
    async created() {
      await this.get(this.$route.params.evaluationId)
    },
    data: () => ({
      consultant: null,
      evaluation: null,
      loading: false
    }),
    methods: {
      async get(evaluationId) {
        this.loading = true

        await this.loadEvaluation({ id: evaluationId })

        this.evaluation = Object.assign({}, this.getEvaluation(evaluationId))

        await this.loadConsultant({ id: this.evaluation.consultantId })

        this.consultant = Object.assign({}, this.getConsultant(this.evaluation.consultantId))

        await this.loadConsultingTopics()

        this.loading = false
      },
      ...mapActions({
        loadConsultant: 'base/loadConsultant',
        loadConsultingTopics: 'base/loadConsultingTopics',
        loadEvaluation: 'base/loadEvaluation'
      })
    },
    name: 'EvaluationSummaryView'
  }
</script>

<i18n>
  {
    "de": {
      "additionallyYouCanReviewYourAnswersAndOurNotesOnThemBelow": "Zusätzlich können Sie nachfolgend Ihre Antworten und unsere Hinweise zu diesen nochmals einsehen.",
      "belowYouWillSeeYourPubliclyDisplayedReview": "Im Folgenden sehen Sie Ihre öffentlich angezeigte Bewertung.",
      "thankYou": "Vielen Dank",
      "yourProtocol": "Ihr Protokoll",
      "lowerFourStars": "Ihre Bewertung zeigt, dass möglicherweise noch Punkte verbessert werden können. Wir räumen Ihrem Berater diese Chance ein, sodass im Anschluss Sie und Ihr Berater zufriedener sind. Bis dahin wird die Bewertung gespeichert und in spätenstens 7 Tagen veröffentlicht."
    },
    "en": {
      "additionallyYouCanReviewYourAnswersAndOurNotesOnThemBelow": "Additionally you can review your answers and our notes on them below.",
      "belowYouWillSeeYourPubliclyDisplayedReview": "Below you will see your publicly displayed review.",
      "thankYou": "Thank you",
      "yourProtocol": "Your protocol",
      "lowerFourStars": "Your assessment shows that there may still be room for improvement. We will give your consultant this opportunity so that you and your consultant are more satisfied afterwards. Until then, the rating will be saved and published in 7 days at the latest."
    }
  }
</i18n>