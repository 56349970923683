<template>
  <div>
    <top-header v-bind:title="$t('salesAgent')" />
    <v-container>
    </v-container>
  </div>
</template>

<script>
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      TopHeader
    },
    name: 'SalesAgentAdd'
  }
</script>

<i18n>
  {
    "de": {
      "salesAgent": "Verkäufer"
    },
    "en": {
      "salesAgent": "Sales agent"
    }
  }
</i18n>