<template>
  <v-dialog hide-overlay
            persistent
            v-model="dialog"
            width="320">
    <v-card color="secondary"
            dark>
      <v-card-text class="pa-6">
        {{ $t('signingIn') }}
      </v-card-text>
      <v-expand-transition>
        <v-card-actions v-show="showCancel">
          <v-spacer />
          <v-btn color="deep-orange"
                 text
                 v-on:click="cancel">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-expand-transition>
      <v-progress-linear color="primary"
                         indeterminate />
    </v-card>
  </v-dialog>
</template>

<script>
import { signInCallbackMixin } from 'kayleen-vue-auth0-client'

export default {
  data: () => ({
    dialog: true,
    showCancel: false
  }),
  mixins: [ signInCallbackMixin ],
  mounted() {
    if (!!amplitude)
    {
      amplitude.track('Sign In');
    }
    setTimeout(() => this.showCancel = true, 2000)
  },
  name: 'SignInCallback'
}
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "signingIn": "Anmeldung erfolgt…"
    },
    "en": {
      "cancel": "Cancel",
      "signingIn": "Signing in…"
    }
  }
</i18n>