<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <company-details ref="companyDetails"
                       v-bind:company="company" />
    </v-card>
  </div>
</template>

<script>
  import CompanyDetails from '@/components/v2/account/CompanyDetails'

  export default {
    components: {
      CompanyDetails
    },
    methods: {
      validate() {
        return this.$refs.companyDetails.validate()
      }
    },
    name: 'CompanyDetailsCard',
    props: {
      company: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Stammdaten"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>