<template>
  <v-card color="secondary"
          flat>
    <v-tabs background-color="primary" centered
            dark
            grow
            v-model="options.type">
      <v-tab href="#perimeter">{{ $t('find') }}</v-tab>
      <v-tab href="#name">{{ $t('rate') }}</v-tab>
      <v-tab href="#ranking">{{ $t('compare') }}</v-tab>
    </v-tabs>
    <v-tabs-items class="transparent"
                  v-model="options.type">
      <v-tab-item value="perimeter">
        <v-card-text class="pa-6">
          <h4 class="primary--text text-h4">{{ $t('makeFinancialSuccessAPriority') }}</h4>
          <h6 class="mb-12 primary--text text-h6">{{ $t('layTheFoundationForLongTermAndSustainableFinancialSatisfaction') }}</h6>
          <consultant-perimeter-search v-bind:options="options"
                                       v-on:search="$emit('search', options)" />
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="name">
        <v-card-text class="pa-6">
          <h4 class="primary--text text-h4">{{ $t('rateYourConsultantNow') }}</h4>
          <h6 class="mb-12 primary--text text-h6">{{ $t('helpOthersBySharingYourPersonalExperiencesAndAssessments') }}</h6>
          <consultant-name-search v-bind:options="options"
                                  v-on:search="$emit('search', options)" />
        </v-card-text>
      </v-tab-item>
      <v-tab-item value="ranking">
        <v-card-text class="pa-6">
          <h4 class="primary--text text-h4">{{ $t('discoverTheBestConsultants') }}</h4>
          <h6 class="mb-12 primary--text text-h6">{{ $t('browseOurRegionalRankingsForTheBestOfTheBest') }}</h6>
          <p class="mb-0">{{ $t('wereWorkingHardToMakeThisFeatureAvailableForYouStayTunedItsJustAboutToBeReleasedAndWillBeReadyForYouSoon') }}</p>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <v-progress-linear color="primary"
                       indeterminate
                       v-bind:active="loading" />
  </v-card>
</template>

<script>
  import ConsultantNameSearch from '@/components/v2/base/ConsultantNameSearch.vue'
  import ConsultantPerimeterSearch from '@/components/v2/base/ConsultantPerimeterSearch.vue'

  export default {
    components: {
      ConsultantNameSearch,
      ConsultantPerimeterSearch
    },
    data: () => ({
      options: {
        consultingTopics: [],
        perimeter: 50
      }
    }),
    name: 'ConsultantSearchCard',
    props: {
      loading: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "browseOurRegionalRankingsForTheBestOfTheBest": "Durchstöbern Sie unser regionales Ranking nach den Besten der Besten",
      "compare": "Vergleichen",
      "discoverTheBestConsultants": "Entdecken Sie die besten Berater",
      "find": "Finden",
      "makeFinancialSuccessAPriority": "Machen Sie finanziellen Erfolg zur Priorität",
      "helpOthersBySharingYourPersonalExperiencesAndAssessments": "Helfen Sie anderen, indem Sie Ihre persönlichen Erfahrungen und Einschätzungen teilen",
      "layTheFoundationForLongTermAndSustainableFinancialSatisfaction": "Legen Sie den Grundstein für eine langfristige und nachhaltige finanzielle Zufriedenheit",
      "rate": "Bewerten",
      "rateYourConsultantNow": "Jetzt Ihren Berater bewerten",
      "wereWorkingHardToMakeThisFeatureAvailableForYouStayTunedItsJustAboutToBeReleasedAndWillBeReadyForYouSoon": "Wir arbeiten mit Hochdruck daran, diese Funktion für Sie verfügbar zu machen. Bleiben Sie dran, sie steht kurz vor der Veröffentlichung und wird bald für Sie bereit sein!"
    },
    "en": {
      "browseOurRegionalRankingsForTheBestOfTheBest": "Browse our regional rankings for the best of the best",
      "compare": "Compare",
      "discoverTheBestConsultants": "Discover the best consultants",
      "find": "Find",
      "makeFinancialSuccessAPriority": "Make financial success a priority",
      "helpOthersBySharingYourPersonalExperiencesAndAssessments": "Help others by sharing your personal experiences and assessments",
      "layTheFoundationForLongTermAndSustainableFinancialSatisfaction": "Lay the foundation for long-term and sustainable financial satisfaction",
      "rate": "Rate",
      "rateYourConsultantNow": "Rate your consultant now",
      "wereWorkingHardToMakeThisFeatureAvailableForYouStayTunedItsJustAboutToBeReleasedAndWillBeReadyForYouSoon": "We're working hard to make this feature available for you. Stay tuned, it's just about to be released and will be ready for you soon!"
    }
  }
</i18n>