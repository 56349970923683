import Vue from 'vue'

//import CompanyService from '@/api/base/company-service'
//import SalesAgentService from '@/api/base/sales-agent-service'

import settings from '@/settings'

//const companyService = new CompanyService(settings.apiUrl)
//const salesAgentService = new SalesAgentService(settings.apiUrl)

export default {
  actions: {
    // company
    // =======
    async createCompany({ commit, rootGetters }, { company }) {
      const accessToken = rootGetters['auth/accessToken']

      //const result = await companyService.create(company, { accessToken })

      company.id = 20 //result.id

      commit('createCompany', { company })
    },
    async deleteCompany({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      //const result = await companyService.delete(id, { accessToken })

      if (true) { // result
        commit('deleteCompany', { id })
      }
    },
    async loadCompany({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      /*const company = await companyService.find(id, { accessToken })

      if (getters.company(id) != null) {
        commit('updateCompany', { company })
      } else {
        commit('createCompany', { company })
      }*/
    },
    async loadCompanies({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      //const companies = await companyService.getAll({ accessToken })

      //commit('setCompanies', { companies })
    },
    async updateCompany({ commit, rootGetters }, { company }) {
      const accessToken = rootGetters['auth/accessToken']

      //const result = await companyService.update(company.id, company, { accessToken })

      if (true) { // result
        commit('updateCompany', { company })
      }
    },

    // salesAgents
    // ===========
    async createSalesAgent({ commit, rootGetters }, { salesAgent }) {
      const accessToken = rootGetters['auth/accessToken']

      //const result = await salesAgentService.create(salesAgents, { accessToken })

      salesAgent.id = 17 //result.id

      commit('createSalesAgent', { salesAgent })
    },
    async deleteSalesAgent({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      //const result = await salesAgentService.delete(id, { accessToken })

      if (true) { // result
        commit('deleteSalesAgent', { id })
      }
    },
    async loadSalesAgent({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      /*const salesAgent = await salesAgentService.find(id, { accessToken })

      if (getters.salesAgent(id) != null) {
        commit('updateSalesAgent', { salesAgent })
      } else {
        commit('createSalesAgent', { salesAgent })
      }*/
    },
    async loadSalesAgents({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      //const salesAgents = await salesAgentService.getAll({ accessToken })

      //commit('setSalesAgents', { salesAgents })
    },
    async updateSalesAgent({ commit, rootGetters }, { salesAgent }) {
      const accessToken = rootGetters['auth/accessToken']

      //const result = await salesAgentService.update(salesAgent.id, salesAgent, { accessToken })

      if (true) { // result
        commit('updateSalesAgent', { salesAgent })
      }
    }
  },
  getters: {
    company: state => id => state.companies.find(c => c.id === id),
    companyLabel: (state, getters) => id => {
      const company = getters.company(id)

      if (company == null) {
        return null
      }

      return `${company.companyName}`
    },
    companies: state => state.companies,
    salesAgent: state => id => state.salesAgents.find(s => s.id === id),
    salesAgentLabel: (state, getters) => id => {
      const salesAgent = getters.salesAgent(id)

      if (salesAgent == null) {
        return null
      }

      return `${salesAgent.givenName} ${salesAgent.familyName} (${salesAgent.number})`
    },
    salesAgents: state => state.salesAgents
  },
  mutations: {
    // companies
    // =========
    createCompany(state, { company }) {
      state.companies.push(company)
    },
    deleteCompany(state, { id }) {
      const index = state.companies.findIndex(c => c.id === id)

      if (index !== -1) {
        state.companies.splice(index, 1)
      }
    },
    setCompanies(state, { companies }) {
      state.companies = companies
    },
    updateCompany(state, { company }) {
      const index = state.companies.findIndex(c => c.id === company.id)

      Vue.set(state.companies, index, company)
    },

    // salesAgents
    // ===========
    createSalesAgent(state, { salesAgent }) {
      state.salesAgents.push(salesAgent)
    },
    deleteSalesAgent(state, { id }) {
      const index = state.salesAgents.findIndex(s => s.id === id)

      if (index !== -1) {
        state.salesAgents.splice(index, 1)
      }
    },
    setSalesAgents(state, { salesAgents }) {
      state.salesAgents = salesAgents
    },
    updateSalesAgent(state, { salesAgent }) {
      const index = state.salesAgents.findIndex(s => s.id === salesAgent.id)

      Vue.set(state.salesAgents, index, salesAgent)
    }
  },
  namespaced: true,
  state: {
    companies: [
      {
        id: 1,
        companyName: 'Muster GmbH & Co. KG',
        streetName: 'Beispielweg',
        streetNumber: '10',
        postalCode: '1234',
        city: 'Demo',
        canton: 'zh',
        country: 'Schweiz'
      },
      {
        id: 2,
        companyName: 'Beispiel GmbH',
        streetName: 'Hauptstr.',
        streetNumber: '5',
        postalCode: '3456',
        city: 'Musterstadt',
        canton: 'zh',
        country: 'Schweiz'
      }
    ],
    salesAgents: [
      {
        id: 1,
        salutation: 'mrs',
        number: '123',
        givenName: 'Sarah',
        familyName: 'Muster',
        companyName: 'Beispiel GmbH',
        streetName: 'Beispielweg',
        streetNumber: '10',
        postalCode: '1234',
        city: 'Demo',
        canton: 'zh',
        country: 'Schweiz',
        commissionLevelId: 3
      },
      {
        id: 2,
        salutation: 'mr',
        number: '123',
        givenName: 'Heinrich',
        familyName: 'Hermann',
        companyName: 'Versicherungs AG',
        streetName: 'Hauptstr.',
        streetNumber: '5',
        postalCode: '3456',
        city: 'Musterstadt',
        canton: 'zh',
        country: 'Schweiz',
        commissionLevelId: 2
      }
    ]
  }
}