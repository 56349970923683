import settings from './settings'

export default async () => {
  const loadMapKitJs = () => new Promise(resolve => {
    const element = document.createElement('script')

    element.addEventListener('load', resolve, { once : true })
    element.crossOrigin = 'anonymous'
    element.src = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js'

    document.head.appendChild(element)
  })

  await loadMapKitJs()

  mapkit.init({
    authorizationCallback: done => {
        done(settings.mapKitJsToken)
    },
    language: "de"
  })
}