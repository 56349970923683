import settings from '@/settings'

export default {
  actions: {
    async setSearchResults({ commit }, { searchResults}) {
      commit('setSearchResults', { searchResults })
    },
  },
  getters: {
    searchResults: state => state.searchResults,
    attachmentBaseUrl: _ => settings.staticUri,
    canton: state => value => state.cantons.find(c => c.value === value),
    cantonLabel: state => value => state.cantons.find(c => c.value === value)?.label,
    cantons: state => state.cantons,
    companyLinkState: state => value => state.companyLinkStates.find(c => c.value === value),
    companyLinkStates: state => state.companyLinkStates,
    commissionLevel: state => id => state.commissionLevels.find(c => c.id === value),
    commissionLevelLabel: state => id => state.commissionLevels.find(c => c.id === value)?.title,
    commissionLevels: state => state.commissionLevels,
    consultantState: state => value => state.consultantStates.find(c => c.value === value),
    consultantStateLabel: state => value => state.consultantStates.find(c => c.value === value)?.label,
    consultantStates: state => state.consultantStates,
    country: state => value => state.countries.find(c => c.value === value),
    countryLabel: state => value => state.countries.find(c => c.value === value)?.label,
    countries: state => state.countries,
    perimeter: state => value => state.perimeters.find(p => p.value === value),
    perimeterLabel: state => value => state.perimeters.find(p => p.value === value)?.label,
    perimeters: state => state.perimeters,
    ratingCount: state => value => state.ratingCounts.find(r => r.value === value),
    ratingCountLabel: state => value => state.ratingCounts.find(r => r.value === value)?.label,
    ratingCounts: state => state.ratingCounts,
    honorific: state => value => state.honorifics.find(h => h.value === value),
    honorificLabel: state => value => state.honorifics.find(h => h.value === value)?.label,
    honorifics: state => state.honorifics
  },
  mutations: {
    setSearchResults(state, { searchResults }) {
      state.searchResults = searchResults
    },
  },
  namespaced: true,
  state: {
    cantons: [
      {
        label: 'global.appenzellInnerRhodes',
        value: 'ai'
      },
      {
        label: 'global.appenzellOuterRhodes',
        value: 'ar'
      },
      {
        label: 'global.aargau',
        value: 'ag'
      },
      {
        label: 'global.baselCity',
        value: 'bs'
      },
      {
        label: 'global.baselLandschaft',
        value: 'bl'
      },
      {
        label: 'global.bern',
        value: 'be'
      },
      {
        label: 'global.freiburg',
        value: 'fr'
      },
      {
        label: 'global.geneva',
        value: 'ge'
      },
      {
        label: 'global.glarus',
        value: 'gl'
      },
      {
        label: 'global.graubunden',
        value: 'gr'
      },
      {
        label: 'global.jura',
        value: 'ju'
      },
      {
        label: 'global.lucerne',
        value: 'lu'
      },
      {
        label: 'global.neuchatel',
        value: 'ne'
      },
      {
        label: 'global.nidwalden',
        value: 'nw'
      },
      {
        label: 'global.obwalden',
        value: 'ow'
      },
      {
        label: 'global.schaffhausen',
        value: 'sh'
      },
      {
        label: 'global.schwyz',
        value: 'sz'
      },
      {
        label: 'global.solothurn',
        value: 'so'
      },
      {
        label: 'global.stGallen',
        value: 'sg'
      },
      {
        label: 'global.thurgau',
        value: 'tg'
      },
      {
        label: 'global.ticino',
        value: 'ti'
      },
      {
        label: 'global.uri',
        value: 'ur'
      },
      {
        label: 'global.valais',
        value: 'vs'
      },
      {
        label: 'global.vaud',
        value: 'vd'
      },
      {
        label: 'global.zug',
        value: 'zg'
      },
      {
        label: 'global.zurich',
        value: 'zh'
      }
    ],
    companyLinkStates: [
      {
        label: 'global.unlinked',
        value: 'unlinked'
      },
      {
        label: 'global.requestedByConsultant',
        value: 'requestedByConsultant'
      },
      {
        label: 'global.requestedByCompany',
        value: 'requestedByCompany'
      },
      {
        label: 'global.accepted',
        value: 'accepted'
      },
      {
        label: 'global.administrator',
        value: 'administrator'
      }
    ],
    commissionLevels: [
      {
        id: 1,
        title: 'Junior Sales Agent'
      },
      {
        id: 2,
        title: 'Senior Sales Agent'
      },
      {
        id: 3,
        title: 'Junior Sales Expert'
      },
      {
        id: 4,
        title: 'Senior Sales Expert'
      },
      ,
      {
        id: 5,
        title: 'Master Sales Expert'
      }
    ],
    consultantStates: [
      {
        label: 'global.active',
        value: 'active'
      },
      {
        label: 'global.unclaimed',
        value: 'unclaimed'
      },
    ],
    countries: [
      {
        label: 'global.switzerland',
        value: 'ch'
      }
    ],
    perimeters: [
      {
        label: '5 km',
        value: 5
      },
      {
        label: '10 km',
        value: 10
      },
      {
        label: '20 km',
        value: 20
      },
      {
        label: '30 km',
        value: 30
      },
      {
        label: '40 km',
        value: 40
      },
      {
        label: '50 km',
        value: 50
      }
    ],
    ratingCounts: [
      {
        label: '> 50',
        value: 50
      },
      {
        label: '> 100',
        value: 100
      },
      {
        label: '> 200',
        value: 200
      },
      {
        label: '> 500',
        value: 500
      },
      {
        label: '> 1000',
        value: 1000
      }
    ],
    searchResults: [],
    honorifics: [
      {
        label: 'global.mr',
        value: 'mr'
      },
      {
        label: 'global.mrs',
        value: 'mrs'
      },
      {
        label: 'global.mx',
        value: 'mx'
      }
    ]
  }
}