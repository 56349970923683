<template>
  <div>
    <company-invite-dialog ref="companyInviteDialog"
                           v-model="companyInviteDialog" />

    <v-progress-linear indeterminate
                       v-bind:active="loading" />
    <top-header color="white"
                v-bind:title="$t('allCompanies')">
      <template slot="body">
        <div class="mb-12 mt-2 text-body-1">
          {{ $t('allCompaniesOnConfederia') }}
        </div>
      </template>
    </top-header>
    <div>

      <full-width-section color="secondary">
        <v-container>
          <v-row justify="center">
            <v-col>
              <company-grid v-bind:companies="companies" />
            </v-col>
          </v-row>
        </v-container>
      </full-width-section>

      <full-width-section color="secondary">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-icon class="mb-2"
                    color="primary"
                    size="64">
              mdi-magnify
            </v-icon>
            <h4 class="mb-6 text-h4 primary--text">{{ $t('missingYourCompany') }}</h4>
            <p class="mb-12">{{ $t('isYourCompanyNotOnConfederiaYetWeWouldBeHappyToEstablishContact') }}</p>
            <v-btn
                  depressed
                  color="primary"
                  v-on:click="showCompanyInviteDialog"
                  x-large>
            <v-icon color="accent"
                    left>
              mdi-plus
            </v-icon>
            <span>{{ $t('sendRequest') }}</span>
          </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import CompanyGrid from '@/components/v2/base/CompanyGrid.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'
  import CompanyInviteDialog from '@/components/v2/base/CompanyInviteDialog.vue'

  export default {
    components: {
      CompanyGrid,
      FullWidthSection,
      TopHeader,
      CompanyInviteDialog
    },
    computed: {
      ...mapGetters({
        companies: 'base/companies'
      })
    },
    async created() {
      this.loading = true

      await this.loadCompanies()

      this.loading = false
    },
    data: () => ({
      loading: false,
      companyInviteDialog: false
    }),
    methods: {
      async inviteCompany(email) {
        let clientSideEmail = {
          recipientEmail: 'info@confederia.ch',
          recipientName: 'Confederia',
          templateName: 'DefaultText',
          values: {
            subject: `Neue Unternehmesanfrage`,
            headline: `${email.name} möchte ${email.companyName} gerne auf Confederia sehen!`,
            text: `Hallo,<br><br>Ein Berater ${email.name} möchte gerne sein Unternehmen  ${email.companyName} auf Confederia sehen.<br><br>Name des Beraters: ${email.name}<br>E-Mail-Adresse des Beraters: ${email.email}<br>Name des Unternehmens: ${email.companyName}<br>E-Mail des Unternehmens: ${email.companyEmail}<br>Name des Ansprechpartners: ${email.contactName}<br>E-Mail des Ansprechpartners: ${email.contactEmail}<br>Telefonnummer des Ansprechpartners: ${email.contactPhone}`,
            closingText: '<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },

      async showCompanyInviteDialog() {
        await this.$refs.companyInviteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.inviteCompany(r)

          this.loading = false
        })
      },
      ...mapActions({
        createEmail: 'base/createEmail',
        loadCompanies: 'base/loadCompanies'
      })
    },
    name: 'CompaniesView'
  }
</script>

<i18n>
  {
    "de": {
      "allCompanies": "Alle Unternehmen",
      "allCompaniesOnConfederia": "Alle Unternehmen auf Confederia",
      "missingYourCompany": "Ihr Unternehmen fehlt?",
      "isYourCompanyNotOnConfederiaYetWeWouldBeHappyToEstablishContact": "Ihr Unternehmen ist noch nicht auf Confederia? Gerne stellen wir den Kontakt her!",
      "sendRequest": "Jetzt anfragen"
    },
    "en": {
      "allCompanies": "All companies",
      "allCompaniesOnConfederia": "All companies on Confederia",
      "missingYourCompany": "Missing your company?",
      "isYourCompanyNotOnConfederiaYetWeWouldBeHappyToEstablishContact": "Is your company not on Confederia yet? We would be happy to establish contact!",
      "sendRequest": "Send request"
    }
  }
</i18n>