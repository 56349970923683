<template>
  <div>
    <v-form ref="form">
      <v-card-text>
        <p>Bitte beachten Sie, dass Sie Confederia derzeit in einer Testversion befindet. Die Nutzung dieser Version ist völlig kostenlos. Wir möchten Sie jedoch darauf aufmerksam machen, dass diese Testversion jederzeit und ohne vorherige Ankündigung eingestellt werden kann.</p>
        <p>Diese Testversion ist nicht für die endgültige Nutzung vorgesehen. Alle während dieser Testphase eingegebenen und generierten Daten können jederzeit und ohne Vorankündigung gelöscht werden. Wir empfehlen daher, keine kritischen oder unersetzlichen Daten in dieser Testumgebung zu speichern oder zu verarbeiten.</p>
        <p class="mb-0">Wir danken Ihnen für Ihr Verständnis und Ihre Mitwirkung bei dieser Testphase. Ihre Rückmeldungen und Erfahrungen sind für die Verbesserung und Weiterentwicklung dieses Produkts unerlässlich.</p>
      </v-card-text>
      <v-card-text>
        <v-checkbox v-bind:rules="[ rules.required ]"
                    v-model="consultant.acceptsPrivacyPolicy">
          <template v-slot:label>
            <div v-html="$t('iAgreeWithThePrivacyPolicy')"></div>
          </template>
        </v-checkbox>
        <v-checkbox v-bind:rules="[ rules.required ]"
                    v-model="consultant.termsAndConditions">
          <template v-slot:label>
            <div v-html="$t('iAgreeWithTheTermsAndConditions')"></div>
          </template>
        </v-checkbox>
      </v-card-text>
    </v-form>
  </div>
</template>

<script>
  import rules from '@/mixins/v2/rules'

  export default {
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    mixins: [ rules ],
    name: 'AccountCompletionDetails',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "iAgreeWithThePrivacyPolicy": "Ich erkläre mich mit der <a href=\"#\">Datenschutzerklärung</a> einverstanden.",
      "iAgreeWithTheTermsAndConditions": "Ich erkläre mich mit den <a href=\"#\">allgemeinen Geschäftsbedingungen (AGB)</a> einverstanden."
    },
    "en": {
      "iAgreeWithThePrivacyPolicy": "I agree with the <a href=\"#\">privacy policy</a>.</a>",
      "iAgreeWithTheTermsAndConditions": "I agree with the <a href=\"#\">general terms and conditions.</a>"
    }
  }
</i18n>