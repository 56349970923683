<template>
  <language-select ref="languageSelect"
                   v-model="consultant.languages" />
</template>

<script>
  import LanguageSelect from '@/components/v2/account/LanguageSelect'

  export default {
    components: {
      LanguageSelect
    },
    methods: {
      validate() {
        return this.$refs.languageSelect.validate()
      }
    },
    name: 'AccountLanguages',
    props: {
      consultant: Object
    }
  }
</script>