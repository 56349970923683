<template>
  <v-card flat
          v-bind:color="color">
    <v-card-text class="pa-6">
      <h2 class="text-h2 accent--text mb-3">{{ value }}</h2>
      <h5 class="text-h6 white--text">{{ title }}</h5>

    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'NumberCard',
    props: {
      title: String,
      value: String,
      color: String
    }
  }
</script>