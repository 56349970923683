<template>
  <div>
    <top-header color="secondary"
                v-bind:title="$t('termsOfService')">
    </top-header>
    <full-width-section color="transparent">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 xl="8">

              <v-btn depressed
                    color="secondary"
                    href="/files/agb.pdf" target="_blank"
                    x-large
                    class="primary--text">
              <v-icon color="pirmary"
                      left>
                mdi-file-pdf-box
              </v-icon>
              <span>AGB</span>
            </v-btn><br>

            <v-btn depressed
                    color="secondary"
                    href="/files/richtlinien-fuer-berater.pdf" target="_blank"
                    x-large
                    class="primary--text mt-4">
              <v-icon color="pirmary"
                      left>
                mdi-file-pdf-box
              </v-icon>
              <span>Richtlinien für Berater</span>
            </v-btn><br>

            <v-btn depressed
                    color="secondary"
                    href="/files/richtlinien-fuer-bewerter.pdf" target="_blank"
                    x-large
                    class="primary--text mt-4">
              <v-icon color="pirmary"
                      left>
                mdi-file-pdf-box
              </v-icon>
              <span>Richtlinien für Bewertende</span>
            </v-btn>

          </v-col>
        </v-row>
      </v-container>
    </full-width-section>
  </div>
</template>

<script>
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      FullWidthSection,
      TopHeader
    },
    name: 'TermsOfService'
  }
</script>

<i18n>
  {
    "de": {
      "termsOfService": "AGB"
    },
    "en": {
      "termsOfService": "Terms of service"
    }
  }
</i18n>