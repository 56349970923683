<template>
  <div>
    <v-dialog v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="500"
              persistent>
      <v-card>
        <v-card-title class="headline primary--text">
          {{ $t('cookieNotice') }}
        </v-card-title>
        <v-card-text>
          {{ $t('text') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text
                 small
                 v-on:click="acceptRequiredOnly">
            {{ $t('acceptRequiredOnly') }}
          </v-btn>
          <v-btn depressed
                 color="primary"
                 v-on:click="acceptAll">
            {{ $t('acceptAll') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data: () => ({
      confirmation: null,
      resolve: null
    }),
    methods: {
      acceptRequiredOnly() {
        this.$emit('input', false)
        this.resolve(false)
      },
      acceptAll() {
        localStorage.setItem('cookieDialogShown', true)
        localStorage.setItem('analyticCookiesEnabled', true)


        this.$emit('input', false)
        this.$emit('confirm')
        this.resolve(true)
      },
      async open(resolve) {
        if (localStorage.getItem('cookieDialogShown')) {
          return
        }

        this.resolve = resolve
        this.$emit('input', true)
      }
    },
    name: 'CookieDialog',
    props: {
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "acceptAll": "Alle akzeptieren",
      "acceptRequiredOnly": "Nur erforderliche",
      "text": "Wir verwenden Cookies, um Ihnen die bestmögliche Benutzererfahrung auf unserer Website zu bieten. Diese Cookies ermöglichen es uns, Analysen durchzuführen und die Leistung unserer Website zu verbessern.",
      "cookieNotice": "Cookie-Hinweis"
    },
    "en": {
      "acceptAll": "Accept all",
      "acceptRequiredOnly": "Accept required only",
      "text": "We use cookies to provide you with the best possible user experience on our website. These cookies enable us to conduct analyses and enhance the performance of our website. By using our website, you agree to the use of cookies in accordance with our privacy policy. Click here to learn more or to change your cookie settings.",
      "cookieNotice": "Cookie Notice"
    }
  }
</i18n>