<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('socialMedia') }}</v-toolbar-title>
      </v-toolbar>
      <company-social-details ref="companySocialDetails"
                              v-bind:company="company" />
    </v-card>
  </div>
</template>

<script>
  import CompanySocialDetails from '@/components/v2/account/CompanySocialDetails'

  export default {
    components: {
      CompanySocialDetails
    },
    name: 'CompanySocialDetailsCard',
    methods: {
      validate() {
        return this.$refs.companySocialDetails.validate()
      }
    },
    props: {
      company: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "socialMedia": "Soziale Netzwerke"
    },
    "en": {
      "socialMedia": "Social media"
    }
  }
</i18n>