import Vue from 'vue'

import App from './App.vue'
import './filters'
import initMapKitJs from './map-kit-js'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

const init = async () => {
  await initMapKitJs()

  new Vue({
    i18n,
    render: h => h(App),
    router,
    store,
    vuetify
  }).$mount('#app')
}

init()

import VueMask from 'v-mask'
Vue.use(VueMask);