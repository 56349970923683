<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-select filled
                    hide-details="auto"
                    v-bind:items="commissionLevels | items({ text: 'title', value: 'id' })"
                    v-bind:label="$t('level')"
                    v-bind:rules="[ rules.required ]"
                    v-model="salesAgent.commissionLevelId" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        commissionLevels: 'local/commissionLevels'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'SalesAgentCommissionDetails',
    props: {
      salesAgent: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "level": "Stufe"
    },
    "en": {
      "level": "Level"
    }
  }
</i18n>