<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="text-h5 pb-2">{{ $t('aboutMe') }}</h2>
        <p>{{ consultant.introduction }}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'Rating',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "aboutMe": "Über mich"
    },
    "en": {
      "aboutMe": "About me"
    }
  }
</i18n>