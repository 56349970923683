<template>
  <div>
          <h4 class="primary--text text-h4">{{ $t('ourPlans') }}</h4>
          <h6 class="mb-6 primary--text text-h6">{{ $t('thePerfectPlansForYouOrYourCompany') }}</h6>
          <p class="mb-12" v-if="internal">Sie befinden sich im Testzeitraum. Aktuell testen Sie „Confederia Professional“ und können auf die darin enthaltenen Funktionen zugreifen. So bald Sie Confederia ausgiebig getestet haben, wählen Sie bitte Ihr gewünschtes Abonnent und schließen Sie die Zahlung ab!</p>

          <v-row>
            <v-col cols="12"
                   md="4"
                   v-bind:key="plan.id"
                   v-for="plan in plans">
              <v-card flat
                      v-bind:color="plan.highlight ? 'accent' : 'white'"
                      class="fill-height"
                      v-bind:class="plan.priceId === currentPriceId ? 'current' : ''">
                <v-card-text class="pb-0">
                  <v-row>
                    <v-col>
                      <img alt="Logo icon"
                    class="icon-pricing"
                    src="/images/v2/icon.svg" />
                    </v-col>
                    <v-col cols="auto">
                      <v-chip class="text-overline" label v-bind:color="plan.offerColor">{{ plan.offerText }}</v-chip>
                    </v-col>
                  </v-row>

                </v-card-text>
                <v-card-text>
                  <h6 v-bind:class="`${plan.highlight ? 'black--text' : 'black--text' } mb-6 text-h4`">{{ plan.name }}</h6>

                  <p class="mb-6 text-body-1">{{ plan.text }}</p>

                  <h6 v-bind:class="`${plan.highlight ? 'black--text' : 'black--text' } text-h6 mb-0 mt-4`">
                    <span v-if="!!plan.value"><span class="text-h4">{{ plan.value }}</span> CHF</span><span v-else>Auf Anfrage<span class="text-h4">{{ plan.value }}</span></span>
                  </h6>
                  <p class="mb-6" v-if="!!plan.valueSuffix">{{ plan.valueSuffix }}</p><p v-else class="mb-6">&nbsp;</p>
                  
                  <v-btn block
                         class="mb-2"
                         depressed
                         v-bind:color="plan.highlight ? 'white' : 'primary'"
                         v-bind:dark="!plan.highlight"
                         v-on:click="$emit('show-dialog')"
                         v-if="plan.requestsOnly"
                         x-large>
                    <v-icon color="accent"
                            left>
                      mdi-send
                    </v-icon>
                    <span>{{ $t('contactUsNow') }}</span>
                  </v-btn>

                  <v-btn block
                         class="mb-2"
                         depressed
                         v-bind:color="plan.highlight ? 'white' : 'primary'"
                         v-bind:dark="!plan.highlight"
                         v-on:click="$emit('subscribe', plan.priceId)"
                         v-else-if="internal"
                         x-large>
                    <v-icon color="accent"
                            left>
                      mdi-account-check
                    </v-icon>
                    <span>{{ $t('subscribeNow') }}</span>
                  </v-btn>
                  
                  <v-btn block
                         class="mb-2"
                         depressed
                         v-bind:color="plan.highlight ? 'white' : 'primary'"
                         v-bind:dark="!plan.highlight"
                         v-bind:to="{ name: 'account-create' }"
                         v-else
                         x-large>
                    <v-icon color="accent"
                            left>
                      mdi-account-check
                    </v-icon>
                    <span>{{ $t('tryNow') }}</span>
                  </v-btn>

                  <p class="mb-6 text-caption">Kostenlose 14-tägige Testversion. Keine Kreditkarte erforderlich.</p>

                  <template v-if="plan.includesProfessional">
                    <div class="subtitle-height mb-0">
                      <v-row align="center">
                        <v-col>
                          <p class="font-weight-bold mb-0">Professional-Plan</p>
                          <p>für alle Mitarbeiter</p>
                        </v-col>
                        <v-col>
                          <p class="mb-0"><span class="text-h5">79 </span> CHF</p>
                          <p><small>pro Benutzer und Monat</small></p>
                        </v-col>
                      </v-row>
                    </div>
                  </template>
                  <template v-else>
                    <p class="font-weight-bold subtitle-height">{{ plan.featureTitle }}</p>
                  </template>

                  <v-row v-bind:key="index"
                         v-for="(feature, index) in plan.features">
                    <v-col cols="auto">
                      <v-icon v-bind:color="feature.name.length === 0 ? 'transparent' : plan.highlight ? feature.active ? 'primary' : 'rgba(255, 255, 255, 0.25)' : feature.active ? 'accent' : 'grey lighten-2'">
                       {{ feature.active ? 'mdi-check-circle' : 'mdi-circle' }}
                      </v-icon>
                    </v-col>
                    <v-col>
                      <p v-bind:class="`${plan.highlight ? feature.active ? 'black--text' : 'translucent--text' : feature.active ? 'black-text' : 'grey--text text--lighten-1'} mb-0`">{{ feature.name }}</p>
                    </v-col>
                  </v-row>


                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
</template>

<script>
  export default {
    data: () => ({
      plans: [
        {
          id: 1,
          priceId: 'price_1OQFrjHPVurj6SLMoOVSs0dz',
          name: 'Essential',
          text: 'Grundlegende Funktionen für jeden Benutzer',
          offerText: '',
          offerColor: 'white',
          features: [
            { active: true, name: 'Persönliches Beraterprofil' },
            { active: true, name: 'Unbegrenzte Anfragen' },
            { active: true, name: 'Bewertungen verifizieren' },
            { active: true, name: 'Bewertungen kommentieren' }
          ],
          value: 79,
          valueSuffix: 'pro Benutzer und Monat',
          featureTitle: 'Essentielle Funktionen'
        },
        {
          id: 2,
          priceId: 'price_1OQFs4HPVurj6SLM7ufRMFA6',
          highlight: true,
          name: 'Professional',
          text: 'Leistungsfähige Funktionen für den Berater von heute',
          offerText: 'Am beliebtesten',
          offerColor: 'primary',
          features: [
            { active: true, name: 'Calenso - Terminbuchungstool' },
            { active: true, name: 'Aufschub vor Veröffentlichung von kritischen Bewertungen' },
            { active: true, name: 'Aufnahme in die öffentliche Rangliste' },
            { active: true, name: 'Persönliche Statistiken' }
          ],
          value: 99,
          valueSuffix: 'pro Benutzer und Monat',
          includesEssential: true,
          featureTitle: 'Essential-Plan +'
        },
        {
          id: 3,
          highlight: false,
          name: 'Corporate',
          text: 'Für moderne Geschäftsführer und Teamleiter',
          offerText: 'Top Preis-Leistung',
          offerColor: 'amber',
          features: [
            { active: true, name: 'Öffentliches Unternehmensprofil' },
            { active: true, name: 'Individuelle Teamstrukturenanlegen' },
            { active: true, name: 'Teambasierte Statistiken auswerten' },
            { active: true, name: 'Unbegrenzte Anzahl an Mitarbeiterprofilen' }
          ],
          requestsOnly: true,
          includesProfessional: true
        }
      ],
      tab: null
    }),
    name: 'PricingTableCard',
    props: {
      internal: Boolean,
      currentPriceId: String
    }
  }
</script>

<style scoped>
  .current {
    border: 4px solid #ffc107;
  }

  .icon-pricing {
    display: block;
    height: 48px;
    margin: 0;
  }
  .translucent--text {
    color: rgba(255, 255, 255, 0.25);
  }

  .subtitle-height {
    min-height: 64px;
  }
</style>

<i18n>
  {
    "de": {
      "consultation": "Beratung",
      "contactUsNow": "Jetzt anfragen",
      "ourMostPopularOffer": "Unser beliebtetes Angebot",
      "ourPlans": "Unsere Preispläne",
      "plans": "Pläne",
      "stillUndecided": "Noch unentschlossen?",
      "thePerfectPlansForYouOrYourCompany": "Die perfekten Pläne für Sie oder Ihr Unternehmen",
      "tryNow": "Kostenlos testen",
      "weAreHappyToAdviseYou": "Wir beraten Sie gerne",
      "subscribeNow": "Jetzt abonnieren",
      "weInviteYouToGetInTouchWithUsRegardingOurOffersOurTeamIsReadyToProvideYouWithAPersonalizedOfferTailoredToYourUniqueBusinessRequirementsPleaseDoNotHesitateToContactUsSoThatWeCanStartCreatingYourCustomSolutionTodayWeLookForwardToBeingYourPartnerForSuccess": "Wir laden Sie herzlich ein, sich wegen unseren Angeboten mit uns in Verbindung zu setzen. Unser Team ist bereit, Ihnen ein persönliches Angebot zu unterbreiten, das auf Ihre einzigartigen Geschäftsanforderungen zugeschnitten ist. Zögern Sie bitte nicht, uns zu kontaktieren, damit wir noch heute mit der Erstellung Ihrer individuellen Lösung beginnen können. Wir freuen uns darauf, Ihr Partner für Ihren Erfolg zu sein."
    },
    "en": {
      "consultation": "Consultation",
      "contactUsNow": "Contact us now",
      "ourMostPopularOffer": "Our most popular offer",
      "ourPlans": "Our plans",
      "plans": "Plans",
      "stillUndecided": "Still undecided?",
      "thePerfectPlansForYouOrYourCompany": "The perfect plans for you or your company",
      "tryNow": "Try now",
      "subscribeNow": "Subscribe now",
      "weAreHappyToAdviseYou": "We are happy to advise you",
      "weInviteYouToGetInTouchWithUsRegardingOurOffersOurTeamIsReadyToProvideYouWithAPersonalizedOfferTailoredToYourUniqueBusinessRequirementsPleaseDoNotHesitateToContactUsSoThatWeCanStartCreatingYourCustomSolutionTodayWeLookForwardToBeingYourPartnerForSuccess": "We invite you to get in touch with us regarding our offers. Our team is ready to provide you with a personalized offer tailored to your unique business requirements. Please do not hesitate to contact us so that we can start creating your custom solution today. We look forward to being your partner for success."
    }
  }
</i18n>