<template>
  <div>
    <top-header v-bind:title="$t('dashboard')">
      <template slot="body">
        <v-row class="mt-2">
          <v-col cols="12" md="3">
            <v-select flat
                      rounded
                      solo
                      v-bind:items="periods"
                      v-model="period" />
          </v-col>
        </v-row>
      </template>
    </top-header>
    <v-container>
      <v-row>
        <v-col cols="12"
            >
          <dashboard-rating-card v-bind:title="`Rating`"
                                 v-bind:rating="5"
                                 v-bind:rating-count="15" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <dashboard-stat-graph v-bind:graph-values="randomValues()"
                                v-bind:title="`Ratingentwicklung`"
                                v-bind:value="null" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <dashboard-stat-graph v-bind:graph-values="randomValues()"
                                v-bind:title="`Profilbesucher`"
                                v-bind:value="randomValue(10, 100) | formatValue({ fractionDigits: 0 })" />
        </v-col>
        <v-col cols="12"
               md="6">
               <dashboard-stat-graph v-bind:graph-values="randomValues()"
                                v-bind:title="`Empfehlungen`"
                                v-bind:value="randomValue(1, 10) | formatValue({ fractionDigits: 0 })" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <dashboard-stat-graph v-bind:graph-values="randomValues()"
                                v-bind:title="`Neubewertungen`"
                                v-bind:value="randomValue(10, 100) | formatValue({ fractionDigits: 0 })" />
        </v-col>
        <v-col cols="12"
               md="6">
            <dashboard-stat-graph v-bind:graph-values="randomValues()"
                            v-bind:title="`Folgebewertungen`"
                            v-bind:value="randomValue(1, 10) | formatValue({ fractionDigits: 0 })" />
        </v-col>
      </v-row>



      <v-row>
        <v-col cols="12"
               md="6">
          <dashboard-text-card v-bind:title="`Häufigstes Thema`"
                               v-bind:value="'Konto'" />
        </v-col>
        <v-col cols="12"
               md="6">
          <dashboard-text-card v-bind:title="`Bester Bereich`"
                          v-bind:value="'Service'" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import DashboardListCard from '@/components/administration/DashboardListCard.vue'
  import DashboardRatingCard from '@/components/administration/DashboardRatingCard.vue'
  import DashboardStatGraph from '@/components/administration/DashboardStatGraph.vue'
  import DashboardTextCard from '@/components/administration/DashboardTextCard.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      DashboardListCard,
      DashboardRatingCard,
      DashboardStatGraph,
      DashboardTextCard,
      TopHeader
    },
    computed: {
      consultantItems() {
        return this.consultants.map(c => ({
          id: c.id,
          subtitle: `${c.streetName} ${c.streetNumber}, ${c.postalCode} ${c.city}`,
          title: `${c.givenName} ${c.familyName} (${c.number})`
        }))
      },
      periods() {
        return [
          {
            text: this.$t('day'),
            value: 'day'
          },
          {
            text: this.$t('week'),
            value: 'week'
          },
          {
            text: this.$t('month'),
            value: 'month'
          },
          {
            text: this.$t('year'),
            value: 'year'
          }
        ]
      },
      salesAgentItems() {
        return this.salesAgents.map(c => ({
          id: c.id,
          subtitle: `${c.streetName} ${c.streetNumber}, ${c.postalCode} ${c.city}`,
          title: `${c.givenName} ${c.familyName} (${c.number})`
        }))
      },
      ...mapGetters({
        consultants: 'base/consultants',
        salesAgents: 'administration/salesAgents'
      })
    },
    async created() {
      await this.loadConsultants()
      await this.loadSalesAgents()
    },
    data: () => ({
      period: 'day'
    }),
    methods: {
      randomValue(min, max) {
        return Math.random() * (max - min) + min;
      },
      randomValues() {
        return Array.from({ length: 20 }, () => Math.floor(Math.random() * 20));
      },
      ...mapActions({
        loadConsultants: 'base/loadConsultants',
        loadSalesAgents: 'administration/loadSalesAgents'
      })
    },
    name: 'DashboardConsultant'
  }
</script>

<i18n>
  {
    "de": {
      "consultants": "Consultants",
      "day": "Tag",
      "dashboard": "Dashboard",
      "month": "Monat",
      "profit": "Gewinn",
      "revenue": "Umsatz",
      "salesAgents": "Verkäufer",
      "week": "Woche",
      "year": "Jahr"
    },
    "en": {
      "consultants": "Consultants",
      "day": "Day",
      "dashboard": "Dashboard",
      "month": "Month",
      "profit": "Profit",
      "revenue": "Revenue",
      "salesAgents": "Sales agents",
      "week": "Week",
      "year": "Year"
    }
  }
</i18n>