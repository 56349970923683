<template>
  <div>
    <v-form ref="form"
            v-on:submit.prevent="search">
      <v-row>
        <v-col cols="12"
               lg="2"
               md="3">
          <v-text-field append-icon="mdi-map-marker"
                        flat
                        hide-details="auto"
                        solo
                        v-bind:label="$t('postalCode')"
                        v-bind:rules="[ rules.required ]"
                        v-model="options.postalCode"
                        v-on:click:append=""
                        v-on:keydown.enter="search" />
        </v-col>
        <v-col cols="12"
               lg="7"
               md="5">
          <v-select chips flat
                          hide-details="auto"
                          multiple
                          solo
                          v-bind:items="consultingTopics | items({ text: 'name', value: 'id' })"
                          v-bind:label="$t('topics')"
                          v-model="options.consultingTopics"
                          v-on:keydown.enter="search">
              <template v-slot:selection="{ item, selected }">
                <v-chip close
                        v-on:click:close="options.consultingTopics = options.consultingTopics.filter(i => i !== item.value)">
                  <span>{{ truncate(item.text, 20) }}</span>
                </v-chip>
              </template>
          </v-select>
        </v-col>
        <v-col cols="12"
               lg="2"
               md="3">
          <v-select clearable
                    flat
                    hide-details="auto"
                    multiple
                    solo
                    v-bind:items="locales | items({ text: 'nativeTitle', value: 'id' })"
                    v-bind:label="$t('languages')"
                    v-model="options.languages" />
        </v-col>
        <v-col cols="12"
               lg="1"
               md="1">
          <v-btn block
                 class="v-btn--search"
                 color="primary"
                 dark
                 depressed
                 large
                 v-on:click="search">
            <v-icon color="accent"
                    v-bind:left="$vuetify.breakpoint.smAndDown">
              mdi-magnify
            </v-icon>
            <span class="hidden-md-and-up">{{ $t('search') }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary darken-2"
                 small
                 text
                 v-on:click="showDetailedOptions = !showDetailedOptions">
            {{ $t('detailedOptions') }}
            <v-icon right>
              {{ showDetailedOptions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-if="showDetailedOptions">
          <v-col cols="12"
                 md="6">
            <v-select clearable
                      flat
                      hide-details="auto"
                      solo
                      v-bind:items="perimeters | items({ text: 'label' })"
                      v-bind:label="$t('perimeter')"
                      v-model="options.perimeter" />
          </v-col>
          <v-col cols="12"
                 md="6">
            <v-select clearable
                      flat
                      hide-details="auto"
                      solo
                      v-bind:items="ratingCounts | items({ text: 'label' })"
                      v-bind:label="$t('ratingCount')"
                      v-model="options.ratingCount" />
          </v-col>
          <v-col>
          <v-combobox hide-details="auto"
          flat solo
                        v-bind:label="$t('tags')"
                        v-model="options.tags"
                        v-bind:placeholder="$t('eGfinanceConsulting')"
                        multiple
                        chips
                        v-on:input="updateTags">
            <template v-slot:selection="data">
              <v-chip
                
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
                
              >
                
                #{{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        </v-row>
      </v-expand-transition>
    </v-form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import locales from '@/locales.json'
  import rules from '@/mixins/v2/rules'

  export default {
    computed: {
      locales() {
        return locales
      },
      ...mapGetters({
        consultingTopics: 'base/consultingTopics',
        perimeters: 'local/perimeters',
        ratingCounts: 'local/ratingCounts'
      })
    },
    data: () => ({
      showDetailedOptions: false
    }),
    methods: {
      updateTags() {
        this.options.tags = this.options.tags.map(tag => tag.replaceAll('#', ''))

        this.options.tags = [...new Set(this.options.tags)]
      },
      truncate(text, maxLength) {
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '…'
        }

        return text
      },
      search() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.$emit('search', this.options)
      }
    },
    mixins: [ rules ],
    name: 'ConsultantPerimeterSearch',
    props: {
      options: Object
    }
  }
</script>

<style scoped>
  .v-btn.v-btn--search {
    min-height: 48px;
  }

  .v-list-item__subtitle, .v-list-item__title{
      text-overflow: initial!important;
    white-space: initial!important;
}
</style>

<i18n>
  {
    "de": {
      "detailedOptions": "Erweiterte Optionen",
      "languages": "Sprachen",
      "perimeter": "Umkreis",
      "postalCode": "PLZ",
      "ratingCount": "Anzahl der Bewertungen",
      "tags": "Schlagworte",
      "search": "Suchen",
      "topics": "Anliegen",
      "eGfinanceConsulting": "z. B. #finanzberater"
    },
    "en": {
      "detailedOptions": "Detailed options",
      "languages": "Languages",
      "perimeter": "Perimeter",
      "postalCode": "Postal code",
      "ratingCount": "Rating count",
      "search": "Search",
      "tags": "Tags",
      "topics": "Topics",
      "eGfinanceConsulting": "e. g. #financeConsulting"
    }
  }
</i18n>