<template>
  <div v-bind:class="center ? 'd-flex justify-center' : null">
    <v-chip-group column>
      <v-tooltip bottom
      retain-focus-on-click
                 v-bind:key="consultingTopicId"
                 v-for="consultingTopicId in consultingTopicIds">
        <template v-slot:activator="{ on, attrs }">
          <v-chip color="primary"
                  label
                  retain-focus-on-click
                  v-bind="attrs"
                  v-on="on">
                  <img v-bind="attrs"
                   v-on="on"
                  alt="icon"
                  v-bind:src="`/images/v2/${consultingTopicIcon(consultingTopicId)}-small.svg`" />
          </v-chip>
        </template>
        <p class="font-weight-bold">{{ consultingTopicLabel(consultingTopicId) }}</p>
        <span v-html="consultingTopicDescription(consultingTopicId)"></span>
      </v-tooltip>
    </v-chip-group>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        consultingTopic: 'base/consultingTopic',
        consultingTopicDescription: 'base/consultingTopicDescription',
        consultingTopicIcon: 'base/consultingTopicIcon',
        consultingTopicLabel: 'base/consultingTopicLabel'
      })
    },
    name: 'ConsultingTopicChips',
    props: {
      center: Boolean,
      consultingTopicIds: Array
    }
  }
</script>