<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('commissionDetails') }}</v-toolbar-title>
      </v-toolbar>
      <sales-agent-commission-details ref="salesAgentCommissionDetails"
                                      v-bind:salesAgent="salesAgent"/>
    </v-card>
  </div>
</template>

<script>
  import SalesAgentCommissionDetails from '@/components/administration/SalesAgentCommissionDetails'

  export default {
    components: {
      SalesAgentCommissionDetails
    },
    name: 'SalesAgentCommissionDetailsCard',
    methods: {
      validate() {
        return this.$refs.salesAgentCommissionDetails.validate()
      }
    },
    props: {
      salesAgent: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "commissionDetails": "Provisionsdaten"
    },
    "en": {
      "commissionDetails": "Commission details"
    }
  }
</i18n>