<template>
  <div>
    <v-dialog persistent
              v-bind:fullscreen="$vuetify.breakpoint.mdAndDown"
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="840">
      <v-card height="912">
        <v-toolbar color="primary"
                   dark
                   elevation="0">
          <v-toolbar-title>{{ $t('makeAppointment') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn dark
                 icon
                 v-on:click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <iframe v-bind:src="`https://widget.calenso.com/?partner=${calensoToken}&isFrame=true&type=appointment&worker_id=${calensoWorkerId}`" loading="lazy" frameborder="0" style="height: 840px; width: 100%; max-width: 840px;" scrolling="yes"></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '@/mixins/v2/rules'

  export default {
    data: () => ({
      resolve: null
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      async open(resolve, message) {
        this.resolve = resolve

        this.$emit('input', true)
      }
    },
    mixins: [ rules ],
    name: 'AppointmentDialog',
    props: {
      calensoToken: String,
      calensoWorkerId: String,
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "makeAppointment": "Termin vereinbaren"
    },
    "en": {
      "makeAppointment": "Make appointment"
    }
  }
</i18n>