<template>
  <div>
    <v-dialog persistent
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-card-text class="pt-4">
          <h4 class="mb-6 text-h4 primary--text">{{ $t('yourFeedback') }}</h4>
          <v-form ref="form"
                  v-if="!sent">
            <v-row>
              <v-col>
                <v-select hide-details="auto"
                          outlined
                          v-bind:items="feedbackItems | items({ text: 'text', value: 'text' })"
                          v-bind:label="$t('topic')"
                          v-bind:rules="[ rules.required ]"
                          v-model="message.topic" />
                <p class="mb-0 mt-2"
                   v-if="!!selectedItemDescription">
                  {{ selectedItemDescription }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea hide-details="auto"
                            outlined
                            v-bind:counter="500"
                            v-bind:label="$t('comment')"
                            v-bind:rules="[ rules.required, rules.maxLength(500) ]"
                            v-model="message.comment" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>{{ $t('additionallyTheCurrentlyOpenSubpageAndIfYouAreLoggedInYourAccountEmailAddressAreTransmitted') }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox class="mt-0"
                            hide-details="auto"
                            v-bind:label="$t('iAgreeWithThePrivacyPolicyAndTheTermsAndConditions')"
                            v-bind:rules="[ rules.required, rules.true ]"
                            v-model="message.privacyPolicyAndtermsAndConditions" />
              </v-col>
            </v-row>
          </v-form>
          <p v-else>
            {{ $t('thankYouForTakingTheTimeToMakeConfederiaBetterThroughYourFeedback') }}
          </p>
        </v-card-text>
        <v-card-actions class="pa-4"
                        v-if="!sent">
          <v-btn color="primary"
                 large
                 text
                 v-on:click="cancel">
            <v-icon color="accent"
                    left>
              mdi-close
            </v-icon>
            <span>{{ $t('cancel') }}</span>
          </v-btn>
          <v-spacer />
          <v-btn color="primary"
                 depressed
                 large
                 v-on:click="confirm">
            <span>{{ $t('send') }}</span>
            <v-icon color="accent"
                    right>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions class="pa-4"
                        v-else>
          <v-spacer />
          <v-btn color="primary"
                 depressed
                 large
                 v-on:click="close">
            <span>{{ $t('close') }}</span>
            <v-icon color="accent"
                    right>
              mdi-check
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '@/mixins/v2/rules'


  export default {
    computed: {
      feedbackItems() {
        return [
          {
            text: 'Benutzerfreundlichkeit',
            description: 'Die Webseite war nicht einfach zu bedienen und zu navigieren.'
          },
          {
            text: 'Inhalt',
            description: 'Ich hätte mir zusätzliche Inhalte auf der Webseite gewünscht.'
          },
          {
            text: 'Design und Layout',
            description: 'Das Design der Webseite hat mir nicht gefallen.'
          },
          {
            text: 'Ladezeit',
            description: 'Die Seiten haben sich langsam geladen.'
          },
          {
            text: 'Fehler und Probleme',
            description: 'Mir sind während der Nutzung der Webseite Fehler oder Probleme aufgefallen.'
          },
          {
            text: 'Zusätzliche Funktionen',
            description: 'Es gibt zusätzliche Funktionen oder Tools, die ich mir auf der Webseite wünschen würde.'
          },
          {
            text: 'Sonstiges Feedback',
            description: 'Gibt es sonstiges Feedback, das du gerne teilen möchtest und das dazu beitragen könnte, die Webseite zu verbessern?'
          }
        ]
      },
      selectedItemDescription() {
        const item = this.feedbackItems.find(item => item.text === this.message.topic)

        return item?.description
      }
    },
    data: () => ({
      message: {},
      resolve: null,
      sent: false
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      close() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.sent = true
        this.resolve(this.message)
      },
      async open(resolve, message) {
        this.resolve = resolve
        this.message = message || {}
        this.$emit('input', true)
      }
    },
    mixins: [ rules ],
    name: 'FeedbackDialog',
    props: {
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "send": "Senden",
      "cancel": "Abbrechen",
      "close": "Schließen",
      "yourFeedback": "Ihr Feedback",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "Ich erkläre mich mit der Datenschutzerklärung und den allgemeinen Geschäftsbedingungen (AGB) einverstanden.",
      "topic": "Thema",
      "comment": "Kommentar",
      "additionallyTheCurrentlyOpenSubpageAndIfYouAreLoggedInYourAccountEmailAddressAreTransmitted": "Zusätzlich werden die aktuell geöffnete Unterseite sowie, sofern Sie angemeldet sind, Ihre Konto-E-Mail-Adresse übermittelt.",
      "thankYouForTakingTheTimeToMakeConfederiaBetterThroughYourFeedback": "Vielen Dank, dass Sie sich die Zeit genommen haben durch Ihr Feedback Confederia besser zu machen!"
    },
    "en": {
      "send": "Send",
      "cancel": "Cancel",
      "close": "Close",
      "yourFeedback": "Your feedback",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "I agree with the privacy policy and the terms and conditions.",
      "topic": "Topic",
      "comment": "Comment",
      "additionallyTheCurrentlyOpenSubpageAndIfYouAreLoggedInYourAccountEmailAddressAreTransmitted": "Additionally, the currently open subpage and, if you are logged in, your account email address are transmitted.",
      "thankYouForTakingTheTimeToMakeConfederiaBetterThroughYourFeedback": "Thank you for taking the time to make Confederia better through your feedback!"
    }
  }
</i18n>