<template>
  <div>
    <v-dialog persistent
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-card-text class="pt-4">
          <h4 class="mb-6 text-h4 primary--text">{{ $t('reportEvaluation') }}</h4>
          <v-form ref="form"
                  v-if="!sent">
            <v-row>
              <v-col>
                <v-select hide-details="auto" class="select-multi-line"
                          outlined
                          v-bind:items="reasonItems | items({ text: 'text', value: 'text' })"
                          v-bind:label="$t('reason')"
                          v-bind:rules="[ rules.required ]"
                          v-model="message.reason" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea hide-details="auto"
                            outlined
                            v-bind:counter="500"
                            v-bind:label="$t('comment')"
                            v-bind:rules="requireComment ? [ rules.required ] : []"
                            v-model="message.comment" />
              </v-col>
            </v-row>
           <!--<v-row>
              <v-col>
                <v-checkbox class="mt-0"
                            hide-details="auto"
                            v-bind:label="$t('iAgreeWithThePrivacyPolicyAndTheTermsAndConditions')"
                            v-bind:rules="[ rules.required, rules.true ]"
                            v-model="message.privacyPolicyAndtermsAndConditions" />
              </v-col>
            </v-row>--> 
          </v-form>
          <p v-else>
            {{ $t('thankYouForYourReportYourFeedbackIsImportantToUsAndWeWillPromptlyReviewYourReviewWeAreCommittedToTheQualityAndTransparencyOfOurPlatformAndWillEnsureThatAllReviewsComplyWithOurGuidelines') }}
          </p>
        </v-card-text>
        <v-card-actions class="pa-4"
                        v-if="!sent">
          <v-btn color="primary"
                 large
                 text
                 v-on:click="cancel">
            <v-icon color="accent"
                    left>
              mdi-close
            </v-icon>
            <span>{{ $t('cancel') }}</span>
          </v-btn>
          <v-spacer />
          <v-btn color="primary"
                 depressed
                 large
                 v-on:click="confirm">
            <span>{{ $t('send') }}</span>
            <v-icon color="accent"
                    right>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions class="pa-4"
                        v-else>
          <v-spacer />
          <v-btn color="primary"
                 depressed
                 large
                 v-on:click="close">
            <span>{{ $t('close') }}</span>
            <v-icon color="accent"
                    right>
              mdi-check
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '@/mixins/v2/rules'


  export default {
    computed: {
      reasonItems() {
        return [
          {
            text: 'Unangemessener oder beleidigender Inhalt'
          },
          {
            text: 'Falsche Darstellung von Fakten'
          },
          {
            text: 'Verstoß gegen Richtlinien des Portals'
          },
          {
            text: 'Verdacht auf Manipulation der Bewertung'
          },
          {
            text: 'Andere Gründe',
            requireComment: true
          }
        ]
      },
      requireComment() {
        const item = this.reasonItems.find(item => item.text === this.message.reason)

        return item?.requireComment
      }
    },
    data: () => ({
      message: {},
      resolve: null,
      sent: false
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      close() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.sent = true
        this.resolve(this.message)
      },
      async open(resolve, message) {
        this.resolve = resolve
        this.message = message || {}
        this.$emit('input', true)
      }
    },
    mixins: [ rules ],
    name: 'ReportEvaluationDialog',
    props: {
      value: Boolean
    }
  }
</script>

<style>
.v-select-list .v-list-item__subtitle, .v-select-list .v-list-item__title {
      text-overflow: initial!important;
    white-space: initial!important;
}
</style>

<i18n>
  {
    "de": {
      "send": "Senden",
      "cancel": "Abbrechen",
      "close": "Schließen",
      "reportEvaluation": "Bewertung melden",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "Ich erkläre mich mit der Datenschutzerklärung und den allgemeinen Geschäftsbedingungen (AGB) einverstanden.",
      "reason": "Grund",
      "comment": "Kommentar",
      "thankYouForYourReportYourFeedbackIsImportantToUsAndWeWillPromptlyReviewYourReviewWeAreCommittedToTheQualityAndTransparencyOfOurPlatformAndWillEnsureThatAllReviewsComplyWithOurGuidelines": "Vielen Dank für Ihre Meldung! Ihre Rückmeldung ist uns wichtig, und wir werden Ihre Bewertung umgehend überprüfen. Wir setzen uns für die Qualität und Transparenz unserer Plattform ein und werden sicherstellen, dass alle Bewertungen unseren Richtlinien entsprechen. "
    },
    "en": {
      "send": "Send",
      "cancel": "Cancel",
      "close": "Close",
      "reportEvaluation": "Report evaluation",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "I agree with the privacy policy and the terms and conditions.",
      "reason": "Reason",
      "comment": "Comment",
      "thankYouForYourReportYourFeedbackIsImportantToUsAndWeWillPromptlyReviewYourReviewWeAreCommittedToTheQualityAndTransparencyOfOurPlatformAndWillEnsureThatAllReviewsComplyWithOurGuidelines": "Thank you for your report! Your feedback is important to us, and we will promptly review your review. We are committed to the quality and transparency of our platform and will ensure that all reviews comply with our guidelines."
    }
  }
</i18n>