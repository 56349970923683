<template>
  <div>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header color="secondary"
                v-bind:title="$t('companies')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'administration-company-add' }"
                     v-if="hasPermission('write:companies')" />
    </top-header>
    <full-width-section color="transparent">
      <v-container>
        <company-table-card v-bind:companies="companies"
                            v-on:click:row="$router.push({ name: 'administration-company', params: { id: $event.id }})" />
      </v-container>
    </full-width-section>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import CompanyTableCard from '@/components/v2/administration/CompanyTableCard.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      CompanyTableCard,
      FullWidthSection,
      HeaderButton,
      TopHeader
    },
    computed: {
      ...mapGetters({
        companies: 'base/companies',
        hasPermission: 'auth/hasPermission'
      })
    },
    async created() {
      this.loading = true

      await this.loadCompanies()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadCompanies: 'base/loadCompanies'
      })
    },
    name: 'CompaniesView'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "companies": "Unternehmen"
    },
    "en": {
      "add": "Add",
      "companies": "Companies"
    }
  }
</i18n>