<template>
  <div v-bind:class="centered ? 'text-center' : null">
    <a target="_blank"
       v-bind:href="consultant.linkedInUrl"
       v-if="!!consultant.linkedInUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-linkedin</v-icon>
      </v-avatar>
    </a>

    <a target="_blank"
       v-bind:href="consultant.twitterUrl"
       v-if="!!consultant.twitterUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-twitter</v-icon>
      </v-avatar>
    </a>

    <a target="_blank"
       v-bind:href="consultant.facebookUrl"
       v-if="!!consultant.facebookUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-facebook</v-icon>
      </v-avatar>
    </a>

    <a target="_blank"
       v-bind:href="consultant.instagramUrl"
       v-if="!!consultant.instagramUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-instagram</v-icon>
      </v-avatar>
    </a>

    <a target="_blank"
       v-bind:href="whatsAppUrl"
       v-if="!!consultant.whatsAppUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-whatsapp</v-icon>
      </v-avatar>
    </a>
  </div>
</template>

<script>
  export default {
    computed: {
      whatsAppUrl() {
        return `https://wa.me/${this.consultant.whatsAppUrl}`
      }
    },
    name: 'ConsultantSocialLinks',
    props: {
      centered: Boolean,
      consultant: Object
    }
  }
</script>