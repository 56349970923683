<template>
  <div>
    <v-card color="white"
            flat>
      <v-stepper class="elevation-0 transparent"
                 v-bind:alt-labels="$vuetify.breakpoint.mdAndUp"
                 v-model="step">
        <v-stepper-header class="elevation-0">
          <v-stepper-step color="primary"
                          step="1"
                          v-bind:complete="step > 1">
            {{ $t('selection') }}
          </v-stepper-step>
          <v-stepper-step color="primary"
                          step="2"
                          v-bind:complete="step > 2">
            {{ $t('personalDetails') }}
          </v-stepper-step>
          <v-stepper-step color="primary"
                          step="3"
                          v-bind:complete="step > 3">
            {{ $t('consultingTopics') }}
          </v-stepper-step>
          <v-stepper-step color="primary"
                          step="4"
                          v-bind:complete="step > 4">
            {{ $t('languages') }}
          </v-stepper-step>
          <v-stepper-step color="primary"
                          step="5"
                          v-bind:complete="step > 5">
            {{ $t('paymentDetails') }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content class="pa-0"
                             step="1">
            <account-claim-step ref="accountClaimStep"
                                v-bind:email="email"
                                v-on:next="goToNextStep"
                                v-on:select-consultant="selectConsultant" />
          </v-stepper-content>
          <v-stepper-content class="pa-0"
                             step="2">
            <account-personal-details-step ref="accountPersonalDetailsStep"
                                           v-bind:consultant="consultant" />
          </v-stepper-content>
          <v-stepper-content class="pa-0"
                             step="3">
            <account-consulting-topic-step ref="accountConsultingTopicsStep"
                                           v-bind:consultant="consultant" />
          </v-stepper-content>
          <v-stepper-content class="pa-0"
                             step="4">
            <account-language-step ref="accountLanguagesStep"
                                   v-bind:consultant="consultant" />
          </v-stepper-content>
          <!--<v-stepper-content class="pa-0"
                                 step="4">
            <account-payment-details-step ref="accountPaymentDetailsStep"
                                          v-bind:consultant="consultant" />
          </v-stepper-content>-->
          <v-stepper-content class="pa-0"
                             step="5">
            <account-completion-details-step ref="accountCompletionDetailsStep"
                                             v-bind:consultant="consultant" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-card-actions class="pa-4">
      <v-fade-transition>
        <v-btn color="primary"
               depressed
               large
               outlined
               v-if="hasPreviousStep"
               v-on:click="goToPreviousStep">
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          <span>{{ $t('back') }}</span>
        </v-btn>
      </v-fade-transition>

      <v-spacer />

      <v-btn color="primary"
              depressed
              large
              v-if="hasNextStep"
              v-on:click="goToNextStep">
        <span>{{ nextStepLabel }}</span>
        <v-icon color="accent"
                right>
          mdi-arrow-right
        </v-icon>
      </v-btn>

      <v-btn color="primary"
              depressed
              large
              v-bind:loading="loading"
              v-if="isLastStep"
              v-on:click="submit">
        <span>{{ $t('complete') }}</span>
        <v-icon color="accent"
                right>
          mdi-check
        </v-icon>
      </v-btn>
    </v-card-actions>
    </v-card>


  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import AccountClaimStep from '@/components/v2/account/AccountClaimStep'
  import AccountCompletionDetailsStep from '@/components/v2/account/AccountCompletionDetailsStep'
  import AccountConsultingTopicStep from '@/components/v2/account/AccountConsultingTopicStep'
  import AccountLanguageStep from '@/components/v2/account/AccountLanguageStep'
  import AccountPersonalDetailsStep from '@/components/v2/account/AccountPersonalDetailsStep'

  export default {
    components: {
      AccountClaimStep,
      AccountCompletionDetailsStep,
      AccountConsultingTopicStep,
      AccountLanguageStep,
      AccountPersonalDetailsStep
    },
    computed: {
      hasNextStep() {
        return this.step < this.stepCount
      },
      hasPreviousStep() {
        return this.step > 1
      },
      isLastStep() {
        return this.step === this.stepCount
      },
      nextStepLabel() {
        return this.step !== 1 ? this.$t('continue') : this.$t('continueWithoutClaiming')
      },
      stepCount() {
        return 5
      },
      ...mapGetters({
        email: 'auth/email',
        getConsultant: 'base/consultant'
      })
    },
    data: () => ({
      step: 1
    }),
    methods: {
      goToNextStep() {
        if (!this.hasNextStep) {
          return
        }

        if (this.step === 2) {
          if (!this.$refs.accountPersonalDetailsStep.validate()) {
            return
          }
        } else if (this.step === 3) {
          if (!this.$refs.accountConsultingTopicsStep.validate()) {
            return
          }
        } else if (this.step === 4) {
          if (!this.$refs.accountLanguagesStep.validate()) {
            return
          }
        }

        // scroll to top
        window.scrollTo(0, 0)

        this.step += 1
      },
      goToPreviousStep() {
        if (!this.hasPreviousStep) {
          return
        }

        this.step -= 1
      },
      selectConsultant(id) {
        const consulant = Object.assign({}, this.getConsultant(id))

        this.$emit('update:consultant', consulant)

        this.goToNextStep()
      },
      submit() {
        if (!this.$refs.accountCompletionDetailsStep.validate()) {
          return
        }

        this.$emit('submit', this.model)
      }
    },
    name: 'AccountCreateStepper',
    props: {
      consultant: Object,
      loading: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "complete": "Abschließen",
      "consultingTopics": "Themen",
      "continue": "Weiter",
      "continueWithoutClaiming": "Weiter ohne Datenübernahme",
      "createAccount": "Konto erstellen",
      "languages": "Sprachen",
      "paymentDetails": "Zahlungsdetails",
      "personalDetails": "Personalien",
      "selection": "Auswahl"
    },
    "en": {
      "back": "Back",
      "complete": "Complete",
      "consultingTopics": "Consulting topics",
      "continue": "Continue",
      "continueWithoutClaiming": "Continue without claiming",
      "createAccount": "Konto erstellen",
      "languages": "Languages",
      "paymentDetails": "Payment details",
      "personalDetails": "Personal details",
      "selection": "Selection"
    }
  }
</i18n>