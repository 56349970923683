<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('consultingTopics') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <consulting-topic-select ref="consultingTopicSelect"
                                 v-model="consultant.consultingTopicIds" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import ConsultingTopicSelect from '@/components/v2/account/ConsultingTopicSelect'

  export default {
    components: {
      ConsultingTopicSelect
    },
    name: 'AccountConsultingTopicCard',
    methods: {
      validate() {
        return this.$refs.consultingTopicSelect.validate()
      }
    },
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "consultingTopics": "Themen"
    },
    "en": {
      "consultingTopics": "Consulting topics"
    }
  }
</i18n>