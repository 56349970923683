<template>
  <div v-bind:class="[ color, $vuetify.breakpoint.mdAndUp ? 'py-12' : 'py-6' ]">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'FullWidthSection',
    props: {
      color: String
    }
  }
</script>