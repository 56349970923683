export default {
  methods: {
    calculateDistance(lat1, lon1, lat2, lon2) {
      const toRadians = degrees => degrees * (Math.PI / 180)

      const earthRadius = 6371.0;
      const rLat1 = toRadians(lat1)
      const rLon1 = toRadians(lon1)
      const rLat2 = toRadians(lat2)
      const rLon2 = toRadians(lon2)

      const dLon = rLon2 - rLon1

      const a = Math.sin((rLat2 - rLat1) / 2) ** 2 + Math.cos(rLat1) * Math.cos(rLat2) *  Math.sin(dLon / 2) ** 2
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

      return earthRadius * c
    }
  }
}