<template>
  <v-form ref="form">
    <v-subheader>{{ $t('personalDetails') }}</v-subheader>
    <v-card-text>
      <v-row>
        <v-col cols="12"
               md="4">
          <v-select hide-details="auto"
                    outlined
                    v-bind:items="honorifics | vueI18nItems({ text: 'label' })"
                    v-bind:label="$t('honorific')"
                    v-bind:rules="[ rules.required ]"
                    v-model="evaluation.customer.honorific" />
        </v-col>
        <v-col cols="12"
               md="4">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('givenName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="evaluation.customer.givenName" />
        </v-col>
        <v-col cols="12"
               md="4">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('familyName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="evaluation.customer.familyName" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:hint="$t('yourEmailAddressWillBeSharedOnlyWithYourConsultantAndWillNotBeDisplayedPublicly')"
                        v-bind:label="$t('email')"
                        v-bind:rules="[ rules.required ]"
                        v-model="evaluation.customer.email" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-subheader>{{ $t('nameDisplayStyle') }}</v-subheader>
    <v-card-text>
      <v-radio-group class="mt-0 pt-0"
                     hide-details
                     mandatory
                     v-model="evaluation.customer.nameDisplayStyle">
        <v-row>
          <v-col cols="12"
                 md="4">
            <v-radio  color="primary"
                      value="fullName"
                      v-bind:label="`${evaluation.customer.givenName || $t('givenName')} ${evaluation.customer.familyName ||  $t('familyName')}`" />
          </v-col>
          <v-col cols="12"
                 md="4">
            <v-radio color="primary"
                     value="givenNameAndFamilyNameInitial"
                     v-bind:label="`${evaluation.customer.givenName || $t('givenName')} ${(evaluation.customer.familyName ||  $t('familyName'))[0]}.`" />
          </v-col>
          <v-col cols=12
                 md="4">
            <v-radio color="primary"
                     value="givenName"
                     v-bind:label="`${evaluation.customer.givenName || $t('givenName')}`" />
          </v-col>
        </v-row>
      </v-radio-group>
      <p class="mt-2 text-caption">{{ $t('chooseHowYourNameShouldBeDisplayedInThePublicReviewYourFullNameWillBeSharedWithYourConsultant') }}</p>
    </v-card-text>

    <v-divider />

    <v-subheader>{{ $t('consultation') }}</v-subheader>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select hide-details="auto"
                    multiple
                    outlined
                    v-bind:items="consultingTopics | items({ text: 'name', value: 'id' })"
                    v-bind:label="$t('consultingTopics')"
                    v-bind:rules="[ rules.hasItems ]"
                    v-model="evaluation.consultingTopicIds" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select hide-details="auto"
                    outlined
                    v-bind:items="locales | items({ text: 'nativeTitle', value: 'id' })"
                    v-bind:label="$t('language')"
                    v-model="evaluation.language" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea hide-details="auto"
                      outlined
                      v-bind:counter="500"
                      v-bind:label="$t('yourPublicComment')"
                      v-bind:rules="[ rules.maxLength(500) ]"
                      v-model="evaluation.comment" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-card-text>
      <v-row>
        <v-col>
          <v-checkbox class="mt-0"
                      hide-details="auto"
                      v-bind:label="$t('iAgreeWithThePrivacyPolicyAndTheTermsAndConditions')"
                      v-bind:rules="[ rules.required, rules.true ]"
                      v-model="evaluation.privacyPolicyAndtermsAndConditions">
                      <template v-slot:label>
                        <div>
                          Ich erkläre mich mit der <a @click.stop href="/privacy-policy" target="_blank">Datenschutzerklärung</a> und den <a @click.stop href="/terms-of-service" target="_blank">allgemeinen Geschäftsbedingungen (AGB)</a> einverstanden.
                        </div>
                      </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import locales from '@/locales.json'
  import rules from '@/mixins/v2/rules'

  export default {
    computed: {
      locales() {
        return locales
      },
      ...mapGetters({
        consultingTopics: 'base/consultingTopics',
        honorifics: 'local/honorifics'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    mixins: [ rules ],
    name: 'QuestionnaireDetails',
    props: {
      evaluation: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "chooseHowYourNameShouldBeDisplayedInThePublicReviewYourFullNameWillBeSharedWithYourConsultant": "Wählen Sie, wie Ihr Name in der öffentlichen Bewertung angezeigt werden soll. Ihrem Berater wird Ihr vollständiger Name mitgeteilt.",
      "consultation": "Beratung",
      "consultingTopics": "Themen",
      "email": "E-Mail-Adresse",
      "familyName": "Nachname",
      "givenName": "Vorname",
      "honorific": "Anrede",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "Ich erkläre mich mit der Datenschutzerklärung und den allgemeinen Geschäftsbedingungen (AGB) einverstanden.",
      "language": "Sprache",
      "nameDisplayStyle": "Anzeigepräferenz",
      "personalDetails": "Persönliche Angaben",
      "yourEmailAddressWillBeSharedOnlyWithYourConsultantAndWillNotBeDisplayedPublicly": "Ihre E-Mail-Adresse wird nur mit Ihrem Berater geteilt aber nicht öffentlich angezeigt.",
      "yourPublicComment": "Ihr öffentlicher Kommentar"
    },
    "en": {
      "chooseHowYourNameShouldBeDisplayedInThePublicReviewYourFullNameWillBeSharedWithYourConsultant": "Choose how your name should be displayed in the public review. Your full name will be shared with your consultant.",
      "consultation": "Consultation",
      "consultingTopics": "Consulting topics",
      "email": "Email",
      "familyName": "Family name",
      "givenName": "Given name",
      "honorific": "Honorific",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "I agree with the privacy policy and the terms and conditions.",
      "language": "Language",
      "nameDisplayStyle": "Name display style",
      "personalDetails": "Personal details",
      "yourEmailAddressWillBeSharedOnlyWithYourConsultantAndWillNotBeDisplayedPublicly": "Your email address will be shared only with your consultant and will not be displayed publicly.",
      "yourPublicComment": "Your public comment"
    }
  }
</i18n>