<template>
  <div>
    <v-row class="mb-6"
           v-if="consultants.length > 0">
      <v-spacer />
      <v-col cols="12"
             md="auto">
        <v-select background-color="secondary"
                  flat
                  hide-details="auto"
                  prepend-inner-icon="mdi-sort"
                  solo
                  v-bind:items="sortItems"
                  v-bind:label="$t('sortBy')"
                  v-model="sortBy" />
      </v-col>
    </v-row>
    <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                     v-bind:items="consultants"
                     v-bind:items-per-page="10"
                     v-bind:sort-by="sortBy"
                     v-bind:sort-desc="sortDesc">
      <template v-slot:no-data v-slot:no-results>
        <notification-with-icon icon="mdi-magnify"
                                v-bind:text="$t('tryExpandingYourSearchForExampleByChoosingALargerRadiusOrMoreTopicsOrInviteYourConsultantNow')"
                                v-bind:title="$t('noConsultantsFound')">
          <template slot="extension">
            <v-btn
                  class="mt-6"
                  color="primary"
                  dark
                  depressed
                  large
                  v-on:click="$emit('invite')">
              <v-icon color="accent"
                      left>
                mdi-send
              </v-icon>
              {{ $t('invite') }}
            </v-btn>
          </template>
        </notification-with-icon>
      </template>
      <template v-slot:default="props">
        <consultant-list-card class="mb-4"
                              v-bind:consultant="consultant"
                              v-bind:key="consultant.id"
                              v-for="consultant in props.items" />
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
  import ConsultantListCard from '@/components/v2/base/ConsultantListCard.vue'
  import InviteForm from '@/components/base/InviteForm.vue'
  import NotificationWithIcon from '@/components/v2/base/NotificationWithIcon.vue'

  export default {
    components: {
      ConsultantListCard,
      InviteForm,
      NotificationWithIcon
    },
    computed: {
      sortDesc () {
        return this.sortBy === 'overallRating'
      },
      sortItems() {
        return [
          {
            text: this.$t('name'),
            value: 'formattedName'
          },
          {
            text: this.$t('rating'),
            value: 'overallRating'
          }
        ]
      }
    },
    data: () => ({
      sortBy: 'overallRating'
    }),
    name: 'ConsultantList',
    props: {
      consultants: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "invite": "Einladen",
      "name": "Name",
      "noConsultantsFound": "Keine Berater gefunden",
      "rating": "Bewertung",
      "sortBy": "Sortieren nach",
      "tryExpandingYourSearchForExampleByChoosingALargerRadiusOrMoreTopicsOrInviteYourConsultantNow": "Versuchen Sie Ihre Suche zu erweitern, z. B. in dem Sie einen größeren Radius oder weitere Anliegen wählen – oder laden Sie Ihren Berater jetzt ein!"
    },
    "en": {
      "invite": "Invite",
      "name": "Name",
      "noConsultantsFound": "No consultants found",
      "rating": "Rating",
      "sortBy": "Sort by",
      "tryExpandingYourSearchForExampleByChoosingALargerRadiusOrMoreTopicsOrInviteYourConsultantNow": "Try expanding your search, for example by choosing a larger radius or more topics - or invite your consultant now!"
    }
  }
</i18n>