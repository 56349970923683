<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="error"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisConsultantIrrevocably')"
                    v-bind:title="$t('deleteConsultant')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="warning"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header color="secondary"
                v-bind:title="formattedName | defaultValue($t('consultant'))">
      <header-button icon="mdi-chevron-left"
                     secondary
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'administration-consultants' }" />

      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-if="hasPermission('write:consultants')"
                     v-on:click="updateConsultant" />

      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary"
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="deleteConsultant">
            <v-list-item-title class="error--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>

    <full-width-section color="transparent"
                        v-if="consultant != null">
      <v-container>
        <v-row>
          <v-col cols="12"
                 lg="6">
            <consultant-personal-details-card class="mb-6"
                                              ref="consultantPersonalDetailsCard"
                                              v-bind:consultant="consultant" />
            <consultant-state-card ref="consultantStateCard"
                                   v-bind:consultant="consultant" />
          </v-col>
          <v-col cols="12"
                 lg="6">
            <account-media-card ref="accountMediaCard"
                                v-bind:consultant="consultant" />
            <consultant-company-details-card class="mt-6" ref="consultantCompanyDetailsCard"
                                             v-bind:consultant="consultant" />

            <account-link-card class="mt-6" v-bind:consultant="consultant" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import AccountMediaCard from '@/components/v2/account/AccountMediaCard.vue' // IMPRV: Rename to ConsultantMediaCard and add to validation

  import ConsultantCompanyDetailsCard from '@/components/v2/administration/ConsultantCompanyDetailsCard.vue'
  import ConsultantPersonalDetailsCard from '@/components/v2/account/ConsultantPersonalDetailsCard.vue'
  import ConsultantStateCard from '@/components/v2/administration/ConsultantStateCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import AccountLinkCard from '@/components/v2/account/AccountLinkCard.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      AccountMediaCard,
      AccountLinkCard,
      ConfirmDialog,
      ConsultantCompanyDetailsCard,
      ConsultantPersonalDetailsCard,
      ConsultantStateCard,
      FullWidthSection,
      HeaderButton,
      TopHeader
    },
    computed: {
      formattedName() {
        return this.consultant?.formattedName
      },
      id() {
        return this.consultant?.id
      },
      ...mapGetters({
        getConsultant: 'base/consultant',
        hasPermission: 'auth/hasPermission'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      confirmDeleteDialog: false,
      consultant: null,
      loading: false,
      validationSnackbar: false
    }),
    methods: {
      async deleteConsultant() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteConsultant({ id: this.id })

          this.loading = false

          this.$router.push({ name: 'administration-consultants' })
        })
      },
      async get(id) {
        this.loading = true

        await this.loadConsultant({ id })
        await this.loadCompanies()

        this.consultant = Object.assign({}, this.getConsultant(id))

        this.loading = false
      },
      async updateConsultant() {
        if (!this.$refs.consultantCompanyDetailsCard.validate() || !this.$refs.consultantPersonalDetailsCard.validate() || !this.$refs.consultantStateCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateConsultant({ consultant: this.consultant })

        this.loading = false

        this.$router.push({ name: 'administration-consultants' })
      },
      ...mapActions({
        doDeleteConsultant: 'base/deleteConsultant',
        doUpdateConsultant: 'base/updateConsultant',
        loadCompanies: 'base/loadCompanies',
        loadConsultant: 'base/loadConsultant'
      })
    },
    name: 'ConsultantView'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisConsultantIrrevocably": "Sind Sie sicher, dass Sie diesen Berater unwiderruflich löschen möchten?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "consultant": "Berate",
      "delete": "Löschen",
      "deleteConsultant": "Berater löschen",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisConsultantIrrevocably": "Are you sure you want to delete this consultant irrevocably?",
      "back": "Back",
      "cancel": "Cancel",
      "consultant": "Consultant",
      "delete": "Delete",
      "deleteConsultant": "Delete consultant",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>