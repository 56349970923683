<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('media') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <image-attachment-field accept="image/jpeg"
                                v-bind:label="$t('photo')"
                                v-bind:max-size="2048"
                                v-bind:attachment.sync="consultant.photoAttachment"
                                v-model="consultant.photoAttachmentId" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import ImageAttachmentField from '@/components/common/ImageAttachmentField'

  export default {
    components: {
      ImageAttachmentField
    },
    name: 'AccountMediaCard',
    methods: {
      validate() {
        return true
      }
    },
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "media": "Medien",
      "photo": "Foto"
    },
    "en": {
      "media": "Media",
      "photo": "Photo"
    }
  }
</i18n>