<template>
  <div>
    <v-dialog persistent
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-card-text class="pt-4">
          <h4 class="mb-6 text-h4 primary--text">{{ $t('corporateRequest') }}</h4>
          <v-form ref="form"
                  v-if="!sent">
            <v-row>
              <v-col cols="12"
                     md="6">
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.givenName"
                              v-bind:label="$t('givenName')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
              <v-col cols="12"
                     md="6">
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.familyName"
                              v-bind:label="$t('familyName')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.company"
                              v-bind:label="$t('company')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.email"
                              v-bind:label="$t('email')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.phone"
                              v-bind:label="$t('phone')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.consultantsCount"
                              v-bind:label="$t('consultantsCount')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select hide-details="auto"
                          outlined
                          v-bind:items="locales | vueI18nItems({ text: 'label' })"
                          v-bind:label="$t('language')"
                          v-bind:rules="[ rules.required ]"
                          v-model="message.language" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea hide-details="auto"
                            outlined
                            v-bind:counter="500"
                            v-bind:label="$t('comment')"
                            v-bind:rules="[ rules.maxLength(500) ]"
                            v-model="message.comment" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox class="mt-0"
                            hide-details="auto"
                            v-bind:rules="[ rules.required, rules.true ]"
                            v-model="message.privacyPolicyAndtermsAndConditions">
                  <template v-slot:label>
                    <span @click.stop v-html="$t('iAgreeWithThePrivacyPolicyAndTheTermsAndConditions')"></span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
          <p v-else>
            {{ $t('thankYouForYourInquiryWithConfederiaWeWillGetBackToYouAsSoonAsPossible') }}
          </p>
        </v-card-text>
        <v-card-actions class="pa-4"
                        v-if="!sent">
          <v-btn color="primary"
                 large
                 text
                 v-on:click="cancel">
            <v-icon color="accent"
                    left>
              mdi-close
            </v-icon>
            <span>{{ $t('cancel') }}</span>
          </v-btn>
          <v-spacer />
          <v-btn color="primary"
                 depressed
                 large
                 v-on:click="confirm">
            <span>{{ $t('send') }}</span>
            <v-icon color="accent"
                    right>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions class="pa-4"
                        v-else>
          <v-spacer />
          <v-btn color="primary"
                 depressed
                 large
                 v-on:click="close">
            <span>{{ $t('close') }}</span>
            <v-icon color="accent"
                    right>
              mdi-check
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '@/mixins/v2/rules'

  import locales from '@/locales.json'

  export default {
    computed: {
      locales() {
        return locales.filter(l => l.hasLocalization)
      }
    },
    data: () => ({
      message: {},
      resolve: null,
      sent: false
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      close() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.sent = true
        this.resolve(this.message)
      },
      async open(resolve, message) {
        this.resolve = resolve
        this.message = message || {}
        this.$emit('input', true)
      }
    },
    mixins: [ rules ],
    name: 'CorporateRequestDialog',
    props: {
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "close": "Schließen",
      "corporateRequest": "Corporate-Anfrage",
      "comment": "Kommentar",
      "company": "Firma",
      "consultantsCount": "Anzahl gewünschter Beraterprofile",
      "email": "E-Mail-Adresse",
      "givenName": "Vorname",
      "familyName": "Nachname",
      "language": "Sprache",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "Ich erkläre mich mit der <a target=\"_blank\" href=\"/privacy-policy\">Datenschutzerklärung</a> und den <a target=\"_blank\" href=\"/terms-of-service\">allgemeinen Geschäftsbedingungen (AGB)</a> einverstanden.",
      "phone": "Telefonnummer",
      "send": "Senden",
      "thankYouForYourInquiryWithConfederiaWeWillGetBackToYouAsSoonAsPossible": "Vielen Dank für Ihre Anfrage bei Confederia. Wir werden uns schnellstmöglich bei Ihnen melden."


    },
    "en": {
      "cancel": "Cancel",
      "close": "Close",
      "corporateRequest": "Corporate Request",
      "comment": "Comment",
      "company": "Company",
      "consultantsCount": "Consultants count",
      "email": "Email",
      "givenName": "Given name",
      "familyName": "Family name",
      "language": "Sprache",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "I agree with the <a target=\"_blank\" href=\"/privacy-policy\">privacy policy</a> and the <a target=\"_blank\" href=\"/terms-of-service\">terms and conditions</a>.",
      "phone": "Phone",
      "send": "Send",
      "thankYouForYourInquiryWithConfederiaWeWillGetBackToYouAsSoonAsPossible": "Thank you for your inquiry with Confederia. We will get back to you as soon as possible."

    }
  }
</i18n>