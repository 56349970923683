export default class CompanyService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(company, options) {
    const response = await fetch(`${this.baseUri}/company`, {
      body: JSON.stringify(company),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUri}/company/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    const response = await fetch(`${this.baseUri}/company/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/company`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(id, company, options) {
    const response = await fetch(`${this.baseUri}/company/${id}`, {
      body: JSON.stringify(company),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }




  async createConsultant(id, consultantId, options) {
    const response = await fetch(`${this.baseUri}/company/${id}/consultant/${consultantId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'POST'
    })

    return await response.ok
  }

  async deleteConsultant(id, consultantId, options) {
    const response = await fetch(`${this.baseUri}/company/${id}/consultant/${consultantId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }
}