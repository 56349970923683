<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('media') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <image-attachment-field accept="image/jpeg,image/png"
                                v-bind:label="$t('logo')"
                                v-bind:max-size="2048"
                                v-bind:attachment.sync="company.logoAttachment"
                                v-model="company.logoAttachmentId" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import ImageAttachmentField from '@/components/common/ImageAttachmentField'

  export default {
    components: {
      ImageAttachmentField
    },
    name: 'CompanyMediaCard',
    methods: {
      validate() {
        return true
      }
    },
    props: {
      company: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "media": "Medien",
      "logo": "Logo"
    },
    "en": {
      "media": "Media",
      "logo": "Logo"
    }
  }
</i18n>