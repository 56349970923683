<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field disabled
                        outlined
                        persistent-hint
                        v-bind:hint="$t('willBeAssignedByConfederia')"
                        v-bind:label="$t('number')"
                        v-model="consultant.id" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:disabled="disableEmail"
                        v-bind:label="$t('email')"
                        v-bind:rules="!disableEmail ? [ rules.required ] : []"
                        v-model="consultant.email" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <v-select hide-details="auto"
                    outlined
                    v-bind:items="honorifics | vueI18nItems({ text: 'label' })"
                    v-bind:label="$t('honorific')"
                    v-bind:rules="[ rules.required ]"
                    v-model="consultant.honorific" />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('title')"
                        v-model="consultant.title" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="6">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('givenName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="consultant.givenName" />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('familyName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="consultant.familyName" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />
    <v-subheader>{{ $t('businessAddress') }}</v-subheader>

    <v-card-text>
      <v-row>
        <v-col cols="12"
               md="8">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('streetName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="consultant.streetName" />
        </v-col>
        <v-col cols="12"
               md="4">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('streetNumber')"
                        v-bind:rules="[ rules.required ]"
                        v-model="consultant.streetNumber" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="4">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('postalCode')"
                        v-bind:rules="[ rules.required ]"
                        v-model="consultant.postalCode" />
        </v-col>
        <v-col cols="12"
               md="8">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('city')"
                        v-bind:rules="[ rules.required ]"
                        v-model="consultant.city" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select hide-details="auto"
                    outlined
                    v-bind:items="cantons | vueI18nItems({ text: 'label' })"
                    v-bind:label="$t('canton')"
                    v-bind:rules="[ rules.required ]"
                    v-model="consultant.canton" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select hide-details="auto"
                    outlined
                    v-bind:items="countries | vueI18nItems({ text: 'label' })"
                    v-bind:label="$t('country')"
                    v-bind:rules="[ rules.required ]"
                    v-model="consultant.country" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />
    <v-subheader>{{ $t('contactInformation') }}</v-subheader>

    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('publicEmail')"
                        v-bind:rules="[ rules.required ]"
                        v-model="consultant.publicEmail" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('phoneCellular')"
                        v-model="consultant.phoneCellular" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('website')"
                        v-model="consultant.websiteUrl" />
        </v-col>
      </v-row>
      <p class="mb-0 mt-4">{{ $t('theseDetailsWillBeDisplayedInYourPublicProfile') }}</p>
    </v-card-text>

    <v-divider />
    <template v-if="hasRole('ConfederiaAdministrator')">
      <v-subheader>Development</v-subheader>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field hide-details="auto"
                          outlined
                          label="Calenso-Token"
                          v-model="consultant.calensoToken" />
          </v-col>
          <v-col>
            <v-text-field hide-details="auto"
                          outlined
                          label="Calenso-WorkerId"
                          v-model="consultant.calensoWorkerId" />
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import rules from '@/mixins/v2/rules'

  export default {
    computed: {
      ...mapGetters({
        cantons: 'local/cantons',
        countries: 'local/countries',
        honorifics: 'local/honorifics',
        hasRole: 'auth/hasRole'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    mixins: [ rules ],
    name: 'ConsultantPersonalDetails',
    props: {
      consultant: Object,
      disableEmail: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "businessAddress": "Geschäftsadresse",
      "canton": "Kanton",
      "city": "Ort",
      "contactInformation": "Kontaktdaten",
      "country": "Land",
      "email": "E-Mail-Adresse",
      "familyName": "Nachname",
      "givenName": "Vorname",
      "honorific": "Anrede",
      "number": "Nummer",
      "phoneCellular": "Telefonnummer (mobil)",
      "postalCode": "Postleitzahl",
      "publicEmail": "Öffentliche E-Mail-Adresse",
      "streetName": "Straße",
      "streetNumber": "Hausnummer",
      "theseDetailsWillBeDisplayedInYourPublicProfile": "Diese Angaben werden in Ihrem öffentlichen Profil angezeigt.",
      "title": "Titel",
      "website": "Website",
      "willBeAssignedByConfederia": "Wird von Confederia zugewiesen"
    },
    "en": {
      "businessAddress": "Business address",
      "canton": "Canton",
      "city": "City",
      "contactInformation": "Contact information",
      "country": "Country",
      "email": "Email",
      "familyName": "Family name",
      "givenName": "Given name",
      "honorific": "Honorific",
      "number": "Number",
      "phoneCellular": "Phone (cellular)",
      "postalCode": "Postal code",
      "publicEmail": "Public email",
      "streetName": "Street name",
      "streetNumber": "Street number",
      "theseDetailsWillBeDisplayedInYourPublicProfile": "These details will be displayed in your public profile.",
      "title": "Title",
      "website": "Website",
      "willBeAssignedByConfederia": "Will be assigned by Confederia"
    }
  }
</i18n>