<template>
  <v-card color="white"
          flat>
    <v-card-text class="pa-6"
                 v-bind:class="isQuestion ? 'text-center' : null">
      <questionnaire-progress-indicator class="py-2" v-bind:number="questionStep"
                                        v-bind:count="questionCount"
                                        v-if="isQuestion" />
      <h4 class="primary--text text-h4" v-else>{{ currentTitle }}</h4>
      <h6 class="primary--text text-h6">{{ currentSubTitle }}</h6>
    </v-card-text>

    <v-stepper class="transparent"
               elevation="0"
               v-model="step"
               id="stepper" ref="stepper">
      <v-stepper-items>
        <v-stepper-content class="pa-0"
                           step="1">
          <questionnaire-introduction />
        </v-stepper-content>
        <v-stepper-content class="px-0 py-4"
                           v-bind:key="questionDefinition.id"
                           v-bind:step="index + 2"
                           v-for="(questionDefinition, index) in questionDefinitions">
          <component ref="question"
                     v-bind:is="getComponent(questionDefinition.type)"
                     v-bind:model="evaluation.model"
                     v-bind:question-definition="questionDefinition"
                     v-on:next="goToNextStep" />
        </v-stepper-content>
        <v-stepper-content class="pa-0"
                           v-bind:step="stepCount">
          <questionnaire-details ref="questionnaireDetails"
                                 v-bind:evaluation="evaluation" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card-actions class="pa-4">
      <v-fade-transition>
        <v-btn color="primary"
               depressed
               large
               outlined
               v-if="hasPreviousStep"
               v-on:click="goToPreviousStep">
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          <span>{{ $t('back') }}</span>
        </v-btn>
      </v-fade-transition>

      <v-spacer />

      <v-btn color="primary"
              depressed
              large
              v-if="hasNextStep"
              v-on:click="goToNextStep">
        <span>{{ $t('continue') }}</span>
        <v-icon color="accent"
                right>
          mdi-arrow-right
        </v-icon>
      </v-btn>

      <v-btn color="primary"
              depressed
              large
              v-bind:loading="loading"
              v-if="isLastStep"
              v-on:click="submit">
        <span>{{ $t('submit') }}</span>
        <v-icon color="accent"
                right>
          mdi-check
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import QuestionnaireDetails from '@/components/v2/questionnaire/QuestionnaireDetails.vue'
  import QuestionnaireIntroduction from '@/components/v2/questionnaire/QuestionnaireIntroduction.vue'
  import QuestionnaireProgressIndicator from '@/components/v2/questionnaire/QuestionnaireProgressIndicator.vue'
  import RatingQuestion from '@/components/v2/questionnaire/RatingQuestion.vue'
  import ScaleRatingQuestion from '@/components/v2/questionnaire/ScaleRatingQuestion.vue'
  import SelectQuestion from '@/components/v2/questionnaire/SelectQuestion.vue'
  import TextQuestion from '@/components/v2/questionnaire/TextQuestion.vue'
  import YesNoQuestion from '@/components/v2/questionnaire/YesNoQuestion.vue'

  export default {
    components: {
      QuestionnaireDetails,
      QuestionnaireIntroduction,
      QuestionnaireProgressIndicator,
      RatingQuestion,
      ScaleRatingQuestion,
      SelectQuestion,
      TextQuestion,
      YesNoQuestion
    },
    computed: {
      currentQuestion() {
        return this.questionDefinitions[this.step - 2]
      },
      currentSubTitle() {
        if (this.isIntroduction) {
          return this.$t('shapeBetterCounselingWithUs')
        } else if (this.isQuestion) {
          return this.currentQuestion.ratingCategory.name
        } else if (this.isDetails) {
          return this.$t('nowOverToYou')
        }

        return null
      },
      currentTitle() {
        if (this.isIntroduction) {
          return this.$t('yourVoiceMatters')
        } else if (this.isDetails) {
          return this.$t('yourDetails')
        }

        return null
      },
      hasNextStep() {
        return this.step < this.stepCount
      },
      hasPreviousStep() {
        return this.step > 1
      },
      isDetails() {
        return this.step === this.stepCount
      },
      isIntroduction() {
        return this.step === 1
      },
      isLastStep() {
        return this.step === this.stepCount
      },
      isQuestion() {
        return this.step > 1 && this.step < this.stepCount
      },
      questionCount() {
        return this.questionDefinitions.length
      },
      questionStep() {
        return this.step - 1
      },
      stepCount() {
        return this.questionCount + 2
      }
    },
    data: () => ({
      step: 1
    }),
    methods: {
      getComponent(type) {
        const kebabType = type.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
        return `${kebabType}-question`
      },
      goToNextStep() {
        if (!this.hasNextStep) {
          return
        }

        if (this.isQuestion && !this.$refs.question[this.questionStep - 1].validate()) {
          return
        }

        // scroll up to #stepper minus 64px
        const element = this.$refs.stepper.$el;
        console.log(element)
        const y = element.getBoundingClientRect().top + window.scrollY - 192;

        window.scrollTo({top: y, behavior: 'smooth'});


        this.step += 1
      },
      goToPreviousStep() {
        if (!this.hasPreviousStep) {
          return
        }

        this.step -= 1
      },
      submit() {
        if (!this.$refs.questionnaireDetails.validate()) {
          return
        }

        this.$emit('submit', this.model)
      }
    },
    name: 'QuestionnaireStepperCard',
    props: {
      evaluation: Object,
      loading: Boolean,
      questionDefinitions: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "continue": "Weiter",
      "nowOverToYou": "Nun zu Ihnen",
      "yourVoiceMatters": "Ihre Stimme zählt:",
      "submit": "Absenden",
      "shapeBetterCounselingWithUs": "Gestalten Sie bessere Beratung mit!",
      "yourDetails": "Ihre Angaben"
    },
    "en": {
      "back": "Back",
      "continue": "Continue",
      "nowOverToYou": "Now over to you",
      "yourVoiceMatters": "Your voice matters:",
      "submit": "Submit",
      "shapeBetterCounselingWithUs": "Shape better counseling with us!",
      "yourDetails": "Your details"
    }
  }
</i18n>