<template>
  <v-card outlined>
    <v-toolbar color="primary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-inner-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
          <v-col>
            <v-autocomplete clearable
                            flat
                            hide-details
                            multiple
                            prepend-inner-icon="mdi-city"
                            solo
                            v-bind:items="postalCodeItems"
                            v-bind:label="$t('city')"
                            v-model="postalCodeFilter" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('companies') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 25, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredCompanies"
                  v-bind:items-per-page="50"
                  v-bind:options="{ sortBy: [ 'name' ] }"
                  v-on:click:row="$emit('click:row', $event)">
      <template v-slot:[`item.city`]="{ item }">
        {{ item.postalCode }} {{ item.city }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    computed: {
      postalCodeItems() {
        return [...new Set(this.companies.filter(c => c.postalCode != null && c.city != null).map(c => ({
          text: `${c.postalCode} ${c.city}`,
          value: c.postalCode
        })))]
      },
      filteredCompanies() {
        let filteredCompanies = this.companies

        if (this.search != null && this.search.length !== 0) {
          filteredCompanies = filteredCompanies.filter(f => `${f.id} ${f.name}`.toLowerCase().includes(this.search.toLowerCase()))
        }

        if (this.postalCodeFilter != null && this.postalCodeFilter.length !== 0) {
          filteredCompanies = filteredCompanies.filter(f => this.postalCodeFilter.includes(f.postalCode))
        }

        return filteredCompanies
      },
      headers() {
        return [
        {
            text: this.$t('number'),
            value: 'id'
          },
          {
            text: this.$t('name'),
            value: 'name'
          },
          {
            text: this.$t('city'),
            value: 'city'
          }
        ]
      }
    },
    data: () => ({
      postalCodeFilter: null,
      search: null
    }),
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    name: 'CompanyTableCard',
    props: {
      companies: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "city": "Ort",
      "companies": "Unternehmen",
      "name": "Name",
      "number": "Nummer",
      "search": "Suche"
    },
    "en": {
      "city": "City",
      "companies": "Companies",
      "name": "Name",
      "number": "Number",
      "search": "Search"
    }
  }
</i18n>