<template>
  <div>
    <top-header color="secondary"
                v-bind:title="$t('privacyPolicy')">
    </top-header>
    <full-width-section color="transparent">
      <v-container>
        <v-row>
          <v-col cols="12"
                 xl="8">

                 <h4 class="mb-3 text-h4 primary--text">Datenschutzerklärung</h4>
<p class="mb-9">
  Wir legen großen Wert auf den Schutz Ihrer Daten. In dieser Datenschutzerklärung erläutern wir, welche Rechte Sie im Hinblick auf den Schutz Ihrer Daten haben und wie wir Ihre personenbezogenen Daten erfassen, verwenden, offenbaren, übermitteln und speichern.
</p>
<h4 class="mb-3 text-h4 primary--text">Begriffe</h4>
<p class="mb-9">
  Wenn wir von “Confederia“, „wir“, „unsere“ oder „uns“ sprechen, meinen wir damit Confederia KlG, Bergstrasse 44, 9445-Rebstein, welches das für die Verarbeitung Ihrer personenbezogenen Daten verantwortliche Unternehmen ist.
</p>
<p class="mb-9">
  Wenn wir von unserer „Website“ oder unserer „Plattform“ sprechen, meinen wir damit sämtliche Websites und Anwendungen von Confederia.
</p>
<h4 class="mb-3 text-h4 primary--text">Websites Dritter</h4>
<p class="mb-9">
  Unsere Plattform enthält Links zu anderen Websites. Dies bedeutet jedoch nicht, dass wir diese Websites befürworten. Für deren Inhalte und deren Umgang mit Daten übernehmen wir keine Haftung. Wir empfehlen Ihnen daher, die Datenschutzerklärungen dieser anderen Websites unbedingt zu prüfen, da sich deren Vorgehensweise in Bezug auf die Erfassung, Handhabung und Verarbeitung personenbezogener Daten von unserer Vorgehensweise unterscheiden kann.
</p>


<h4 class="mb-3 text-h4 primary--text">Welche personenbezogenen Daten wir erfassen</h4>
<p class="mb-9">
  Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierbare Person beziehen. Wenn Sie bei confederia.ch ein Berater-Profil anlegen, eine Bewertung abgeben, einen Unternehmens-Account im Namen Ihres Unternehmens einrichten oder unsere Plattform auf andere Weise nutzen, können wir die folgenden personenbezogenen Daten über Sie erfassen und verarbeiten:
</p>
<h5 class="mb-3 text-h5 primary--text">1. Kontaktinformationen:</h5>
<p class="mb-9">
  - Ihren Namen und Ihre E-Mail-Adresse sowie sonstige Kontaktinformationen, die Sie uns ggf. mitteilen.
</p>
<h5 class="mb-3 text-h5 primary--text">2. Informationen zu Ihrem Gerät und Standort:</h5>
<p class="mb-9">
  - Ihre IP-Adresse, Browsereinstellungen (die Art des von Ihnen verwendeten Browsers, die Browsersprache, die Zeitzone) und Ihren Standort.
</p>
<h5 class="mb-3 text-h5 primary--text">3. Informationen zu Ihrem Berater-Account:</h5>
<p class="mb-9">
  - Ihren Nutzernamen, Ihr Passwort, Ihr Foto, Ihren Standort und Ihre bevorzugte Sprache.
</p>
<h5 class="mb-3 text-h5 primary--text">4. Informationen zu Ihrem Unternehmens-Account:</h5>
<p class="mb-9">
  - Ihr Passwort, den Namen und die Webadresse Ihres Unternehmens sowie bestimmte weitere Informationen, die Sie Ihrem Unternehmensprofil hinzufügen, wie z. B. detaillierte Angaben zu den Dienstleistungen, die Ihr Unternehmen anbietet.
</p>
<h5 class="mb-3 text-h5 primary--text">5. Nutzungs- und Profilerstellungsdaten:</h5>
<p class="mb-9">
  - Ihre Suchhistorie auf confederia.ch, die E-Mails, die wir an Sie senden, Ihre Interaktionen mit unserer Plattform, einschließlich der Zeit, die Sie auf unserer Website verbringen, der von Ihnen genutzten Funktionen und Funktionalitäten, der von Ihnen geöffneten Marketing-E-Mails und der von Ihnen angeklickten Links.
</p>
<h5 class="mb-3 text-h5 primary--text">6. Informationen zu Bewertungen:</h5>
<p class="mb-9">
  - Welchen Berater Sie bewertet haben, der Inhalt Ihrer Bewertung und die von Ihnen erteilte Sternebewertung, das Datum Ihrer Bewertung und ggf. das Datum, an dem Sie Änderungen an der Bewertung vorgenommen oder die Bewertung gelöscht haben, Ihre Referenz- bzw. Angebotsnummer oder eine ähnliche Nummer (sofern Sie oder das Unternehmen diese angegeben haben).
</p>
<!-- Weitere Abschnitte hier einfügen basierend auf Ihrer Datenschutzerklärung -->
<h5 class="mb-3 text-h5 primary--text">7. Anrufaufzeichnungen:</h5>
<p class="mb-9">
  - Alle bei confederia.ch eingehenden oder von confederia.ch aus getätigten Telefonate können zu Qualitätssicherungs-, Konformitäts- und internen Schulungszwecken aufgezeichnet werden. Personenbezogene Daten (wie z. B. Name und Kontaktinformationen), die Sie während eines Telefonats bekanntgeben, können digital aufgezeichnet werden, um die entsprechenden Dienstleistungen erbringen zu können.
</p>
<h4 class="mb-3 text-h4 primary--text">Wie und warum wir Ihre personenbezogenen Daten verwenden</h4>
<p class="mb-9">
  Ihre personenbezogenen Daten verwenden wir ggf., um:
</p>
<ol class="mb-9">
  <li>
    unsere Dienstleistungen für Sie zu erbringen, wie u. a. Ihre Bewertungen anzuzeigen und Ihnen Zugriff auf unsere Plattform zu ermöglichen oder Ihnen Zugriff auf den Unternehmens-Account Ihres Unternehmens und unsere Website zu gewähren,
  </li>
  <li>
    Sie als registrierten Nutzer zu identifizieren, wenn Sie sich in unserer Plattform einloggen und die Plattform erneut besuchen,
  </li>
  <li>
    unsere Plattform und unsere Dienstleistungen zu verbessern,
  </li>
  <li>
    auf Ihre Fragen zu reagieren und unseren Kundenservice bereitzustellen,
  </li>
  <li>
    verschiedene interne geschäftliche Maßnahmen durchzuführen, wie z. B. Datenanalysen, Kontrollen, Überwachung und vorbeugende Maßnahmen zum Schutz gegen Betrugsversuche, Entwicklung neuer Produkte und Dienstleistungen, Verbesserungen oder Veränderungen unserer Plattform oder unserer Dienstleistungen, einschließlich der Ermittlung von Nutzungstrends, Ermittlung der Wirksamkeit unserer Werbekampagnen sowie Durchführung und Ausdehnung unserer Geschäftstätigkeiten,
  </li>
  <li>
    unsere Mitarbeiter zu schulen und zu Qualitätssicherungszwecken, was uns dabei hilft, die Qualität unserer Dienstleistungen zu verbessern,
  </li>
  <li>
    bei Bedarf im Zusammenhang mit Rechtsansprüchen oder zu Konformitäts-, regulatorischen und Prüfungszwecken unsere eigenen Rechte geltend zu machen bzw. unsere gesetzlichen Verpflichtungen zu erfüllen. Beispielsweise bewahren wir ggf. Daten auf, wenn wir gesetzlich oder aufgrund einer gerichtlichen oder behördlichen Anordnung dazu verpflichtet sind.
  </li>
</ol>

<h4 class="mb-3 text-h4 primary--text">Zusätzliche Verwendung Ihrer personenbezogenen Daten bei Unternehmens-Accounts</h4>
<p class="mb-9">
  Wenn Sie bei confederia.ch über einen Unternehmens-Account im Namen Ihres Unternehmens verfügen, verwenden wir Ihre personenbezogenen Daten außerdem, um:
</p>
<ol class="mb-9">
  <li>
    Sie über Bewertungen zu den Beratern Ihres Unternehmens zu informieren,
  </li>
  <li>
    die Zulässigkeit Ihrer Antworten auf Bewertungen zu verifizieren,
  </li>
  <li>
    Sie zu informieren, wenn Endkunden Feedback zu Ihrer Antwort auf eine Bewertung oder zu Ihrer Meldung einer Bewertung übermitteln,
  </li>
  <li>
    unsere allgemeinen Nutzungsbedingungen geltend zu machen.
  </li>
</ol>
<p class="mb-9">
  Wir können diese Informationen ggf. auch in anderer Weise verwenden, über die wir Sie zum Zeitpunkt der Erfassung gesondert informieren.
</p>
<p class="mb-9">
  Wir müssen Ihre personenbezogenen Daten verarbeiten, um:
</p>
<ol class="mb-9">
  <li>
    unseren Vertrag mit Ihnen oder Ihrem Unternehmen zu erfüllen,
  </li>
  <li>
    unsere eigenen Rechte geltend zu machen und unsere gesetzlichen Verpflichtungen zu erfüllen und um unsere Online-Bewertungsplattform in Übereinstimmung mit den geltenden Gesetzen zu betreiben,
  </li>
  <li>
    berechtigten Geschäftsinteressen in Bezug auf den Betrieb unserer Website und die Bereitstellung unserer Dienstleistungen für Sie nachzugehen. Sofern wir Ihre personenbezogenen Daten auf der Basis eines berechtigten Interesses verarbeiten, erfolgt dies nur, soweit die Verarbeitung relevant, angemessen und auf die für den Erfassungszweck notwendigen Daten beschränkt ist. Selbstverständlich stellen wir dabei stets sicher, dass sich unsere berechtigten Interessen nicht in unfairer Weise auf Ihre persönlichen Rechte und Freiheiten auswirken.
  </li>
  <li>
    bei Bedarf Rechtsansprüche geltend zu machen, wahrzunehmen oder zu verteidigen.
  </li>
</ol>
<h4 class="mb-3 text-h4 primary--text">Zugriff auf Ihre personenbezogenen Daten</h4>
<h5 class="mb-3 text-h5 primary--text">Ihre personenbezogenen Daten auf confederia.ch</h5>
<p class="mb-9">
  Wir zeigen Ihre Bewertungen auf unserer Plattform, damit andere über Ihre Erfahrungen mit bei uns registrierten Finanzberatern lesen können. Wenn Sie eine Bewertung abgeben, können andere Nutzer unserer Plattform den von Ihnen gewählten Anzeigenamen, die von Ihnen bewertete Beratungsdienstleistung und sonstige Informationen sehen, die Sie im Kommentarfeld veröffentlicht haben.
</p>
<p class="mb-9">
  Wenn Sie Ihren richtigen Namen als Nutzernamen verwenden, denken Sie bitte daran, dass dies Ihre Identität preisgeben kann. Wir empfehlen, dass Sie bei der Entscheidung, welche Informationen Sie auf unserer Plattform verfügbar machen wollen, mit Umsicht vorgehen und sich bewusst sind, dass Sie je nach Art und Umfang der angegebenen Informationen möglicherweise nicht anonym sind.
</p>
<h5 class="mb-3 text-h5 primary--text">Ihre personenbezogenen Daten auf confederia.ch, wenn Sie im Namen Ihres Unternehmens einen Unternehmens-Account einrichten</h5>
<p class="mb-9">
  Wenn Sie im Namen Ihres Unternehmens einen Unternehmens-Account einrichten, zeigen wir den Namen, die Adresse, die Domain sowie das Land Ihres Unternehmens und sonstige Informationen an, die Sie mit dem öffentlichen Profil des Unternehmens verbunden haben.
</p>
<p class="mb-9">
  Wir empfehlen, dass Sie bei der Entscheidung, welche Informationen Sie für die Veröffentlichung auf der Website verfügbar machen wollen, mit Umsicht vorgehen und sich bewusst sind, dass Sie je nach Art und Umfang der angegebenen Informationen möglicherweise personenbezogene Daten preisgeben.
</p>
<p class="mb-9">
  Auch andere Informationen, die Sie auf der Website verfügbar machen, einschließlich Ihrer Antworten auf Bewertungen zu Ihrem Unternehmen, können von anderen Nutzern der Website eingesehen werden.
</p>
<h5 class="mb-3 text-h5 primary--text">Ihre personenbezogenen Daten, die an andere Dienste, Websites und Unternehmen weitergegeben werden</h5>
<p class="mb-9">
  Eines der wichtigsten Ziele von confederia.ch besteht darin, die Verfügbarkeit und Präsenz von Bewertungen zu steigern. Daher gestatten wir es anderen Diensten, auf unserer Plattform abgegebene Bewertungen, einschließlich u. a. der Antworten von Unternehmen zu den auf unserer Website abgegebenen Bewertungen, anzuzeigen. Dies erweitert die potenzielle Zielgruppe und steigert den Bekanntheitsgrad von confederia.ch und den Bewertungen bei Verbrauchern.
</p>
<p class="mb-9">
  Diese Drittanbieter und Unternehmen, die Ihre Bewertungen zusammen mit Ihrem öffentlichen Nutzerprofil (oder, sofern Sie einen Unternehmens-Account auf confederia.ch haben, Ihre Antworten auf Bewertungen zu Ihrem Unternehmen) anzeigen können, gehören zu folgenden Kategorien:
</p>
<ul class="mb-9">
  <li>Suchmaschinen, wie u. a. Google und Bing</li>
  <li>Unternehmen, die Sie auf confederia.ch bewertet haben</li>
  <li>sonstige ähnliche Websites, die nach Einschätzung von confederia.ch für Nutzer relevant sind, die nach Bewertungen suchen</li>
  <li>soziale Netzwerke</li>
</ul>
<p class="mb-9">
  Wir geben Nachweise, die Sie uns zur Verifizierung Ihrer Erfahrung übermitteln, nicht an das bewertete Unternehmen weiter. Falls Sie jedoch eine Referenz- oder Angebotsnummer oder dergleichen haben, können wir diese an das Unternehmen weiterleiten, sofern Sie der Weitergabe nicht vorab widersprechen.
</p>
<h4 class="mb-3 text-h4 primary--text">Sonstige Offenlegungen</h4>
<p class="mb-9">
  Zusätzlich zu den vorgenannten Fällen geben wir Ihre personenbezogenen Daten an folgende Dritte und unter folgenden Umständen weiter:
</p>
<ul class="mb-9">
  <li>
    um gesetzliche Vorschriften einzuhalten oder auf Klagen und Gerichtsverfahren (wie u. a. Vorladungen oder Gerichtsbeschlüsse) oder Aufforderungen öffentlicher und staatlicher Behörden zu reagieren,
  </li>
  <li>
    um mit aufsichtsrechtlichen und staatlichen Behörden im Zusammenhang mit Untersuchungen oder verwiesenen Verfahren zu kooperieren,
  </li>
  <li>
    an Dritte in Verbindung mit der Durchsetzung unserer allgemeinen Nutzungsbedingungen für Nutzer,
  </li>
  <li>
    an Dritte, um unseren Geschäftsbetrieb bzw. den unserer verbundenen Unternehmen zu schützen,
  </li>
  <li>
    an Dritte, um unsere Rechte, Privatsphäre, Sicherheit oder unser Eigentum und/oder die Rechte, Privatsphäre, Sicherheit oder das Eigentum unserer verbundenen Unternehmen, von Ihnen oder anderen zu schützen,
  </li>
  <li>
    an Dritte, um uns zur Verfügung stehende Rechtsmittel in Anspruch zu nehmen oder uns entstandene Schäden einzugrenzen,
  </li>
  <li>
    an Dritte, damit wir mutmaßliche oder tatsächliche verbotene Handlungen, wie u. a. Betrug oder Missbrauch unserer Plattform, untersuchen, diesen vorbeugen oder diesbezüglich Maßnahmen ergreifen können,
  </li>
  <li>
    an Dritte im Falle von Umstrukturierungen, Fusionen, Übernahmen, Verkäufen, Joint Ventures, Abtretungen, Übertragungen oder sonstigen Veräußerungen des gesamten oder Teilen unseres Geschäfts bzw. unserer Vermögenswerte (wie auch in Verbindung mit etwaigen Insolvenz- oder ähnlichen Verfahren).
  </li>
</ul>
<h4 class="mb-3 text-h4 primary--text">Änderungen an dieser Datenschutzerklärung</h4>
<p class="mb-9">
  Diese Datenschutzerklärung kann von uns bei Bedarf abgeändert werden. Gesetze, Vorschriften und Branchenstandards entwickeln sich beständig weiter, was derartige Änderungen erforderlich machen kann. Auch Änderungen an unseren Dienstleistungen oder unseren Geschäftstätigkeiten können zu Änderungen an unserer Datenschutzerklärung führen. Wir veröffentlichen diese Änderungen jeweils auf dieser Seite und empfehlen Ihnen, unsere Datenschutzerklärung gelegentlich durchzusehen, um über den aktuellen Stand informiert zu bleiben.
</p>
<p class="mb-9">
  Wenn wir Änderungen vornehmen, die erhebliche Auswirkungen auf Ihre Datenschutzrechte haben, geben wir dies zusätzlich per E-Mail oder über unsere Plattform bekannt. Ihre weitere Nutzung unserer Plattform oder unserer Dienste nach Veröffentlichung der Änderungen oder einer Mitteilung über Änderungen an der Datenschutzerklärung bedeutet, dass Sie diese akzeptieren und der aktualisierten Datenschutzerklärung zustimmen.
</p>
<h4 class="mb-3 text-h4 primary--text">Eingebettete Inhalte von anderen Seiten</h4>
<p class="mb-9">
  Beiträge auf unserer Website können eingebettete Inhalte enthalten, wie beispielsweise YouTube-Videos. Solche eingebetteten Inhalte verhalten sich genau wie der Besuch auf der ursprünglichen Website. Dritte Websites können Daten sammeln, Cookies verwenden und zusätzliche Tracking-Dienste einbetten. Diese Interaktionen werden erfasst, insbesondere wenn der Besucher ein Konto hat und auf der Website angemeldet ist.
</p>
<h4 class="mb-3 text-h4 primary--text">Löschung von Daten</h4>
<p class="mb-9">
  Benutzer mit einem Konto auf unserer Website können einen Export ihrer personenbezogenen Daten anfordern, inklusive aller mit der Plattform geteilten Daten. Ebenso besteht die Möglichkeit, die Löschung sämtlicher personenbezogener Daten anzufordern. Hierbei sind jedoch Daten aus administrativen, rechtlichen oder sicherheitsrelevanten Gründen ausgenommen.
</p>
<h4 class="mb-3 text-h4 primary--text">Cookies</h4>
<p class="mb-9">
  Wir setzen Cookies und ähnliche Technologien ein, um unsere Dienstleistungen bereitzustellen, zu optimieren, zu personalisieren und zu analysieren sowie auch für Werbezwecke.
</p>
<p class="mb-9">
  Wir setzen eine Kombination aus Cookies und anderen Technologien ein, wie z. B. Pixel und Tracking-Codes, um Daten zur Verwendung entsprechend der in dieser Datenschutzerklärung aufgeführten Zwecke zu erfassen.
</p>
<h5 class="mb-3 text-h5 primary--text">Was ist ein Cookie?</h5>
<p class="mb-9">
  Ein Cookie ist eine kleine Textdatei, die von Websites, die Sie besuchen, oder Anwendungen, die Sie nutzen, über Ihren Webbrowser auf Ihrem Computer bzw. Gerät gespeichert wird. Indem Cookies z. B. Ihre Vorlieben und Einstellungen erfassen, liefern sie uns die Informationen, die wir benötigen, um Ihnen ein möglichst stimmiges und relevantes Nutzererlebnis zu bieten. Darüber hinaus setzen wir Cookies ein, um Informationen über Versuche oder über Verbesserungen zu erhalten, die wir testen, um zu verfolgen, wie Sie unsere Dienste nutzen, und herauszufinden, ob sie sich als wirksam erwiesen haben.
</p>
<p class="mb-9">
  Die Cookies auf unserer Website werden von uns selbst, von Drittanbietern, mit denen wir zusammenarbeiten, oder von unabhängigen Dritten, z. B. Werbeunternehmen, gesetzt.
</p>
<h5 class="mb-3 text-h5 primary--text">Was sind Pixel?</h5>
<p class="mb-9">
  Pixel sind kleine, transparente Bildelemente in einer Webseite oder E-Mail. Wir nutzen Pixel, um nachzuvollziehen, wie Sie mit unseren Diensten, dazu zählen auch Marketing-E-Mails, interagieren.
</p>
<h5 class="mb-3 text-h5 primary--text">Was sind Tracking-Codes?</h5>
<p class="mb-9">
  Tracking-Codes sind Codeschnipsel, die in eine Webseite eingebunden werden, um beispielsweise Besuche und Interaktionen zu messen. Wir setzen Tracking-Codes ein, um mehr darüber zu erfahren, wie Sie mit unseren Diensten und Ihnen angezeigter Werbung interagieren, und ganz allgemein, wie Sie unsere Plattform nutzen.
</p>
<h4 class="mb-3 text-h4 primary--text">Analysedienste</h4>
<p class="mb-9">
  Unsere Website verwendet Google Analytics und andere Webanalysedienste von Google Inc. Diese nutzen Cookies zur Analyse der Website-Nutzung. Die durch den Cookie erzeugten Informationen, einschließlich Ihrer IP-Adresse, werden an einen Server von Google in den USA übertragen und dort gespeichert. Sie können die Installation von Cookies durch entsprechende Browsereinstellungen verhindern.
</p>
<h4 class="mb-3 text-h4 primary--text">Social Plugins</h4>
<p class="mb-9">
  Wir setzen Social Plugins, wie von Facebook, ein, um die Effektivität unserer Kommunikation über Social Media-Kanäle zu bewerten. Ihr Browser stellt bei Besuch unserer Seite eine direkte Verbindung zu den Servern der Social Media-Anbieter her. Diese erhalten Informationen über Ihren Seitenbesuch. Sind Sie gleichzeitig eingeloggt, kann der Anbieter Ihren Besuch mit Ihrem Profil verknüpfen.
</p>
<h4 class="mb-3 text-h4 primary--text">Kontaktaufnahme</h4>
<p class="mb-9">
  Wir möchten die hier bereitgestellten Informationen so klar und transparent wie möglich machen. Wenn Sie jedoch trotzdem Fragen dazu haben, wie wir Ihre personenbezogenen Daten verarbeiten, oder wenn Sie die Ihnen gemäß unserer Datenschutzerklärung zustehenden Rechte ausüben möchten, kontaktieren Sie bitte unseren Datenschutzbeauftragten per E-Mail an privacy@confederia.ch.
</p>
<p class="mb-9">
  Sie können uns per Post über folgende Adresse erreichen:
</p>
<p class="mb-9">
  Confederia KlG<br>
  Bergstrasse 44<br>
  9445 Rebstein<br>
  Schweiz
</p>
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>
  </div>
</template>

<script>
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      FullWidthSection,
      TopHeader
    },
    name: 'PrivacyPolicy'
  }
</script>

<i18n>
  {
    "de": {
      "privacyPolicy": "Daten&shy;schutz&shy;erklärung"
    },
    "en": {
      "privacyPolicy": "Privacy policy"
    }
  }
</i18n>