<template>
  <div>
    <v-card-text class="pa-6">
      <p class="text-h6 text-center">{{ questionDefinition.text }}</p>
      <p class="text-body-1 text-center">{{ questionDefinition.description }}</p>
      <div class="text-center">
        <v-item-group v-model="model[questionDefinition.id]"
                      v-on:change="next">
          <v-item active-class="primary white--text"
                  v-bind:key="option.id"
                  v-bind:value="option.id"
                  v-for="option in questionDefinition.options"
                  v-slot="{ toggle }">
            <v-btn block
                   depressed
                   class="mb-2"
                   v-on:click="toggle"
                   x-large>
              {{ option.text }}
            </v-btn>
          </v-item>
        </v-item-group>
        <v-expand-transition>
        <p class="mb-0 mt-4"
           v-if="!hasPoints && !!questionDefinition.customerHint">
          {{ questionDefinition.customerHint}}
        </p>
      </v-expand-transition>
      <v-expand-transition>
        <p class="error--text mb-0 mt-4"
           v-if="error">
          {{ $t('global.pleaseAnswerThisQuestion') }}
        </p>
      </v-expand-transition>
      </div>
    </v-card-text>
  </div>
</template>

<script>
  export default {
    data: () => ({
      error: false
    }),
    computed: {
      hasPoints() {
        if (this.value == null) {
          return false
        }

        const selectedOption = this.questionDefinition.options.find(option => option.id === this.value)

        if (selectedOption == null) {
          return false
        }

        return selectedOption.points > 0
      },
      value() {
        return this.model[this.questionDefinition.id]
      }
    },
    methods: {
      next() {
        this.error = false

        if (this.hasPoints || !this.questionDefinition.customerHint) {
          this.$emit('next')
        }
      },
      validate() {
        if (!this.questionDefinition.required) {
          return true
        }

        if (this.value == null) {
          this.error = true

          return false
        }

        return true
      }
    },
    name: 'SelectQuestion',
    props: {
      model: Object,
      questionDefinition: Object
    }
  }
</script>