import PrimaryNavigationView from '@/views/v2/base/navigation/PrimaryNavigationView.vue'
import EvaluationSummaryView from '@/views/v2/questionnaire/EvaluationSummaryView.vue'
import QuestionnaireView from '@/views/v2/questionnaire/QuestionnaireView.vue'

export default [
  {
    components: {
      default: EvaluationSummaryView,
      navigation: PrimaryNavigationView
    },
    name: 'evaluation-summary',
    path: '/evaluation/:evaluationId/summary'
  },
  {
    components: {
      default: QuestionnaireView,
      navigation: PrimaryNavigationView
    },
    name: 'questionnaire',
    path: '/consultants/:consultantId/questionnaire'
  }
]