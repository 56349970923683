<template>
  <div>
    <appointment-dialog ref="appointmentDialog"
                        v-bind:calenso-token="consultant.calensoToken"
                        v-bind:calenso-worker-id="consultant.calensoWorkerId"
                        v-if="consultant != null"
                        v-model="appointmentDialog" />

    <invite-dialog hide-consultant-details
                   ref="inviteDialog"
                   v-model="inviteDialog" />

    <v-progress-linear indeterminate
                       v-bind:active="loading" />
    <div v-if="consultant != null">
      <consultant-header v-bind:allow-sharing="canShare"
                         v-bind:consultant="consultant"
                         v-on:make-appointment="showAppointmentDialog"
                         v-on:share="share">
                         <v-row slot="tabs" justify="center">
                          <v-col cols="12" xl="8">
  <v-tabs color="primary"
                
                v-if="!unclaimed"
                v-model="tab">
          <v-tab href="#ratings">{{ $t('ratings') }}</v-tab>
          <v-tab href="#details">{{ $t('aboutMe') }}</v-tab>
        </v-tabs>
                          </v-col>
                         </v-row>
      
      </consultant-header>
      <v-tabs-items v-if="!unclaimed"
                    v-model="tab">
        <v-tab-item value="ratings">
          <full-width-section color="secondary">
            <v-container>
              <v-row justify="center">
                <!--<v-col cols="12"
                       md="6"
                       xl="4">
                  <consultant-rank-card v-bind:global-rank="null"
                                        v-bind:name="consultant.formattedName"
                                        v-bind:region="consultant.canton"
                                        v-bind:region-rank="null" />
                </v-col>-->
                <v-col cols="12"
                       md="6"
                       xl="4">
                  <consultant-overall-rating-card v-bind:average-ratings="consultant.averageRatings"
                                                  v-bind:count="consultant.evaluationCount"
                                                  v-bind:rating="consultant.overallRating" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
          <full-width-section color="transparent" id="evaluations">
            <v-container>
              <v-row justify="center">
                <v-col cols="12" xl="8">
                  <h4 class="mb-6 text-h4 primary--text">{{ $t('evaluations') }}</h4>
                  <evaluation-list v-bind:evaluations="evaluations" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
        </v-tab-item>
        <v-tab-item value="details">
          <full-width-section color="secondary">
            <v-container>
              <v-row justify="center" v-if="!!embedLink">
                <v-col cols="12"
                        md="6" xl="4">
                  <iframe width="100%" height="480px" v-bind:src="embedLink" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12"
                       md="6" xl="4">
                  <consulting-topics-card v-bind:consultant="consultant" />
                </v-col>
                <v-col cols="12"
                       md="6" xl="4"
                       v-if="consultant.company != null && (consultant.companyLinkState === 'accepted' || consultant.companyLinkState === 'administrator')">
                  <consultant-company-card v-bind:company="consultant.company" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
          <full-width-section color="transparent">
            <v-container>
              <v-row justify="center">
                <v-col cols="12"
                       md="6" xl="4"
                       v-if="!!consultant.introduction">
                  <h4 class="mb-6 text-h4 primary--text">{{ $t('introduction') }}</h4>
                   <p>{{ consultant.introduction }}</p>
                </v-col>
                <v-col cols="12"
                       md="6" xl="4"
                       v-if="!!consultant.qualifications && consultant.qualifications.length > 0">
                  <h4 class="mb-6 text-h4 primary--text">{{ $t('qualifications') }}</h4>
                  <p v-for="qualification in consultant.qualifications">
                    <v-icon color="black">mdi-certificate</v-icon>
                    {{ qualification }}
                    <!--<v-tooltip bottom
                              v-if="true">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary"
                                v-bind="attrs"
                                v-on="on">
                          mdi-check-decagram
                        </v-icon>
                      </template>
                      <span>{{ $t('verified') }}</span>
                    </v-tooltip>-->
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
        </v-tab-item>
      </v-tabs-items>

      <full-width-section color="white"
                          v-else>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" xl="8">
              <notification-with-icon icon="mdi-emoticon-sad-outline"
                                      v-bind:text="$t('yourConsultantHasNotYetClaimedHisProfileThereforeHisRatingsAndInformationAreNotYetPubliclyDisplayedHoweverYouAreWelcomeToLeaveAEvaluation')"
                                      v-bind:title="$t('unclaimedProfile')">
                                      <template slot="extension">
                <v-btn class="mt-6"
                      color="primary"
                      dark
                      depressed
                      large
                      v-on:click="showInviteDialog">
                  <v-icon color="accent"
                          left>
                    mdi-send
                  </v-icon>
                  {{ $t('invite') }}
                </v-btn>
              </template>
              </notification-with-icon>
            </v-col>
          </v-row>
        </v-container>
      </full-width-section>

      <full-width-section color="secondary"
                          v-if="!unclaimed">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" xl="8">
              <h4 class="mb-6 text-h4 primary--text">{{ $t('contactMe') }}</h4>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12"
                   md="4" xl="2"
                   order-md="2">
              <p v-if="!!consultant.phoneCellular">
                <a class="text-decoration-none"
                   v-bind:href="`tel:${consultant.phoneCellular}`">
                  <v-avatar class="mr-2"
                            color="primary">
                    <v-icon color="accent">mdi-cellphone</v-icon>
                  </v-avatar>
                  <span class="black--text">
                    {{ consultant.phoneCellular }}
                  </span>
                </a>
              </p>
              <p v-if="!!consultant.publicEmail">
                <a class="text-decoration-none"
                   v-bind:href="`mailto:${consultant.publicEmail}`">
                  <v-avatar class="mr-2"
                            color="primary">
                    <v-icon color="accent">mdi-email</v-icon>
                  </v-avatar>
                  <span class="black--text">
                    {{ consultant.publicEmail }}
                  </span>
                </a>
              </p>
              <p v-if="!!webUrl">
                <a class="text-decoration-none"
                   target="_blank"
                   v-bind:href="webUrl">
                  <v-avatar class="mr-2"
                            color="primary">
                    <v-icon color="accent">mdi-web</v-icon>
                  </v-avatar>
                  <span class="black--text">
                    {{ webUrl }}
                  </span>
                </a>
              </p>
            </v-col>
            <v-col cols="12"
                   md="8" xl="6"
                   order-md="1">
              <contact-form v-on:send="sendEmail" />
            </v-col>
          </v-row>
        </v-container>
      </full-width-section>
      <full-width-section color="transparent">
        <v-container>
          <consultant-social-links centered
                                   v-bind:consultant="consultant" />
        </v-container>
      </full-width-section>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import AppointmentDialog from '@/components/v2/base/AppointmentDialog.vue'
  import ConsultingTopicsCard from '@/components/v2/base/ConsultingTopicsCard.vue'
  import ConsultantCompanyCard from '@/components/v2/base/ConsultantCompanyCard.vue'
  import ConsultantDetails from '@/components/base/ConsultantDetails.vue'
  import ConsultantHeader from '@/components/v2/base/ConsultantHeader.vue'
  import ConsultantOverallRatingCard from '@/components/v2/base/ConsultantOverallRatingCard.vue'
  import ConsultantRankCard from '@/components/v2/base/ConsultantRankCard.vue'
  import ConsultantSocialLinks from '@/components/v2/base/ConsultantSocialLinks.vue'
  import ContactForm from '@/components/v2/base/ContactForm.vue'
  import EvaluationList from '@/components/v2/base/EvaluationList.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import InviteDialog from '@/components/v2/base/InviteDialog.vue'
  import NotificationWithIcon from '@/components/v2/base/NotificationWithIcon.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      AppointmentDialog,
      ConsultingTopicsCard,
      ConsultantCompanyCard,
      ConsultantDetails,
      ConsultantHeader,
      ConsultantOverallRatingCard,
      ConsultantRankCard,
      ConsultantSocialLinks,
      ContactForm,
      EvaluationList,
      FullWidthSection,
      InviteDialog,
      NotificationWithIcon
    },
    computed: {
      embedLink() {
        const youtubeLink = this.consultant.youTubeUrl

        if (!youtubeLink) {
          return null
        }

        const regExp = /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/

        const match = youtubeLink.match(regExp);

        if (match && match[4]) {
            const videoID = match[4];
            return `https://www.youtube.com/embed/${videoID}`
        } else {
            return null
        }
      },
      webUrl() {
        let url = this.consultant.websiteUrl

        if (!url) {
          return null
        }

        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'https://' + url
        }
        return url
      },
      canShare() {
        return navigator.share !== undefined
      },
      evaluations() {
        if (this.consultant == null) {
          return []
        }

        return this.consultant.evaluations.sort((a, b) => {
          return new Date(b.dateOfCreation) - new Date(a.dateOfCreation)
        }).filter(e => e.state === 'published')
      },
      unclaimed() {
        return this.consultant.state === 'unclaimed'
      },
      ...mapGetters({
        getConsultant: 'base/consultant'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      appointmentDialog: false,
      consultant: null,
      inviteDialog: false,
      loading: false,
      tab: null,
    }),
    methods: {
      async get(id) {
        this.loading = true

        await this.loadConsultant({ id })
        await this.loadConsultingTopics()

        if (this.getConsultant(id)?.state === 'disabled') {
          this.$router.push({ name: 'home' })

          return
        }

        this.consultant = Object.assign({}, this.getConsultant(id))

        this.loading = false
      },
      async inviteConsultant(email) {
        // IMPRV: Refactor and move to backend
        let clientSideEmail = {
          recipientEmail: email.consultantEmail,
          recipientName: email.consultantName,
          templateName: 'DefaultCallToAction',
          values: {
            cc: 'info@confederia.ch',
            callToActionHeadline: 'Jetzt kostenlos testen',
            callToActionText: 'Registrieren Sie sich jetzt für einen kostenlosen Probemonat und überzeugen Sie sich selbst von den Vorteilen.',
            callToActionLabel: 'Jetzt registrieren',
            callToActionUrl: 'https://beta.confederia.ch/for-consultants',
            subject: `Sie wurden eingeladen, Confederia beizutreten`,
            headline: `Grüezi ${email.consultantName}!<br>${email.name} hat Sie zu Confederia eingeladen!`,
            text: `Hallo ${email.consultantName},<br><br>Ihr Kunde ${email.name} (${email.email}) möchte Sie auf unserer Plattform Confederia bewerten.<br><br>Confederia ist die Schweizer Plattform für die Bewertung und Suche von Top-Beratern im Finanz-, Verwaltungs- und Immobilienbereich.<br><br>Wir bieten Ihnen die Möglichkeit, Ihr Können und Ihre Qualifikationen in den Vordergrund zu stellen.`,
            closingText: 'Wir freuen uns auf Sie!<br><br><strong>Thomas Oster und Christian Sommerfeld</strong><br>Gründer von Confederia'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      async sendEmail(email) {
        if (!!document.amplitude) {
          amplitude.track('Contact consultant');
        }
       

        // IMPRV: Refactor and move to backend 
        let clientSideEmail = {
          recipientEmail: this.consultant.email,
          recipientName: this.consultant.formattedName,
          templateName: 'DefaultText',
          values: {
            subject: `Kontaktanfrage von einem Interessenten ${email.name}`,
            headline: `${email.name} hat eine Frage an Sie!`,
            text: `Hallo ${this.consultant.formattedName},<br><br>ein Interessent hat Ihnen über Confederia eine Frage zukommen lassen:<br><br>${email.text}<br><br>Name: ${email.name}<br>E-Mail-Adresse: ${email.email}`,
            closingText: 'Diese Nachricht wurde automatisch an Sie versandt. Bitte antworten Sie nicht auf diese E-Mail.<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      share() {
        if (!!document.amplitude) {
          amplitude.track('Share Consultant');
        }
        

        // IMPRV: Refactor to own component/service
        if (navigator.share !== undefined) {
          navigator.share({
            title: `${this.consultant.formattedName} auf Confederia`,
            text: `Ich empfehle dir das Profil von ${this.consultant.formattedName} auf der Finanzberaterplattform Confederia – Made for more Transparency.`,
            url: window.location.href
          })
        }
      },
      async showAppointmentDialog() {
        if (!!document.amplitude) {
          amplitude.track('Book Consultant');
        }
        

        await this.$refs.appointmentDialog.open(async r => {
          if (!r) {
            return
          }
        })
      },
      async showInviteDialog() {
        await this.$refs.inviteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.inviteConsultant(r)

          this.loading = false
        }, {
          consultantName: this.consultant.formattedName,
          consultantEmail: this.consultant.email
        })
      },
      ...mapActions({
        loadConsultant: 'base/loadConsultant',
        loadConsultingTopics: 'base/loadConsultingTopics',
        createEmail: 'base/createEmail'
      })
    },
    name: 'ConsultantView'
  }
</script>

<i18n>
  {
    "de": {
      "aboutMe": "Über mich",
      "contactMe": "Kontaktieren Sie mich",
      "evaluations": "Einzelbewertungen",
      "introduction": "Vorstellung",
      "invite": "Einladen",
      "qualifications": "Qualifikationen",
      "ratings": "Bewertungen",
      "unclaimedProfile": "Unbeanspruchtes Profil",
      "verified": "Verifiziert",
      "yourConsultantHasNotYetClaimedHisProfileThereforeHisRatingsAndInformationAreNotYetPubliclyDisplayedHoweverYouAreWelcomeToLeaveAEvaluation": "Ihr Berater hat sein Profil noch nicht beansprucht, daher werden seine Bewertungen und seine Informationen noch nicht öffentlich angezeigt. Gerne können Sie dennoch selbst bereits eine Bewertung abgeben."
    },
    "en": {
      "aboutMe": "About me",
      "contactMe": "Contact me",
      "evaluations": "Evaluations",
      "introduction": "Introduction",
      "invite": "Invite",
      "qualifications": "Qualifications",
      "ratings": "Ratings",
      "unclaimedProfile": "Unclaimed profile",
      "verified": "Verified",
      "yourConsultantHasNotYetClaimedHisProfileThereforeHisRatingsAndInformationAreNotYetPubliclyDisplayedHoweverYouAreWelcomeToLeaveAEvaluation": "Your consultant has not yet claimed his profile, therefore his ratings and his information are not yet publicly displayed. However, you are welcome to leave a evaluation."
    }
  }
</i18n>