<template>
  <div>
    <div v-bind:key="rating.ratingCategoryId"
          v-for="rating in averageRatings">
      <div class="d-flex align-center justify-space-between">
        <p class="mb-0 secondary--text"
           v-bind:class="dark ? 'secondary--text' : 'black--text'">
          {{ rating.ratingCategory.name }}
        </p>
        <div class="d-flex align-center">
          <v-rating half-increments
                    readonly
                    v-bind:background-color="dark ? 'secondary darken-4' : 'secondary darken-1'"
                    v-bind:color="dark ? 'accent' : 'primary'"
                    v-bind:value="rating.value"
                    small />
          <p class="mb-0 ml-3"
             v-bind:class="dark ? 'secondary--text' : 'primary--text'">
            {{ $options.filters.formatValue(rating.value, { fractionDigits: 1 }) }}
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex align-center justify-space-between mt-3">
      <p class="font-weight-bold mb-0"
         v-bind:class="dark ? 'white--text' : 'primary--text'">
        {{ $t('overall') }}
      </p>
      <div class="d-flex align-center">
        <v-rating half-increments
                  readonly
                  v-bind:background-color="dark ? 'secondary darken-4' : 'secondary darken-1'"
                  v-bind:color="dark ? 'accent' : 'primary'"
                  v-bind:value="rating" />
        <p class="font-weight-bold mb-0 ml-3"
           v-bind:class="dark ? 'secondary--text' : 'primary--text'">
          {{ $options.filters.formatValue(rating, { fractionDigits: 1 }) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OverallRatingCard',
    props: {
      averageRatings: Array,
      count: Number,
      dark: Boolean,
      rating: Number
    }
  }
</script>

<i18n>
  {
    "de": {
      "overall": "Gesamt"
    },
    "en": {
      "overall": "Overall"
    }
  }
</i18n>