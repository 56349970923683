<template>
  <v-card flat
          v-bind:color="color">
    <v-card-text class="pa-6">
      <v-row v-if="ratingsFiltered.length > 0">
        <v-col
               v-for="topic in ratingsFiltered"
               v-bind:key="topic">
          <h5 class="text-h6 primary--text" style="min-height: 5rem;">{{ topic }}</h5>
          <div v-for="rating in averageRatings[topic]">
            <p class="font-weight-bold mb-0">{{ rating.ratingCategory.name }}</p>
            <v-rating class="mb-4"
                      half-increments
                      readonly
                      v-bind:background-color="'secondary darken-1'"
                      v-bind:color="'primary'"
                      v-bind:size="16"
                      v-bind:value="rating.value" />
          </div>
        </v-col>
      </v-row>
      <p v-else class="mb-0 text-center">
        {{ $t('noRatingsYes') }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    computed: {
      ratingsFiltered() {
        return Object.keys(this.averageRatings).filter(key => this.averageRatings[key].length > 0)
      }
    },
    name: 'RatingMatrixCard',
    props: {
      averageRatings: Object,
      color: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "noRatingsYes": "Noch keine Bewertungen vorhanden."
    },
    "en": {
      "noRatingsYes": "No ratings yet."
    }
  }
</i18n>