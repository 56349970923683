<template>
  <div>
    <v-card-text>
      <v-row v-bind:key="index"
            v-for="(qualification, index) in consultant.qualifications">
        <v-col>
          {{ qualification }}
        </v-col>
        <v-col cols="auto">
          <v-btn color="error"
                  icon
                  v-on:click="deleteQualification(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <!--<v-list>
      <v-list-item v-bind:key="index"
                   v-for="(qualification, index) in consultant.qualifications">
        <v-list-item-content>
          <v-list-item-title>{{ qualification }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn color="error"
                 icon
                 v-on:click="deleteQualification(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>-->

    <v-divider />

    <v-card-text>
      <v-row>
        <v-col cols="12"
              md="10">
          <v-text-field flat
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('newQualification')"
                        v-model="newQualification" />
        </v-col>
        <v-col cols="12"
              md="2">
          <v-btn block
                class="v-btn--add"
                color="primary"
                depressed
                large
                v-on:click="addQualification"
                v-bind:disabled="!newQualification">
            <v-icon color="accent"
                    v-bind:left="$vuetify.breakpoint.smAndDown">
              mdi-plus
            </v-icon>
            <span class="hidden-md-and-up">{{ $t('add') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
  export default {
    data: () => ({
      newQualification: ''
    }),
    methods: {
      addQualification() {
        this.consultant.qualifications.push(this.newQualification)
        this.newQualification = ''
      },
      deleteQualification(index) {
        this.consultant.qualifications.splice(index, 1)
      },
      validate() {
        return true
      }
    },
    name: 'AccountIntroduction',
    props: {
      consultant: Object
    }
  }
</script>

<style scoped>
  .v-btn.v-btn--add {
    min-height: 48px;
  }
</style>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "newQualification": "Neue Qualifikation"
    },
    "en": {
      "add": "Add",
      "newQualification": "New qualification"
    }
  }
</i18n>