<template>
  <div v-bind:class="centered ? 'text-center' : null">
    <a target="_blank"
       v-bind:href="company.linkedInUrl"
       v-if="!!company.linkedInUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-linkedin</v-icon>
      </v-avatar>
    </a>

    <a target="_blank"
       v-bind:href="company.twitterUrl"
       v-if="!!company.twitterUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-twitter</v-icon>
      </v-avatar>
    </a>

    <a target="_blank"
       v-bind:href="company.facebookUrl"
       v-if="!!company.facebookUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-facebook</v-icon>
      </v-avatar>
    </a>

    <a target="_blank"
       v-bind:href="company.instagramUrl"
       v-if="!!company.instagramUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-instagram</v-icon>
      </v-avatar>
    </a>

    <a target="_blank"
       v-bind:href="whatsAppUrl"
       v-if="!!company.whatsAppUrl">
      <v-avatar class="mr-2"
                color="primary">
        <v-icon color="accent">mdi-whatsapp</v-icon>
      </v-avatar>
    </a>
  </div>
</template>

<script>
  export default {
    computed: {
      whatsAppUrl() {
        return `https://wa.me/${this.company.whatsAppUrl}`
      }
    },
    name: 'CompanySocialLinks',
    props: {
      centered: Boolean,
      company: Object
    }
  }
</script>