<template>
  <v-card-text class="pa-6">
    <p class="mb-12">{{ $t('theProcessTakesOnlyAFewMinutesFastAndEffective') }}</p>
    <v-row justify="center">
      <v-col cols="12"
             md="4">
        <v-icon class="mb-2"
                color="accent"
                size="48">
          mdi-numeric-1-circle-outline
        </v-icon>
        <h5 class="mb-3 text-h5 primary--text">{{ $t('rateServiceConsultingAndTransparency') }}</h5>
        <p>
          <v-icon class="mr-6"
                  color="grey">
            mdi-thumb-up
          </v-icon>
          <v-icon color="grey">mdi-thumb-down</v-icon>
        </p>
        <p v-html="$t('simplyAnswerOurPredefinedQuestions')" />
        <p class="text-caption mb-0 grey--text text--darken-2">{{ $t('ImportantFollowOurTipsWhenYouEvaluateAQuestion') }}</p>
      </v-col>
      <v-col cols="12"
             md="4">
        <v-icon class="mb-2"
                color="accent"
                size="48">
          mdi-numeric-2-circle-outline
        </v-icon>
        <h5 class="mb-3 text-h5 primary--text">{{ $t('giveAnOverallImpression') }}</h5>
        <p>{{ $t('howSatisfiedAreYouInGeneralWithYourConsultation') }}</p>
      </v-col>
      <v-col cols="12"
             md="4">
        <v-icon class="mb-2"
                color="accent"
                size="48">
          mdi-numeric-3-circle-outline
        </v-icon>
        <h5 class="mb-3 text-h5 primary--text">{{ $t('receiveTheProtocolAndClarifyOpenPoints') }}</h5>
        <p>{{ $t('ifThereIsStillANeedYouCanScheduleAnAppointmentToAddressAnyOpenQuestions') }}</p>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
  export default {
    name: 'QuestionnaireIntroduction'
  }
</script>

<i18n>
  {
    "de": {
      "theProcessTakesOnlyAFewMinutesFastAndEffective": "Der Prozess dauert nur wenige Minuten - schnell und effektiv.",
      "rateServiceConsultingAndTransparency": "Service, Beratungsleistung & Transparenz bewerten",
      "giveAnOverallImpression": "Übergreifenden Gesamteindruck abgeben",
      "receiveTheProtocolAndClarifyOpenPoints": "Protokoll erhalten und offene Punkte klären",
      "simplyAnswerOurPredefinedQuestions": "Beantworten Sie ganz einfach unsere vorgegebenen Fragen.",
      "ImportantFollowOurTipsWhenYouEvaluateAQuestion": "Wichtig: Beachten Sie beim Bewerten unsere Tipps",
      "howSatisfiedAreYouInGeneralWithYourConsultation": "Wie zufrieden sind Sie generell mit Ihrer Beratung?",
      "ifThereIsStillANeedYouCanScheduleAnAppointmentToAddressAnyOpenQuestions": "Falls es noch Bedarf gibt, können Sie einen Termin vereinbaren und sich offene Fragen beantworten lassen."
    },
    "en": {
      "theProcessTakesOnlyAFewMinutesFastAndEffective": "The process takes only a few minutes - fast and effective.",
      "rateServiceConsultingAndTransparency": "Rate service, consulting, and transparency",
      "giveAnOverallImpression": "Give an overall impression",
      "receiveTheProtocolAndClarifyOpenPoints": "Receive the protocol and clarify open points",
      "simplyAnswerOurPredefinedQuestions": "Simply answer our predefined questions.",
      "ImportantFollowOurTipsWhenYouEvaluateAQuestion": "Important: Follow our tips when you evaluate a question.",
      "howSatisfiedAreYouInGeneralWithYourConsultation": "How satisfied are you in general with your consultation?",
      "ifThereIsStillANeedYouCanScheduleAnAppointmentToAddressAnyOpenQuestions": "If there is still a need, you can schedule an appointment to address any open questions."
    }
  }
</i18n>