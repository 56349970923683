<template>
  <div class="py-12">
    <v-container>
      <v-row v-bind:class="!$vuetify.breakpoint.mdAndUp ? 'text-center' : null">
        <v-col cols="12"
               md="3">
          <h6 class="mb-2 primary--text text-h6">{{ $t('company') }}</h6>
          <p class="mb-0">
            <router-link class="black--text text-decoration-none"
                         v-bind:to="{ name: 'about-us' }">
              {{ $t('aboutUs') }}
            </router-link>
          </p>
        </v-col>
        <v-col cols="12"
               md="3">
          <h6 class="mb-2 primary--text text-h6">{{ $t('forCustomers') }}</h6>
          <p class="mb-1">
            <router-link class="black--text text-decoration-none"
                         v-bind:to="{ name: 'home' }">
              {{ $t('findConsultants') }}
            </router-link>
          </p>
          <p class="mb-1">
            <router-link class="black--text text-decoration-none"
                         v-bind:to="{ name: 'consultants' }">
              {{ $t('allConsultants') }}
            </router-link>
          </p>
          <p class="mb-0">
            <router-link class="black--text text-decoration-none"
                         v-bind:to="{ name: 'companies' }">
              {{ $t('allCompanies') }}
            </router-link>
          </p>
        </v-col>
        <v-col cols="12"
               md="3">
          <h6 class="mb-2 primary--text text-h6">{{ $t('forConsultants') }}</h6>
          <p class="mb-0">
            <router-link class="black--text text-decoration-none"
                         v-bind:to="{ name: 'for-consultants', hash: '#pricing' }">
              {{ $t('pricingAndDetails') }}
            </router-link>
          </p>
        </v-col>
        <v-col cols="12"
               md="3">
          <h6 class="mb-2 primary--text text-h6">{{ $t('legal') }}</h6>
          <p class="mb-1">
            <router-link class="black--text text-decoration-none"
                         v-bind:to="{ name: 'terms-of-service' }">
              {{ $t('termsOfService') }}
            </router-link>
          </p>
          <p class="mb-1">
            <router-link class="black--text text-decoration-none"
                         v-bind:to="{ name: 'imprint' }">
              {{ $t('imprint') }}
            </router-link>
          </p>
          <p class="mb-0">
            <router-link class="black--text text-decoration-none"
                         v-bind:to="{ name: 'privacy-policy' }">
              {{ $t('privacyPolicy') }}
            </router-link>
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-12"
             justify="center">
        <v-col cols="auto">
          <img class="logo-footer" src="/images/v2/logo_light.svg" />
        </v-col>
      </v-row>
      <p class="mb-0 mt-6 text-center">
        <strong>Made For More Transparency</strong> <span class="secondary--text text--darken-4">|</span> ©&nbsp;2023-2024&nbsp;Confederia
      </p>
    </v-container>


  </div>
</template>

<script>
  export default {
    name: 'FatFooter'
  }
</script>

<style>
  .logo-footer {
    height: 56px;
  }
</style>

<i18n>
  {
    "de": {
      "aboutUs": "Über uns",
      "allCompanies": "Alle Unternehmen",
      "allConsultants": "Alle Berater",
      "company": "Unternehmen",
      "contact": "Kontakt",
      "findConsultants": "Berater finden",
      "forConsultants": "Für Berater",
      "forCustomers": "Für Kunden",
      "imprint": "Impressum",
      "legal": "Rechtliches",
      "pricingAndDetails": "Preise und Details",
      "privacyPolicy": "Datenschutzerklärung",
      "termsOfService": "AGB"
    },
    "en": {
      "aboutUs": "About us",
      "allCompanies": "All companies",
      "allConsultants": "All consultants",
      "company": "Company",
      "contact": "Contact",
      "findConsultants": "Find consultants",
      "forConsultants": "For consultants",
      "forCustomers": "For customers",
      "imprint": "Imprint",
      "legal": "Legal",
      "pricingAndDetails": "Pricing and details",
      "privacyPolicy": "Privacy policy",
      "termsOfService": "Terms of service"
    }
  }
</i18n>