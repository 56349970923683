<template>
  <div>
    <v-row class="mb-6"
           v-if="consultants.length > 0">
      <v-spacer />
      <v-col cols="12"
             md="auto">
        <v-select clearable
                  flat
                  hide-details="auto"
                  prepend-inner-icon="mdi-message-outline"
                  solo
                  v-bind:background-color="color"
                  v-bind:items="consultingTopics | items({ text: 'name', value: 'id' })"
                  v-bind:label="$t('consultingTopics')"
                  v-model="consultingTopicFilter" />
      </v-col>
      <v-col cols="12"
             md="auto">
        <v-select flat
                  hide-details="auto"
                  prepend-inner-icon="mdi-sort"
                  solo
                  v-bind:background-color="color"
                  v-bind:items="sortItems"
                  v-bind:label="$t('sortBy')"
                  v-model="sortBy" />
      </v-col>
    </v-row>
    <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                     v-bind:items="filteredConsultants"
                     v-bind:items-per-page="10"
                     v-bind:sort-by="sortBy"
                     v-bind:sort-desc="sortDesc">
      <template v-slot:no-data v-slot:no-results>
        <notification-with-icon icon="mdi-emoticon-sad-outline"
                                v-bind:text="$t('thereAreNoConsultantsYet')"
                                v-bind:title="$t('noConsultantsYet')" />
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col cols="12"
                 lg="4"
                 sm="6"
                 v-bind:key="consultant.id"
                 v-for="consultant in props.items"
                 xl="3">
            <consultant-card v-bind:color="color"
                             v-bind:consultant="consultant"
                             v-bind:editable="editable"
                             v-on:unlink="$emit('unlink', $event)"
                             v-on:set-administrator="$emit('set-administrator', $event)"
                             v-on:remove-administrator="$emit('remove-administrator', $event)"
                             v-on:accept="$emit('accept', $event)"
                             v-bind:is-administrator="isAdministrator" />
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ConsultantCard from '@/components/v2/base/ConsultantCard.vue'
  import NotificationWithIcon from '@/components/v2/base/NotificationWithIcon.vue'

  export default {
    components: {
      ConsultantCard,
      NotificationWithIcon
    },
    computed: {
      filteredConsultants() {
        if (this.consultingTopicFilter) {
          return this.consultants.filter(c => c.consultingTopicIds.includes(this.consultingTopicFilter))
        }

        return this.consultants
      },
      sortDesc () {
        return this.sortBy === 'overallRating'
      },
      sortItems() {
        return [
          {
            text: this.$t('name'),
            value: 'formattedName'
          },
          {
            text: this.$t('location'),
            value: 'city'
          },
          {
            text: this.$t('rating'),
            value: 'overallRating'
          }
        ]
      },
      ...mapGetters({
        consultingTopics: 'base/consultingTopics'
      })
    },
    data: () => ({
      consultingTopicFilter: null,
      sortBy: 'overallRating'
    }),
    name: 'ConsultantGrid',
    props: {
      color: String,
      consultants: Array,
      editable: Boolean,
      isAdministrator: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "consultingTopics": "Themen",
      "name": "Name",
      "noConsultantsYet": "Noch keine Berater",
      "rating": "Bewertung",
      "sortBy": "Sortieren nach",
      "location": "Standort",
      "thereAreNoConsultantsYet": "Es sind noch keine Berater vorhanden."
    },
    "en": {
      "consultingTopics": "Consulting topics",
      "name": "Name",
      "noConsultantsYet": "No consultants yet",
      "rating": "Rating",
      "sortBy": "Sort by",
      "location": "Location",
      "thereAreNoConsultantsYet": "There are no consultants yet."
    }
  }
</i18n>