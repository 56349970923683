<template>
  <div>
    <v-dialog v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="500">
      <v-card>
        <v-card-title class="headline primary--text">
          {{ title }}
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-text v-if="confirmationValue != null">
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="confirmationLabel"
                        v-model="confirmation" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text
                 v-on:click="cancel">
            {{ cancelButtonLabel }}
          </v-btn>
          <v-btn text
                 v-bind:color="confirmButtonColor"
                 v-bind:disabled="confirmationValue != null && confirmation !== confirmationValue"
                 v-on:click="confirm">
            {{ confirmButtonLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data: () => ({
      confirmation: null,
      resolve: null
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        this.$emit('input', false)
        this.$emit('confirm')
        this.resolve(true)
      },
      async open(resolve) {
        this.resolve = resolve
        this.$emit('input', true)
      }
    },
    name: 'ConfirmDialog',
    props: {
      cancelButtonLabel: String,
      confirmationLabel: String,
      confirmationValue: String,
      confirmButtonColor: {
        default: 'primary',
        type: String
      },
      confirmButtonLabel: String,
      message: String,
      title: String,
      value: Boolean
    }
  }
</script>