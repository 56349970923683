import Vue from 'vue'
import Vuex from 'vuex'

import { createAuthStoreModule } from 'kayleen-vue-auth0-client'

import administrationStore from './administration-store'
import auth0Settings from '@/auth0-settings'
import baseStore from './base-store'
import questionnaireModelStore from './questionnaire-model-store'
import localStore from './local-store'

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
  },
  getters: {
  },
  modules: {
    administration: administrationStore,
    auth: createAuthStoreModule(auth0Settings),
    base: baseStore,
    questionnaireModel: questionnaireModelStore,
    local: localStore
  },
  mutations: {
  },
  state: {
  }
})