<template>
  <top-header white>
    <v-row justify="center">
      <v-col cols="12" xl="8">

    <v-row align="center"
           class="mb-6"
           justify="center">
      <v-col cols="auto">
        <v-img contain
               height="128"
               width="256"
               v-bind:position="$vuetify.breakpoint.mdAndUp ? 'left center' : 'center'"
               v-bind:src="company.logoAttachment | attachmentUrl({ defaultUrl: '/images/profile-none.jpg'} )" />
      </v-col>
      <v-col v-bind:class="!$vuetify.breakpoint.mdAndUp ? 'text-center' : null"
             v-bind:cols="!$vuetify.breakpoint.mdAndUp ? 12 : null">
        <h3 class="mb-2 primary--text text-h3 mb-4">{{ company.name }}</h3>
        <p>
          {{ company.streetName }} {{ company.streetNumber }}<br>
          {{ company.postalCode }} {{ company.city }}
        </p>
      </v-col>
      <v-col cols="12"
             md="auto">
        <!--<rating class="mb-6"
                v-bind:rating="company.overallRating"
                v-bind:count="company.evaluationCount" />-->
        <v-btn block
               color="primary"
               dark
               depressed>
          <v-icon color="accent"
                  left>
            mdi-send
          </v-icon>
          {{ $t('contactNow') }}
        </v-btn>
        <v-btn block
               color="primary"
               class="mt-2"
               dark
               depressed
               v-if="showJoin"
               v-on:click="$emit('join')">
          <v-icon color="accent"
                  left>
            mdi-plus
          </v-icon>
          {{ $t('join') }}
        </v-btn>
        <v-btn block
               class="mt-2"
               color="primary"
               dark
               outlined
               v-if="allowSharing"
               v-on:click="$emit('share')">
          <v-icon color="accent"
                  left>
            mdi-share
          </v-icon>
          {{ $t('share') }}
        </v-btn>
      </v-col>
    </v-row>

    </v-col>
    </v-row>
    <template slot="tabs">
      <slot name="tabs"></slot>
    </template>
  </top-header>
</template>

<script>
  import Rating from '@/components/v2/base/Rating.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      Rating,
      TopHeader
    },
    name: 'CompanyHeader',
    props: {
      allowSharing: Boolean,
      company: Object,
      showJoin: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "contactNow": "Jetzt kontaktieren",
      "share": "Teilen",
      "join": "Beitreten"
    },
    "en": {
      "contactNow": "Contact now",
      "share": "Share",
      "join": "Join"
    }
  }
</i18n>