<template>
   <v-card class="fill-height"
           flat
           v-bind:color="color"
           v-bind:to="!editable ? { name: 'consultant', params: { id: consultant.id }} : null">
    <v-card-text class="pa-6 text-center">
      <v-row v-if="editable">
        <v-col cols="auto">
          <v-chip color="warning"
                  v-if="consultant.companyLinkState === 'administrator'">
            {{ $t('admin') }}
          </v-chip>
          <v-chip color="warning"
                  v-if="consultant.companyLinkState === 'requestedByConsultant' || consultant.companyLinkState === 'requestedByCompany'">
            {{ $t('requested') }}
          </v-chip>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-menu slot="menu">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon
                     v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-bind:to="{ name: 'consultant', params: { id: consultant.id }}">
                <v-list-item-title>{{ $t('showDetails') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-on:click="$emit('accept', consultant.id)"
                           v-if="isAdministrator && consultant.companyLinkState === 'requestedByConsultant'">
                <v-list-item-title>{{ $t('acceptRequest') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-on:click="$emit('set-administrator', consultant.id)"
                           v-if="isAdministrator && consultant.companyLinkState === 'accepted'">
                <v-list-item-title>{{ $t('setAsAdministrator') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-on:click="$emit('remove-administrator', consultant.id)"
                           v-if="isAdministrator && consultant.companyLinkState === 'administrator'">
                <v-list-item-title>{{ $t('removeAsAdministrator') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="isAdministrator"
                           v-on:click="$emit('unlink', consultant.id)">
                <v-list-item-title class="error--text">{{ $t('unlink') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-avatar class="mb-6"
                 v-bind:size="$vuetify.breakpoint.mdAndDown ? 90 : 128">
        <v-img v-bind:src="consultant.photoAttachment | attachmentUrl({ defaultUrl: '/images/profile-none.jpg'} )" />
      </v-avatar>

      <h5 class="primary--text text-h5">{{ consultant.givenName }} {{ consultant.familyName }}</h5>
      <h6 class="primary--text text-h6">
        {{ consultant.companyName }}
      </h6>
      <p class="mb-6">{{ consultant.postalCode }} {{ consultant.city }}</p>

      <rating class="mb-6"
              v-bind:rating="consultant.overallRating"
              v-bind:count="consultant.evaluationCount"
              v-bind:unclaimed="consultant.state === 'unclaimed'" />
      <consulting-topic-chips center
                              v-bind:consulting-topic-ids="consultant.consultingTopicIds" />
    </v-card-text>
  </v-card>
</template>

<script>
  import ConsultingTopicChips from '@/components/v2/base/ConsultingTopicChips.vue'
  import Rating from '@/components/v2/base/Rating.vue'

  export default {
    components: {
      ConsultingTopicChips,
      Rating
    },
    name: 'ConsultantCard',
    props: {
      color: {
        default: 'white',
        type: String
      },
      consultant: Object,
      editable: Boolean,
      isAdministrator: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "admin": "Admin",
      "removeAsAdministrator": "Als Administrator entfernen",
      "requested": "Angefragt",
      "setAsAdministrator": "Als Administrator festlegen",
      "showDetails": "Details anzeigen",
      "unlink": "Verknüpfung aufheben",
      "acceptRequest": "Anfrage akzeptieren"
    },
    "en": {
      "admin": "Admin",
      "removeAsAdministrator": "Remove as administrator",
      "requested": "Requested",
      "setAsAdministrator": "Set as administrator",
      "showDetails": "Show details",
      "unlink": "Unlink",
      "acceptRequest": "Accept request"
    }
  }
</i18n>