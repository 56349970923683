<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('name')"
                        v-bind:rules="[ rules.required ]"
                        v-model="company.name" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />
    <v-subheader>{{ $t('address') }}</v-subheader>

    <v-card-text>
      <v-row>
        <v-col cols="12"
               md="8">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('streetName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="company.streetName" />
        </v-col>
        <v-col cols="12"
               md="4">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('streetNumber')"
                        v-bind:rules="[ rules.required ]"
                        v-model="company.streetNumber" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"
               md="4">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('postalCode')"
                        v-bind:rules="[ rules.required ]"
                        v-model="company.postalCode" />
        </v-col>
        <v-col cols="12"
               md="8">
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('city')"
                        v-bind:rules="[ rules.required ]"
                        v-model="company.city" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select hide-details="auto"
                    outlined
                    v-bind:items="cantons | vueI18nItems({ text: 'label' })"
                    v-bind:label="$t('canton')"
                    v-bind:rules="[ rules.required ]"
                    v-model="company.canton" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select hide-details="auto"
                    outlined
                    v-bind:items="countries | vueI18nItems({ text: 'label' })"
                    v-bind:label="$t('country')"
                    v-bind:rules="[ rules.required ]"
                    v-model="company.country" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />
    <v-subheader>{{ $t('contactInformation') }}</v-subheader>

    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('email')"
                        v-bind:rules="[ rules.required ]"
                        v-model="company.email" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('phone')"
                        v-model="company.phone" />
        </v-col>
      </v-row>
      <!--<v-row>
        <v-col>
          <v-text-field hide-details="auto"
                        outlined
                        v-bind:label="$t('website')"
                        v-model="company.websiteUrl" />
        </v-col>
      </v-row>-->
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import rules from '@/mixins/v2/rules'

  export default {
    computed: {
      ...mapGetters({
        cantons: 'local/cantons',
        countries: 'local/countries'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    mixins: [ rules ],
    name: 'CompanyDetails',
    props: {
      company: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "address": "Adresse",
      "canton": "Kanton",
      "city": "Ort",
      "contactInformation": "Kontaktdaten",
      "country": "Land",
      "email": "E-Mail-Adresse",
      "name": "Name",
      "phone": "Telefonnummer",
      "postalCode": "Postleitzahl",
      "streetName": "Straße",
      "streetNumber": "Hausnummer",
      "website": "Website"
    },
    "en": {
      "address": "Address",
      "canton": "Canton",
      "city": "City",
      "contactInformation": "Contact information",
      "country": "Country",
      "email": "Email",
      "name": "Name",
      "phone": "Phone",
      "postalCode": "Postal code",
      "streetName": "Street name",
      "streetNumber": "Street number",
      "website": "Website"
    }
  }
</i18n>