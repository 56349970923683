<template>
  <top-header white>
    <header-button icon="mdi-chevron-left"
                   secondary
                   slot="left-button"
                   v-bind:label="$t('back')"
                   v-bind:to="{ name: 'consultant', params: { id: consultant.id } }" />

    <v-row align="center"
           class="mb-6">
      <v-col v-bind:class="!$vuetify.breakpoint.mdAndUp ? 'text-center' : null"
             cols="12"
             md="auto">
        <v-avatar size="180">
          <v-img v-bind:src="consultant.photoAttachment | attachmentUrl({ defaultUrl: '/images/profile-none.jpg'} )" />
        </v-avatar>
      </v-col>
      <v-col v-bind:class="!$vuetify.breakpoint.mdAndUp ? 'text-center' : null"
             v-bind:cols="!$vuetify.breakpoint.mdAndUp ? 12 : null">
        <h3 class="primary--text text-h3">{{ !title ? $t('thankYou') : title }}</h3>
        <h4 class="primary--text text-h4">{{ !text ? $t('forTakingTheTimeToRateYourConsultant', { name: `${consultant.givenName} ${consultant.familyName}` }) : text }}</h4>
      </v-col>
    </v-row>
  </top-header>
</template>

<script>
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      HeaderButton,
      TopHeader
    },
    name: 'QuestionnaireConsultantHeader',
    props: {
      consultant: Object,
      text: String,
      title: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "thankYou": "Vielen Dank",
      "forTakingTheTimeToRateYourConsultant": "für Ihre Bewertung von {name}.",
      "youRate": "Sie bewerten"
    },
    "en": {
      "back": "Back",
      "thankYou": "Thank you",
      "forTakingTheTimeToRateYourConsultant": "for rating {name}.",
      "youRate": "You rate"
    }
  }
</i18n>