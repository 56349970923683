<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('personalDetails') }}</v-toolbar-title>
      </v-toolbar>
      <consultant-personal-details ref="consultantPersonalDetails"
                                   v-bind:consultant="consultant"
                                   v-bind:disable-email="disableEmail" />
    </v-card>
  </div>
</template>

<script>
  import ConsultantPersonalDetails from '@/components/v2/account/ConsultantPersonalDetails'

  export default {
    components: {
      ConsultantPersonalDetails
    },
    methods: {
      validate() {
        return this.$refs.consultantPersonalDetails.validate()
      }
    },
    name: 'ConsultantPersonalDetailsCard',
    props: {
      consultant: Object,
      disableEmail: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "personalDetails": "Personalien"
    },
    "en": {
      "personalDetails": "Personal details"
    }
  }
</i18n>