<template>
  <div>
    <v-item-group v-bind:value="value"
                  v-on:change="$emit('input', $event)">
      <v-container>
        <v-row dense>
          <v-col cols="12"
                 v-bind:key="plan.id"
                 v-for="plan in plans">
            <v-item v-bind:value="plan.id"
                    v-slot="{ active, toggle }">
              <v-card flat
                      rounded="lg"
                      v-bind:color="active ? 'accent' : ( dark ? 'primary' : 'white')"

                      v-on:click="toggle">
                <v-card-text v-bind:class="active ? 'white--text' : ( dark ? 'white--text' : null)">
                   <v-row align="center" dense>
                     <v-col>
                       <span class="font-weight-bold">{{ plan.name }}</span><br>
                       {{ plan.description }}
                     </v-col>
                     <v-col cols="auto text-right">
                      <span class="text-h6">{{ plan.value | formatCurrencyValue({ currency: 'CHF' }) }}</span> / {{ $t('month') }}
                     </v-col>
                   </v-row>
                </v-card-text>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>

    <v-expand-transition>
      <v-card-text v-if="error">
        <p class="error--text my-0">
          {{ $t('global.pleaseFillOutThisField') }}
        </p>
      </v-card-text>
    </v-expand-transition>
  </div>
</template>

<script>
  export default {
    computed: {
      plans() {
        return [
          {
            id: 1,
            color: 'primary',
            callToAction: 'Kostenlos testen',
            name: 'Standard',
            features: [
              { name: 'Beraterprofil' },
              { name: 'unlimitierte Anfragen' },
              { name: '' },
              { name: 'Kundenbewertungen mit Verifizierung' },
              { name: 'Kommentarfunktion' }
            ],
            value: 79
          },
          {
            id: 2,
            color: 'primary',
            callToAction: 'Kostenlos testen',
            highlight: true,
            name: 'Professional',
            features: [
              { name: 'Beraterprofil' },
              { name: 'unlimitierte Anfragen' },
              { name: 'Vorstellungsvideo' },
              { name: 'Kundenbewertungen mit Verifizierung' },
              { name: 'Kommentarfunktion' },
              { name: 'Reaktionszeit vor Veröffentlichung einer Bewertung < 4/5 Sternen: 1 Woche' },
              { name: 'Aufnahme in die öffentliche Rangliste' },
              { name: 'Statistiken und Auswertungen' }
            ],
            value: 99
          },
          {
            id: 3,
            color: 'primary',
            callToAction: 'Kostenlos testen',
            highlight: false,
            name: 'Expert',
            features: [
              { name: 'Beraterprofil' },
              { name: 'unlimitierte Anfragen' },
              { name: 'Vorstellungsvideo' },
              { name: 'Kundenbewertungen mit Verifizierung' },
              { name: 'Kommentarfunktion' },
              { name: 'Reaktionszeit vor Veröffentlichung einer Bewertung < 4/5 Sternen: 1 Woche' },
              { name: 'Aufnahme in die öffentliche Rangliste' },
              { name: 'Statistiken und Auswertungen' },
              { name: 'Support mit Priorität' },
              { name: 'Begleitetes Onboarding' },
              { name: 'White-Label-Lösung' }
            ],
            value: 179
          }
        ]
      }
    },
    data: () => ({
      error: false
    }),
    methods: {
      validate() {
        if (this.value == null || this.value.length === 0) {
          this.error = true

          return false
        }

        return true
      }
    },
    name: 'PlanSelect',
    props: {
      dark: Boolean,
      value: Number
    },
    watch: {
      value() {
        this.error = false
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "invitationCode": "Einladungscode",
      "month": "Monat"
    },
    "en": {
      "invitationCode": "Invitation code",
      "month": "Month"
    }
  }
</i18n>