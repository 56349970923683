<template>
  <v-card flat
          v-bind:color="color">
    <v-card-title class="px-3 py-6">
      <v-btn color="primary darken-2"
             small
             text
             v-on:click="showAllQuestions = !showAllQuestions">
        {{ !showAllQuestions ? $t('showAllQuestions') : $t('showCriticalOnly') }}
        <v-icon right>
          {{ showAllQuestions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <template v-for="(category, categoryIndex) in categories">
      <v-card-text class="pb-0 pt-6 px-6">
        <h5 class="black--text text-h5">{{ category.name }}</h5>
      </v-card-text>

      <template v-for="(questionResult, index) in category.questions">
        <v-card-text class="pa-6"
                     v-bind:key="`${questionResult.questionDefinitionId}-card_text`" >
          <v-row>
            <v-col>
              <p class="font-weight-medium mb-1"
                 v-bind:class="questionResult.points === 0 && !!questionResult.questionDefinition.customerHint ? 'deep-orange--text' : 'black--text'">
                {{ questionResult.questionDefinition.text }}
              </p>
              <p class="mb-0"
                 v-if="questionResult.points === 0">
                {{ questionResult.questionDefinition.customerHint }}
              </p>
            </v-col>
            <v-col cols="auto">
              <template v-if="questionResult.questionDefinition.type === 'rating'">
                <v-rating half-increments
                          readonly
                          background-color="black"
                          color="black"
                          size="medium"
                          v-bind:value="questionResult.value" />
              </template>
              <template v-if="questionResult.questionDefinition.type === 'scaleRating'">
                <p class="black--text mb-0">
                  {{ questionResult.value }}
                </p>
              </template>
              <template v-if="questionResult.questionDefinition.type === 'select'">
                <p class="mb-0"
                   v-bind:class="questionResult.points === 0 && !!questionResult.questionDefinition.customerHint ? 'deep-orange--text' : 'black--text'">
                  {{ questionResult.questionDefinition.options.find(o => o.id === questionResult.value)?.text }}
                </p>
              </template>
              <template v-if="questionResult.questionDefinition.type === 'text'">
                {{ questionResult.questionDefinition.prefix ?? '' }} {{ questionResult.textValue }} {{ questionResult.questionDefinition.suffix ?? '' }}
              </template>
              <template v-if="questionResult.questionDefinition.type === 'yesNo'">
                <v-icon v-bind:color="questionResult.points === 0 ? 'deep-orange' : 'black'" small>
                  {{ questionResult.value === 1 ? 'mdi-thumb-up' : 'mdi-thumb-down' }}
                </v-icon>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider v-bind:key="`${questionResult.questionDefinitionId}-divider`"
                   v-if="index !== category.questions.length - 1" />
      </template>
    </template>
  </v-card>
</template>

<script>
  export default {
    computed: {
      categories() {
        if (this.evaluation == null) {
          return []
        }

        return this.evaluation.questionResults.reduce((categories, questionResult) => {
          if (!this.showAllQuestions && (questionResult.points !== 0 || !questionResult.questionDefinition.customerHint)) {
            return categories
          }

          let category = categories.find(c => c.categoryId === questionResult.questionDefinition.ratingCategory.id)

          if (category == null) {
            category = {
              categoryId: questionResult.questionDefinition.ratingCategory.id,
              name: questionResult.questionDefinition.ratingCategory.name,
              questions: []
            }

            categories.push(category)
          }

          category.questions.push(questionResult)

          return categories;
        }, [])
      }
    },
    data: () => ({
      showAllQuestions: true
    }),
    name: 'EvaluationQuestionOverviewCard',
    props: {
      color: String,
      evaluation: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "showAllQuestions": "Alle Fragen anzeigen",
      "showCriticalOnly": "Nur kritische Fragen anzeigen"
    },
    "en": {
      "showAllQuestions": "Show all questions",
      "showCriticalOnly": "Show critical questions only"
    }
  }
</i18n>