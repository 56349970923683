<template>
  <div>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header color="secondary"
                v-bind:title="$t('consultants')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'administration-consultant-add' }"
                     v-if="hasPermission('write:consultants')" />
    </top-header>
    <full-width-section color="transparent">
      <v-container>
        <consultant-table-card v-bind:consultants="consultants"
                               v-on:click:row="$router.push({ name: 'administration-consultant', params: { id: $event.id }})" />
      </v-container>
    </full-width-section>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ConsultantTableCard from '@/components/v2/administration/ConsultantTableCard.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      ConsultantTableCard,
      FullWidthSection,
      HeaderButton,
      TopHeader
    },
    computed: {
      ...mapGetters({
        consultants: 'base/consultants',
        hasPermission: 'auth/hasPermission'
      })
    },
    async created() {
      this.loading = true

      await this.loadConsultants()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadConsultants: 'base/loadConsultants'
      })
    },
    name: 'ConsultantsView'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "consultants": "Berater"
    },
    "en": {
      "add": "Add",
      "consultants": "Consultants"
    }
  }
</i18n>