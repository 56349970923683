<template>
  <div>
    <v-item-group multiple
                v-bind:value="value"
                v-on:change="$emit('input', $event)">
      <v-row dense>
        <v-col cols="6"
               md="3"
               sm="4"
               v-bind:key="locale.id"
               v-for="locale in locales">
          <v-item v-bind:value="locale.id"
                  v-slot="{ active, toggle }">
            <v-card class="fill-height"
                    flat
                    v-bind:color="active ? 'primary' : 'secondary'"
                    v-on:click="toggle">
              <v-card-text class="text-center">
                <v-avatar class="mb-3"
                          size="48"
                          tile
                          v-if="locale.highlight">
                  <v-img v-bind:src="`/images/v2/flags/${ locale.id }.png`" />
                </v-avatar>
                <p class="mb-0"
                   v-bind:class="active ? 'white--text' : 'black--text'">
                  {{ $t(locale.label) }}<br>
                  <span class="text-caption">{{ locale.nativeTitle }}</span>
                </p>
              </v-card-text>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>

    <v-expand-transition>
      <p class="error--text mb-0 mt-4"
        v-if="error">
        {{ $t('global.pleaseFillOutThisField') }}
      </p>
    </v-expand-transition>
  </div>
</template>

<script>
  import locales from '@/locales.json'

  export default {
    computed: {
      locales() {
        return locales
      }
    },
    data: () => ({
      error: false
    }),
    methods: {
      validate() {
        if (this.value == null || this.value.length === 0) {
          this.error = true

          return false
        }

        return true
      }
    },
    name: 'LanguageSelect',
    props: {
      value: Array
    },
    watch: {
      value() {
        this.error = false
      }
    }
  }
</script>