<template>
  <v-card class="fill-height"
          color="white"
          dark
          flat>
    <v-card-text class="pa-6">
      <h4 class="text-h4 primary--text">{{ $t('overallRating') }}</h4>
      <h6 class="mb-6 primary--text text-h6">{{ $tc('basedOnEvaluations', count, { count } ) }}</h6>
      <overall-rating v-bind:average-ratings="averageRatings"
                      v-bind:rating="rating" />
    </v-card-text>
  </v-card>
</template>

<script>
  import OverallRating from '@/components/v2/base/OverallRating.vue'

  export default {
    components: {
      OverallRating
    },
    name: 'ConsultantOverallRatingCard',
    props: {
      averageRatings: Array,
      count: Number,
      rating: Number
    }
  }
</script>

<i18n>
  {
    "de": {
      "basedOnEvaluations": "Basierend auf einer Bewertung | Basierend auf {count} Bewertungen",
      "overallRating": "Gesamt"
    },
    "en": {
      "basedOnEvaluations": "Based on one evaluation | Based on {count} evaluations",
      "overallRating": "Overall rating"
    }
  }
</i18n>