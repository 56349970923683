<template>
  <div>
    <v-form ref="form"
            v-on:submit.prevent="search">
      <v-row>
        <v-col cols="12"
               md="11">
          <v-text-field flat
                        hide-details="auto"
                        solo
                        v-bind:label="$t('nameCompanyNameOrConsultantNumber')"
                        v-bind:rules="[ rules.required ]"
                        v-model="options.name"
                        v-on:keydown.enter="search" />
        </v-col>
        <v-col cols="12"
               md="1">
          <v-btn block
                 class="v-btn--search"
                 color="primary"
                 dark
                 depressed
                 large
                 v-on:click="search">
            <v-icon color="accent"
                    v-bind:left="$vuetify.breakpoint.smAndDown">
              mdi-magnify
            </v-icon>
            <span class="hidden-md-and-up">{{ $t('search') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  import rules from '@/mixins/v2/rules'

  export default {
    methods: {
      search() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.$emit('search', this.options)
      }
    },
    mixins: [ rules ],
    name: 'ConsultantNameSearch',
    props: {
      options: Object
    }
  }
</script>

<style scoped>
  .v-btn.v-btn--search {
    min-height: 48px;
  }
</style>

<i18n>
  {
    "de": {
      "nameCompanyNameOrConsultantNumber": "Name oder Firmenname",
      "search": "Suchen"
    },
    "en": {
      "nameCompanyNameOrConsultantNumber": "Name or company name",
      "search": "Search"
    }
  }
</i18n>