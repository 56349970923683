<template>
  <div>
    <v-form ref="form" v-if="!emailSent">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field class="v-text-field--white-error-text"
                      clearable
                      v-model="email.name"
                      hide-details="auto"
                      outlined
                      v-bind:label="$t('yourName')"
                      v-bind:rules="[ rules.required ]" />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field class="v-text-field--white-error-text"
                      clearable
                      v-model="email.email"
                      hide-details="auto"
                      outlined
                      v-bind:label="$t('yourEmail')"
                      v-bind:rules="[ rules.required ]" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field class="v-text-field--white-error-text"
                      clearable
                      v-model="email.consultantName"
                      hide-details="auto"
                      outlined
                      v-bind:label="$t('conultantName')"
                      v-bind:rules="[ rules.required ]" />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field class="v-text-field--white-error-text"
                      clearable
                      v-model="email.consultantEmail"
                      hide-details="auto"
                      outlined
                      v-bind:label="$t('conultantEmail')"
                      v-bind:rules="[ rules.required ]" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-btn color="accent"
               dark
               depressed
               large
               rounded
               v-on:click="send">
          <v-icon left>
            mdi-send
          </v-icon>
          {{ $t('invite') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
  <div v-else class="text-center">
    <p>Wir haben Ihren Berater eingeladen!</p>
  </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    components: {

    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    data: () => ({
      emailSent: false,
      email: {}
    }),
    methods: {
      send() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.emailSent = true

        this.$emit('send', this.email)
      }
    },
    name: 'InviteForm',
    props: {
      title: String
    }
  }
</script>

<style scoped>
  .v-btn.search-button {
    min-height: 48px;
  }
</style>

<i18n>
  {
    "de": {
      "conultantName": "Beratername",
      "conultantEmail": "Berater E-Mail",
      "send": "Senden",
      "yourEmail": "Ihre E-Mail-Adresse",
      "yourMessage": "Ihre Nachricht",
      "yourName": "Ihr Name",
      "invite": "Einladen"
    },
    "en": {
      "conultantName": "Consultant name",
      "conultantEmail": "Consultant email",
      "send": "Send",
      "yourEmail": "Your email",
      "yourMessage": "Your message",
      "yourName": "Your name",
      "invite": "Invite"
    }
  }
</i18n>