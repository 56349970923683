<template>
  <div>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />
    <div v-if="consultant != null">
      <questionnaire-header v-bind:consultant="consultant"
                            v-bind:text="name"
                            v-bind:title="$t('youRate')" />

      
      <full-width-section color="secondary">
        <v-container>
          <v-row justify="center">
            <v-col cols="12"
                   lg="12"
                   xl="8">
              <questionnaire-stepper-card v-bind:evaluation="evaluation"
                                          v-bind:loading="loading"
                                          v-bind:question-definitions="questionDefinitions"
                                          v-on:submit="submit" />
            </v-col>
          </v-row>
        </v-container>
      </full-width-section>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import QuestionnaireStepperCard from '@/components/v2/questionnaire/QuestionnaireStepperCard.vue'
  import QuestionnaireHeader from '@/components/v2/questionnaire/QuestionnaireHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const consultantId = parseInt(to.params.consultantId)

      this.editToken = to.query.editToken
      this.id = to.query.id

      await this.get(consultantId)

      next()
    },
    components: {
      FullWidthSection,
      QuestionnaireStepperCard,
      QuestionnaireHeader
    },
    computed: {
      name() {
        if (this.consultant == null) return ''

        return `${this.consultant.givenName} ${this.consultant.familyName}`
      },
      ...mapGetters({
        currentEvaluation: 'base/currentEvaluation',
        getConsultant: 'base/consultant',
        questionDefinitions: 'base/questionDefinitions',
        getEvaluation: 'base/evaluation'
      })
    },
    async created() {
      if (!!document.amplitude) {
        amplitude.track('Start Rate Consultant');
      }
      

      if (!!this.$route.query?.editToken) {
        this.editToken = this.$route.query.editToken
      }

      if (!!this.$route.query?.id) {
        this.id = this.$route.query.id
      }

      const consultantId = parseInt(this.$route.params.consultantId)

      await this.get(consultantId)
    },
    data: () => ({
      consultant: null,
      evaluation: {
        customer: {},
        questionResults: [],
        model: {}
      },
      questionnaire: null,
      loading: false,
      id: null,
      editToken: null
    }),
    methods: {
      async get(consultantId) {
        this.loading = true

        await this.loadConsultant({ id: consultantId })
        await this.loadConsutlingTopics()
        await this.loadQuestionDefinitions()

        this.consultant = Object.assign({}, this.getConsultant(consultantId))

        if (!!this.id && !!this.editToken) {
          await this.loadEvaluation({ id: this.id })
          const evaluation = Object.assign({}, this.getEvaluation(this.id))


          this.evaluation.model = evaluation.questionResults.map(q => {
            return {
              [q.questionDefinitionId]: q.textValue ?? q.value 
            }
          }).reduce((a, b) => Object.assign({}, a, b), {})

          this.evaluation.id = this.id
          this.evaluation.editToken = this.editToken
          
          this.evaluation.consultingTopicIds = evaluation.consultingTopicIds
          this.evaluation.language = evaluation.language
          this.evaluation.comment = evaluation.comment

          this.evaluation.customer = evaluation.customer

          /*this.evaluation.customer.givenName = evaluation.customer.givenName
          this.evaluation.customer.familyName = evaluation.customer.familyName
          this.evaluation.customer.email = evaluation.customer.email
          this.evaluation.customer.nameDisplayStyle = evaluation.customer.nameDisplayStyle
          this.evaluation.customer.honorific = evaluation.customer.honorific*/


        }

        this.loading = false
      },
      async submit() {
        if (!!document.amplitude) {
          amplitude.track('Rate Consultant');
        }
        

        this.loading = true

        this.evaluation.consultantId = this.consultant.id
        this.evaluation.questionResults = []

        for (const [key, value] of Object.entries(this.evaluation.model)) {
          this.evaluation.questionResults.push({
            questionDefinitionId: parseInt(key),
            value: typeof value == 'number' ? value : 0,
            textValue: typeof value == 'string' ? value : null
          })
        }

        const evaluation = await this.doCreateEvaluation({ evaluation: this.evaluation })

        this.loading = false

        this.$router.push({ name: 'evaluation-summary', params: { evaluationId: evaluation.id } })
      },
      ...mapActions({
        doCreateEvaluation: 'base/createEvaluation',
        loadConsultant: 'base/loadConsultant',
        loadConsutlingTopics: 'base/loadConsultingTopics',
        loadQuestionDefinitions: 'base/loadQuestionDefinitions',
        loadEvaluation: 'base/loadEvaluation'
      })
    },
    name: 'QuestionnaireView'
  }
</script>

<style>
  .v-list-item__subtitle, .v-list-item__title{
      text-overflow: initial!important;
    white-space: initial!important;
}
</style>

<i18n>
  {
    "de": {
      "youRate": "Sie bewerten jetzt"
    },
    "en": {
      "youRate": "You evaluate"
    }
  }
</i18n>