<template>
  <div>
    <top-header v-bind:title="$t('salesAgents')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'administration-sales-agent-add' }" />
    </top-header>
    <v-container>
      <sales-agent-table-card item-route-name="administration-sales-agent"
                             v-bind:sales-agents="salesAgents"
                             v-bind:loading="loading" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import SalesAgentTableCard from '@/components/administration/SalesAgentTableCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      SalesAgentTableCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      ...mapGetters({
        salesAgents: 'administration/salesAgents'
      })
    },
    async created() {
      this.loading = true

      await this.loadSalesAgents()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadSalesAgents: 'administration/loadSalesAgents'
      })
    },
    name: 'SalesAgents'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "salesAgents": "Verkäufer"
    },
    "en": {
      "add": "Add",
      "salesAgents": "Sales agents"
    }
  }
</i18n>