<template>
  <v-card color="secondary"
          flat
          v-bind:to="{ name: 'consultant', params: { id: consultant.id }}">
    <v-card-text class="pa-6">
      <v-row align="center">
        <v-col v-bind:class="!$vuetify.breakpoint.mdAndUp ? 'text-center' : null"
               cols="12"
               md="auto">
          <v-avatar v-bind:size="$vuetify.breakpoint.mdAndDown ? 90 : 128">
            <v-img v-bind:src="consultant.photoAttachment | attachmentUrl({ defaultUrl: '/images/profile-none.jpg'} )" />
          </v-avatar>
        </v-col>
        <v-col v-bind:class="!$vuetify.breakpoint.mdAndUp ? 'text-center' : null"
               v-bind:cols="!$vuetify.breakpoint.mdAndUp ? 12 : null">
          <h5 class="mb-2 primary--text text-h5">{{ consultant.formattedName }}</h5>
          <h6 class="primary--text text-h6"
              v-if="!!consultant.companyId">
            {{ consultant.companyName }}
          </h6>
          <language-display class="mb-4"
                            v-bind:languages="consultant.languages" />
          <p>
            {{ consultant.streetName }} {{ consultant.streetNumber }}<br>
            {{ consultant.postalCode }} {{ consultant.city }}
          </p>
          <p class="grey--text text--darken-2 text-subtitle-2">
            <span v-for="tag in consultant.tags.slice(0, 10)"
                  v-bind:key="tag">
              #{{ tag }}
            </span>
          </p>
          <v-chip color="primary"
                  label
                  small
                  v-if="consultant.distance != null">
            {{ $t('away', { distance: $options.filters.formatValue(consultant.distance, { fractionDigits: 2, suffix: 'km' }) }) }}
          </v-chip>
        </v-col>
        <v-col cols="12"
               md="auto">
          <rating v-bind:rating="consultant.overallRating"
                  v-bind:count="consultant.evaluationCount"
                  v-bind:unclaimed="consultant.state === 'unclaimed'" />
          <consulting-topic-chips center
                                  v-bind:consulting-topic-ids="consultant.consultingTopicIds" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ConsultingTopicChips from '@/components/v2/base/ConsultingTopicChips.vue'
  import LanguageDisplay from '@/components/v2/base/LanguageDisplay.vue'
  import Rating from '@/components/v2/base/Rating.vue'

  export default {
    components: {
      ConsultingTopicChips,
      LanguageDisplay,
      Rating
    },
    computed: {
      ...mapGetters({
        consultingTopicLabel: 'base/consultingTopicLabel'
      })
    },
    name: 'ConsultantListCard',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "away": "{distance} entfernt",
      "ratings": "{value} Bewertungen",
      "stars": "{value} Sterne"
    },
    "en": {
      "away": "{distance} away",
      "ratings": "{value} ratings",
      "stars": "{value} stars"
    }
  }
</i18n>