import Vue from 'vue'
import VueRouter from 'vue-router'

import goTo from 'vuetify/lib/services/goto'

import { createAuthRouterGuard } from 'kayleen-vue-auth0-client'

import store from '@/store'
import accountRoutes from './account-routes'
import administrationRoutes from './administration-routes'
import baseRoutes from './base-routes'
import commonRoutes from './common-routes'
import questionnaireRoutes from './questionnaire-routes'

Vue.use(VueRouter)

const routes = [
  ...accountRoutes,
  ...administrationRoutes,
  ...baseRoutes,
  ...commonRoutes,
  ...questionnaireRoutes
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return goTo(to.hash)
    }

    return { x: 0, y: 0 }
  }
})

router.beforeEach(createAuthRouterGuard(store))

export default router