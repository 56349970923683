<template>
  <div>
    <v-card color="secondary"
            outlined>
      <v-card-text>
        <h1 class="text-center text-h5 primary--text text--darken-4">{{ title }}</h1>
        <rating primary
                v-bind:rating="rating"
                v-bind:rating-count="ratingCount" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import Rating from '@/components/v2/base/Rating.vue'

  export default {
    components: {
      Rating
    },
    name: 'DashboardRatingCard',
    props: {
      graphValues: Array,
      title: String,
      rating: Number,
      ratingCount: Number
    }
  }
</script>