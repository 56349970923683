<template>
  <div>
    <v-rating class="text-center"
              half-increments
              readonly
              v-bind:background-color="dark ? 'secondary darken-4' : 'secondary darken-1'"
              v-bind:color="dark ? 'accent' : 'primary'"
              v-bind:size="size"
              v-bind:value="unclaimed ? null : rating" />
    <div class="mb-0 text-center">
      <p class="mb-0 text-center"
         v-bind:class="dark ? 'secondary--text' : 'secondary--text text--darken-4'"
         v-if="unclaimed">
        <span>{{ $t('ratingHidden') }}</span>
      </p>
      <p v-bind:class="dark ? 'secondary--text' : 'secondary--text text--darken-4'"
         v-else-if="count != 0">
        <span v-bind:class="dark ? 'accent--text' : 'primary--text'">{{ $t('stars', { value: $options.filters.formatValue(rating, { fractionDigits: 1 }) }) }}</span> |
        <span>{{ $t('ratings', { value: count }) }}</span>
      </p>
      <p class="mb-0 text-center"
         v-bind:class="dark ? 'secondary--text' : 'secondary--text text--darken-4'"
         v-else>
        <span>{{ $t('noEvaluationsYet') }}</span>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Rating',
    props: {
      count: Number,
      dark: Boolean,
      rating: Number,
      size: {
        type: String,
        default: '32'
      },
      unclaimed: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "noEvaluationsYet": "Noch keine Bewertungen vorhanden",
      "ratingHidden": "Bewertung ausgeblendet",
      "ratings": "{value} Bewertungen",
      "stars": "{value} Sterne"
    },
    "en": {
      "noEvaluationsYet": "No evaluations yet",
      "ratingHidden": "Rating hidden",
      "ratings": "{value} ratings",
      "stars": "{value} stars"
    }
  }
</i18n>