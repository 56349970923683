<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('languages') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <language-select ref="languageSelect"
                         v-model="consultant.languages" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import LanguageSelect from '@/components/v2/account/LanguageSelect'

  export default {
    components: {
      LanguageSelect
    },
    name: 'AccountLanguagesCard',
    methods: {
      validate() {
        return this.$refs.languageSelect.validate()
      }
    },
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "languages": "Sprachen"
    },
    "en": {
      "languages": "Languages"
    }
  }
</i18n>