import Vue from 'vue'

import AttachmentService from '@/api/base/attachment-service'
import CompanyService from '@/api/base/company-service'
import ConsultantService from '@/api/base/consultant-service'
import ConsultingTopicService from '@/api/base/consulting-topic-service'
import EvaluationService from '@/api/base/evaluation-service'
import QuestionDefintionService from '@/api/base/question-definition-service'
import EmailService from '@/api/base/email-service'

import MapService from '@/api/map-service'

import settings from '@/settings'

const attachmentService = new AttachmentService(settings.apiUrl)
const companyService = new CompanyService(settings.apiUrl)
const consultantService = new ConsultantService(settings.apiUrl)
const consultingTopicService = new ConsultingTopicService(settings.apiUrl)
const evaluationService = new EvaluationService(settings.apiUrl)
const questionDefintionService = new QuestionDefintionService(settings.apiUrl)
const emailService = new EmailService(settings.apiUrl)

const mapService = new MapService()

export default {
  actions: {
    // attachments
    // ===========
    async createAttachment({ commit, rootGetters }, { attachment }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await attachmentService.create(attachment, { accessToken })

      attachment.id = result.id
      attachment.fileName = result.fileName

      commit('createAttachment', { attachment: result })
    },


    // companies
    // =========
    async createCompany({ commit, rootGetters }, { company }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await companyService.create(company, { accessToken })

      commit('createCompany', { company: result })
    },
    async deleteCompany({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await companyService.delete(id, { accessToken })

      if (result) {
        commit('deleteCompany', { id })
      }
    },
    async loadCompany({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await companyService.find(id, { accessToken })

      if (getters.company(id) != null) {
        commit('updateCompany', { company: result })
      } else {
        commit('createCompany', { company: result })
      }
    },
    async linkCompanyConsultant({ commit, rootGetters }, { id, consultantId, companyLinkState }) {
      const accessToken = rootGetters['auth/accessToken']

      const consultant = await consultantService.find(consultantId, { accessToken })

      if (consultant == null) {
        return
      }

      consultant.companyLinkState = companyLinkState
      consultant.companyId = id

      if (consultant.companyId == null) {
        const result = await companyService.createConsultant(id, consultantId, { accessToken })
      }

      const result2 = await consultantService.update(consultant.id, consultant, { accessToken })




      commit('linkCompanyConsultant', { id, consultantId, companyLinkState })

    },
    async loadCompanies({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const companies = await companyService.getAll({ accessToken })

      commit('setCompanies', { companies })
    },
    async unlinkCompanyConsultant({ commit, rootGetters }, { id, consultantId }) {
      const accessToken = rootGetters['auth/accessToken']

      const consultant = await consultantService.find(consultantId, { accessToken })

      if (consultant == null) {
        return
      }

      consultant.companyLinkState = 'unlinked'
      consultant.companyId = null

      const result = await companyService.deleteConsultant(id, consultantId, { accessToken })

      const result2 = await consultantService.update(consultant.id, consultant, { accessToken })



      if (result) {
        commit('unlinkCompanyConsultant',  { id, consultantId })
      }
    },
    async updateCompany({ commit, rootGetters }, { company }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await companyService.update(company.id, company, { accessToken })

      if (result) {
        commit('updateCompany', { company })
      }
    },


    // consultants
    // ===========
    async createConsultant({ commit, rootGetters }, { consultant }) {
      const accessToken = rootGetters['auth/accessToken']

      try {
        const coordinate = await mapService.lookup(`${consultant.streetName} ${consultant.streetNumber}, ${consultant.postalCode} ${consultant.city}`)

        if (coordinate != null) {
          consultant.latitude = coordinate.latitude
          consultant.longitude = coordinate.longitude
        }
      } catch {}

      const result = await consultantService.create(consultant, { accessToken })

      commit('createConsultant', { consultant })
    },
    async deleteConsultant({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultantService.delete(id, { accessToken })

      if (result) {
        commit('deleteConsultant', { id })
      }
    },
    async loadConsultant({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultantService.find(id, { accessToken })

      if (getters.consultant(id) != null) {
        commit('updateConsultant', { consultant: result })
      } else {
        commit('createConsultant', { consultant: result })
      }
    },
    async loadConsultantStats({ commit, getters, rootGetters }, { id, start, end }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultantService.getStats(id, start, end, { accessToken })

      return result
    },
    async loadConsultants({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const consultants = await consultantService.getAll({ accessToken })

      commit('setConsultants', { consultants })
    },
    async loadTopConsultants({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const consultants = await consultantService.getTop({ accessToken })

      commit('setTopConsultants', { consultants })
    },
    async updateConsultant({ commit, rootGetters }, { consultant }) {
      const accessToken = rootGetters['auth/accessToken']

      try {
        const coordinate = await mapService.lookup(`${consultant.streetName} ${consultant.streetNumber}, ${consultant.postalCode} ${consultant.city}`)

        if (coordinate != null) {
          consultant.latitude = coordinate.latitude
          consultant.longitude = coordinate.longitude
        }
      } catch {}

      const result = await consultantService.update(consultant.id, consultant, { accessToken })

      if (result) {
        commit('updateConsultant', { consultant })
      }
    },
    async getPortalUrl({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultantService.getPortalUrl(id, { accessToken })

      return result
    },
    async getCheckoutUrl({ commit, rootGetters }, { id, priceId }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultantService.getCheckoutUrl(id, priceId, { accessToken })

      return result
    },
    async activateConsultant({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultantService.activate(id, { accessToken })

      if (result) {
        commit('activateConsultant', { id })
      }
    },
    async disableConsultant({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultantService.disable(id, { accessToken })

      if (result) {
        commit('disableConsultant', { id })
      }
    },


    // consultingTopics
    // ================
    async createConsultingTopic({ commit, rootGetters }, { consultingTopic }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultingTopicService.create(consultingTopic, { accessToken })

      commit('createConsultingTopic', { consultignTopic: result })
    },
    async deleteConsultingTopic({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultingTopicService.delete(id, { accessToken })

      if (result) {
        commit('deleteConsultingTopic', { id })
      }
    },
    async loadConsultingTopic({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultingTopicService.find(id, { accessToken })

      if (getters.consultingTopic(id) != null) {
        commit('updateConsultingTopic', { consultingTopic: result })
      } else {
        commit('createConsultingTopic', { consultingTopic: result })
      }
    },
    async loadConsultingTopics({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const consultingTopics = await consultingTopicService.getAll({ accessToken })

      commit('setConsultingTopics', { consultingTopics })
    },
    async updateConsultingTopic({ commit, rootGetters }, { consultingTopic }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await consultingTopicService.update(consultingTopic.id, consultingTopic, { accessToken })

      if (result) {
        commit('updateConsultingTopic', { consultingTopic })
      }
    },


    // evaluations
    // ===========
    async loadEvaluation({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await evaluationService.find(id, { accessToken })

      if (getters.evaluation(id) != null) {
        commit('updateEvaluation', { evaluation: result })
      } else {
        commit('createEvaluation', { evaluation: result })
      }
    },
    async createEvaluation({ commit, rootGetters }, { evaluation }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await evaluationService.create(evaluation, { accessToken })

      commit('createEvaluation', { evaluation: result })

      return result
    },
    async publishEvaluation({ commit, rootGetters }, { consultantId, id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await evaluationService.publish(id, { accessToken })

      if (result) {
        commit('publishEvaluation', { consultantId, id })
      }
    },
    async verifyEvaluation({ commit, rootGetters }, { consultantId, id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await evaluationService.verify(id, { accessToken })

      if (result) {
        commit('verifyEvaluation', { consultantId, id })
      }
    },
    async addConsultantComment({ commit, rootGetters }, { consultantId, id, comment }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await evaluationService.addConsultantComment(id, comment, { accessToken })

      if (result) {
        commit('addConsultantComment', { consultantId, id, comment })
      }
    },


    // questionDefinitions
    // ===================
    async loadQuestionDefinitions({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const questionDefinitions = await questionDefintionService.getAll({ accessToken })

      commit('setQuestionDefinitions', { questionDefinitions })
    },


    // emails
    // ======
    async createEmail({ commit, rootGetters }, { email }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await emailService.create(email, { accessToken })

    },

    // map
    // ===
    async lookup({ }, { address }) {
      const coordinate = await mapService.lookup(address)
      try {
        const coordinate = await mapService.lookup(address)

        return coordinate
      } catch {
        return null
      }
    }
  },
  getters: {
    attachment: state => id => state.attachments.find(a => a.id === id),
    attachments: state => state.attachments,

    company: state => id => state.companies.find(c => c.id === id),
    companies: state => state.companies,

    consultant: state => id => state.consultants.find(c => c.id === id),
    consultantLabel: (state, getters) => id => {
      const consultant = getters.consultant(id)

      if (consultant == null) {
        return null
      }

      return `${consultant.formattedName} (${consultant.number})`
    },
    consultantLabelShort: (state, getters) => id => {
      const consultant = getters.consultant(id)

      if (consultant == null) {
        return null
      }

      return `${consultant.formattedName}`
    },
    consultants: state => state.consultants,
    topConsultants: state => state.topConsultants,

    consultingTopic: state => id => state.consultingTopics.find(c => c.id === id),
    consultingTopicDescription: state => id => state.consultingTopics.find(c => c.id === id)?.description,
    consultingTopicLabel: state => id => state.consultingTopics.find(c => c.id === id)?.name,
    consultingTopicIcon: state => id => state.consultingTopics.find(c => c.id === id)?.icon,
    consultingTopics: state => state.consultingTopics,

    evaluation: state => id => state.evaluations.find(e => e.id === id),
    evaluations: state => state.evaluations,

    questionDefinitions: state => state.questionDefinitions
  },
  mutations: {
    // attachments
    // ===========
    createAttachment(state, { attachment }) {
      state.attachments.push(attachment)
    },


    // companies
    // =========
    createCompany(state, { company }) {
      state.companies.push(company)
    },
    deleteCompany(state, { id }) {
      const index = state.companies.findIndex(c => c.id === id)

      if (index !== -1) {
        state.companies.splice(index, 1)
      }
    },
    setCompanies(state, { companies }) {
      state.companies = companies
    },
    linkCompanyConsultant(state, { id, consultantId, companyLinkState }) {
      let consultant = state.consultants.find(c => c.id === consultantId)

      if (consultant == null) {
        return
      }

      let index = state.consultants.findIndex(c => c.id === consultantId)

      consultant.companyId = id
      consultant.companyLinkState = companyLinkState

      Vue.set(state.consultants, index, consultant)

      let company = state.companies.find(c => c.id === id)

      if (company == null) {
        return
      }

      index = company.consultants.findIndex(c => c.id === consultantId)

      if (index != -1) {
        company.consultants.splice(index, 1)
      }

      company.consultants.push(consultant)
    },
    unlinkCompanyConsultant(state, { id, consultantId }) {
      let consultant = state.consultants.find(c => c.id === consultantId)

      if (consultant == null) {
        return
      }

      let index = state.consultants.findIndex(c => c.id === id)

      consultant.companyId = null
      consultant.companyLinkState = 'unlinked'

      Vue.set(state.consultants, index, consultant)

      let company = state.companies.find(c => c.id === id)

      if (company == null) {
        return
      }

      index = company.consultants.findIndex(c => c.id === consultantId)

      company.consultants.splice(index, 1)
    },
    updateCompany(state, { company }) {
      const index = state.companies.findIndex(c => c.id === company.id)

      if (index !== -1) {
        Vue.set(state.companies, index, company)
      }
    },


    // consultants
    // ===========
    createConsultant(state, { consultant }) {
      state.consultants.push(consultant)
    },
    deleteConsultant(state, { id }) {
      const index = state.consultants.findIndex(c => c.id === id)

      if (index !== -1) {
        state.consultants.splice(index, 1)
      }
    },
    setConsultants(state, { consultants }) {
      state.consultants = consultants
    },
    setTopConsultants(state, { consultants }) {
      console.log(consultants)
      state.topConsultants = consultants
    },
    updateConsultant(state, { consultant }) {
      const index = state.consultants.findIndex(c => c.id === consultant.id)

      if (index !== -1) {
        Vue.set(state.consultants, index, consultant)
      }
    },
    activateConsultant(state, { id }) {
      let consultant = state.consultants.find(c => c.id === id)

      if (consultant != null) {
        consultant.state = 'active'
      }
    },
    disableConsultant(state, { id }) {
      let consultant = state.consultants.find(c => c.id === id)

      if (consultant != null) {
        consultant.state = 'disabled'
      }
    },

    // consultingTopics
    // ================
    createConsultingTopic(state, { consultingTopic }) {
      state.consultingTopics.push(consultingTopic)
    },
    deleteConsultingTopic(state, { id }) {
      const index = state.consultingTopics.findIndex(c => c.id === id)

      if (index !== -1) {
        state.consultingTopics.splice(index, 1)
      }
    },
    setConsultingTopics(state, { consultingTopics }) {
      state.consultingTopics = consultingTopics
    },
    updateConsultingTopic(state, { consultingTopic }) {
      const index = state.consultingTopics.findIndex(c => c.id === consultingTopic.id)

      if (index !== -1) {
        Vue.set(state.consultingTopics, index, consultingTopic)
      }
    },


    // evaluations
    // ===========
    createEvaluation(state, { evaluation }) {
      state.evaluations.push(evaluation)
    },
    updateEvaluation(state, { evaluation }) {
      const index = state.evaluations.findIndex(e => e.id === evaluation.id)

      if (index !== -1) {
        Vue.set(state.evaluations, index, evaluation)
      }
    },
    publishEvaluation(state, { consultantId, id }) {
      let evaluation = state.evaluations.find(e => e.id === id)

      if (evaluation != null) {
        evaluation.state = 'published'
      }

      let consultant = state.consultants.find(c => c.id === consultantId)

      if (consultant == null) {
        return
      }

      let consultantEvaluation = consultant.evaluations.find(e => e.id === id)

      if (consultantEvaluation != null) {
        consultantEvaluation.state = 'published'
      }
    },
    addConsultantComment(state, { consultantId, id, comment }) {
      let evaluation = state.evaluations.find(e => e.id === id)

      if (evaluation != null) {
        evaluation.consultantComment = comment
      }

      let consultant = state.consultants.find(c => c.id === consultantId)

      if (consultant == null) {
        return
      }

      let consultantEvaluation = consultant.evaluations.find(e => e.id === id)

      if (consultantEvaluation != null) {
        consultantEvaluation.isVerifed = true
      }
    },
    verifyEvaluation(state, { consultantId, id }) {
      let evaluation = state.evaluations.find(e => e.id === id)

      if (evaluation != null) {
        evaluation.isVerifed = true
      }

      let consultant = state.consultants.find(c => c.id === consultantId)

      if (consultant == null) {
        return
      }

      let consultantEvaluation = consultant.evaluations.find(e => e.id === id)

      if (consultantEvaluation != null) {
        consultantEvaluation.isVerifed = true
      }
    },


    // questionDefinitions
    // ===================
    setQuestionDefinitions(state, { questionDefinitions }) {
      state.questionDefinitions = questionDefinitions
    }
  },
  namespaced: true,
  state: {
    attachments: [],
    companies: [],
    consultants: [],
    topConsultants: [],
    consultingTopics: [],
    evaluations: [],
    questionDefinitions: []
  }
}