<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-select hide-details="auto"
                    outlined
                    v-bind:items="consultantStates | vueI18nItems({ text: 'label' })"
                    v-bind:label="$t('state')"
                    v-bind:rules="[ rules.required ]"
                    v-model="consultant.state" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import rules from '@/mixins/v2/rules'

  export default {
    computed: {
      ...mapGetters({
        consultantStates: 'local/consultantStates'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    mixins: [ rules ],
    name: 'ConsultantState',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "state": "Status"
    },
    "en": {
      "state": "State"
    }
  }
</i18n>