<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('introduction') }}</v-toolbar-title>
      </v-toolbar>
      <account-introduction ref="accountIntroduction"
                            v-bind:consultant="consultant" />
    </v-card>
  </div>
</template>

<script>
  import AccountIntroduction from '@/components/v2/account/AccountIntroduction'

  export default {
    components: {
      AccountIntroduction
    },
    name: 'AccountIntroductionCard',
    methods: {
      validate() {
        return this.$refs.accountIntroduction.validate()
      }
    },
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "introduction": "Vorstellung"
    },
    "en": {
      "introduction": "Introduction"
    }
  }
</i18n>