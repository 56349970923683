<template>
  <v-card class="fill-height"
          color="primary"
          dark
          flat>
    <v-card-text class="pa-6">
      <h4 class="text-h4 white--text">{{ $t('rank') }}</h4>
      <h6 class="mb-6 secondary--text text-h6">{{ $t('compared', { name }) }}</h6>
      <v-row align="center">
        <v-col class="text-center">
          <v-avatar class="mb-6"
                    color="accent"
                    size="64">
            <span class="primary--text text-h4">{{ displayGlobalRank }}</span>
          </v-avatar>
          <h5 class="mb-2 text-h5 white--text">{{ $t('switzerland') }}</h5>
        </v-col>
        <v-col class="text-center">
          <v-avatar class="mb-6"
                    color="accent"
                    size="64">
            <span class="primary--text text-h4">{{ displayRegionRank }}</span>
          </v-avatar>
          <h5 class="mb-2 text-h5 white--text">{{ region?.toUpperCase() }}</h5>
        </v-col>
      </v-row>
      <p class="mb-0 mt-4 text-caption white--text">Wir arbeiten mit Hochdruck daran, diese Funktion für Sie verfügbar zu machen. Bleiben Sie dran, sie steht kurz vor der Veröffentlichung und wird bald für Sie bereit sein!</p>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      displayGlobalRank() {
        return this.globalRank ?? '?'
      },
      displayRegionRank() {
        return this.regionRank ?? '?'
      },
      ...mapGetters({
        cantonLabel: 'local/cantonLabel'
      })
    },
    name: 'ConsultantRankCard',
    props: {
      globalRank: Number,
      name: String,
      region: String,
      regionRank: Number
    }
  }
</script>

<i18n>
  {
    "de": {
      "compared": "{name} im Vergleich",
      "rank": "Rang",
      "switzerland": "Schweiz"
    },
    "en": {
      "compared": "{name} compared",
      "rank": "Rank",
      "switzerland": "Switzerland"
    }
  }
</i18n>