<template>
  <div>
    <v-snackbar color="warning"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header color="secondary"
                v-bind:title="$t('newCompany')">
      <header-button icon="mdi-close"
                     secondary
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'administration-companies' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="saveCompany" />
    </top-header>

    <full-width-section color="transparent">
      <v-container>
        <v-row>
          <v-col cols="12"
                 lg="6">
            <company-details-card ref="companyDetailsCard"
                                  v-bind:company="company" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import CompanyDetailsCard from '@/components/v2/account/CompanyDetailsCard.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      CompanyDetailsCard,
      FullWidthSection,
      HeaderButton,
      TopHeader
    },
    data: () => ({
      loading: false,
      company: {},
      validationSnackbar: false
    }),
    methods: {
      async saveCompany() {
        if (!this.$refs.companyDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.createCompany({ company: this.company })

        this.$router.push({ name: 'administration-companies' })
      },
      ...mapActions({
        createCompany: 'base/createCompany'
      })
    },
    name: 'CompanyAddView'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newCompany": "Neues Unternehmen",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "cancel": "Cancel",
      "newCompany": "New company",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>