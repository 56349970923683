<template>
  <div>
    <v-icon color="accent"
            small
            v-bind:key="`empty-${index}`"
            v-for="index in number - 1">mdi-circle</v-icon>
    <v-icon color="primary"
            small>mdi-circle</v-icon>
    <v-icon color="secondary darken-1"
            small
            v-bind:key="`full-${index}`"
            v-for="index in count - number">mdi-circle-outline</v-icon>
  </div>
</template>

<script>
  export default {
    name: 'QuestionnaireProgressIndicator',
    props: {
      number: Number,
      count: Number
    }
  }
</script>