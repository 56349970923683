import AccountCompanyView from '@/views/v2/account/AccountCompanyView'
import AccountCreateView from '@/views/v2/account/AccountCreateView'
import AccountProfileView from '@/views/v2/account/AccountProfileView'
import PrimaryNavigationView from '@/views/v2/base/navigation/PrimaryNavigationView.vue'

export default [
  {
    components: {
      default: AccountCompanyView,
      navigation: PrimaryNavigationView
    },
    meta: {
      auth: ({ isAuthenticated }) => isAuthenticated,
      hasNavigation: true
    },
    name: 'account-company',
    path: '/account/company'
  },
  {
    components: {
      default: AccountCreateView,
      navigation: PrimaryNavigationView
    },
    meta: {
      auth: ({ isAuthenticated }) => isAuthenticated,
      hasNavigation: true
    },
    name: 'account-create',
    path: '/account/create'
  },
  {
    components: {
      default: AccountProfileView,
      navigation: PrimaryNavigationView
    },
    meta: {
      auth: ({ isAuthenticated }) => isAuthenticated,
      hasNavigation: true
    },
    name: 'account-profile',
    path: '/account/profile'
  }
]