<template>
  <div>
    <v-snackbar color="accent"
                light
                right
                top
                v-model="confirmationSnackbar">
      {{ $t('saved') }}
    </v-snackbar>

    <v-snackbar color="warning"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header color="secondary"
                v-bind:title="name | defaultValue($t('company'))">
      <header-button class="mr-2"
                     icon="mdi-eye"
                     right
                     secondary
                     slot="right-button"
                     v-bind:label="$t('preview')"
                     v-bind:to="{ name: 'company', params: { id: company.id }}"
                     v-if="company != null" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateCompany" />
      <v-tabs background-color="transparent"
              color="primary"
              show-arrows
              slot="tabs"
              v-model="tab">
        <v-tab href="#consultants">
          {{ $t('consultants') }}
        </v-tab>
        <v-tab href="#details">
          {{ $t('details') }}
        </v-tab>
        <v-tab href="#dashboard">
          {{ $t('dashboard') }}
        </v-tab>
        <v-tab href="#organisation">
          {{ $t('organisation') }}
        </v-tab>
      </v-tabs>
    </top-header>
    <div v-if="company != null">
      <v-tabs-items class="transparent" v-model="tab">
        <v-tab-item eager
                    value="consultants">
          <full-width-section color="transparent">
            <v-container>
              <h4 class="mb-6 text-h4 primary--text">{{ $t('inviteConsultants') }}</h4>
              <p>{{ $t('inviteConsultantsToYourCompanyProfileThisWayConsultantsCanShowThatTheyWorkForYourCompanyLikewiseTheyWillBeIncludedInTheCompanysListOfConsultants') }}</p>
              <v-row>
                <v-col cols="12"
                       md="11">
                  <v-autocomplete background-color="secondary"
                                  clearable
                                  flat
                                  hide-details="auto"
                                  prepend-inner-icon="mdi-account-tie"
                                  solo
                                  v-bind:items="filteredConsultants | items({ text: 'formattedName', value: 'id' })"
                                  v-bind:label="$t('newConsultant')"
                                  v-model="newConsultantId" />
                </v-col>
                <v-col cols="12"
                       md="1">
                  <v-btn block
                         class="v-btn--add"
                         color="primary"
                         dark
                         depressed
                         large
                         v-on:click="linkConsultant(newConsultantId, 'requestedByCompany')">
                    <v-icon color="accent"
                            v-bind:left="$vuetify.breakpoint.smAndDown">
                      mdi-plus
                    </v-icon>
                    <span class="hidden-md-and-up">{{ $t('add') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
          <full-width-section color="transparent">
            <v-container>
              <h4 class="mb-6 text-h4 primary--text">{{ $t('currentConsultants') }}</h4>
              <consultant-grid color="secondary"
                               editable
                               v-bind:consultants="company.consultants"
                               v-bind:is-administrator="isAdministrator"
                               v-on:unlink="unlinkConsultant"
                               v-on:set-administrator="linkConsultant($event, 'administrator')"
                               v-on:remove-administrator="linkConsultant($event, 'accepted')"
                               v-on:accept="linkConsultant($event, 'accepted')" />
            </v-container>
          </full-width-section>
        </v-tab-item>

        <v-tab-item eager
                    value="details">
          <full-width-section color="transparent">
            <v-container>
              <v-row>
                <v-col cols="12"
                       lg="6">
                  <company-media-card ref="companyMediaCard"
                                      class="mb-6"
                                      v-bind:company="company" />
                  <company-details-card ref="companyDetailsCard"
                                        v-bind:company="company" />
                </v-col>
                <v-col cols="12"
                       lg="6">
                  <company-introduction-card class="mb-6"
                                             ref="companyIntroductionCard"
                                             v-bind:company="company" />
                  <company-social-details-card ref="companySocialDetailsCard"
                                               v-bind:company="company" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
        </v-tab-item>

        <v-tab-item eager
                    value="dashboard">
          <full-width-section color="transparent">
            <v-container>
              <v-img class="align-end"
                     gradient="to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)"
                     src="/images/teaser1.png">
                <h6 class="primary--text text-h6">{{ $t('comingSoon') }}</h6>
                <h4 class="mb-6  text-h4 primary--text">{{ $t('dashboard') }}</h4>
              </v-img>
              <p>Unser Dashboard bietet eine umfassende Übersicht über verschiedene Metriken, die die Leistung Ihrer Berater widerspiegeln. Sie erhalten nicht nur Einblicke in die Anzahl der Seitenaufrufe, sondern auch in die Gesamtbewertungen, detaillierte Bewertungskategorien und vieles mehr. Dies ermöglicht es Ihnen, objektive und fundierte Entscheidungen zu treffen, um die besten Beratungsdienste für Ihr Unternehmen sicherzustellen.</p>
              <p>Egal, ob Sie Ihr Beraterteam optimieren, die Effektivität Ihrer Vertriebsstrategien steigern oder einfach nur sicherstellen möchten, dass Ihre Kunden die bestmögliche Beratung erhalten – unser Dashboard gibt Ihnen das Werkzeug an die Hand, um diese Ziele zu erreichen.</p>
            </v-container>
          </full-width-section>
        </v-tab-item>

        <v-tab-item eager
                    value="organisation">
          <full-width-section color="transparent">
            <v-container>
              <v-img class="align-end"
                     gradient="to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)"
                     src="/images/teaser2.png">
                <h6 class="primary--text text-h6">{{ $t('comingSoon') }}</h6>
                <h4 class="mb-6  text-h4 primary--text">{{ $t('organisation') }}</h4>
              </v-img>
              <p>Uns ist bewusst, wie wichtig eine klare Hierarchie und Struktur für den reibungslosen Ablauf Ihres Beratungsgeschäfts ist. Daher haben wir es für Sie einfacher gemacht als je zuvor, individuelle Vertriebseinheiten und Standorte zu erstellen und zu verwalten. Sie können Ihre Teams auf einzigartige Weise organisieren und somit die Effektivität und Effizienz Ihrer Beratungsaktivitäten maximieren.</p>
            </v-container>
          </full-width-section>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import CompanyDetailsCard from '@/components/v2/account/CompanyDetailsCard.vue'
  import CompanyIntroductionCard from '@/components/v2/account/CompanyIntroductionCard.vue'
  import CompanyMediaCard from '@/components/v2/account/CompanyMediaCard.vue'
  import CompanySocialDetailsCard from '@/components/v2/account/CompanySocialDetailsCard.vue'
  import ConsultantGrid from '@/components/v2/base/ConsultantGrid.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  import HeaderButton from '@/components/common/HeaderButton.vue'
import { isShallow } from 'vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      await this.get()

      next()
    },
    components: {
      CompanyDetailsCard,
      CompanyIntroductionCard,
      CompanyMediaCard,
      CompanySocialDetailsCard,
      ConsultantGrid,
      FullWidthSection,
      TopHeader,

      HeaderButton
    },
    computed: {
      isAdministrator() {
        return true
      },
      filteredConsultants() {
        return this.consultants.filter(c => c.companyId == null)
      },
      name() {
        return this.company?.name
      },
      ...mapGetters({
        email: 'auth/email',
        consultants: 'base/consultants',
        getCompany: 'base/company'
      })
    },
    async created() {
      await this.get()
    },
    data: () => ({
      company: null,
      confirmationSnackbar: false,
      loading: false,
      newConsultantId: null,
      tab: null,
      validationSnackbar: false
    }),
    methods: {
      async get() {
        this.loading = true

        await this.loadConsultants()
        await this.loadConsultingTopics()

        const consultant = this.consultants.find(c => c.email === this.email)

        if (consultant == null) {
          this.$router.push({ name: 'account-create' })
        }

        await this.loadCompany({ id: consultant.companyId })

        this.company = Object.assign({}, this.getCompany(consultant.companyId))

        this.loading = false
      },
      async linkConsultant(id, linkState) {
        await this.doLinkCompanyConsultant({ id: this.company.id, consultantId: id, companyLinkState: linkState })
      },
      async unlinkConsultant(id) {
        await this.doUnlinkCompanyConsultant({ id: this.company.id, consultantId: id })
      },
      async updateCompany() {
        if (!this.$refs.companyDetailsCard.validate() || !this.$refs.companyIntroductionCard.validate() || !this.$refs.companyMediaCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateCompany({ company: this.company })

        this.loading = false

        this.confirmationSnackbar = true
      },
      ...mapActions({
        doUpdateCompany: 'base/updateCompany',
        loadCompany: 'base/loadCompany',
        loadConsultingTopics: 'base/loadConsultingTopics',
        loadConsultants: 'base/loadConsultants',
        doLinkCompanyConsultant: 'base/linkCompanyConsultant',
        doUnlinkCompanyConsultant: 'base/unlinkCompanyConsultant'
      })
    },
    name: 'AccountCompanyView'
  }
</script>

<style scoped>
  .v-btn.v-btn--add {
    min-height: 48px;
  }
</style>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "comingSoon": "Bald verfügbar",
      "company": "Unternehmen",
      "consultants": "Berater",
      "currentConsultants": "Aktuelle Berater",
      "dashboard": "Dashboard",
      "details": "Details",
      "inviteConsultants": "Berater einladen",
      "inviteConsultantsToYourCompanyProfileThisWayConsultantsCanShowThatTheyWorkForYourCompanyLikewiseTheyWillBeIncludedInTheCompanysListOfConsultants": "Laden Sie Berater zu Ihrem Unternehmensprofil ein. So können Berater zeigen, dass sie für Ihr Unternehmen tätig sind. Ebenso werden diese dann in der Liste der Berater des Unternehmens aufgeführt.",
      "newConsultant": "Neuer Berater",
      "organisation": "Struktur",
      "preview": "Vorschau",
      "save": "Speichern",
      "saved": "Gespeichert",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "add": "Add",
      "comingSoon": "Coming soon",
      "company": "Company",
      "consultants": "Consultant",
      "currentConsultants": "Current Consultants",
      "dashboard": "Dashboard",
      "details": "Details",
      "inviteConsultants": "Invite Consultants",
      "inviteConsultantsToYourCompanyProfileThisWayConsultantsCanShowThatTheyWorkForYourCompanyLikewiseTheyWillBeIncludedInTheCompanysListOfConsultants": "Invite consultants to your company profile. This way, consultants can show that they work for your company. Likewise, they will be included in the company's list of consultants.",
      "organisation": "Organisation",
      "preview": "Preview",
      "newConsultant": "New Consultant",
      "save": "Save",
      "saved": "Saved",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>