<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('links') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p class="mb-0">{{ $t('useYourPersonalRatingLinksToGiveYourCustomersTheOpportunityToRateYouDirectlyTheGeneralLinkCanBeUsedForYourSignatureWebsiteOrPromotionalMaterialsReviewsThatComeInViaThisLinkHaveTheSameBindingNatureAsThoseRecordedDirectlyViaTheConfederiaPlatformTheVerifiedLinkCanBePassedOnDirectlyToExistingCustomersReviewsCreatedViaThisLinkAreParticularlyHighlighted') }}</p>
      </v-card-text>
      <v-divider />
      <v-subheader>{{ $t('commonLink') }}</v-subheader>
      <v-card-text>
        <p>
          <code>https://beta.confederia.ch/consultants/{{ this.consultant.id }}/questionnaire</code>
        </p>
        <p class="mb-0">
          <img ref="qrCodeCommon" class="qrcode" /><br>
          <a v-bind:href="qrCodeCommon" download>Download</a>
        </p>
      </v-card-text>
      <v-divider />
      <v-subheader>{{ $t('linkForVerifiedEvaluations') }}</v-subheader>
      <v-card-text>
        <p>
          <code>https://beta.confederia.ch/consultants/{{ this.consultant.id }}/questionnaire?code={{ code }}</code>
        </p>
        <p class="mb-0">
          <img ref="qrCodeVerifed" class="qrcode" /><br>
          <a v-bind:href="qrCodeVerified" download>Download</a>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import QRCode from 'qrcode';

  export default {
    computed: {
      code() {
        // IMPRV
        return Math.floor(1000000000 + Math.random() * 9000000000)
      }
    },
    data: () => ({
      qrCodeCommon: null,
      qrCodeVerified: null
    }),
    async mounted() {
      const options = {
        type: 'svg',
        margin: 0,
        width: 1440
      }
      const qrCodeCommon = await QRCode.toDataURL(`https://beta.confederia.ch/consultants/${this.consultant.id}/questionnaire`, options)
      const qrCodeVerified = await QRCode.toDataURL(`https://beta.confederia.ch/consultants/${this.consultant.id}/questionnaire?code=${this.code}`, options)
      this.$refs.qrCodeCommon.src = qrCodeCommon
      this.$refs.qrCodeVerifed.src = qrCodeVerified

      this.qrCodeCommon = qrCodeCommon
      this.qrCodeVerified = qrCodeVerified

    },
    name: 'AccountLinkCard',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "commonLink": "Allgemeiner Link",
      "linkForVerifiedEvaluations": "Link für verifizierte Bewertungen",
      "links": "Links",
      "useYourPersonalRatingLinksToGiveYourCustomersTheOpportunityToRateYouDirectlyTheGeneralLinkCanBeUsedForYourSignatureWebsiteOrPromotionalMaterialsReviewsThatComeInViaThisLinkHaveTheSameBindingNatureAsThoseRecordedDirectlyViaTheConfederiaPlatformTheVerifiedLinkCanBePassedOnDirectlyToExistingCustomersReviewsCreatedViaThisLinkAreParticularlyHighlighted": "Nutzen Sie Ihre persönlichen Bewertungslinks, um Ihren Kunden die Möglichkeit zu geben, Sie direkt zu bewerten. Den allgemeinen Link können Sie für Ihre Signatur, Webseite oder Werbematerialien verwenden. Bewertungen, welche über diesen Link eingehen, haben die gleiche Verbindlichkeit, wie jene, die direkt über die Confederia-Plattform erfasst werden. Der Verifizierte Link, kann an bestehende Kunden direkt weitergegeben werden. Bewertungen, welche über diesen Link erstellt werden, werden besonders hervorgehoben."
    },
    "en": {
      "commonLink": "Common link",
      "linkForVerifiedEvaluations": "Link for verified evaluations",
      "links": "Links",
      "useYourPersonalRatingLinksToGiveYourCustomersTheOpportunityToRateYouDirectlyTheGeneralLinkCanBeUsedForYourSignatureWebsiteOrPromotionalMaterialsReviewsThatComeInViaThisLinkHaveTheSameBindingNatureAsThoseRecordedDirectlyViaTheConfederiaPlatformTheVerifiedLinkCanBePassedOnDirectlyToExistingCustomersReviewsCreatedViaThisLinkAreParticularlyHighlighted": "Use your personal rating links to give your customers the opportunity to rate you directly. The general link can be used for your signature, website or promotional materials. Reviews that come in via this link have the same binding nature as those recorded directly via the Confederia platform. The verified link can be passed on directly to existing customers. Reviews created via this link are particularly highlighted."
    }
  }
</i18n>

<style scoped>
  .qrcode {
    width: 200px;
    height: 200px;
  }
  </style>