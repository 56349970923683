<template>
  <div>
    <v-card-text>
      <h4 class="mb-6 text-h4 primary--text">{{ $t('completion') }}</h4>
      <p class="mb-0">{{ $t('weLookForwardToWelcomingYouToConfederiaTodayPleaseCompleteYourRegistrationByAgreeingToOurConditions') }}</p>
    </v-card-text>

    <account-completion-details ref="accountCompletionDetails"
                                v-bind:consultant="consultant" />
  </div>
</template>

<script>
  import AccountCompletionDetails from '@/components/v2/account/AccountCompletionDetails'

  export default {
    components: {
      AccountCompletionDetails
    },
    methods: {
      validate() {
        return this.$refs.accountCompletionDetails.validate()
      }
    },
    name: 'AccountCompletionDetailsStep',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "completion": "Abschluss",
      "weLookForwardToWelcomingYouToConfederiaTodayPleaseCompleteYourRegistrationByAgreeingToOurConditions": "Für freuen uns, Sie bald bei Confederia begrüßen zu dürfen! Bitte schließen Sie Ihre Registrierung ab, in dem Sie unseren Rahmenbedinungen zustimmen."
    },
    "en": {
      "completion": "Completion",
      "weLookForwardToWelcomingYouToConfederiaTodayPleaseCompleteYourRegistrationByAgreeingToOurConditions": "We look forward to welcoming you to Confederia today! Please complete your registration by agreeing to our conditions."
    }
  }
</i18n>