<template>
  <div>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />
      <div v-if="consultant != null && evaluation != null">
        <consultant-header v-bind:consultant="consultant" />

        <full-width-section color="secondary">
          <v-container>
            <v-row  justify="center" v-if="validEditToken">
              <v-col cols="12"
                     lg="12"
                     xl="8">
              <p class="px-7 py-3">Diese Seite ist privat! Bitte teilen Sie sie niemals mit Anderen.</p>
            </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12"
                     lg="12"
                     xl="8">
                <evaluation-card color="white"
                                 v-bind:evaluation="evaluation" />
              </v-col>
            </v-row>
            <v-row v-if="!!validEditToken && isBad" justify="center">
              <v-col  cols="12"
                     lg="12"
                     xl="8">
                <v-btn block
                        class="mt-2"
                        depressed
                        color="primary"
                        v-bind:to="{ name: 'questionnaire', params: { consultantId: evaluation.consultantId }, query: { id: evaluation.id, editToken: editToken }}"
                        x-large>
                  <v-icon color="accent"
                          left>
                    mdi-pencil
                  </v-icon>
                  <span>{{ $t('editEvaluation') }}</span>
                  <v-chip class="ml-3 primary--text" small label color="accent">{{ $t('daysLeft', { value: timeToEdit }) }}</v-chip>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </full-width-section>

        <full-width-section v-if="isOwnEvaluation">
          <v-container>
            <v-row justify="center">
              <v-col cols="12"
                     lg="12"
                     xl="8">
                <h4 class="primary--text text-h4 mb-6">{{ $t('personalData') }}</h4>
                <p><strong class="primary--text">Name</strong>: {{  evaluation.customer.givenName }} {{  evaluation.customer.familyName }}</p>
                <p><strong class="primary--text">E-Mail</strong>: {{  evaluation.customer.email }}</p>
              </v-col>
            </v-row>
          </v-container>
        </full-width-section>

        <full-width-section color="white">
          <v-container>
            <v-row justify="center">
              <v-col cols="12"
                     lg="12"
                     xl="8">
                <h4 class="primary--text text-h4">{{ $t('protocol') }}</h4>
                <h6 class="mb-6 primary--text text-h6">{{ $t('allQuestionsAndAnswers') }}</h6>
                <evaluation-question-overview-card color="secondary"
                                                   v-bind:evaluation="evaluation" />
              </v-col>
            </v-row>
          </v-container>
        </full-width-section>
      </div>
  </div>
</template>

<script>
  import moment from 'moment'

  import { mapActions, mapGetters } from 'vuex'

  import ConsultantHeader from '@/components/v2/base/ConsultantHeader.vue'
  import EvaluationCard from '@/components/v2/base/EvaluationCard.vue'
  import EvaluationQuestionOverviewCard from '@/components/v2/base/EvaluationQuestionOverviewCard.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      if (!!toquery?.editToken) {
        this.editToken = to.query.editToken
      }

      await this.get(to.params.id)

      next()
    },
    components: {
      ConsultantHeader,
      EvaluationCard,
      EvaluationQuestionOverviewCard,
      FullWidthSection,
    },
    computed: {
      isBad() {
        return this.evaluation.overallRating < 4
      },
      timeToEdit() {
        const limit = moment(this.evaluation.date).add(7, 'days')
        const now = moment()

        return limit.diff(now, 'days')
      },
      validEditToken() {
        return !!this.editToken && this.editToken === this.evaluation.editToken
      },
      ...mapGetters({
        consultingTopics: 'base/consultingTopics',
        getConsultant: 'base/consultant',
        getEvaluation: 'base/evaluation',
        email: 'auth/email',
        consultants: 'base/consultants'
      })
    },
    async created() {
      if (!!this.$route.query?.editToken) {
        this.editToken = this.$route.query.editToken
      }

      await this.get(this.$route.params.id)
    },
    data: () => ({
      consultant: null,
      evaluation: null,
      loading: false,
      isOwnEvaluation: false,
      editToken: null
    }),
    methods: {
      async get(id) {
        this.loading = true

        await this.loadEvaluation({ id })

        this.evaluation = Object.assign({}, this.getEvaluation(id))

        await this.loadConsultant({ id: this.evaluation.consultantId })

        this.consultant = Object.assign({}, this.getConsultant(this.evaluation.consultantId))

        await this.loadConsultingTopics()

        this.isOwnEvaluation = this.email === this.consultant.email

        this.loading = false
      },
      ...mapActions({
        loadConsultant: 'base/loadConsultant',
        loadConsultingTopics: 'base/loadConsultingTopics',
        loadEvaluation: 'base/loadEvaluation'
      })
    },
    name: 'EvaluationSummaryView'
  }
</script>

<i18n>
  {
    "de": {
      "allQuestionsAndAnswers": "Alle Fragen und zugehörige Antworten",
      "protocol": "Protokoll",
      "personalData": "Personalien",
      "editEvaluation": "Bewertung bearbeiten",
      "daysLeft": "noch {value} Tage"
    },
    "en": {
      "allQuestionsAndAnswers": "All questions and answers",
      "protocol": "Protocol",
      "personalData": "Personal data",
      "editEvaluation": "Edit evaluation",
      "daysLeft": "{value} days left"
    }
  }
</i18n>