<template>
  <div>
    <disable-account-dialog ref="disableAccountDialog"
                            v-model="disableAccountDialog" />
    <report-evaluation-dialog ref="reportEvaluationDialog"
                              v-model="reportEvaluationDialog" />
                              <corporate-request-dialog ref="corporateRequestDialog"
                              v-model="corporateRequestDialog" />

    <v-snackbar color="accent"
                light
                right
                top
                v-model="confirmationSnackbar">
      {{ $t('saved') }}
    </v-snackbar>

    <v-snackbar color="warning"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header color="white"
                v-bind:title="formattedName | defaultValue($t('consultant'))">
      <header-button class="mr-2"
                     icon="mdi-eye"
                     right
                     secondary
                     slot="right-button"
                     v-bind:label="$t('showProfile')"
                     v-bind:to="{ name: 'consultant', params: { id: consultant.id }}"
                     v-if="consultant != null" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateConsultant" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="showDisableDialog">
            <v-list-item-title class="deep-orange--text">{{ $t('disableAccount') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tabs background-color="transparent"
              color="primary"
              show-arrows
              slot="tabs"
              v-if="!disabled"
              v-model="tab">
        <v-tab href="#dashboard">
          {{ $t('dashboard') }}
        </v-tab>
        <v-tab href="#rating">
          {{ $t('rating') }}
        </v-tab>
        <v-tab href="#personalDetails">
          {{ $t('personalDetails') }}
          <v-icon class="ml-1"
                  color="deep-orange"
                  v-if="errorPersonalDetails">
            mdi-alert
          </v-icon>
        </v-tab>
        <v-tab href="#details">
          {{ $t('details') }}
          <v-icon class="ml-1"
                  color="deep-orange"
                  v-if="errorDetails">
            mdi-alert
          </v-icon>
        </v-tab>
        <v-tab href="#topicsAndLanguages">
          {{ $t('topicsAndLanguages') }}
          <v-icon class="ml-1"
                  color="deep-orange"
                  v-if="errorTopicsAndLanguages">
            mdi-alert
          </v-icon>
        </v-tab>
        <v-tab href="#links">
          {{ $t('links') }}
          <v-icon class="ml-1"
                  color="deep-orange"
                  v-if="errorLinks">
            mdi-alert
          </v-icon>
        </v-tab>
        <v-tab href="#subscription" v-if="hasRole('ConfederiaAdministrator')">
          {{ $t('subscription') }}
        </v-tab>
      </v-tabs>
    </top-header>
    <div v-if="consultant != null && !disabled">
      <v-tabs-items class="transparent" v-model="tab">
        <v-tab-item eager
                    value="dashboard">
          <full-width-section color="secondary" v-if="!!stats">
            <v-container v-if="hasRole('ConfederiaAdministrator')">
              <v-row>
                <v-col>
                  <v-alert type="warning">
                    <v-row>
                      <v-col>
                        Die Betaphase von Confederia endet bald. Bitte wählen Sie Ihr gewünschtes Abonnement aus, wenn Sie Confederia weiter nutzen möchten.
                      </v-col>
                      <v-col class="shrink">
                        <v-btn depressed v-on:click="tab = 'subscription'">Jetzt abschließen</v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row v-if="consultant.companyLinkState === 'requestedByConsultant'">
                <v-col>
                  <v-alert type="warning">
                    <v-row>
                      <v-col>
                        Ihre Anfrage zum Unternehmensbeitritt an {{ consultant.company.name }} steht noch aus.
                      </v-col>
                      <v-col class="shrink">
                        <v-btn small depressed v-on:click="unlinkConsultant(consultant.company.id)">Abbrechen</v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="consultant.companyLinkState === 'requestedByCompany'">
                <v-col>
                  <v-alert type="success">
                    <v-row>
                      <v-col>
                        Sie wurden von {{ consultant.company.name }} eingeleden, dem Unternehmen beizutreten.
                      </v-col>
                      <v-col class="shrink">
                        <v-btn small depressed color v-on:click="linkConsultant(consultant.company.id, 'accepted')"><v-icon left>mdi-check</v-icon> Annehmen</v-btn>
                      </v-col>
                      <v-col class="shrink">
                        <v-btn small depressed color v-on:click="unlinkConsultant(consultant.company.id)"><v-icon left>mdi-close</v-icon> Ablehnen</v-btn>
                      </v-col>
                    </v-row>

                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"
                      md="6">
                  <date-field solo
                              flat
                              hide-details="auto"
                              prepend-inner-icon="mdi-calendar-start"
                              v-bind:label="$t('start')"
                              v-bind:rules="[ rules.required ]"
                              v-model="start" />
                </v-col>
                <v-col cols="12"
                      md="6">
                  <date-field solo
                              flat
                              hide-details="auto"
                              prepend-inner-icon="mdi-calendar-end"
                              v-bind:label="$t('end')"
                              v-bind:rules="[ rules.required ]"
                              v-model="end" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <consultant-overall-rating-card v-bind:average-ratings="stats.averageRatings"
                                                  v-bind:count="stats.evaluationCount"
                                                  v-bind:rating="stats.totalRating" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <rating-matrix-card v-bind:average-ratings="stats.averageTopicRatings"
                                      v-bind:color="stats?.color" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
          <full-width-section color="transparent">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <number-card color="primary"
                               v-bind:title="$t('evaluations')"
                               v-bind:value="`${stats?.evaluationCount}`" />
                </v-col>
                <v-col cols="12" md="4">
                  <number-card color="primary"
                               v-bind:title="$t('verifiedEvalutions')"
                               v-bind:value="`${stats?.verifiedEvaluationCount}`" />
                </v-col>
                <v-col cols="12" md="4">
                  <number-card color="primary"
                               v-bind:title="$t('invitedConsultants')"
                               v-bind:value="`${stats?.invitedConsultantCount}`" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
        </v-tab-item>
        <v-tab-item eager
                    value="rating">
          <full-width-section color="secondary">
            <v-container>
              <consultant-overall-rating-card v-bind:average-ratings="consultant.averageRatings"
                                              v-bind:count="consultant.evaluationCount"
                                              v-bind:rating="consultant.overallRating" />
            </v-container>
          </full-width-section>
          <full-width-section color="transparent">
            <v-container>
              <evaluation-list editable
                               internal
                               v-bind:evaluations="evaluations"
                               v-on:publish-evaluation="publishEvaluation"
                               v-on:verify-evaluation="verifyEvaluation"
                               v-on:report-evaluation="showReportEvaluationDialog"
                               v-on:add-comment="addComment" />
            </v-container>
          </full-width-section>
        </v-tab-item>

        <v-tab-item eager
                    value="personalDetails">
          <full-width-section color="transparent">
            <v-container>
              <v-row>
                <v-col cols="12"
                       lg="6">
                  <account-media-card ref="accountMediaCard"
                                      class="mb-6"
                                      v-bind:consultant="consultant" />
                  <consultant-personal-details-card disable-email
                                                    ref="consultantPersonalDetailsCard"
                                                    v-bind:consultant="consultant" />
                </v-col>
                <v-col cols="12"
                       lg="6">
                  <account-social-details-card ref="accountSocialDetailsCard"
                                               v-bind:consultant="consultant" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
        </v-tab-item>
        <v-tab-item eager
                    value="details">
          <full-width-section color="transparent">
            <v-container>
              <v-row>
                <v-col cols="12"
                       lg="6">
                  <account-introduction-card ref="accountIntroductionCard"
                                             v-bind:consultant="consultant" />
                </v-col>
                <v-col cols="12"
                       lg="6">
                  <account-qualifications-card ref="accountQualificationsCard"
                                               v-bind:consultant="consultant" />
                </v-col>
              </v-row>
          </v-container>
          </full-width-section>
        </v-tab-item>

        <v-tab-item eager
                    value="topicsAndLanguages">
          <full-width-section color="transparent">
            <v-container>
              <v-row>
                <v-col cols="12"
                       lg="6">
                  <account-consulting-topics-card ref="accountConsultingTopicsCard"
                                                  v-bind:consultant="consultant" />
                </v-col>
                <v-col cols="12"
                       lg="6">
                  <account-languages-card ref="accountLanguagesCard"
                                          v-bind:consultant="consultant" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
        </v-tab-item>

        <v-tab-item eager
                    value="links">
          <full-width-section color="transparent">
            <v-container>
              <v-row>
                <v-col cols="12"
                       lg="6">
                  <account-link-card v-bind:consultant="consultant" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>

        </v-tab-item>

        <v-tab-item eager
                    value="subscription">
          <full-width-section color="white">
            <v-container>
              <v-row>
                <v-col cols="12"
                      md="8">
                  Sie können jederzeit Ihr Abonnement wechseln oder kündigen sowie Rechnungen einsehen und Ihre Zahlungsmethode ändern. Klicken Sie dazu einfach auf „Zahlungen verwalten“.
                </v-col>
                <v-col cols="12"
                      md="4">
                  <v-btn color="primary"
                        dark
                        depressed
                        v-on:click="openPortal"
                        x-large>
                    <v-icon color="accent"
                            left>
                      mdi-pencil
                    </v-icon>
                    Zahlungen verwalten
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
          <full-width-section color="secondary">
            <v-container>
              <v-row justify="center">
                <v-col cols="12"
                       xl="8">
                  <pricing-table-card v-on:show-dialog="showCorporateRequestDialog" internal v-on:subscribe="subscribe" />
                </v-col>
              </v-row>
            </v-container>
          </full-width-section>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div v-else-if="consultant != null && disabled">
      <full-width-section>
        <v-container>
          <text-card color="secondary"
                     v-bind:subtitle="$t('yourAccountHasBeenDisabled')"
                     v-bind:text="$t('youHaveDecidedToDeactivateYourConfederiaAccountYourProfileAsWellAsYourEvaluationsAreThereforeNoLongerPubliclyVisibleIfYouWishYouCanReactivateYourAccountAtAnyTime')"
                     v-bind:title="$t('activateAccount')">
            <div class="mt-6 text-center">
              <v-btn color="primary"
                     dark
                     depressed
                     v-on:click="activateConsultant"
                     x-large>
                <v-icon color="accent"
                        left>
                  mdi-account-check
                </v-icon>
                <span>{{ $t('activateAccount') }}</span>
              </v-btn>
            </div>
          </text-card>
        </v-container>
      </full-width-section>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import CorporateRequestDialog from '@/components/v2/base/CorporateRequestDialog.vue'


  import ReportEvaluationDialog from '@/components/v2/base/ReportEvaluationDialog.vue'
  import AccountConsultingTopicsCard from '@/components/v2/account/AccountConsultingTopicsCard.vue'
  import AccountIntroductionCard from '@/components/v2/account/AccountIntroductionCard.vue'
  import AccountLanguagesCard from '@/components/v2/account/AccountLanguagesCard.vue'
  import AccountLinkCard from '@/components/v2/account/AccountLinkCard.vue'
  import AccountMediaCard from '@/components/v2/account/AccountMediaCard.vue'
  import AccountQualificationsCard from '@/components/v2/account/AccountQualificationsCard.vue'
  import AccountSocialDetailsCard from '@/components/v2/account/AccountSocialDetailsCard.vue'
  import ConsultantPersonalDetailsCard from '@/components/v2/account/ConsultantPersonalDetailsCard.vue'
  import DisableAccountDialog from '@/components/v2/account/DisableAccountDialog.vue'
  import ConsultantOverallRatingCard from '@/components/v2/base/ConsultantOverallRatingCard.vue'
  import EvaluationList from '@/components/v2/base/EvaluationList.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import TextCard from '@/components/v2/base/TextCard.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'
  import DateField from '@/components/common/DateField.vue'
  import RatingMatrixCard from '@/components/v2/base/RatingMatrixCard.vue'
  import NumberCard from '@/components/v2/base/NumberCard.vue'
  import PricingTableCard from '@/components/v2/base/PricingTableCard.vue'


  import HeaderButton from '@/components/common/HeaderButton.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      await this.get()

      next()
    },
    components: {
      DateField,
      CorporateRequestDialog,
      ReportEvaluationDialog,
      AccountConsultingTopicsCard,
      AccountIntroductionCard,
      AccountLanguagesCard,
      AccountLinkCard,
      AccountMediaCard,
      ConsultantOverallRatingCard,
      ConsultantPersonalDetailsCard,
      DisableAccountDialog,
      AccountQualificationsCard,
      AccountSocialDetailsCard,
      EvaluationList,
      FullWidthSection,
      TextCard,
      TopHeader,
      RatingMatrixCard,
      HeaderButton,
      NumberCard,
      PricingTableCard
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      disabled() {
        return this.consultant?.state === 'disabled'
      },
      evaluations() {
        if (this.consultant == null || this.consultant.evaluations == null) {
          return []
        }

        return this.consultant.evaluations.sort((a, b) => {
          return new Date(b.dateOfCreation) - new Date(a.dateOfCreation)
        })
      },
      formattedName() {
        return this.consultant?.formattedName
      },
      ...mapGetters({
        email: 'auth/email',
        consultants: 'base/consultants',
        hasRole: 'auth/hasRole'
      })
    },
    async created() {
      if (!!document.amplitude) {
        amplitude.track('Open Dashboard');
      }
      

      await this.get()
    },
    data: () => ({
      corporateRequestDialog: false,
      stats: null,
      reportEvaluationDialog: false,
      confirmationSnackbar: false,
      consultant: null,
      disableAccountDialog: false,
      loading: false,
      tab: null,
      validationSnackbar: false,
      errorPersonalDetails: false,
      errorDetails: false,
      errorTopicsAndLanguages: false,
      errorLinks: false,
      start: '2024-01-01',
      end: '2024-12-31'
    }),
    methods: {
      async subscribe(priceId) {
        const url = await this.getCheckoutUrl({ id: this.consultant.id, priceId })

        window.document.location = url
      },
      async openPortal() {
        const url = await this.getPortalUrl({ id: this.consultant.id })

        window.document.location = url
      },
      async activateConsultant() {
        this.loading = true

        await this.doActivateConsultant({ id: this.consultant.id })

        this.consultant.state = 'active'

        this.loading = false
      },
      async loadStats() {
        const start = this.start.substring(0, 10)
        const end = this.end.substring(0, 10)
        this.stats = await this.loadConsultantStats({ id: this.consultant.id, start, end })
      },
      async get() {
        this.loading = true

        await this.loadConsultingTopics()
        await this.loadConsultants()

        const consultant = this.consultants.find(consultant => consultant.email === this.email && (consultant.state === 'active' || consultant.state === 'disabled'))

        if (consultant == null) {
          this.$router.push({ name: 'account-create' })
        }

        this.consultant = Object.assign({}, consultant)

        this.loadStats()

        this.loading = false
      },
      async updateConsultant() {
        this.errorPersonalDetails = false
        this.errorDetails = false
        this.errorTopicsAndLanguages = false
        this.errorLinks = false

        if (!this.$refs.accountMediaCard.validate() || !this.$refs.consultantPersonalDetailsCard.validate() || !this.$refs.accountSocialDetailsCard.validate()) {
          this.validationSnackbar = true
          this.errorPersonalDetails = true
          this.tab = 'personalDetails'
        }

        if (!this.$refs.accountIntroductionCard.validate() || !this.$refs.accountQualificationsCard.validate()) {
          this.validationSnackbar = true
          this.errorDetails = true
          this.tab = 'details'
        }

        if (!this.$refs.accountConsultingTopicsCard.validate() || !this.$refs.accountLanguagesCard.validate()) {
          this.validationSnackbar = true
          this.errorTopicsAndLanguages = true
          this.tab = 'topicsAndLanguages'
        }

        this.loading = true

        await this.doUpdateConsultant({ consultant: this.consultant })

        this.loading = false

        this.confirmationSnackbar = true
      },
      async publishEvaluation(id) {
        await this.doPublishEvaluation({ consultantId: this.consultant.id, id })
        await this.get()
      },
      async showDisableDialog() {
        await this.$refs.disableAccountDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.disableConsultant({ id: this.consultant.id })

          this.consultant.state = 'disabled'

          this.loading = false
        })
      },
      async verifyEvaluation(id) {
        await this.doVerifyEvaluation({ consultantId: this.consultant.id, id })
        await this.get()
      },
      async addComment({ id, comment }) {
        await this.doAddConsultantComment({ consultantId: this.consultant.id, id, comment })
        await this.get()
      },
      async sendReportEvaluationEmail(email) {
        // IMPRV: Refactor and move to backend
        let clientSideEmail = {
          recipientEmail: 'report@confederia.ch',
          recipientName: 'Confederia',
          templateName: 'DefaultCallToAction',
          values: {
            callToActionHeadline: 'Gemeldete Bewertung',
            callToActionText: 'Prüfen Sie die Bewertung.',
            callToActionLabel: 'Jetzt aufrufen',
            callToActionUrl: `https://beta.confederia.ch/evaluation/${email.id}`,
            subject: `Meldung einer Bewertung auf Condederia`,
            headline: `Meldung einer Bewertung`,
            text: `Hallo,<br><br>ein Berater hat eine erhaltene Bewertung gemeldet!<br>E-Mail-Adresse des Beraters: ${this.email}<br>Grund: ${email.reason}<br>Kommentar (wenn vorhanden): ${email.comment}`,
            closingText: 'Diese Nachricht wurde automatisch an Sie versandt. Bitte antworten Sie nicht auf diese E-Mail.<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      async unlinkConsultant(id) {
        await this.doUnlinkCompanyConsultant({ id, consultantId: this.consultant.id })


        await this.get()
      },
      async linkConsultant(id, linkState) {
        await this.doLinkCompanyConsultant({ id, consultantId: this.consultant.id, companyLinkState: linkState })



        await this.get()
      },
      async showReportEvaluationDialog(id) {
        await this.$refs.reportEvaluationDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          r.id = id

          await this.sendReportEvaluationEmail(r)

          this.loading = false
        })
      },
      async showCorporateRequestDialog() {
        await this.$refs.corporateRequestDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.sendExtendedEmail(r)

          this.loading = false
        })
      },
      ...mapActions({
        createEmail: 'base/createEmail',
        doActivateConsultant: 'base/activateConsultant',
        disableConsultant: 'base/disableConsultant',
        doUpdateConsultant: 'base/updateConsultant',
        doPublishEvaluation: 'base/publishEvaluation',
        doVerifyEvaluation: 'base/verifyEvaluation',
        doAddConsultantComment: 'base/addConsultantComment',
        loadConsultants: 'base/loadConsultants',
        loadConsultingTopics: 'base/loadConsultingTopics',
        loadConsultantStats: 'base/loadConsultantStats',
        loadConsultants: 'base/loadConsultants',
        doLinkCompanyConsultant: 'base/linkCompanyConsultant',
        doUnlinkCompanyConsultant: 'base/unlinkCompanyConsultant',
        getPortalUrl: 'base/getPortalUrl',
        getCheckoutUrl: 'base/getCheckoutUrl'
      })
    },
    name: 'AccountProfileView',
    watch: {
      start() {
        this.loadStats()
      },
      end() {
        this.loadStats()
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "evaluations": "Bewertungen",
      "verifiedEvalutions": "Verifizierte Bewertungen",
      "start": "Von",
      "end": "Bin",
      "dashboard": "Dashboard",
      "activateAccount": "Konto aktivieren",
      "consultant": "Berater",
      "details": "Details",
      "disableAccount": "Konto deaktivieren",
      "personalDetails": "Personalien",
      "rating": "Bewertung",
      "showProfile": "Zum Profil",
      "save": "Speichern",
      "saved": "Gespeichert",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern.",
      "topicsAndLanguages": "Bereiche & Sprachen",
      "links": "Links",
      "youHaveDecidedToDeactivateYourConfederiaAccountYourProfileAsWellAsYourEvaluationsAreThereforeNoLongerPubliclyVisibleIfYouWishYouCanReactivateYourAccountAtAnyTime": "Sie haben sich dazu entschlossen, Ihr Confederia-Konte zu deaktivieren. Ihr Profil sowie Ihre Bewertungen sind somit nicht mehr öffentlich sichtbar. Wenn Sie möchten, können Sie Ihr Konto jederzeit wieder aktivieren.",
      "yourAccountHasBeenDisabled": "Ihr Konto wurde deaktiviert.",
      "invitedConsultants": "Eingeladene Berater",
      "subscription": "Abo"
    },
    "en": {
      "evaluations": "Evaluations",
      "verifiedEvalutions": "Verified evaluations",
      "start": "From",
      "end": "To",
      "dashboard": "Dashboard",
      "activateAccount": "Activate account",
      "consultant": "Consultant",
      "details": "Details",
      "disableAccount": "Disable account",
      "personalDetails": "Personal details",
      "rating": "Rating",
      "showProfile": "Show profile",
      "save": "Save",
      "saved": "Saved",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention.",
      "topicsAndLanguages": "Topics and languages",
      "links": "Links",
      "youHaveDecidedToDeactivateYourConfederiaAccountYourProfileAsWellAsYourEvaluationsAreThereforeNoLongerPubliclyVisibleIfYouWishYouCanReactivateYourAccountAtAnyTime": "You have decided to deactivate your Confederia account. Your profile as well as your evaluations are therefore no longer publicly visible. If you wish, you can reactivate your account at any time.",
      "yourAccountHasBeenDisabled": "Your account has been disabled.",
      "invitedConsultants": "Invited consultants",
      "subscription": "Subscription"
    }
  }
</i18n>