<template>
  <top-header white>
    <v-row justify="center">
      <v-col cols="12" xl="8">

    <v-row align="center"
           class="mb-6"
           justify="center">
      <v-col cols="auto">
        <v-avatar size="180">
          <v-img v-bind:src="consultant.photoAttachment | attachmentUrl({ defaultUrl: '/images/profile-none.jpg'} )" />
        </v-avatar>
      </v-col>
      <v-col v-bind:class="!$vuetify.breakpoint.mdAndUp ? 'text-center' : null"
             v-bind:cols="!$vuetify.breakpoint.mdAndUp ? 12 : null">
        <h3 class="mb-2 primary--text text-h3">{{ consultant.formattedName }}</h3>
        <language-display class="mb-4"
                          v-bind:languages="consultant.languages" />
        <p>
          {{ consultant.streetName }} {{ consultant.streetNumber }}<br>
          {{ consultant.postalCode }} {{ consultant.city }}
        </p>
        <p class="grey--text text--darken-2">
          <span v-for="tag in consultant.tags"
                v-bind:key="tag">
            #{{ tag }}
          </span>
        </p>
        <!--<v-chip class="primary--text"
                color="secondary"
                label
                small>
          {{ $t('away', { distance: '10 km' }) }}
        </v-chip>-->
      </v-col>
      <v-col cols="12"
             md="auto">
        <a class="no-underline" v-on:click="scrollTo('evaluations')">
          <rating class="mb-6"
                v-bind:rating="consultant.overallRating"
                v-bind:count="consultant.evaluationCount"
                v-bind:unclaimed="consultant.state === 'unclaimed'" />
        </a>
        <v-btn block
               color="primary"
               dark
               depressed
               v-bind:to="{ name: 'questionnaire', params: { consultantId: consultant.id } }">
          <v-icon color="accent"
                  left>
            mdi-star-half-full
          </v-icon>
          {{ $t('rateNow') }}
        </v-btn>
        <v-btn block
               class="mt-2"
               color="primary"
               dark
               outlined
               v-if="!isAuthenticated && consultant.state === 'unclaimed'"
               v-bind:to="{ name: 'account-create', query: { consultantId: consultant.id} }">
          <v-icon color="accent"
                  left>
            mdi-card-account-details-outline
          </v-icon>
          {{ $t('thisIsMe') }}
        </v-btn>
        <v-btn block
               class="mt-2"
               color="primary"
               dark
               depressed
               v-if="!!consultant.calensoToken && !!consultant.calensoWorkerId"
               v-on:click="$emit('make-appointment')">
          <v-icon color="accent"
                  left>
            mdi-calendar
          </v-icon>
          {{ $t('makeAppointment') }}
        </v-btn>
        <v-btn block
               class="mt-2"
               color="primary"
               dark
               outlined
               v-if="allowSharing"
               v-on:click="$emit('share')">
          <v-icon color="accent"
                  left>
            mdi-share
          </v-icon>
          {{ $t('share') }}
        </v-btn>
      </v-col>
    </v-row>

  </v-col>

</v-row>
    <template slot="tabs">
      <slot name="tabs"></slot>
    </template>
  </top-header>
</template>

<script>
  import { mapGetters } from 'vuex'

  import LanguageDisplay from '@/components/v2/base/LanguageDisplay.vue'
  import Rating from '@/components/v2/base/Rating.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      LanguageDisplay,
      Rating,
      TopHeader
    },
    computed: {
      ...mapGetters({
         isAuthenticated: 'auth/isAuthenticated',
      })
    },
    methods: {
      scrollTo(name) {
        this.$vuetify.goTo(`#${name}`), {
        }
      },
    },
    name: 'ConsultantHeader',
    props: {
      allowSharing: Boolean,
      consultant: Object
    }
  }
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}
</style>

<i18n>
  {
    "de": {
      "away": "{distance} entfernt",
      "makeAppointment": "Termin vereinbaren",
      "rateNow": "Jetzt bewerten",
      "share": "Teilen",
      "thisIsMe": "Das bin ich"
    },
    "en": {
      "away": "{distance} away",
      "makeAppointment": "Make appointment",
      "rateNow": "Rate now",
      "share": "Share",
      "thisIsMe": "This is me"
    }
  }
</i18n>