import CompaniesView from '@/views/v2/administration/CompaniesView.vue'
import CompanyAddView from '@/views/v2/administration/CompanyAddView.vue'
import CompanyView from '@/views/v2/administration/CompanyView.vue'
import ConsultantAddView from '@/views/v2/administration/ConsultantAddView.vue'
import ConsultantsView from '@/views/v2/administration/ConsultantsView.vue'
import ConsultantView from '@/views/v2/administration/ConsultantView.vue'

import AdministrationNavigationView from '@/views/v2/administration/navigation/AdministrationNavigationView.vue'




import Dashboard from '@/views/administration/Dashboard.vue'
import DashboardConsultant from '@/views/administration/DashboardConsultant.vue'
import DashboardTeam from '@/views/administration/DashboardTeam.vue'
import SalesAgent from '@/views/administration/SalesAgent.vue'
import SalesAgentAdd from '@/views/administration/SalesAgentAdd.vue'
import SalesAgents from '@/views/administration/SalesAgents.vue'

export default [
  {
    components: {
      default: CompaniesView,
      navigation: AdministrationNavigationView
    },
    meta: {
      alwaysShowMenu: true,
      auth: ({ hasPermission, isAuthenticated }) => isAuthenticated && hasPermission('read:companies')
    },
    name: 'administration-companies',
    path: '/administration/companies'
  },
  {
    components: {
      default: CompanyAddView,
      navigation: AdministrationNavigationView
    },
    meta: {
      alwaysShowMenu: true,
      auth: ({ hasPermission, isAuthenticated }) => isAuthenticated && hasPermission('write:companies')
    },
    name: 'administration-company-add',
    path: '/administration/companies/add'
  },
  {
    components: {
      default: CompanyView,
      navigation: AdministrationNavigationView
    },
    meta: {
      alwaysShowMenu: true,
      auth: ({ hasPermission, isAuthenticated }) => isAuthenticated && hasPermission('write:companies')
    },
    name: 'administration-company',
    path: '/administration/companies/:id'
  },

  {
    components: {
      default: ConsultantsView,
      navigation: AdministrationNavigationView
    },
    meta: {
      alwaysShowMenu: true,
      auth: ({ hasPermission, isAuthenticated }) => isAuthenticated && hasPermission('read:consultants')
    },
    name: 'administration-consultants',
    path: '/administration/consultants'
  },
  {
    components: {
      default: ConsultantAddView,
      navigation: AdministrationNavigationView
    },
    meta: {
      alwaysShowMenu: true,
      auth: ({ hasPermission, isAuthenticated }) => isAuthenticated && hasPermission('write:consultants')
    },
    name: 'administration-consultant-add',
    path: '/administration/consultants/add'
  },
  {
    components: {
      default: ConsultantView,
      navigation: AdministrationNavigationView
    },
    meta: {
      alwaysShowMenu: true,
      auth: ({ hasPermission, isAuthenticated }) => isAuthenticated && hasPermission('write:consultants')
    },
    name: 'administration-consultant',
    path: '/administration/consultants/:id'
  },




  {
    components: {
      default: Dashboard,
      navigation: AdministrationNavigationView
    },
    meta: {
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('AuraCrmAdministrator'),
      alwaysShowMenu: true
    },
    name: 'administration-dashboard',
    path: '/administration'
  },
  {
    components: {
      default: DashboardConsultant,
      navigation: AdministrationNavigationView
    },
    meta: {
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('AuraCrmAdministrator'),
      alwaysShowMenu: true
    },
    name: 'administration-dashboard-consultant',
    path: '/administration/dashboard/consultant'
  },
  {
    components: {
      default: DashboardTeam,
      navigation: AdministrationNavigationView
    },
    meta: {
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('AuraCrmAdministrator'),
      alwaysShowMenu: true
    },
    name: 'administration-dashboard-team',
    path: '/administration/dashboard/team'
  },










  // sales agents
  // ============
  {
    components: {
      default: SalesAgentAdd,
      navigation: AdministrationNavigationView
    },
    meta: {
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('AuraCrmAdministrator'),
      alwaysShowMenu: true
    },
    name: 'administration-sales-agent-add',
    path: '/administration/salesAgents/add'
  },
  {
    components: {
      default: SalesAgent,
      navigation: AdministrationNavigationView
    },
    meta: {
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('AuraCrmAdministrator'),
      alwaysShowMenu: true
    },
    name: 'administration-sales-agent',
    path: '/administration/salesAgent/:id'
  },
  {
    components: {
      default: SalesAgents,
      navigation: AdministrationNavigationView
    },
    meta: {
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('AuraCrmAdministrator'),
      alwaysShowMenu: true
    },
    name: 'administration-sales-agents',
    path: '/administration/salesAgents'
  },
]