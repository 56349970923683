<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('state') }}</v-toolbar-title>
      </v-toolbar>
      <consultant-state ref="consultantState"
                        v-bind:consultant="consultant" />
    </v-card>
  </div>
</template>

<script>
  import ConsultantState from '@/components/v2/administration/ConsultantState'

  export default {
    components: {
      ConsultantState
    },
    methods: {
      validate() {
        return this.$refs.consultantState.validate()
      }
    },
    name: 'ConsultantStateCard',
    props: {
      consultant: Object,
      disableEmail: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "state": "Status"
    },
    "en": {
      "state": "State"
    }
  }
</i18n>