<template>
   <v-card class="fill-height"
           flat
           v-bind:color="color"
           v-bind:to="{ name: 'company', params: { id: company.id }}">
    <v-card-text class="pa-6 text-center">
      <v-row justify="center">
        <v-col cols="auto">
          <v-img class="mb-6"
                 contain
                 height="128"
                 position="center"
                 width="256"
                 v-bind:src="company.logoAttachment | attachmentUrl({ defaultUrl: '/images/profile-none.jpg'} )" />
        </v-col>
      </v-row>
      <h5 class="primary--text text-h5">{{ company.name }}</h5>
      <p>
        {{ company.streetName }} {{ company.streetNumber }}<br>
        {{ company.postalCode }} {{ company.city }}
      </p>

      <!--<rating v-bind:rating="company.overallRating"
              v-bind:count="company.evaluationCount" />-->
    </v-card-text>
  </v-card>
</template>

<script>
  import Rating from '@/components/v2/base/Rating.vue'

  export default {
    components: {
      Rating
    },
    name: 'CompanyCard',
    props: {
      color: {
        default: 'white',
        type: String
      },
      company: Object
    }
  }
</script>