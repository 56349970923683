<template>
  <div v-bind:class="color">
    <div v-bind:class="$vuetify.breakpoint.mdAndUp ? 'pt-16' : 'pt-4'">
      <v-container class="pb-8">
        <v-row>
          <v-col cols="auto"
                 v-if="!!this.$slots['left-button']">
            <slot name="left-button" />
          </v-col>
          <v-spacer />
          <v-col cols="auto"
                 v-if="!!this.$slots['right-button']">
            <slot name="right-button" />
          </v-col>
          <v-col cols="auto"
                 v-if="!!this.$slots['menu']">
            <slot name="menu" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <slot>
              <h2 class="primary--text text-h3" v-html="title"></h2>
            </slot>
            <slot name="body" />
          </v-col>
        </v-row>
      </v-container>
      <div v-if="!!this.$slots['tabs']">
        <v-container class="py-0">
          <slot name="tabs" />
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TopHeader',
    props: {
      color: {
        default: 'white',
        type: String
      },
      title: String
    }
  }
</script>