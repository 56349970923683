<template>
  <div>
    <v-card color="secondary"
            outlined>
      <v-card-text>
        <h1 class="text-h5 primary--text text--darken-4">{{ title }}</h1>
        <h2 class="display-1 primary--text text--darken-4">{{ value }}</h2>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>


  export default {
    components: {

    },
    name: 'DashboardTextCard',
    props: {
      title: String,
      value: String
    }
  }
</script>