<template>
  <div>
    <div>
      <v-tooltip bottom
                 v-bind:key="language"
                 v-for="language in sortedLanguages">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar class="mr-2"
                    size="32"
                    tile
                    v-bind="attrs"
                    v-on="on">
            <v-img v-bind:src="`/images/v2/flags/${ language }.png`" />
          </v-avatar>
        </template>
        <span>{{ $t(getLocale(language).label) }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
  import locales from '@/locales.json'

  export default {
    computed: {
      locales() {
        return locales
      },
      sortedLanguages() {
        if (this.languages == null) {
          return []
        }

        return this.languages.sort((a, b) => {
          const locales = this.locales.map(l => l.id)

          return locales.indexOf(a) - locales.indexOf(b)
        })
      }
    },
    methods: {
      getLocale(locale) {
        return this.locales.find(l => l.id === locale)
      }
    },
    name: 'LanguageDisplay',
    props: {
      languages: Array
    }
  }
</script>