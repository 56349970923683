<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('companyDetails') }}</v-toolbar-title>
      </v-toolbar>
      <consultant-company-details ref="consultantCompanyDetails"
                                  v-bind:consultant="consultant"/>
    </v-card>
  </div>
</template>

<script>
  import ConsultantCompanyDetails from '@/components/v2/administration/ConsultantCompanyDetails'

  export default {
    components: {
      ConsultantCompanyDetails
    },
    methods: {
      validate() {
        return this.$refs.consultantCompanyDetails.validate()
      }
    },
    name: 'ConsultantCompanyDetailsCard',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "companyDetails": "Unternehmensdaten"
    },
    "en": {
      "companyDetails": "Company details"
    }
  }
</i18n>