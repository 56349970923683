<template>
  <div>
    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <top-header color="white"
                v-bind:title="$t('welcomeToConfederia')">
      <template slot="body">
        <div class="mb-12 mt-2 text-body-1">
          {{ $t('weAreDelightedThatYouWantToJoinUsOnOurWayToFinancialSatisfactionForSwissResidents') }}
        </div>
      </template>
    </top-header>
    <full-width-section color="secondary">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 lg="12"
                 xl="8">
            <account-create-stepper v-bind:consultant.sync="consultant"
                                    v-bind:loading="loading"
                                    v-on:submit="submit" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import AccountCreateStepper from '@/components/v2/account/AccountCreateStepper'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import TopHeader from '@/components/v2/common/TopHeader.vue'

  export default {
    components: {
      AccountCreateStepper,
      FullWidthSection,
      TopHeader
    },
    computed: {
      ...mapGetters({
        consultants: 'base/consultants',
        email: 'auth/email'
      })
    },
    async created() {
      this.loading = true

      if (!!document.amplitude) {
        amplitude.track('Register Account');
      }
     

      await this.loadConsultingTopics()
      await this.loadConsultants()

      const consultant = this.consultants.find(consultant => consultant.email === this.email && consultant.state === 'active')

      if (consultant != null) {
        this.$router.push({ name: 'account-profile' })
      }

      this.consultant = {
        email: this.email
      }

      this.loading = false
    },
    data: () => ({
      consultant: {
        state: 'active'
      },
      loading: false
    }),
    methods: {
      async submit() {
        this.loading = true

        if (this.consultant.id == null) {
          this.consultant.state = 'active'

          await this.createConsultant({ consultant: this.consultant })
        } else if (this.consultant.email === this.email) {
          this.consultant.state = 'active'

          await this.updateConsultant({ consultant: this.consultant })
        }

        this.loading = false

        this.$router.push({ name: 'account-profile' })
      },
      ...mapActions({
        createConsultant: 'base/createConsultant',
        updateConsultant: 'base/updateConsultant',
        loadConsultants: 'base/loadConsultants',
        loadConsultingTopics: 'base/loadConsultingTopics'
      })
    },
    name: 'AccountCreate'
  }
</script>

<i18n>
  {
    "de": {
      "weAreDelightedThatYouWantToJoinUsOnOurWayToFinancialSatisfactionForSwissResidents": "Wir freuen uns, dass Sie mit uns den Weg zur finanziellen Zufriedenheit der Schweizer Einwohner gehen wollen.",
      "welcomeToConfederia": "Herzlich willkommen bei Confederia"

    },
    "en": {
      "weAreDelightedThatYouWantToJoinUsOnOurWayToFinancialSatisfactionForSwissResidents": "We are delighted that you want to join us on our way to financial satisfaction for Swiss residents.",
      "welcomeToConfederia": "Welcome to Confederia"

    }
  }
</i18n>