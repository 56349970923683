<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('qualifications') }}</v-toolbar-title>
      </v-toolbar>
      <account-qualifications ref="accountQualifications"
                              v-bind:consultant="consultant" />
    </v-card>
  </div>
</template>

<script>
  import AccountQualifications from '@/components/v2/account/AccountQualifications'

  export default {
    components: {
      AccountQualifications
    },
    name: 'AccountQualificationsCard',
    methods: {
      validate() {
        return this.$refs.accountQualifications.validate()
      }
    },
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "qualifications": "Qualifikationen"
    },
    "en": {
      "qualifications": "Qualifications"
    }
  }
</i18n>