export default class EvaluationService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(evaluation, options) {
    const response = await fetch(`${this.baseUri}/evaluation`, {
      body: JSON.stringify(evaluation),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUri}/evaluation/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    const response = await fetch(`${this.baseUri}/evaluation/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/evaluation`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(id, evaluation, options) {
    const response = await fetch(`${this.baseUri}/evaluation/${id}`, {
      body: JSON.stringify(evaluation),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async publish(id, options) {
    const response = await fetch(`${this.baseUri}/evaluation/${id}/publish`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async verify(id, options) {
    const response = await fetch(`${this.baseUri}/evaluation/${id}/verify`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async addConsultantComment(id, comment, options) {
    const response = await fetch(`${this.baseUri}/evaluation/${id}/comment`, {
      body: JSON.stringify({ comment: comment }),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}