<template>
  <v-card-text class="pa-6">
    <p class="text-h6 text-center">{{ questionDefinition.text }}</p>
    <p class="text-body-1 text-center">{{ questionDefinition.description }}</p>
    <div class="text-center">
      <v-rating background-color="secondary darken-1"
                clearable
                color="primary"
                large
                v-model="model[questionDefinition.id]"
                v-on:input="next" />
      <v-expand-transition>
        <p class="error--text mb-0 mt-4"
           v-if="error">
          {{ $t('global.pleaseAnswerThisQuestion') }}
        </p>
      </v-expand-transition>
    </div>
  </v-card-text>
</template>

<script>
  export default {
    computed: {
      value() {
        return this.model[this.questionDefinition.id]
      }
    },
    data: () => ({
      error: false
    }),
    methods: {
      next() {
        this.error = false

        this.$emit('next')
      },
      validate() {
        if (!this.questionDefinition.required) {
          return true
        }

        if (this.value == null) {
          this.error = true

          return false
        }

        return true
      }
    },
    name: 'RatingQuestion',
    props: {
      model: Object,
      questionDefinition: Object
    }
  }
</script>