export default class ConsultantService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(consultant, options) {
    const response = await fetch(`${this.baseUri}/consultant`, {
      body: JSON.stringify(consultant),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUri}/consultant/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    const response = await fetch(`${this.baseUri}/consultant/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getPortalUrl(id, options) {
    const response = await fetch(`${this.baseUri}/consultant/${id}/portal`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.text()
  }

  async getCheckoutUrl(id, priceId, options) {
    const response = await fetch(`${this.baseUri}/consultant/${id}/checkout/${priceId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.text()
  }

  async getStats(id, start, end, options) {
    const response = await fetch(`${this.baseUri}/consultant/${id}/stats?start=${start}&end=${end}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/consultant`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getTop(options) {
    const response = await fetch(`${this.baseUri}/consultant/top`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(id, consultant, options) {
    const response = await fetch(`${this.baseUri}/consultant/${id}`, {
      body: JSON.stringify(consultant),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async activate(id, options) {
    const response = await fetch(`${this.baseUri}/consultant/${id}/activate`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async disable(id, options) {
    const response = await fetch(`${this.baseUri}/consultant/${id}/disable`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }
}