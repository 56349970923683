<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
      dark
                 flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-chip class="ml-2 primary--text"
                color="accent" >

          {{ items.length }}
        </v-chip>
        <v-spacer />
        <v-btn color="accent"
               icon
               v-bind:to="{ name: addRouteName }">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn class="ml-2"
               color="accent"
               icon
               v-bind:to="{ name: overviewRouteName }">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-text-field clearable
                        flat
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        solo
                        v-bind:label="$t('search')"
                        v-model="search" />
        </template>
      </v-toolbar>
      <v-list two-line>
        <v-list-item v-bind:key="item.id"
                     v-bind:to="{ name: routeName, params: { id: item.id } }"
                     v-for="item in filteredItems">
          <v-list-item-avatar>
            <v-icon class="secondary"
                    >
              {{ icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      filteredItems() {
        let items = [...this.items]

        if (this.search != null && this.search.length !== 0) {
          items = this.items.filter(i => i.title.toLowerCase().includes(this.search.toLowerCase()))
        }

        // IMPRV
        return items.reverse().splice(0, 3)
      }
    },
    data: () => ({
      search: null
    }),
    name: 'DashboardListCard',
    props: {
      addRouteName: String,
      icon: String,
      items: Array,
      routeName: String,
      title: String,
      overviewRouteName: String,
    }
  }
</script>

<i18n>
  {
    "de": {
      "search": "Suche"
    },
    "en": {
      "search": "Search"
    }
  }
</i18n>