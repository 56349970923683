<template>
  <div>
    <div class="header header--desktop"
         v-bind:class="`header--${variant}`"
         v-if="$vuetify.breakpoint.mdAndUp">
      <v-container class="fill-height">
        <v-row align="center"
               class="fill-height"
               no-gutters>
          <v-col cols="6"
                 v-bind:offset="left ? 0 : 6">
            <div class="pa-12"
                 v-bind:class="left ? 'pl-0' : 'pr-0'">
              <slot />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <div class="header header--mobile"
           v-bind:class="`header--${variant}`">
      </div>
      <v-container>
        <div class="py-12 text-center">
          <slot />
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ImageHeader',
    props: {
      left: Boolean,
      variant: String
    }
  }
</script>

<style scoped>
  .header {
    background-size: cover;
    background-repeat: no-repeat;
    height: 40vw;
    min-height: 240px;
  }

  .header--desktop {
    height: 40vw;
    min-height: 400px;
  }

  .header--desktop.header--customer {
    background-image: url('/images/v2/header-customer.jpg');
  }

  .header--desktop.header--consultant {
    background-image: url('/images/v2/header-consultant.jpg');
  }

  .header--desktop.header--about-us {
    background-image: url('/images/v2/header-about_us.jpg');
  }

  .header--mobile.header--customer {
    background-image: url('/images/v2/header-customer-mobile.jpg');
    background-position: center;
  }

  .header--mobile.header--consultant {
    background-image: url('/images/v2/header-consultant-mobile.jpg');
    background-position: center;
  }

  .header--mobile.header--about-us {
    background-image: url('/images/v2/header-about_us-mobile.jpg');
    background-position: center;
  }
</style>