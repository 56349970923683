<template>
  <v-card v-bind:class="fillHeight ? 'fill-height' : null"
          flat
          v-bind:color="color">
    <v-card-text class="pa-6">
      <v-row align="center">
        <v-col>
          <p class="mb-0 secondary--text text-caption text--darken-4">{{ evaluation.dateOfCreation | formatDate('L LT') }}</p>
          <h5 class="primary--text text-h5">
            {{ evaluation.customer.displayName }}
            <v-tooltip bottom
                       v-if="evaluation.isVerified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary"
                        v-bind="attrs"
                        v-on="on">
                  mdi-check-decagram
                </v-icon>
              </template>
              <span>{{ $t('verified') }}</span>
            </v-tooltip>
          </h5>
          <consulting-topic-chips v-bind:consulting-topic-ids="evaluation.consultingTopicIds" />
        </v-col>
        <v-col cols="auto"
               v-if="editable && evaluation.state === 'inReview'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip color="deep-orange"
                      dark
                      label
                      small
                      v-bind="attrs"
                      v-on="on">
              <v-icon left>
                mdi-eye-off
              </v-icon>
              {{ $t('hidden') }}
            </v-chip>
            </template>
            <span>{{ $t('willBeShownByDefault', { period: $options.filters.fromNow(releaseDate) }) }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto"
               v-if="editable">
          <v-menu slot="menu">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon
                     v-bind="attrs"
                     v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-bind:to="{ name: 'evaluation', params: { id: evaluation.id } }">
                <v-list-item-title>{{ $t('showDetails') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="evaluation.state === 'inReview'"
                           v-on:click="$emit('publish-evaluation', evaluation.id)">
                <v-list-item-title>{{ $t('publish') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="!evaluation.isVerified"
                           v-on:click="$emit('verify-evaluation', evaluation.id)">
                <v-list-item-title>{{ $t('verify') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-on:click="$emit('report-evaluation', evaluation.id)">
                <v-list-item-title class="deep-orange--text">{{ $t('report') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-tooltip bottom v-if="!!evaluation.language">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar class="mr-2"
                    size="32"
                    tile
                    v-bind="attrs"
                    v-on="on">
            <v-img v-bind:src="`/images/v2/flags/${ evaluation.language }.png`" />
          </v-avatar>
        </template>
        <span>{{ $t(getLocaleLabel(evaluation.language)) }}</span>
      </v-tooltip>
    </v-card-text>
    <v-divider />
    <v-card-text class="pa-6">
      <p class="font-weight-bold"
          v-if="!!evaluation.comment">
        <span class="secondary--text text--darken-4">„</span><span class="black--text">{{ evaluation.comment }}</span><span class="secondary--text text--darken-4">“</span>
      </p>
      <overall-rating v-bind:average-ratings="evaluation.ratings"
                      v-bind:rating="evaluation.overallRating" />
    </v-card-text>

    <template v-if="!!evaluation.consultantComment">
      <v-divider />
      <v-card-text>
        <p class="mb-0 secondary--text text-caption text--darken-4">{{ evaluation.consultantCommentDate | formatDate('L LT') }}</p>
        <h6 class="primary--text text-h6 mb-3">{{ $t('answerFrom', { value: consultantLabelShort(evaluation.consultantId) }) }}</h6>
        <p><span class="secondary--text text--darken-4">„</span>{{ evaluation.consultantComment }}<span class="secondary--text text--darken-4">“</span></p>
      </v-card-text>
    </template>
    <v-card-text v-else-if="editable">
      <v-btn text small v-on:click="showAddComment = true">
        <v-icon left>
          mdi-plus
        </v-icon>
        {{ $t('addComment') }}
      </v-btn>
      <v-expand-transition >
        <div class="text-right">
        <v-textarea class="mt-4"
                  flat
                  hide-details="always"
                  rows="5"
                  solo
                  v-bind:counter="500"
                  v-bind:label="$t('yourComment')"
                  v-bind:rules="[ rules.maxLength(500) ]"
                  v-model="newComment"
                  v-if="showAddComment" />

        <v-btn color="primary" class="mt-2"
               dark
               depressed
               large
               v-bind:disabled="!newComment"
               v-on:click="$emit('add-comment', { id: evaluation.id, comment: newComment })">
          <v-icon left>
            mdi-send
          </v-icon>
          {{ $t('send') }}
        </v-btn>
      </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import locales from '@/locales.json'

  import rules from '@/mixins/v2/rules'

  import ConsultingTopicChips from '@/components/v2/base/ConsultingTopicChips.vue'
  import OverallRating from '@/components/v2/base/OverallRating.vue'

  export default {
    components: {
      ConsultingTopicChips,
      OverallRating
    },
    computed: {
      releaseDate() {
        return moment(this.evaluation.dateOfCreation).add(14, 'days')
      },
      ...mapGetters({
        consultantLabelShort: 'base/consultantLabelShort'
      })
    },
    data: () => ({
      showAddComment: false,
      newComment: null
    }),
    mixins: [
      rules
    ],
    methods: {
      getLocaleLabel(locale) {
        return locales.find(l => l.id === locale)?.label
      }
    },
    name: 'EvaluationCard',
    props: {
      color: String,
      editable: Boolean,
      fillHeight: Boolean,
      evaluation: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "hidden": "Versteckt",
      "publish": "Veröffentlichen",
      "report": "Melden",
      "showDetails": "Details anzeigen",
      "verified": "Verifiziert",
      "verify": "Verifizieren",
      "willBeShownByDefault": "Wird {period} automatisch angezeigt",
      "addComment": "Kommentar hinzufügen",
      "yourComment": "Ihr Kommentar",
      "send": "Senden",
      "answerFrom": "Antwort von {value}"
    },
    "en": {
      "hidden": "Hidden",
      "publish": "Publish",
      "report": "Report",
      "showDetails": "Show details",
      "verified": "Verified",
      "verify": "Verify",
      "willBeShownByDefault": "Will be shown by default {period}",
      "addComment": "Add comment",
      "yourComment": "Your comment",
      "send": "Send",
      "answerFrom": "Answer from {value}"
    }
  }
</i18n>