<template>
  <v-form ref="form">
    <v-textarea flat
                hide-details="auto"
                rows="10"
                solo
                v-bind:counter="1000"
                v-bind:label="$t('pleaseWriteSomethingAboutYou')"
                v-bind:rules="[ rules.maxLength(1000) ]"
                v-model="consultant.introduction" />
    <v-card-text>
      <v-row>
        <v-col>
          <v-combobox hide-details="auto"
                        outlined
                        v-bind:label="$t('tags')"
                        v-model="consultant.tags"
                        v-bind:placeholder="$t('eGfinanceConsulting')"
                        multiple
                        chips
                        v-on:input="updateTags">
            <template v-slot:selection="data">
              <v-chip
                
                :key="JSON.stringify(data.item)"
                v-bind="data.attrs"
                :input-value="data.selected"
                :disabled="data.disabled"
                @click:close="data.parent.selectItem(data.item)"
                
              >
                
                #{{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field append-icon="mdi-youtube"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('youTubeVideo')"
                        
                        v-on:click:append="openLink(consultant.youTubeUrl)"
                        v-model="consultant.youTubeUrl" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import rules from '@/mixins/v2/rules'

  export default {
    methods: {
      updateTags() {
        this.consultant.tags = this.consultant.tags.map(tag => tag.replaceAll('#', ''))

        this.consultant.tags = [...new Set(this.consultant.tags)]
      },
      openLink(href) {
        if (href) {
          window.open(href, '_blank')
        }
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    mixins: [ rules ],
    name: 'AccountIntroduction',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "pleaseWriteSomethingAboutYou": "Bitte stellen Sie sich vor. Gehen Sie hier vor allem auf Ihre Beratungsbereiche im Detail ein.",
      "youTubeVideo": "Vorstellungsvideo (YouTube-Link)",
      "tags": "Schlagworte",
      "eGfinanceConsulting": "z. B. #finanzberater"
    },
    "en": {
      "pleaseWriteSomethingAboutYou": "Please write something about you…",
      "youTubeVideo": "Introduction video (YouTube-Link)",
      "eGfinanceConsulting": "e. g. #financeConsulting",
      "tags": "Tags"
    }
  }
</i18n>