<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field append-icon="mdi-web"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('website')"
                        v-on:click:append="openLink(company.websiteUrl)"
                        v-model="company.websiteUrl" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field append-icon="mdi-linkedin"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('linkedIn')"
                        v-on:click:append="openLink(company.linkedInUrl)"
                        v-model="company.linkedInUrl" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field append-icon="mdi-twitter"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('twitter')"
                        v-on:click:append="openLink(company.twitterUrl)"
                        v-model="company.twitterUrl" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field append-icon="mdi-facebook"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('facebook')"
                        v-on:click:append="openLink(company.facebookUrl)"
                        v-model="company.facebookUrl" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field append-icon="mdi-instagram"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('instagram')"
                        v-on:click:append="openLink(company.instagramUrl)"
                        v-model="company.instagramUrl" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field append-icon="mdi-whatsapp"
                        hide-details="auto"
                        outlined
                        v-bind:label="$t('whatsapp')"
                        v-model="company.whatsAppUrl" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    methods: {
      openLink(href) {
        if (href) {
          window.open(href, '_blank')
        }
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'CompanySocialDetails',
    props: {
      company: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "website": "Website",
      "linkedIn": "LinkedIn",
      "twitter": "Twitter",
      "facebook": "Facebook",
      "instagram": "Instagram",
      "tiktok": "TikTok",
      "whatsapp": "WhatsApp"
    },
    "en": {
      "website": "Website",
      "linkedIn": "LinkedIn",
      "twitter": "Twitter",
      "facebook": "Facebook",
      "instagram": "Instagram",
      "tiktok": "TikTok",
      "whatsapp": "WhatsApp"
    }
  }
</i18n>