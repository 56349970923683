import moment from 'moment'
import Vue from 'vue'

import i18n from './plugins/i18n'

import settings from './settings'


Vue.filter('attachmentUrl', (value, { defaultUrl }) => {
  if (value == null || value.fileName == null || value.fileName.length === 0) {
    return defaultUrl ?? ''
  }

  return `${settings.staticUri}/${value.fileName}`
})

Vue.filter('defaultValue', (value, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue
  }

  return value
})

Vue.filter('defaultPropertyValue', (value, property, defaultValue) => {
  if (value == null) {
    return defaultValue
  }

  if (value[property] == null || value[property].length === 0) {
    return defaultValue
  }

  return `${value[property]}`
})

Vue.filter('formatCurrencyValue', (value, options, defaultValue) => {
  options = options ?? {}
  options.currency = options.currency ?? 'EUR'
  options.fractionDigits = options.fractionDigits ?? 2

  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  var number = Number(value)

  if (isNaN(number)) {
    return defaultValue ?? ''
  }

  return number.toLocaleString(i18n.locale, {
    currency: options.currency,
    minimumFractionDigits: options.fractionDigits,
    maximumFractionDigits: options.fractionDigits,
    style: 'currency'
  })
})

Vue.filter('formatValue', (value, options, defaultValue) => {
  options = options ?? {}
  options.fractionDigits = options.fractionDigits ?? 2
  options.suffix = options.suffix ?? ''

  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  var number = Number(value)

  if (isNaN(number)) {
    return defaultValue ?? ''
  }

  return `${number.toLocaleString(i18n.locale, {
    minimumFractionDigits: options.fractionDigits,
    maximumFractionDigits: options.fractionDigits,
    style: 'decimal'
  })} ${options.suffix}`.trim()
})

Vue.filter('formatDate', (value, format, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  return moment(value).locale(i18n.locale).format(format ?? 'LL')
})

Vue.filter('fromNow', (value, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  return moment(value).locale(i18n.locale).fromNow()
})

Vue.filter('i18nItems', (values, options) => {
  options = options ?? {}
  options.text = options.text ?? 'text'
  options.value = options.value ?? 'value'

  if (values == null) {
    return []
  }

  return values.map(v => ({
    text: v[options.text][i18n.locale],
    value: v[options.value]
  }))
})

Vue.filter('i18nLabel', (labels, options) => {
  if (labels == null) {
    return ''
  }

  return labels[i18n.locale]
})

Vue.filter('items', (values, options) => {
  options = options ?? {}
  options.text = options.text ?? 'text'
  options.value = options.value ?? 'value'

  if (values == null) {
    return []
  }

  return values.map(v => ({
    text: typeof options.text === 'function' ? options.text(v) : v[options.text],
    value: v[options.value]
  }))
})

Vue.filter('vueI18nItems', (values, options) => {
  options = options ?? {}
  options.text = options.text ?? 'text'
  options.value = options.value ?? 'value'

  if (values == null) {
    return []
  }

  return values.map(v => ({
    text: i18n.t(v[options.text]),
    value: v[options.value]
  }))
})