<template>
  <div>
    <v-card-text>
      <h4 class="mb-6 text-h4 primary--text">{{ $t('yourConsultingTopics') }}</h4>
      <p class="mb-0">{{ $t('pleaseSelectTheAreasInWhichYouProvideConsultingServicesSoThatWeCanPresentYourProfileInATargetedMannerToThoseWhoWouldLikeToUseYourConsultingServices') }}</p>
    </v-card-text>

    <v-card-text>
      <account-consulting-topics ref="accountConsultingTopics"
                                 v-bind:consultant="consultant" />
    </v-card-text>
  </div>
</template>

<script>
  import AccountConsultingTopics from '@/components/account/AccountConsultingTopics'

  export default {
    components: {
      AccountConsultingTopics
    },
    methods: {
      validate() {
        return this.$refs.accountConsultingTopics.validate()
      }
    },
    name: 'AccountConsultingTopicStep',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "pleaseSelectTheAreasInWhichYouProvideConsultingServicesSoThatWeCanPresentYourProfileInATargetedMannerToThoseWhoWouldLikeToUseYourConsultingServices": "Bitte wählen Sie die Bereiche an, in denen Sie beratend tätig sind, damit wir Ihr Profil zielgerichtet jenen Personen präsentieren können, die Ihre Beratungsleistungen in Anspruch nehmen möchten.",
      "yourConsultingTopics": "Ihre Themen"
    },
    "en": {
      "pleaseSelectTheAreasInWhichYouProvideConsultingServicesSoThatWeCanPresentYourProfileInATargetedMannerToThoseWhoWouldLikeToUseYourConsultingServices": "Please select the areas in which you provide consulting services so that we can present your profile in a targeted manner to those who would like to use your consulting services.",
      "yourConsultingTopics": "Your consulting topics"
    }
  }
</i18n>