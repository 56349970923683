<template>
  <div>
    <v-card color="secondary"
            dark
            outlined>
      <v-card-text>
        <h1 class="text-h5 primary--text text--darken-4">{{ title }}</h1>
        <h2 class="display-1 primary--text text--darken-4">{{ value }}</h2>
      </v-card-text>
      <v-sparkline color="primary"
                     height="50"
                     padding="4"
                     smooth
                     v-bind:line-width="1"
                     v-bind:value="graphValues" />
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'DashboardStatGraph',
    props: {
      graphValues: Array,
      title: String,
      value: String
    }
  }
</script>