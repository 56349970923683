<template>
  <v-card class="fill-height"
          color="white"
          flat
          v-bind:to="{ name: 'company', params: { id: company.id }}">
    <v-card-text class="pa-6">
      <v-row align="center"
             justify="center">
        <v-col>
          <h6 class="primary--text text-h6">{{ $t('employedBy') }}</h6>
          <h4 class="mb-6 primary--text text-h4">{{ company.name }}</h4>
          <p class="font-weight-bold">
            {{ company.streetName }} {{ company.streetNumber }}<br>
            {{ company.postalCode }} {{ company.city }}
          </p>
          <p class="mb-0"
             v-if="!!company.introduction">
            {{ company.introduction }}
          </p>
        </v-col>
        <v-col cols="auto">
          <v-img contain
                 height="128"
                 width="256"
                 v-bind:position="$vuetify.breakpoint.mdAndUp ? 'right center' : 'center'"
                 v-bind:src="company.logoAttachment | attachmentUrl({ defaultUrl: '/images/profile-none.jpg'} )" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'ConsultantCompanyCard',
    props: {
      company: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "employedBy": "Tätig für"
    },
    "en": {
      "employedBy": "Employed by"
    }
  }
</i18n>