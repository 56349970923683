<template>
  <div>
    <v-dialog persistent
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-card-text class="pt-4">
          <h4 class="mb-6 text-h4 primary--text">{{ $t('disableAccount') }}</h4>
          <p class="mb-0">
            {{ $t('youAreAboutToDeactivateYourConfederiaAccountAsAResultYourProfileAsWellAsYourEvaluationsWillNoLongerBeVisibleToThePublicHoweverYourDataWillStillBeSavedSoYouCanReactivateYourAccountAtAnyTime') }}
          </p>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="primary"
                 large
                 text
                 v-on:click="cancel">
            <v-icon color="accent"
                    left>
              mdi-close
            </v-icon>
            <span>{{ $t('cancel') }}</span>
          </v-btn>
          <v-spacer />
          <v-btn color="deep-orange"
                 dark
                 depressed
                 large
                 v-on:click="confirm">
            <span>{{ $t('disable') }}</span>
            <v-icon color="white"
                    right>
              mdi-check
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data: () => ({
      resolve: null
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        this.$emit('input', false)
        this.resolve(true)
      },
      async open(resolve) {
        this.resolve = resolve
        this.$emit('input', true)
      }
    },
    name: 'DisableAccountDialog',
    props: {
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "disable": "Deaktivieren",
      "disableAccount": "Konto deaktivieren",
      "youAreAboutToDeactivateYourConfederiaAccountAsAResultYourProfileAsWellAsYourEvaluationsWillNoLongerBeVisibleToThePublicHoweverYourDataWillStillBeSavedSoYouCanReactivateYourAccountAtAnyTime": "Sie sind dabei Ihre Confederia-Konto zu deaktiveren. Dies hat zur Folge, dass Ihr Profil sowie Ihre Bewertungen nicht mehr öffentlich sichtbar sind. Ihre Daten bleiben jedoch weiterhin gespeichert, so dass Sie Ihr Konto jederzeit wieder aktivieren können."
    },
    "en": {
      "cancel": "Cancel",
      "disable": "Disable",
      "disableAccount": "Disable account",
      "youAreAboutToDeactivateYourConfederiaAccountAsAResultYourProfileAsWellAsYourEvaluationsWillNoLongerBeVisibleToThePublicHoweverYourDataWillStillBeSavedSoYouCanReactivateYourAccountAtAnyTime": "You are about to deactivate your Confederia account. As a result, your profile as well as your evaluations will no longer be visible to the public. However, your data will still be saved, so you can reactivate your account at any time."
    }
  }
</i18n>