import SignInCallback from '@/views/common/SignInCallback.vue'
import SignOutCallback from '@/views/common/SignOutCallback.vue'

export default [
  {
    component: SignInCallback,
    name: 'sign-in-callback',
    path: '/sign-in-callback'
  },
  {
    component: SignOutCallback,
    name: 'sign-out-callback',
    path: '/sign-out-callback'
  }
]