<template>
  <!--<v-btn color="primary"
         exact
         v-bind:depressed="!secondary && $vuetify.breakpoint.mdAndUp"
         v-bind:icon="!$vuetify.breakpoint.mdAndUp"
         v-bind:text="secondary && $vuetify.breakpoint.mdAndUp"
         v-bind:to="to"
         v-on:click="$emit('click')">
    <v-icon v-bind:color="secondary ? 'primary' : 'accent'"
            v-bind:left="$vuetify.breakpoint.mdAndUp"
            v-if="!right">
      {{ icon }}
    </v-icon>
    <span v-if="$vuetify.breakpoint.mdAndUp">{{ label }}</span>
    <v-icon v-bind:color="secondary ? 'primary' : 'accent'"
            v-bind:right="$vuetify.breakpoint.mdAndUp"
            v-if="right">
      {{ icon }}
    </v-icon>
  </v-btn>-->
  <v-btn color="primary"
         exact
         depressed
         text
         v-bind:to="to"
         v-on:click="$emit('click')">
    <v-icon v-bind:color="secondary ? 'primary' : 'accent'"
            left
            v-if="!right">
      {{ icon }}
    </v-icon>
    <span>{{ label }}</span>
    <v-icon v-bind:color="secondary ? 'primary' : 'accent'"
            right
            v-if="right">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'HeaderButton',
    props: {
      icon: String,
      label: String,
      right: Boolean,
      secondary: Boolean,
      to: Object
    }
  }
</script>