<template>
  <v-card outlined>
    <v-toolbar color="primary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-inner-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
          <v-col>
            <v-autocomplete clearable
                            flat
                            hide-details
                            multiple
                            prepend-inner-icon="mdi-city"
                            solo
                            v-bind:items="postalCodeItems"
                            v-bind:label="$t('city')"
                            v-model="postalCodeFilter" />
          </v-col>
          <v-col>
            <v-select clearable
                      flat
                      hide-details
                      multiple
                      prepend-inner-icon="mdi-tag"
                      solo
                      v-bind:items="consultantStates | vueI18nItems({ text: 'label' })"
                      v-bind:label="$t('state')"
                      v-model="stateFilter" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('consultants') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 25, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredConsultants"
                  v-bind:items-per-page="50"
                  v-bind:options="{ sortBy: [ 'familyName', 'givenName' ] }"
                  v-on:click:row="$emit('click:row', $event)">
      <template v-slot:[`item.city`]="{ item }">
        {{ item.postalCode }} {{ item.city }}
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-icon v-bind:color="getStateColor(item.state)">mdi-circle</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      postalCodeItems() {
        return [...new Set(this.consultants.filter(c => c.postalCode != null && c.city != null).map(c => ({
          text: `${c.postalCode} ${c.city}`,
          value: c.postalCode
        })))]
      },
      filteredConsultants() {
        let filteredConsultants = this.consultants

        if (this.search != null && this.search.length !== 0) {
          filteredConsultants = filteredConsultants.filter(f => `${f.id} ${f.givenName} ${f.familyName} ${f.companyName}`.toLowerCase().includes(this.search.toLowerCase()))
        }

        if (this.postalCodeFilter != null && this.postalCodeFilter.length !== 0) {
          filteredConsultants = filteredConsultants.filter(f => this.postalCodeFilter.includes(f.postalCode))
        }

        if (this.stateFilter != null && this.stateFilter.length !== 0) {
          filteredConsultants = filteredConsultants.filter(f => this.stateFilter.includes(f.state))
        }

        return filteredConsultants
      },
      headers() {
        return [
          {
            text: this.$t('state'),
            value: 'state',
            width: 96
          },
          {
            text: this.$t('number'),
            value: 'id'
          },
          {
            text: this.$t('givenName'),
            value: 'givenName'
          },
          {
            text: this.$t('familyName'),
            value: 'familyName'
          },
          {
            text: this.$t('email'),
            value: 'email'
          },
          {
            text: this.$t('city'),
            value: 'city'
          },
          {
            text: this.$t('company'),
            value: 'company.name'
          }
        ]
      },
      ...mapGetters({
        consultantStates: 'local/consultantStates'
      })
    },
    data: () => ({
      postalCodeFilter: null,
      search: null,
      stateFilter: null
    }),
    methods: {
      getStateColor(state) {
        if (state === 'active') {
          return 'lime'
        } else if (state === 'unclaimed') {
          return 'grey'
        }
      }
    },
    name: 'ConsultantTableCard',
    props: {
      consultants: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "city": "Ort",
      "company": "Unternehmen",
      "consultants": "Berater",
      "email": "E-Mail-Adresse",
      "familyName": "Nachname",
      "givenName": "Vorname",
      "number": "Nummer",
      "search": "Suche",
      "state": "Status"
    },
    "en": {
      "city": "City",
      "company": "Company",
      "consultants": "Consultants",
      "email": "Email",
      "familyName": "Family name",
      "givenName": "Given name",
      "number": "Number",
      "search": "Search",
      "state": "State"
    }
  }
</i18n>