import AboutUsView from '@/views/base/AboutUsView.vue'
import PrimaryNavigationView from '@/views/v2/base/navigation/PrimaryNavigationView.vue'
import CompaniesView from '@/views/v2/base/CompaniesView.vue'
import CompanyView from '@/views/v2/base/CompanyView.vue'
import ConsultantsView from '@/views/v2/base/ConsultantsView.vue'
import ConsultantView from '@/views/v2/base/ConsultantView.vue'
import EvaluationView from '@/views/v2/base/EvaluationView.vue'
import ForConsultantsView from '@/views/base/ForConsultantsView.vue'
import HomeView from '@/views/base/HomeView.vue'
import ImprintView from '@/views/v2/base/ImprintView.vue'
import PrivacyPolicyView from '@/views/v2/base/PrivacyPolicyView.vue'
import TermsOfServiceView from '@/views/v2/base/TermsOfServiceView.vue'

export default [
  {
    components: {
      default: CompaniesView,
      navigation: PrimaryNavigationView
    },
    name: 'companies',
    path: '/companies'
  },
  {
    components: {
      default: CompanyView,
      navigation: PrimaryNavigationView
    },
    name: 'company',
    path: '/companies/:id'
  },
  {
    components: {
      default: ConsultantsView,
      navigation: PrimaryNavigationView
    },
    name: 'consultants',
    path: '/consultants'
  },
  {
    components: {
      default: ConsultantView,
      navigation: PrimaryNavigationView
    },
    name: 'consultant',
    path: '/consultants/:id'
  },
  {
    components: {
      default: EvaluationView,
      navigation: PrimaryNavigationView
    },
    name: 'evaluation',
    path: '/evaluation/:id'
  },
  {
    components: {
      default: ForConsultantsView,
      navigation: PrimaryNavigationView
    },
    name: 'for-consultants',
    path: '/for-consultants'
  },
  {
    components: {
      default: HomeView,
      navigation: PrimaryNavigationView
    },
    name: 'home',
    path: '/'
  },
  {
    components: {
      default: AboutUsView,
      navigation: PrimaryNavigationView
    },
    name: 'about-us',
    path: '/about-us'
  },
  {
    components: {
      default: ImprintView,
      navigation: PrimaryNavigationView
    },
    name: 'imprint',
    path: '/imprint'
  },
  {
    components: {
      default: PrivacyPolicyView,
      navigation: PrimaryNavigationView
    },
    name: 'privacy-policy',
    path: '/privacy-policy'
  },
  {
    components: {
      default: TermsOfServiceView,
      navigation: PrimaryNavigationView
    },
    name: 'terms-of-service',
    path: '/terms-of-service'
  }
]