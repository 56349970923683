<template>
  <v-card-text class="pa-6">
    <p class="question-text text-h6 text-center">{{ questionDefinition.text }}</p>
    <p class="text-body-1 text-center">{{ questionDefinition.description }}</p>
    <div class="text-center">
      <v-item-group v-model="model[questionDefinition.id]"
                    v-on:change="next">
        <v-item active-class="primary white--text"
                v-bind:value="1"
                v-slot="{ active, toggle }">
          <v-btn block
                 depressed
                 class="mb-2"
                 v-on:click="toggle"
                 x-large>
            <v-icon>{{ active ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}</v-icon>
          </v-btn>
        </v-item>
        <v-item active-class="primary white--text"
                v-bind:value="0"
                v-slot="{ active, toggle }">
          <v-btn block
                 depressed
                 v-on:click="toggle"
                 x-large>
            <v-icon>{{ active ? 'mdi-thumb-down' : 'mdi-thumb-down-outline' }}</v-icon>
          </v-btn>
        </v-item>
      </v-item-group>
      <div ref="hint">
        <v-expand-transition >
          <v-row justify="center">
            <v-col cols="12"
                  md="6"
                  sm="8">
              <p class="mb-0 mt-4"
                v-if="!hasPoints && !!questionDefinition.customerHint">
                {{ questionDefinition.customerHint}}
              </p>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-expand-transition>
          <p class="error--text mb-0 mt-4"
            v-if="error">
            {{ $t('global.pleaseAnswerThisQuestion') }}
          </p>
        </v-expand-transition>
      </div>
    </div>
  </v-card-text>
</template>

<script>
  export default {
    computed: {
      hasPoints() {
        return this.value != 0
      },
      value() {
        return this.model[this.questionDefinition.id]
      }
    },
    data: () => ({
      error: false
    }),
    methods: {
      next() {
        this.error = false

        if (this.hasPoints || !this.questionDefinition.customerHint) {
          this.$emit('next')
        } else {
          this.$vuetify.goTo(this.$refs.hint, {})
        }
      },
      validate() {
        if (!this.questionDefinition.required) {
          return true
        }

        if (this.value == null) {
          this.error = true

          return false
        }

        return true
      }
    },
    name: 'YesNoQuestion',
    props: {
      model: Object,
      questionDefinition: Object
    }
  }
</script>

<style>
  .question-text {
    min-height: 160px;
  }
</style>