export default {
  computed: {
    rules() {
      return {
        hasItems: value => value != null && value.length > 0 || this.$t('global.pleaseFillOutThisField'),
        maxLength: max => value => !value || value.length <= max ||  this.$t('global.mustBeLessThanCharacters', { max }),
        required: value => (value != null && value !== '') || this.$t('global.pleaseFillOutThisField'),
        true:  value => value === true || this.$t('global.pleaseFillOutThisField'),
        unique: (list, selector) => value => {
          if (!value) {
            return true
          }

          const found = list.find(i => selector(i) === value)

          return !found || this.$t('global.thisValueIsAlreadyInUse')
        }
      }
    }
  }
}