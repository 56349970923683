<template>
  <div>
    <v-card outlined>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <sales-agent-details ref="salesAgentDetails"
                           v-bind:sales-agent="salesAgent"/>
    </v-card>
  </div>
</template>

<script>
  import SalesAgentDetails from '@/components/administration/SalesAgentDetails'

  export default {
    components: {
      SalesAgentDetails
    },
    name: 'SalesAgentDetailsCard',
    methods: {
      validate() {
        return this.$refs.salesAgentDetails.validate()
      }
    },
    props: {
      salesAgent: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Stammdaten"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>