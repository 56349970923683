<template>
  <div>
    <v-card-text>
      <h4 class="mb-6 text-h4 primary--text">{{ $t('yourLanguages') }}</h4>
      <p class="mb-0">{{ $t('pleaseSelectTheLanguagesInWhichYouProvideConsultingServices') }}</p>
    </v-card-text>

    <v-card-text>
      <account-languages ref="accountLanguages"
                        v-bind:consultant="consultant" />
    </v-card-text>
  </div>
</template>

<script>
  import AccountLanguages from '@/components/account/AccountLanguages'

  export default {
    components: {
      AccountLanguages
    },
    methods: {
      validate() {
        return this.$refs.accountLanguages.validate()
      }
    },
    name: 'AccountLanguageStep',
    props: {
      consultant: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "pleaseSelectTheLanguagesInWhichYouProvideConsultingServices": "Wählen Sie bitte die Sprachen aus, in denen Sie Beratungsleistungen anbieten.",
      "yourLanguages": "Ihre Sprachen"
    },
    "en": {
      "pleaseSelectTheLanguagesInWhichYouProvideConsultingServices": "Please select the languages in which you provide consulting services.",
      "yourLanguages": "Your languages"
    }
  }
</i18n>