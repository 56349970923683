<template>
  <div>
    <v-menu min-width="auto"
            ref="menu"
            offset-y
            transition="scale-transition"
            v-bind:close-on-content-click="false"
            v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-bind="attrs"
                      v-bind:disabled="disabled"
                      v-bind:filled="filled"
                      v-bind:flat="flat"
                      v-bind:hide-details="hideDetails"
                      v-bind:label="label"
                      v-bind:prepend-inner-icon="prependInnerIcon"
                      v-bind:rules="rules"
                      v-bind:solo="solo"
                      v-mask="mask"
                      v-model="internalValue"
                      v-on="on"
                      v-on:change="change"
                      v-on:input="saveFromTextField"
                      validate-on-blur />
      </template>
      <v-date-picker color="primary"
                     header-color="secondary"
                     ref="datePicker"
                     v-bind:active-picker="activePicker"
                     v-bind:locale="$i18n.locale"
                     v-bind:value="normalizedValue"
                     v-on:change="saveFromDatePicker" />
    </v-menu>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    computed: {
      mask() {
        const format = moment.localeData(this.$i18n.locale).longDateFormat('L')

        return format.replace(/[DMY]/g, '#');
      },
      normalizedValue() {
        return this.value?.substring(0, 10)
      }
    },
    created() {
      this.internalValue = moment.utc(this.value).locale(this.$i18n.locale).format('L')
    },
    data: () => ({
      activePicker: null,
      internalValue: null,
      menu: false
    }),
    methods: {
      change() {
        if (this.value == null) {
          this.internalValue = null
        }
      },
      saveFromDatePicker(date) {
        if (date != null)
        {
          this.internalValue = moment.utc(date).locale(this.$i18n.locale).format('L')
        }

        this.$emit('input', date)
        this.menu = false
      },
      saveFromTextField(date) {
        let value = moment.utc(date, 'L', this.$i18n.locale, true).toISOString()

        this.$emit('input', value)
        this.menu = false
      },
    },
    name: 'DateField',
    props: {
      disabled: Boolean,
      filled: Boolean,
      flat: Boolean,
      hideDetails: String,
      label: String,
      prependInnerIcon: String,
      rules: Array,
      solo: Boolean,
      type: String,
      value: String
    },
    watch: {
      menu(val) {
        val && this.type === 'date-of-birth' && this.$nextTick(() => this.activePicker = 'YEAR')
      }
    }
  }
</script>