<template>
  <div>
    <v-snackbar color="accent"
                light
                right
                top
                v-model="confirmationSnackbar">
      {{ $t('membershipRequested') }}
    </v-snackbar>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />
    <div v-if="company != null">
      <company-header v-bind:allow-sharing="canShare"
                      v-bind:company="company"
                      v-bind:show-join="canJoin"
                      v-on:share="share"
                      v-on:join="linkConsultant(company.id, 'requestedByConsultant')">
      </company-header>
      <full-width-section color="transparent">
        <v-container>
          <v-row justify="center">
            <v-col cols="12"
                    md="6" xl="8">
              <h4 class="mb-6 text-h4 primary--text">{{ $t('introduction') }}</h4>
              <p>{{ company.introduction }}</p>
            </v-col>
          </v-row>
        </v-container>
      </full-width-section>

      <full-width-section color="secondary">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" xl="8">
              <h4 class="mb-6 text-h4 primary--text">{{ $t('ourConsultants') }}</h4>
          <consultant-grid v-bind:consultants="filteredConsultants" />
            </v-col>
          </v-row>
        
        </v-container>
      </full-width-section>

      <full-width-section color="secondary">
        <v-container>
          <v-row justify="center">
            <v-col cols="12" xl="8">
              <h4 class="mb-6 text-h4 primary--text">{{ $t('contactUs') }}</h4>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12"
                   md="4" xl="2"
                   order-md="2">
              <p v-if="!!company.phone">
                <a class="text-decoration-none"
                   v-bind:href="`tel:${company.phone}`">
                  <v-avatar class="mr-2"
                            color="primary">
                    <v-icon color="accent">mdi-phone</v-icon>
                  </v-avatar>
                  <span class="black--text">
                    {{ company.phone }}
                  </span>
                </a>
              </p>
              <p v-if="!!company.email">
                <a class="text-decoration-none"
                   v-bind:href="`mailto:${company.email}`">
                  <v-avatar class="mr-2"
                            color="primary">
                    <v-icon color="accent">mdi-email</v-icon>
                  </v-avatar>
                  <span class="black--text">
                    {{ company.email }}
                  </span>
                </a>
              </p>
              <p v-if="!!company.websiteUrl">
                <a class="text-decoration-none"
                   v-bind:href="company.websiteUrl">
                  <v-avatar class="mr-2"
                            color="primary">
                    <v-icon color="accent">mdi-web</v-icon>
                  </v-avatar>
                  <span class="black--text">
                    {{ company.websiteUrl }}
                  </span>
                </a>
              </p>
            </v-col>
            <v-col cols="12"
                   md="8" xl="6"
                   order-md="1">
              <contact-form v-on:send="sendEmail" />
            </v-col>
          </v-row>
        </v-container>
      </full-width-section>
      <full-width-section color="transparent">
        <v-container>
          <company-social-links centered
                                v-bind:company="company" />
        </v-container>
      </full-width-section>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import CompanyHeader from '@/components/v2/base/CompanyHeader.vue'
  import CompanySocialLinks from '@/components/v2/base/CompanySocialLinks.vue'
  import ConsultantGrid from '@/components/v2/base/ConsultantGrid.vue'
  import ContactForm from '@/components/v2/base/ContactForm.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      CompanyHeader,
      CompanySocialLinks,
      ConsultantGrid,
      ContactForm,
      FullWidthSection
    },
    computed: {
      filteredConsultants() {
        return this.company.consultants.filter(c => c.companyLinkState === 'accepted' || c.companyLinkState === 'administrator')
      },
      canShare() {
        return navigator.share !== undefined
      },
      canJoin() {
        return this.consultant?.companyLinkState === 'unlinked'
      },
      ...mapGetters({
        getCompany: 'base/company',
        email: 'auth/email',
        consultants: 'base/consultants'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      company: null,
      consultant: null,
      loading: false,
      confirmationSnackbar: false
    }),
    methods: {
      async linkConsultant(id, linkState) {
        const consultantId = this.consultants.find(c => c.email === this.email)?.id
        
        if (consultantId == null) {
          console.log('Consultant not found')
          return
        }

        console.log(consultantId)

        await this.doLinkCompanyConsultant({ id, consultantId, companyLinkState: linkState })

        this.confirmationSnackbar = true
      },
      async get(id) {
        this.loading = true

        await this.loadConsultants()
        await this.loadCompany({ id })
        await this.loadConsultingTopics()

        this.company = Object.assign({}, this.getCompany(id))

        this.consultant = this.consultants.find(consultant => consultant.email === this.email && (consultant.state === 'active' || consultant.state === 'disabled'))

        this.loading = false
      },
      async sendEmail(email) {
        // IMPRV: Refactor and move to backend
        let clientSideEmail = {
          recipientEmail: this.company.email,
          recipientName: this.company.name,
          templateName: 'DefaultText',
          values: {
            subject: `Kontaktanfrage von einem Interessenten ${email.name}`,
            headline: `${email.name} hat eine Frage an Sie!`,
            text: `Hallo ${this.company.name},<br><br>ein Interessent hat Ihnen über Confederia eine Frage zukommen lassen:<br><br>${email.text}<br><br>Name: ${email.name}<br>E-Mail-Adresse: ${email.email}`,
            closingText: 'Diese Nachricht wurde automatisch an Sie versandt. Bitte antworten Sie nicht auf diese E-Mail.<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      share() {
        // IMPRV: Refactor to component/service
        if (navigator.share !== undefined) {
          navigator.share({
            title: `${this.company.name} auf Confederia`,
            text: this.company.introduction,
            url: window.location.href
          })
        }
      },
      ...mapActions({
        createEmail: 'base/createEmail',
        loadCompany: 'base/loadCompany',
        loadConsultingTopics: 'base/loadConsultingTopics',
        loadConsultants: 'base/loadConsultants',
        doLinkCompanyConsultant: 'base/linkCompanyConsultant',
      })
    },
    name: 'CompanyView'
  }
</script>

<i18n>
  {
    "de": {
      "contactUs": "Kontaktieren Sie uns",
      "introduction": "Vorstellung",
      "ourConsultants": "Unsere Berater",
      "membershipRequested": "Beitritt beantragt."
    },
    "en": {
      "contactUs": "Contact us",
      "introduction": "Introduction",
      "ourConsultants": "Our consultants",
      "membershipRequested": "Membership requested."
    }
  }
</i18n>