<template>
  <div>
    <v-row align="center"
           class="mb-4"
           v-bind:key="index"
           v-for="(item, index) in items">
      <v-col cols="auto">
        <v-icon large
                v-bind:color="color">
          mdi-check-circle-outline
        </v-icon>
      </v-col>
      <v-col>
        {{ item }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'HighlightList',
  props: {
    color: String,
    items: Array
  }
}
</script>