<template>
  <div>
    <v-card outlined>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('introduction') }}</v-toolbar-title>
      </v-toolbar>
      <company-introduction ref="companyIntroduction"
                            v-bind:company="company" />
    </v-card>
  </div>
</template>

<script>
  import CompanyIntroduction from '@/components/v2/account/CompanyIntroduction'

  export default {
    components: {
      CompanyIntroduction
    },
    name: 'CompanyIntroductionCard',
    methods: {
      validate() {
        return this.$refs.companyIntroduction.validate()
      }
    },
    props: {
      company: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "introduction": "Vorstellung"
    },
    "en": {
      "introduction": "Introduction"
    }
  }
</i18n>