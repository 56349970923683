<template>
  <v-list nav>
    <v-list-item exact
                 link
                 v-bind:to="{ name: 'administration-dashboard' }">
      <v-list-item-icon>
        <v-icon color="accent">mdi-view-dashboard</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('dashboard') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item exact
                 link
                 v-bind:to="{ name: 'administration-companies' }">
      <v-list-item-icon>
        <v-icon color="accent">mdi-domain</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('companies') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item exact
                 link
                 v-bind:to="{ name: 'administration-consultants' }">
      <v-list-item-icon>
        <v-icon color="accent">mdi-account-tie</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('consultants') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: 'AdministrationNavigationView'
  }
</script>

<i18n>
  {
    "de": {
      "dashboard": "Dashboard",
      "companies": "Unternehmen",
      "consultants": "Berater"
    },
    "en": {
      "dashboard": "Dashboard",
      "companies": "Companies",
      "consultants": "Consultants"
    }
  }
</i18n>