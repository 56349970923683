import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

import '@fontsource/montserrat/100.css'
import '@fontsource/montserrat/200.css'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/montserrat/800.css'
import '@fontsource/montserrat/900.css'
import '@mdi/font/css/materialdesignicons.css'

import i18n from './i18n'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  },
  theme: {
    themes: {
      light: {
        accent: "#A5DDFF",
        background: colors.white,
        black: '#151F27',
        error: colors.deepOrange.base,
        primary: "#322B9B",
        secondary: "#F1ECE6",
        warning: colors.orange.darken1,
        white: '#FFFFFF'
      }
    }
  }
})