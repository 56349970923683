<template>
  <div>
    <v-expansion-panels flat class="ma-0 pa-0">
      <v-expansion-panel v-bind:key="index" class="ma-0 pa-0"
                         v-for="(faq, index) in faqs">
        <v-expansion-panel-header class="font-weight-bold primary--text pa-0"
                                  color="white">
          {{ faq.question }}
          <template v-slot:actions>
            <v-icon color="primary">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="white" class="special pa-0 ma-0">
          <p class="mt-8" v-html="faq.text"></p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

  export default {
    name: 'FaqDisplay',
    props: {
      faqs: Array
    }
  }
</script>

<style scoped>
div.v-expansion-panel-content.special div.v-expansion-panel-content__wrap {
  padding: 0 !important;
  margin: 0 !important;
}
</style>