export default class EmailService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(email, options) {
    const response = await fetch(`${this.baseUri}/email`, {
      body: JSON.stringify(email),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }

}