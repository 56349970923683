<template>
  <div>
    <v-form ref="form"
            v-if="!sent">
      <v-row>
        <v-col cols="12"
                md="6">
          <v-text-field flat
                        hide-details="auto"
                        solo
                        v-model="message.name"
                        v-bind:label="$t('yourName')"
                        v-bind:rules="[ rules.required ]" />
        </v-col>
        <v-col cols="12"
               md="6">
          <v-text-field flat
                        hide-details="auto"
                        solo
                        v-model="message.email"
                        v-bind:label="$t('yourEmail')"
                        v-bind:rules="[ rules.required ]" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea flat
                      hide-details="auto"
                      solo
                      v-model="message.text"
                      v-bind:label="$t('yourMessage')"
                      v-bind:rules="[ rules.required ]" />
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn color="primary"
                 dark
                 depressed
                 large
                 v-on:click="send">
            {{ $t('send') }}
            <v-icon color="accent"
                    right>
              mdi-send
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div v-else>
      <notification-with-icon icon="mdi-emoticon-excited-outline"
                              v-bind:text="!!successMessage ? successMessage : $t('wereDelightedThatYouveReachedOutYourMessageHasBeenSuccessfullyForwardedToTheConsultantTheyllBeExcitedToReceiveYourMessageAndWillGetBackToYouAsSoonAsPossibleSoKeepAnEyeOnYourInbox')"
                              v-bind:title="$t('greatYourMessageIsOnItsWay')" />
    </div>
  </div>
</template>

<script>
  import rules from '@/mixins/v2/rules'

  import NotificationWithIcon from '@/components/v2/base/NotificationWithIcon.vue'

  export default {
    components: {
      NotificationWithIcon
    },
    data: () => ({
      sent: false,
      message: {}
    }),
    methods: {
      send() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.sent = true

        this.$emit('send', this.message)
      }
    },
    mixins: [ rules ],
    name: 'ContactForm',
    props: {
      title: String,
      successMessage: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "greatYourMessageIsOnItsWay": "Toll, Ihre Nachricht ist auf dem Weg!",
      "send": "Senden",
      "wereDelightedThatYouveReachedOutYourMessageHasBeenSuccessfullyForwardedToTheConsultantTheyllBeExcitedToReceiveYourMessageAndWillGetBackToYouAsSoonAsPossibleSoKeepAnEyeOnYourInbox": "Wir freuen uns, dass Sie den Kontakt aufgenommen haben. Ihre Nachricht wurde erfolgreich an den Berater weitergeleitet. Dieser wird sich über Ihre Nachricht freuen und sich so schnell wie möglich bei Ihnen melden. Behalten Sie also Ihr Postfach im Auge.",
      "yourEmail": "Ihre E-Mail-Adresse",
      "yourMessage": "Ihre Nachricht",
      "yourName": "Ihr Name"
    },
    "en": {
      "greatYourMessageIsOnItsWay": "Great, your message is on its way!",
      "send": "Send",
      "wereDelightedThatYouveReachedOutYourMessageHasBeenSuccessfullyForwardedToTheConsultantTheyllBeExcitedToReceiveYourMessageAndWillGetBackToYouAsSoonAsPossibleSoKeepAnEyeOnYourInbox": "We're delighted that you've reached out. Your message has been successfully forwarded to the consultant. They'll be excited to receive your message and will get back to you as soon as possible. So, keep an eye on your inbox.",
      "yourEmail": "Your email",
      "yourMessage": "Your message",
      "yourName": "Your name"
    }
  }
</i18n>