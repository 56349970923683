<template>
  <v-card outlined>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
          <v-col>
            <v-autocomplete clearable
                            flat
                            hide-details
                            multiple
                            prepend-icon="mdi-city"
                            solo
                            v-bind:items="postalCodeItems"
                            v-bind:label="$t('city')"
                            v-model="postalCodeFilter" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('salesAgent') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredSalesAgents"
                  v-bind:items-per-page="50"
                  v-bind:loading="loading"
                  v-bind:options="{ sortBy: [ 'familyName', 'givenName' ] }"
                  v-on:click:row="goToItem">
      <template v-slot:[`item.city`]="{ item }">
        {{ item.postalCode }} {{ item.city }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    computed: {
      postalCodeItems() {
        return [...new Set(this.salesAgents.map(c => ({
          text: `${c.postalCode} ${c.city}`,
          value: c.postalCode
        })))]
      },
      filteredSalesAgents() {
        let filteredSalesAgents = this.salesAgents

        if (this.search != null && this.search.length !== 0) {
          filteredSalesAgents = filteredSalesAgents.filter(s => `${s.number} ${s.givenName} ${s.familyName} ${s.companyName}`.toLowerCase().includes(this.search.toLowerCase()))
        }

        if (this.postalCodeFilter != null && this.postalCodeFilter.length !== 0) {
          filteredSalesAgents = filteredSalesAgents.filter(s => this.postalCodeFilter.includes(s.postalCode))
        }

        return filteredSalesAgents
      },
      headers() {
        return [
          {
            text: this.$t('number'),
            value: 'number'
          },
          {
            text: this.$t('companyName'),
            value: 'companyName'
          },
          {
            text: this.$t('givenName'),
            value: 'givenName'
          },
          {
            text: this.$t('familyName'),
            value: 'familyName'
          },
          {
            text: this.$t('city'),
            value: 'city'
          }
        ]
      }
    },
    data: () => ({
      postalCodeFilter: null,
      search: null
    }),
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    name: 'SalesAgentTableCard',
    props: {
      itemRouteName: String,
      loading: Boolean,
      salesAgents: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "city": "Ort",
      "companyName": "Firma",
      "familyName": "Nachname",
      "givenName": "Vorname",
      "number": "Nummer",
      "salesAgent": "Verkäufer",
      "salesAgents": "Verkäufer",
      "search": "Suche",
      "supervisor": "Vorgesetzter"
    },
    "en": {
      "city": "City",
      "companyName": "Company name",
      "familyName": "Family name",
      "givenName": "Given name",
      "number": "Number",
      "salesAgent": "Sales agent",
      "salesAgents": "Sales agents",
      "search": "Search",
      "supervisor": "Supervisor"
    }
  }
</i18n>