<template>
  <v-app>
    <cookie-dialog ref="cookieDialog" v-model="cookieDialog" />
    <feedback-dialog ref="feedbackDialog" v-model="feedbackDialog" />

    <v-navigation-drawer
      app
      color="primary"
      dark
      right
      temporary
      v-model="navigationDrawer"
    >
      <router-view name="navigation" />
    </v-navigation-drawer>

    <v-app-bar
      fixed
      v-bind:app="!$vuetify.breakpoint.mdAndUp"
      v-bind:color="scrolled ? 'white' : 'transparent'"
      elevate-on-scroll
    >
      <v-container class="py-0 fill-height">
        <router-link v-bind:to="{ name: 'home' }">
          <img alt="Confederia" class="logo" src="/images/v2/logo_light.svg" />
        </router-link>

        <v-spacer />

        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn color="primary" exact text v-bind:to="{ name: 'home' }">
            {{ $t("find") }}
          </v-btn>

          <v-btn color="primary" text v-bind:to="{ name: 'for-consultants' }">
            {{ $t("forConsultants") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            v-if="activeRoute === 'home' || activeRoute === 'for-consultants'"
            v-on:click="scrollTo('faq')"
          >
            {{ $t("faq") }}
          </v-btn>
        </template>

        <locale-block
          color="primary"
          v-bind:locales="locales"
          v-bind:selected-locale="selectedLocale"
          v-on:set-locale="setLocale"
        />

        <account-block
          color="primary"
          v-bind:display-name="displayName"
          v-bind:email="email"
          v-bind:is-authenticated="isAuthenticated"
          v-bind:picture="picture"
          v-bind:show-sign-in="activeRoute === 'for-consultants'"
          v-on:sign-in="signIn"
          v-on:sign-out="signOut"
        >
          <v-list-item v-bind:to="{ name: 'account-profile' }">
            <v-list-item-avatar> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t("myProfile") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-bind:to="{ name: 'account-company' }"
            v-if="hasCompany"
          >
            <v-list-item-avatar> </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $t("myCompany") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-if="hasRole('ConfederiaAdministrator')">
            <v-divider />

            <v-list-item v-bind:to="{ name: 'administration-dashboard' }">
              <v-list-item-avatar> </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("administration")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-divider />
        </account-block>

        <v-app-bar-nav-icon
          color="primary"
          v-if="!$vuetify.breakpoint.mdAndUp || alwaysShowMenu"
          v-on:click="navigationDrawer = !navigationDrawer"
        />
      </v-container>
    </v-app-bar>

    <v-main>
      <v-btn
        bottom
        color="primary"
        fab
        fixed
        large
        right
        v-on:click="showFeedbackDialog"
      >
        <v-icon>mdi-chat-alert</v-icon>
      </v-btn>

      <router-view />

      <fat-footer />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import locales from "@/locales.json";

import CookieDialog from "@/components/common/CookieDialog.vue";
import FeedbackDialog from "@/components/v2/base/FeedbackDialog.vue";
import AccountBlock from "@/components/common/AccountBlock";
import LocaleBlock from "@/components/common/LocaleBlock";
import FatFooter from "@/components/v2/base/FatFooter";

export default {
  components: {
    AccountBlock,
    FatFooter,
    FeedbackDialog,
    LocaleBlock,
    CookieDialog,
  },
  async created() {
    if (localStorage.getItem("analyticCookiesEnabled")) {
      this.enableAnalytics();
      return;
    } else {
      setTimeout(async () => {
        await this.$refs.cookieDialog.open(async (r) => {
          if (!r) {
            return;
          }

          this.enableAnalytics();
        });
      }, 3000);
    }
  },
  computed: {
    activeRoute() {
      return this.$route?.name;
    },
    alwaysShowMenu() {
      const matched = this.$route.matched;

      if (!matched[0] || !matched[0].meta) return false;

      return matched[0].meta.alwaysShowMenu;
    },
    hasCompany() {
      return (
        this.consultants.find((c) => c.email === this.email)?.companyId !=
          null &&
        this.consultants.find((c) => c.email === this.email)
          ?.companyLinkState === "administrator"
      );
    },
    locales() {
      return locales.filter((l) => l.hasLocalization);
    },
    selectedLocale() {
      return this.$root.$i18n.locale;
    },
    ...mapGetters({
      consultants: "base/consultants",
      displayName: "auth/displayName",
      email: "auth/email",
      hasRole: "auth/hasRole",
      isAuthenticated: "auth/isAuthenticated",
      picture: "auth/picture",
    }),
  },
  data: () => ({
    feedbackDialog: false,
    navigationDrawer: null,
    scrolled: false,
    cookieDialog: false,
  }),
  methods: {
    enableAnalytics() {
      !(function () {
        "use strict";
        !(function (e, t) {
          var n = e.amplitude || { _q: [], _iq: {} };
          if (n.invoked)
            e.console &&
              console.error &&
              console.error("Amplitude snippet has been loaded.");
          else {
            var r = function (e, t) {
                e.prototype[t] = function () {
                  return (
                    this._q.push({
                      name: t,
                      args: Array.prototype.slice.call(arguments, 0),
                    }),
                    this
                  );
                };
              },
              s = function (e, t, n) {
                return function (r) {
                  e._q.push({
                    name: t,
                    args: Array.prototype.slice.call(n, 0),
                    resolve: r,
                  });
                };
              },
              o = function (e, t, n) {
                e[t] = function () {
                  if (n)
                    return {
                      promise: new Promise(
                        s(e, t, Array.prototype.slice.call(arguments))
                      ),
                    };
                };
              },
              i = function (e) {
                for (var t = 0; t < m.length; t++) o(e, m[t], !1);
                for (var n = 0; n < g.length; n++) o(e, g[n], !0);
              };
            n.invoked = !0;
            var u = t.createElement("script");
            (u.type = "text/javascript"),
              (u.integrity =
                "sha384-x0ik2D45ZDEEEpYpEuDpmj05fY91P7EOZkgdKmq4dKL/ZAVcufJ+nULFtGn0HIZE"),
              (u.crossOrigin = "anonymous"),
              (u.async = !0),
              (u.src =
                "https://cdn.amplitude.com/libs/analytics-browser-2.0.0-min.js.gz"),
              (u.onload = function () {
                e.amplitude.runQueuedFunctions ||
                  console.log("[Amplitude] Error: could not load SDK");
              });
            var a = t.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(u, a);
            for (
              var c = function () {
                  return (this._q = []), this;
                },
                p = [
                  "add",
                  "append",
                  "clearAll",
                  "prepend",
                  "set",
                  "setOnce",
                  "unset",
                  "preInsert",
                  "postInsert",
                  "remove",
                  "getUserProperties",
                ],
                l = 0;
              l < p.length;
              l++
            )
              r(c, p[l]);
            n.Identify = c;
            for (
              var d = function () {
                  return (this._q = []), this;
                },
                f = [
                  "getEventProperties",
                  "setProductId",
                  "setQuantity",
                  "setPrice",
                  "setRevenue",
                  "setRevenueType",
                  "setEventProperties",
                ],
                v = 0;
              v < f.length;
              v++
            )
              r(d, f[v]);
            n.Revenue = d;
            var m = [
                "getDeviceId",
                "setDeviceId",
                "getSessionId",
                "setSessionId",
                "getUserId",
                "setUserId",
                "setOptOut",
                "setTransport",
                "reset",
                "extendSession",
              ],
              g = [
                "init",
                "add",
                "remove",
                "track",
                "logEvent",
                "identify",
                "groupIdentify",
                "setGroup",
                "revenue",
                "flush",
              ];
            i(n),
              (n.createInstance = function (e) {
                return (n._iq[e] = { _q: [] }), i(n._iq[e]), n._iq[e];
              }),
              (e.amplitude = n);
          }
        })(window, document);
      })();

      amplitude.init("63e266a7dc33983702810626ae971bfa");

      (function () {
        const head = document.getElementsByTagName("head")[0];

        var myScript = document.createElement("script");

        myScript.setAttribute(
          "src",
          "https://www.googletagmanager.com/gtag/js?id=G-PYH94BVY1S"
        );

        myScript.onload = function () {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "G-PYH94BVY1S");
        };

        head.insertBefore(myScript, head.children[1]);
      })();
    },
    onScroll() {
      this.scrolled = window.scrollY !== 0;
    },
    setLocale({ localeId }) {
      this.$root.$i18n.locale = localeId;
    },
    scrollTo(name) {
      /*if (this.$route.path !== route) {
          this.$router.push(route + '#' + name)
          return
        }*/

      this.$vuetify.goTo(`#${name}`);
    },
    async sendFeedbackEmail(email) {
      // IMPRV: Move to backend
      let clientSideEmail = {
        recipientEmail: "feedback@confederia.ch",
        recipientName: "Confederia",
        templateName: "DefaultText",
        values: {
          subject: `Feedback zu Condederia`,
          headline: `Neues Feedback`,
          text: `Hallo,<br><br>Sie haben neues Feedback erhalten!<br><br>E-Mail (falls angemeldet): ${this.email}<br>Thema: ${email.topic}<br>Kommentar: ${email.comment}<br>Seite: ${this.activeRoute} (${window.location.href})<br>`,
          closingText:
            "Diese Nachricht wurde automatisch an Sie versandt. Bitte antworten Sie nicht auf diese E-Mail.<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team",
        },
      };

      await this.createEmail({ email: clientSideEmail });
    },
    async showFeedbackDialog() {
      await this.$refs.feedbackDialog.open(async (r) => {
        if (!r) {
          return;
        }

        this.loading = true;

        await this.sendFeedbackEmail(r);

        this.loading = false;
      });
    },
    ...mapActions({
      createEmail: "base/createEmail",
      signIn: "auth/signIn",
      signOut: "auth/signOut",
    }),
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll);
  },
  name: "App",
};
</script>

<style lang="scss">
@import "src/styles/main";

.logo {
  display: block;
  height: 40px;
}
</style>

<i18n>
  {
    "de": {
      "administration": "Administration",
      "createAccount": "Berater werden",
      "faq": "FAQ",
      "find": "Finden",
      "forConsultants": "Für Berater",
      "myCompany": "Mein Unternehmen",
      "myProfile": "Mein Profil"
    },
    "en": {
      "administration": "Administration",
      "createAccount": "Create account",
      "faq": "FAQ",
      "find": "Find",
      "forConsultants": "For consultants",
      "myCompany": "My company",
      "myProfile": "My profile"
    }
  }
</i18n>
