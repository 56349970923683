<template>
  <div>
    <v-dialog persistent
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-card-text class="pt-4">
          <h4 class="mb-6 text-h4 primary--text">{{ $t('inviteConsultant') }}</h4>
          <v-form ref="form">
            <v-row>
              <v-col cols="12"
                     md="6">
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.name"
                              v-bind:label="$t('yourName')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
              <v-col cols="12"
                     md="6">
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.email"
                              v-bind:label="$t('yourEmail')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
            </v-row>
            <v-row v-if="!hideConsultantDetails">
              <v-col cols="12"
                     md="6">
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.consultantName"
                              v-bind:label="$t('consultantName')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
              <v-col cols="12"
                     md="6">
                <v-text-field hide-details="auto"
                              outlined
                              v-model="message.consultantEmail"
                              v-bind:label="$t('consultantEmail')"
                              v-bind:rules="[ rules.required ]" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox class="mt-0"
                            hide-details="auto"
                            v-bind:rules="[ rules.required ]"
                            v-model="message.privacyPolicyAndtermsAndConditions">
                    <template v-slot:label>
                      <span @click.stop v-html="$t('iAgreeWithThePrivacyPolicyAndTheTermsAndConditions')"></span>
                    </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-btn color="primary"
                 large
                 text
                 v-on:click="cancel">
            <v-icon color="accent"
                    left>
              mdi-close
            </v-icon>
            <span>{{ $t('cancel') }}</span>
          </v-btn>
          <v-spacer />
          <v-btn color="primary"
                 depressed
                 large
                 v-on:click="confirm">
            <span>{{ $t('invite') }}</span>
            <v-icon color="accent"
                    right>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '@/mixins/v2/rules'

  export default {
    data: () => ({
      message: {},
      resolve: null
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        if (!this.$refs.form.validate()) {
          return
        }

        if (!!document.amplitude) {
          amplitude.track('Invite Consultant');
        } 
        

        this.$emit('input', false)
        this.$emit('confirm')
        this.resolve(this.message)
      },
      async open(resolve, message) {
        this.resolve = resolve
        this.message = message || {}
        this.$emit('input', true)
      }
    },
    mixins: [ rules ],
    name: 'InviteDialog',
    props: {
      hideConsultantDetails: Boolean,
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "consultantEmail": "E-Mail des Beraters",
      "consultantName": "Name des Beraters",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "Ich erkläre mich mit der <a target=\"_blank\" href=\"/privacy-policy\">Datenschutzerklärung</a> und den <a target=\"_blank\" href=\"/terms-of-service\">allgemeinen Geschäftsbedingungen (AGB)</a> einverstanden.",
      "invite": "Einladen",
      "inviteConsultant": "Berater einladen",
      "yourEmail": "Ihre E-Mail",
      "yourName": "Ihr Name"
    },
    "en": {
      "cancel": "Cancel",
      "consultantEmail": "Consultant's email",
      "consultantName": "Consultant's name",
      "iAgreeWithThePrivacyPolicyAndTheTermsAndConditions": "I agree with the <a target=\"_blank\" href=\"/privacy-policy\">privacy policy</a> and the <a target=\"_blank\" href=\"/terms-of-service\">terms and conditions</a>.",
      "invite": "Invite",
      "inviteConsultant": "Invite consultant",
      "yourEmail": "Your email",
      "yourName": "Your name"
    }
  }
</i18n>