<template>
  <v-card flat
          v-bind:color="color">
    <v-card-text v-bind:class="alignWithArrows ? 'px-12 py-6' : 'pa-6'">
      <v-icon class="mb-3"
              color="primary"
              size="48"
              v-if="!!icon">
        {{ icon }}
      </v-icon>
      <h6 class="black--text text-h6">{{ subtitle }}</h6>
      <h4 class="mb-6 primary--text text-h4">{{ title }}</h4>
      <p class="black--text mb-0 text-body-1">{{ text }}</p>
      <slot />
      <h6 class="primary--text mt-6 text-h6">{{ bottomTitle }}</h6>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'TextCard',
    props: {
      alignWithArrows: Boolean,
      bottomTitle: String,
      color: String,
      icon: String,
      subtitle: String,
      text: String,
      title: String
    },
  }
</script>