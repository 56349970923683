<template>
  <div>
    <v-item-group multiple
                  v-bind:value="value"
                  v-on:change="$emit('input', $event)">
      <v-row dense>
        <v-col cols="12"
               md="4"
               sm="6"
               v-bind:key="consultingTopic.id"
               v-for="consultingTopic in consultingTopics">
          <v-item v-bind:value="consultingTopic.id"
                  v-slot="{ active, toggle }">
            <v-card class="fill-height"
                    flat
                    v-bind:color="active ? 'primary' : 'secondary'"
                    v-on:click="toggle">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-card-text class="text-center"
                               v-bind="attrs"
                               v-on="on">
                    <v-avatar class="mb-3 white">
                      <img v-bind="attrs"
                   v-on="on"
                  class="icon-inner"
                  v-bind:src="`/images/v2/${consultingTopic.icon}-active.svg`" />
                    </v-avatar>
                    <p class="hyphenation mb-0"
                       v-bind:class="active ? 'white--text' : 'black--text'">
                      {{ consultingTopic.name }}
                    </p>
                  </v-card-text>
                </template>
                <span v-html="consultingTopic.description"></span>
              </v-tooltip>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>

    <v-expand-transition>
      <p class="error--text mb-0 mt-4"
        v-if="error">
        {{ $t('pleaseSelectAtLeastOneConsultingTopic') }}
      </p>
    </v-expand-transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        consultingTopics: 'base/consultingTopics'
      })
    },
    data: () => ({
      error: false
    }),
    methods: {
      validate() {
        if (this.value == null || this.value.length === 0) {
          this.error = true

          return false
        }

        return true
      }
    },
    name: 'ConsultingTopicSelect',
    props: {
      value: Array
    },
    watch: {
      value() {
        this.error = false
      }
    }
  }
</script>

<style>
.icon-inner {
  display: block;
  margin: 1rem;
  max-height: 28px;
}
</style>

<i18n>
  {
    "de": {
      "pleaseSelectAtLeastOneConsultingTopic": "Bitte wählen Sie mindestens ein Thema aus."
    },
    "en": {
      "pleaseSelectAtLeastOneConsultingTopic": "Please select at least one consulting topic."
    }
  }
</i18n>