<template>
  <div>
    <v-menu offset-y
            max-width="320"
            v-if="isAuthenticated">
      <template v-slot:activator="{ on }">
        <v-btn v-bind:color="color"
               v-bind:icon="!$vuetify.breakpoint.mdAndUp"
               v-bind:text="$vuetify.breakpoint.mdAndUp"
               v-on="on">
          <v-icon v-bind:left="$vuetify.breakpoint.mdAndUp">
            mdi-account
          </v-icon>
          <span class="font-weight-bold hidden-sm-and-down text-truncate">{{ displayName }}</span>
          <v-icon right
                  v-if="$vuetify.breakpoint.mdAndUp">
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-img v-bind:src="image" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ displayName }}</v-list-item-title>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <slot />

        <v-list-item v-on:click="$emit('sign-out')">
          <v-list-item-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="deep-orange--text">{{ $t('signOut') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn v-bind:color="color"
           v-bind:icon="$vuetify.breakpoint.smAndDown"
           v-bind:text="$vuetify.breakpoint.mdAndUp"
           v-else-if="showSignIn"
           v-bind:to="{ name: 'account-create' }">
      <v-icon v-bind:left="$vuetify.breakpoint.mdAndUp">
        mdi-login
      </v-icon>
      <span class="hidden-sm-and-down">{{ $t('signIn') }}</span>
    </v-btn>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      image() {
        const consultant = this.consultants.find(consultant => consultant.email === this.email && (consultant.state === 'active' || consultant.state === 'disabled'))

        if (!consultant) {
          return this.picture
        }

        return this.$options.filters.attachmentUrl(consultant.photoAttachment, { defaultUrl: this.picture })
      },
      ...mapGetters({
        consultants: 'base/consultants'
      })
    },
    name: 'AccountBlock',
    props: {
      color: String,
      displayName: String,
      email: String,
      isAuthenticated: Boolean,
      picture: String,
      showSignIn: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "signIn": "Anmelden",
      "signOut": "Abmelden"
    },
    "en": {
      "signIn": "Sign in",
      "signOut": "Sign out"
    }
  }
</i18n>