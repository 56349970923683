<template>
  <div>
    <corporate-request-dialog ref="corporateRequestDialog"
                              v-model="corporateRequestDialog" />

    <image-header variant="consultant">
      <h3 class="text-h3 mb-6 primary--text"><span class="d-none d-lg-block">Erfolg im Fokus:</span> Mit&nbsp;Qualität und Transparenz zur Spitze!<span class="font-weight-black primary--text"></span></h3>
      <h5 class="text-h6">Sammeln Sie Bewertungen für Ihre Beratungsleistung und steigern Sie das Vertrauen und Ihre Sichtbarkeit bei Ihren Kunden.</h5>
    </image-header>

    <v-container>
      <v-row justify="center">
        <v-col cols="12"
               xl="8">
          <text-card color="secondary"
                     v-bind:class="$vuetify.breakpoint.mdAndUp ? 'move-up' : null"
                     v-bind:subtitle="$t('setTheCourseForTheTransparencyLawNowVAGAndAVO')"
                     v-bind:text="null">
            <div class="mt-6 text-center">
              <v-btn color="primary"
                     dark
                     depressed
                     v-bind:to="{ name: 'account-create' }"
                     x-large>
                <v-icon color="accent"
                        left>
                  mdi-account-check
                </v-icon>
                <span>{{ $t('startFreeTrail') }}</span>
              </v-btn>
            </div>
          </text-card>
        </v-col>
      </v-row>
    </v-container>

    <full-width-section color="transparent">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 md="6">
            <h4 class="mb-2 text-center text-h4 primary--text">{{ $t('thePerfectPlatformForFinancialExperts') }}</h4>
            <h6 class="mb-12 text-center black--text text-h6">{{ $t('youAreAFinancialAdvisorAndWantToAcquireMoreCustomersThenYouAreInTheRightPlace') }}</h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
                 md="6">
            <text-card class="fill-height"
                       color="secondary"
                       icon="mdi-star-outline"
                       v-bind:text="$t('enhancingCustomerRetentionThroughFeedbackOptions')"
                       v-bind:title="$t('satisfiedAndLoyalCustomers')" />
          </v-col>
          <v-col cols="12"
                 md="6">
            <text-card class="fill-height"
                       color="secondary"
                       icon="mdi-eye-outline"
                       v-bind:text="$t('increaseReachThroughPresenceOnComparisonPlatforms')"
                       v-bind:title="$t('increasedVisibility')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
                 md="6">
            <text-card class="fill-height"
                       color="secondary"
                       icon="mdi-calendar-outline"
                       v-bind:text="$t('fastAndUncomplicatedAppointmentBookingWithCalenso')"
                       v-bind:title="$t('appointmentCalendar')" />
          </v-col>
          <v-col cols="12"
                 md="6">
            <text-card class="fill-height"
                       color="secondary"
                       icon="mdi-chart-line"
                       v-bind:text="$t('drawDataDrivenConclusions')"
                       v-bind:title="$t('analyses')" />
          </v-col>
        </v-row>
      </v-container>

    </full-width-section>

    <!--<full-width-section color="transparent">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 md="4">
            <h4 class="mb-6 text-h4 primary--text">Die Plattform für Finanzberater-Vergleiche</h4>
            <p>Sie sind ein Finanzberater auf der Suche nach neuen Kunden und möchten Ihre Dienstleistungen effektiver und erfolgreicher bewerben? Dann sind Sie hier genau richtig!</p>
            <p>Registrieren Sie sich noch heute als Nutzer unserer Plattform und profitieren Sie von einer Vielzahl an Vorteilen.</p>
            <p class="mb-0">Registrieren Sie sich jetzt als Nutzer unserer Plattform und steigern Sie Ihren Erfolg als Finanzberater! Wir bieten verschiedene Abonnements-Modelle an, die perfekt auf Ihre Bedürfnisse abgestimmt sind. Worauf warten Sie noch? Werden Sie jetzt Teil unserer Community!</p>
          </v-col>
          <v-col cols="12"
                 md="4">
            <h4 class="mb-6 text-h4 primary--text">{{ $t('yourAdvantages') }}</h4>
            <highlight-list color="accent"
                            v-bind:items="highlights" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>-->

    <full-width-section color="secondary"
                        id="pricing">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 xl="8">
            <pricing-table-card v-on:show-dialog="showCorporateRequestDialog" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>

    <full-width-section color="transparent"
                        id="faq">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 xl="8">
            <v-icon class="mb-2"
                    color="primary"
                    size="64">
              mdi-chat-outline
            </v-icon>
            <h4 class="mb-6 text-h4 primary--text">{{ $t('whatWeAreOftenAsked') }}</h4>
            <faq-display v-bind:faqs="faqs" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>

    <full-width-section color="secondary"
                        id="contact">
      <v-container>
        <v-row justify="center">
          <v-col cols="12"
                 xl="8">
            <h4 class="mb-6 text-h4 primary--text">{{ $t('contactUs') }}</h4>
            <contact-form v-bind:success-message="$t('weArePleasedThatYouHaveContactedUsWeWillRespondToYourMessageAsSoonAsPossible')"
                          v-on:send="sendEmail" />
          </v-col>
        </v-row>
      </v-container>
    </full-width-section>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import CorporateRequestDialog from '@/components/v2/base/CorporateRequestDialog.vue'
  import FullWidthSection from '@/components/v2/base/FullWidthSection.vue'
  import HighlightList from '@/components/v2/base/HighlightList.vue'
  import ImageHeader from '@/components/v2/base/ImageHeader.vue'
  import PricingTableCard from '@/components/v2/base/PricingTableCard.vue'
  import TextCard from '@/components/v2/base/TextCard.vue'

  import PlanSelect from '@/components/account/PlanSelect'
  import ContactForm from '@/components/v2/base/ContactForm.vue'
  import FaqDisplay from '@/components/base/FaqDisplay.vue'

  export default {
    components: {
      CorporateRequestDialog,
      FullWidthSection,
      HighlightList,
      ImageHeader,
      PricingTableCard,
      TextCard,

      PlanSelect,

      ContactForm,
      FaqDisplay
    },
    computed: {
      getPrices() {
        if (this.priceModel === "individual") {
          return this.prices
        } else {
          return this.pricesTeam
        }

      },
      highlights() {
        return [
          'Finden Sie neue Kunden: Unsere Plattform verbindet Sie mit potenziellen Kunden, die auf der Suche nach einem zuverlässigen und kompetenten Finanzberater sind.',
          'Erweitern Sie Ihre Reichweite: Durch unsere gezielten Marketing-Kampagnen und unsere hohe Sichtbarkeit im Internet, können Sie Ihre Dienstleistungen einem breiten Publikum präsentieren.',
          'Steigern Sie Ihre Glaubwürdigkeit: Durch die Teilnahme an unserer Plattform, zeigen Sie potenziellen Kunden, dass Sie ein seriöser und zuverlässiger Finanzberater sind.',
          'Verbessern Sie Ihre Bewertungen: Unsere Plattform ermöglicht es Kunden, Bewertungen und Feedback zu hinterlassen. Durch eine hohe Anzahl an positiven Bewertungen, können Sie Ihr Ansehen und Ihre Glaubwürdigkeit steigern.'
        ]
      },
      infoCards() {
          return [
            {
              id: 1,
              title: 'Konto erstellen',
              subTitle: 'Erstellen Sie ein Beraterkonto zu unseren attraktiven Konditionen',
              text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
              icon: 'mdi-numeric-1-circle-outline'
            },
            {
              id: 2,
              title: 'Profil anpassen',
              subTitle: 'Füllen Sie Ihr Profil aus und fügen Sie Bilder und Videos hinzi',
              text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
              icon: 'mdi-numeric-2-circle-outline'
            },
            {
              id: 3,
              title: 'Bewertungen einholen',
              subTitle: 'Lassen Sie sich von Ihren Kunden objektiv bewerten',
              text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
              icon: 'mdi-numeric-3-circle-outline'
            },
            {
              id: 4,
              title: 'Kunden gewinnen',
              subTitle: 'Gewinnen Sie neukunden durch eine vertrauenswürdige Bewertung',
              text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
              icon: 'mdi-numeric-4-circle-outline'
            }
          ]
        }
    },
    data: () => ({
      corporateRequestDialog: false,
      priceModel: null,

      pricesTeam: [
        {
          id: 1,
          color: 'accent',
          callToAction: 'Jetzt anfragen',
          name: 'Team Plan 1',
          features: [
            { name: 'Beraterprofil' },
            { name: 'unlimitierte Anfragen' },
            { name: 'Kundenbewertungen mit Verifizierung' },
            { name: 'Kommentarfunktion' }
          ],
          value: 129
        },
        {
          id: 2,
          color: 'accent',
          callToAction: 'Jetzt anfragen',
          highlight: true,
          name: 'Team Plan 2',
          features: [
            { name: 'Beraterprofil' },
            { name: 'unlimitierte Anfragen' },
            { name: 'Vorstellungsvideo' },
            { name: 'Kundenbewertungen mit Verifizierung' },
            { name: 'Kommentarfunktion' },
            { name: 'Reaktionszeit vor Veröffentlichung einer Bewertung < 4/5 Sternen: 1 Woche' },
            { name: 'Aufnahme in die öffentliche Rangliste' },
            { name: 'Statistiken und Auswertungen' }
          ],
          value: 169
        },
        {
          id: 3,
          color: 'accent',
          callToAction: 'Jetzt anfragen',
          highlight: false,
          name: 'Team Plan 3',
          features: [
            { name: 'Beraterprofil' },
            { name: 'unlimitierte Anfragen' },
            { name: 'Vorstellungsvideo' },
            { name: 'Kundenbewertungen mit Verifizierung' },
            { name: 'Kommentarfunktion' },
            { name: 'Reaktionszeit vor Veröffentlichung einer Bewertung < 4/5 Sternen: 1 Woche' },
            { name: 'Aufnahme in die öffentliche Rangliste' },
            { name: 'Statistiken und Auswertungen' },
            { name: 'Support mit Priorität' },
            { name: 'Begleitetes Onboarding' },
            { name: 'White-Label-Lösung' }
          ],
          value: 299
        }
      ],
      steps: [
        {
          number: '1',
          icon: 'mdi-magnify',
          text: 'Berater finden'
        },
        {
          number: '2',
          icon: 'mdi-calendar',
          text: 'Beratungstermin vereinbaren'
        },
        {
          number: '3',
          icon: 'mdi-star-outline',
          text: 'Bewertung abgeben'
        }
      ],
      faqs: [
        {
          id: 1,
          question: 'Wie profitiere ich von einem Profil bei Confederia?',
          text: 'Ein Profil bei Confederia erweitert Ihre Reichweite und ermöglicht es Ihnen, noch mehr Kundentermine zu erhalten. Neben der Kundengewinnung können Sie mit dem Service von Confederia auch die Zufriedenheit Ihrer bestehenden Kunden steigern, die Kundenbindung stärken und von Empfehlungsmarketing profitieren. Darüber hinaus dienen Ihre Bewertungen als Auszeichnung und Sie können stolz das Confederia Siegel auf Ihrer Online-Präsenz, Visitenkarten, E-Mail-Signatur und anderen geeigneten Plattformen verwenden. Mit Confederia heben Sie sich von anderen Beratern ab und erhöhen deutlich Ihre Vertrauenswürdigkeit.'
        },
        {
          id: 2,
          question: 'Was kostet mich ein Confederia Profil?',
          text: 'Bei der Nutzung von Confederia kann zwischen drei Plänen gewählt werden: Essential, Professional und Corporate. Wir empfehlen Ihnen, zunächst sorgfältig Ihre Bedürfnisse oder die Ihres Unternehmens zu prüfen und mit den Funktionen jedes Plans abzugleichen, ehe Sie sich für ein Preismodell entscheiden. Nutzen Sie die 14-tägige kostenlose Testversion von Confederia, um die Plattform auszuprobieren, bevor Sie eine Entscheidung treffen. Die genaue Preisstaffelung finden Sie bei den Plänen. Good to know: Sie brauchen nur einen neuen Kundenabschluss im Jahr, und die Kosten für Ihr Confederia Profil haben sich bereits amortisiert.'
        },
        {
          id: 3,
          question: 'Wie kann ich mein Confederia Profil kündigen?',
          text: 'Sie können Ihr Confederia Profil jederzeit auf Ende des Monats deaktivieren. Ihre Bewertungen bleiben erhalten, sind jedoch nicht mehr für potenzielle Kunden sichtbar. Sollten Sie sich umentscheiden, ist eine Reaktivierung jederzeit möglich.'
        },
        {
          id: 4,
          question: 'Welche Voraussetzung muss ich für ein Confederia Profil erfüllen?',
          text: 'Sind Sie ein Berater oder eine Beraterin im Bereich Versicherung, Immobilien, Banken, Treuhand oder Steuern? Dann erfüllen Sie alle Voraussetzungen, um mit Confederia loslegen zu können.'
        },
        {
          id: 5,
          question: 'Wie gehe ich mit einer negativen Bewertung um?',
          text: 'Ein Kunde hat Ihnen eine schlechte Bewertung gegeben? Keine Sorge - diese wird nicht gleich veröffentlicht. Sie haben eine Woche Zeit, sich nochmal bei Ihrem Kunden oder ihrer Kundin zu melden und etwaige noch offene Themen zu klären. Ihr Kunde soll dann hoffentlich die abgegebene Bewertung in eine positive Rezension umwandeln. Studien belegen, dass durch die Reaktion auf eine negative Bewertung Ihre Chancen auf die Rückgewinnung, oder sogar den Abschluss zusätzlicher Produkte, immens erhöht wird. Eine negative Bewertung ist also Ihre Chance auf eine noch bessere Kundenbindung.'
        },
        {
          id: 6,
          question: 'Welche Tool-Pakete gibt es bei Confederia?',
          text: 'Je nach Preisplan haben Sie die Möglichkeit, Ihren Auftritt auf Confederia zu gestalten. Sie können sich zwischen dem Essential- und Professional Paket entscheiden und erhalten somit Zugriff auf viele Funktionen, die Sie bei der Kundengewinnung unterstützen. Der Essential Plan bietet Ihnen neben Ihrem Beraterprofil auch unlimitierte Kundenanfragen. Sie können Ihre Bewertungen verifizieren lassen und Bewertungen kommentieren. Mit dem Professional Plan haben Sie zusätzlich die Möglichkeit, mit dem integrierten Terminierungstool von Calenso Kundentermine zu vereinbaren. Sie können sich ihre Statistiken und Auswertungen auslesen lassen und werden in die öffentliche Rangliste aufgenommen.'
        },
        {
          id: 7,
          question: 'Welche Zahlungsmethoden werden akzeptiert?',
          text: 'Es gibt unterschiedliche Zahlungsmöglichkeiten für Confederia, einschließlich aller gängigen Kreditkarten, Debitkarten, Mastercard und Visa.'
        },
        {
          id: 8,
          question: 'Wie läuft die Beta-Phase ab?',
          text: 'Die Beta-Phase erstreckt sich vom 04.12.2023 bis zum 31.03.2024. Während dieses Zeitraums haben ausgewählte Berater die Möglichkeit, den Professional Plan zu testen. Nach Abschluss der Beta-Phase können Sie beim Professional Plan bleiben oder Sie wechseln zum Essential-Plan.'
        },
        {
          id: 9,
          question: 'Wie lade ich meinen Kunden zu einer Bewertung ein?',
          text: 'Nach einer kürzlich durchgeführten Beratung mit einem Neukunden besteht die Möglichkeit, diesen im Anschluss persönlich dazu einzuladen, eine Bewertung abzugeben. Hierfür können Sie bequem Ihren individuellen Beratungslink bereitstellen. Bei Bestandskunden bietet sich hier eine Gelegenheit an, um erneut in Kontakt zu treten und ihnen den Link zur Verfügung zu stellen, damit sie ihre bisherige Erfahrung mit Ihnen bewerten können. Ihren persönlichen Link finden Sie auf der Startseite Ihres Profils.'
        }
      ]
    }),
    methods: {
      async sendEmail(email) {
        // IMPRV: Refactor and move to backend
        let clientSideEmail = {
          recipientEmail: 'info@confederia.ch',
          recipientName: 'Confederia',
          templateName: 'DefaultText',
          values: {
            subject: `Anfrage von ${email.name}`,
            headline: `${email.name} hat eine Nachricht gesendet!`,
            text: `Hallo,<br><br>ein Interessent hat Ihnen über das Kontaktformular (Beraterseite) eine Frage zukommen lassen:<br><br>${email.text}<br><br>Name: ${email.name}<br>E-Mail-Adresse: ${email.email}`,
            closingText: 'Diese Nachricht wurde automatisch an Sie versandt. Bitte antworten Sie nicht auf diese E-Mail.<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      async sendExtendedEmail(email) {
        // IMPRV: Refactor and move to backend
        let clientSideEmail = {
          recipientEmail: 'christian.sommerfeld@confederia.ch',
          recipientName: 'Confederia',
          templateName: 'DefaultText',
          values: {
            subject: `Corporate-Anfrage von ${email.givenName} ${email.familyName} (${email.company})`,
            headline: `${email.company} hat eine Nachricht gesendet!`,
            text: `Hallo,<br><br>ein Interessent hat Ihnen über das Kontaktformular (Corporate-Anfrage) eine Frage zukommen lassen:<br><br>${email.comment}<br><br>Name: ${email.givenName} ${email.familyName}<br>Firma: ${email.company}<br>Telefonnummer: ${email.phone}<br>Anzahl Beraterprofile: ${email.consultantsCount}<br>Sprache: ${email.language}<br>E-Mail-Adresse: ${email.email}`,
            closingText: 'Diese Nachricht wurde automatisch an Sie versandt. Bitte antworten Sie nicht auf diese E-Mail.<br><br>Mit freundlichen Grüßen<br>Ihr Confederia-Team'
          }
        }

        await this.createEmail({ email: clientSideEmail })
      },
      async showCorporateRequestDialog() {
        await this.$refs.corporateRequestDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.sendExtendedEmail(r)

          this.loading = false
        })
      },
      ...mapActions({
        createEmail: 'base/createEmail'
      })
    },
    name: 'ForConsultantsView'
  }
</script>

<style>
  .move-up {
    margin-top: -80px;
  }
</style>

<i18n>
  {
    "de": {
      "contactUs": "Kontaktieren Sie uns",
      "startFreeTrail": "Beta-Tester werden",
      "weArePleasedThatYouHaveContactedUsWeWillRespondToYourMessageAsSoonAsPossible": "Wir freuen uns, dass Sie uns kontaktiert haben. Wir werden uns so schnell wie möglich bei Ihnen melden.",
      "whatWeAreOftenAsked": "Was wir oft gefragt werden",
      "yourAdvantages": "Ihre Vorteile",
      "thePerfectPlatformForFinancialExperts": "Die perfekte Plattform für Finanzexperten",
      "youAreAFinancialAdvisorAndWantToAcquireMoreCustomersThenYouAreInTheRightPlace": "Sie sind Finanzberater und möchten mehr Kunden gewinnen? Dann sind Sie hier genau richtig!",
      "satisfiedAndLoyalCustomers": "Zufriedene und treue Kunden",
      "increasedVisibility": "Mehr Sichtbarkeit",
      "appointmentCalendar": "Terminkalender",
      "analyses": "Auswertungen",
      "enhancingCustomerRetentionThroughFeedbackOptions": "Stärkung der Kundenbindung durch interne Feedbackmöglichkeiten",
      "increaseReachThroughPresenceOnComparisonPlatforms": "Höhere Reichweite durch Ihren persönlichen Auftritt",
      "fastAndUncomplicatedAppointmentBookingWithCalenso": "Schnelle und unkomplizierte Terminbuchung mit Calenso",
      "drawDataDrivenConclusions": "Datenbasierte Rückschlüsse für Ihren Vertrieb ziehen",
      "setTheCourseForTheTransparencyLawNowVAGAndAVO": "Stellen Sie jetzt die Weichen für das Transparenzgesetz (VAG und AVO)",
      "beOneCrucialStepAheadAsABetaTester": "Seien Sie als Beta-Tester den entscheidenden Schritt voraus."
    },
    "en": {
      "contactUs": "Contact us",
      "startFreeTrail": "Start free trial",
      "weArePleasedThatYouHaveContactedUsWeWillRespondToYourMessageAsSoonAsPossible": "We are pleased that you have contacted us. We will respond to your message as soon as possible.",
      "whatWeAreOftenAsked": "What we are often asked",
      "yourAdvantages": "Your advantages",
      "thePerfectPlatformForFinancialExperts": "The perfect platform for financial experts",
      "youAreAFinancialAdvisorAndWantToAcquireMoreCustomersThenYouAreInTheRightPlace": "You are a financial advisor and want to acquire more customers? Then you are in the right place!",
      "satisfiedAndLoyalCustomers": "Satisfied and loyal customers",
      "increasedVisibility": "Increased visibility",
      "appointmentCalendar": "Appointment calendar",
      "analyses": "Analyses",
      "enhancingCustomerRetentionThroughFeedbackOptions": "Enhancing customer retention through feedback options",
      "increaseReachThroughPresenceOnComparisonPlatforms": "Increase reach through presence on comparison platforms",
      "fastAndUncomplicatedAppointmentBookingWithCalenso": "Fast and uncomplicated appointment booking with Calenso",
      "drawDataDrivenConclusions": "Draw data-driven conclusions",
      "setTheCourseForTheTransparencyLawNowVAGAndAVO": "Set the course for the Transparency Law now (VAG and AVO).",
      "beOneCrucialStepAheadAsABetaTester": "Be one crucial step ahead as a beta tester."
    }
  }
</i18n>