<template>
  <div>
    <consulting-topic-select ref="consultingTopicSelect"
                             v-model="consultant.consultingTopicIds" />
  </div>
</template>

<script>
  import ConsultingTopicSelect from '@/components/v2/account/ConsultingTopicSelect'

  export default {
    components: {
      ConsultingTopicSelect
    },
    methods: {
      validate() {
        return this.$refs.consultingTopicSelect.validate()
      }
    },
    name: 'AccountConsultingTopics',
    props: {
      consultant: Object
    }
  }
</script>