<template>
  <div>
    <v-card-text>
      <h4 class="mb-6 text-h4 primary--text">{{ $t('profileSelection') }}</h4>
      <p class="mb-0">{{ $t('pleaseEnterYourFullName') }}</p>
    </v-card-text>

    <account-claim ref="accountClaim"
                   v-bind:email="email"
                   v-on:next="$emit('next', $event)"
                   v-on:select-consultant="$emit('select-consultant', $event)" />
  </div>
</template>

<script>
  import AccountClaim from '@/components/v2/account/AccountClaim'

  export default {
    components: {
      AccountClaim
    },
    name: 'AccountClaimStep',
    props: {
      email: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "pleaseEnterYourFullName": "Bitte geben Sie Ihren vollständigen Namen ein.",
      "profileSelection": "Profilwahl"
    },
    "en": {
      "pleaseEnterYourFullName": "Please enter your full name.",
      "profileSelection": "Profile selection"
    }
  }
</i18n>