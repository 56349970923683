export default {
  actions: {
    async setCustomer({ commit }, { customer }) {
      commit('setCustomer', { customer })
    },
    async setQuestionnaireModel({ commit }, { questionnaireModel }) {
      commit('setQuestionnaireModel', { questionnaireModel })
    }
  },
  getters: {
    customer: state => state.customer,
    questionnaireModel: state => state.questionnaireModel,
  },
  mutations: {
    setCustomer(state, { customer }) {
      state.customer = customer
    },
    setQuestionnaireModel(state, { questionnaireModel }) {
      state.questionnaireModel = questionnaireModel
    }
  },
  namespaced: true,
  state: {
    customer: null,
    questionnaireModel: null
  }
}